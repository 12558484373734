import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../../localizationContext';
import PropTypes from 'prop-types';

import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import {DateTime} from "luxon";
import {DropzoneArea} from 'material-ui-dropzone';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import BankAccount from "../../../shared/parts/BankAccount";
import BirthDate from "../../../shared/parts/onboarding/BirthDate";
import PurifiedTypo from "../../../shared/parts/PurifiedTypo";
import StyledButton from "../../../shared/parts/StyledButton";
import StyledTextField from "../../../shared/parts/StyledTextField";
import StyledWideButton from "../../../shared/parts/StyledWideButton";
import Typography from "../../../shared/parts/Typography";

var he = require('he');

class EmissionStepProfileNewFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    ['state'].map(p => {
      if (this.props[p] !== prevProps[p]) {
        this.setState({...this.props[p]});
      }
      return null;
    });
  }

  check_field = (errors, field) => {
    if (typeof(this.state.user[field]) === 'undefined' || this.state.user[field] === null || this.state.user[field].toString().trim() === "" || (field === 'birth_date' && !DateTime.fromFormat(this.state.user[field], 'dd/MM/yyyy').isValid)) errors[field] = true;
    return errors;
  }

  its_okay = (okay) => {
    if (okay) {
      return (<CheckIcon sx={{color: this.props.theme.palette.tertiary.main, position: 'absolute', top: {xs: '17px', md: '17px'}, left: 0}} />);
    } else {
      return (<CloseIcon sx={{color: this.props.theme.palette.secondary.main, position: 'absolute', top: {xs: '17px', md: '17px'}, left: 0}} />);
    }
  }

  create_profile = (event) => {
    this.setState({loading: true}, () => {
      event.preventDefault();

      let formData = new FormData();
      formData.append('user[first_name]', this.state.user.first_name);
      formData.append('user[last_name]', this.state.user.last_name);
      formData.append('user[birth_date]', this.state.user.birth_date);
      formData.append('user[gender]', this.state.user.gender);
      formData.append('user[email]', this.state.user.email);
      // formData.append('user[repeat_email]', this.state.user.repeat_email);
      formData.append('user[mobile]', this.state.user.mobile);
      formData.append('user[street]', this.state.user.street);
      formData.append('user[number]', this.state.user.number);
      formData.append('user[zip]', this.state.user.zip);
      formData.append('user[city]', this.state.user.city);
      formData.append('user[zicht_account]', this.state.user.zicht_account);
      formData.append('user[identity]', this.state.user.identity);
      formData.append('user[vennoot_number]', `B${DateTime.now().toMillis()}`);
      formData.append('step', 'create_profile');
      formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));

      this.props.helpers.user.emission_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({user: r.user, emission_information: r, step: 2, loading: false, stock_id: r.user.stock_id}, () => {
            this.props.onChangeState(this.state);
            window.scrollTo(0, 0);
          });
        },
        error_callback: (r) => {
          this.setState({show_error: true, loading: false}, () => {
            this.props.onChangeState(this.state);
          });
        },
        parameters: formData
      });
    });
  }

  update_profile = (event) => {
    this.setState({loading: true}, () => {
      event.preventDefault();

      let formData = new FormData();
      formData.append('sub', this.state.user.sub);
      // formData.append('user[first_name]', this.state.user.first_name);
      // formData.append('user[last_name]', this.state.user.last_name);
      // formData.append('user[birth_date]', this.state.user.birth_date);
      // formData.append('user[gender]', this.state.user.gender);
      formData.append('user[email]', this.state.user.email);
      // formData.append('user[repeat_email]', this.state.user.repeat_email);
      // formData.append('user[mobile]', this.state.user.mobile);
      // formData.append('user[street]', this.state.user.street);
      // formData.append('user[number]', this.state.user.number);
      // formData.append('user[zip]', this.state.user.zip);
      // formData.append('user[city]', this.state.user.city);
      formData.append('user[zicht_account]', this.state.user.zicht_account);
      // formData.append('user[identity]', this.state.user.identity);
      // formData.append('user[vennoot_number]', `B${DateTime.now().toMillis()}`);
      formData.append('step', 'update_profile');
      formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));

      this.props.helpers.user.emission_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({user: r.user, emission_information: r, step: 2, loading: false}, () => {
            this.props.onChangeState(this.state);
            window.scrollTo(0, 0);
          });
        },
        error_callback: (r) => {
          this.setState({show_error: true, loading: false}, () => {
            this.props.onChangeState(this.state);
          });
        },
        parameters: formData
      });
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let errors = {age: false};
    [
      'first_name', 'last_name', 'birth_date', 'gender', 'email', 'repeat_email', 'mobile', 'street', 'number', 'zip', 'city', 'zicht_account'
    ].forEach(field => {errors = this.check_field(errors, field);});
    if (!errors.birth_date && !!this.state.user.birth_date) {
      if (!!DateTime.fromFormat(this.state.user.birth_date, 'dd/MM/yyyy').diffNow('years').years && DateTime.fromFormat(this.state.user.birth_date, 'dd/MM/yyyy').diffNow('years').years > -18) {
        errors.birth_date = true;
        errors.age = true;
      }
    }

    let profile_data = (!errors.first_name && !errors.last_name && !errors.birth_date && !errors.gender);
    let contact_data = (!errors.email && !errors.repeat_email && (this.state.profile_step.use_itsme === 1 || (this.state.profile_step.use_itsme !== 1 && !errors.mobile)) && this.state.user.email === this.state.user.repeat_email);
    let address_data = (!errors.street && !errors.number && !errors.zip && !errors.city);
    let banking_data = (!errors.zicht_account && this.state.ignore?.bank_checked);
    let identity_data = (this.state.profile_step.use_itsme === 1 || (!!this.state.user.identity));

    return (<React.Fragment>
      {this.state.profile_step.use_itsme === 0 && <Grid container sx={{marginTop: 2}}>
        <Grid item xs={12}>
          <PurifiedTypo sx={{marginBottom: 2}} text={t("emission.steps.profile.cta_step_1_new.intro")} />
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 1}}>
          <StyledWideButton
            theme={this.props.theme}
            text={t("emission.steps.profile.new.itsme")}
            itsme
            onClick={(e) => {
              // this.setState({profile_step: {...this.state.profile_step, use_itsme: 1}}, () => {
              //   this.props.onChangeState(this.state);
              //   window.scrollTo(0, 0);
              // });
              //staging
              // window.location = `https://oidc.e2e.itsme.services/clientsecret-oidc/csapi/v0.1/connect/authorize?client_id=VPBa0vzFYw&response_type=code&scope=openid service:ARGENCOSB_SHAREDATA profile email address phone&redirect_uri=https://old.argenco.be/itsme/redirection&ui_locales=${((this.props.i18n.language || 'nl').length < 1 ? 'nl' : (this.props.i18n.language || 'nl'))}`;
              //production
              window.location = `https://oidc.prd.itsme.services/clientsecret-oidc/csapi/v0.1/connect/authorize?client_id=fqmwcZDgzF&response_type=code&scope=openid service:ARGENCOPRD_SHAREDATA profile email address phone&redirect_uri=https://old.argenco.be/itsme/redirection&ui_locales=${((this.props.i18n.language || 'nl').length < 1 ? 'nl' : (this.props.i18n.language || 'nl'))}&state=REG/${window.argenco_uuid}`;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 1}}>
          <StyledWideButton
            theme={this.props.theme}
            text={t("emission.steps.profile.new.not_itsme")}
            onClick={(e) => {
              this.setState({profile_step: {...this.state.profile_step, use_itsme: 2}}, () => {
                this.props.onChangeState(this.state);
                window.scrollTo(0, 0);
              });
            }}
          />
        </Grid>
      </Grid>}


      {this.state.profile_step.use_itsme === 1 && <Grid container spacing="0" sx={{marginTop: 2}}>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
        <Grid container item xs={12} md={6} spacing="8">
          {!this.state.user.sub && <Grid item xs={12} align="center">
            <CircularProgress />
            <PurifiedTypo text={t("emission.steps.profile.itsme.loading_data")} />
          </Grid>}
          {!!this.state.user.sub && <React.Fragment>
            <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
              <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.personal_data")}</Typography> {this.its_okay(profile_data)}
            </Grid>
            <Grid item xs={5}>
              <StyledTextField
                disabled
                label={t("profile.my_data.field.first_name").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.first_name}
                id="first_name"
              />
            </Grid>
            <Grid item xs={7}>
              <StyledTextField
                disabled
                label={t("profile.my_data.field.last_name").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.last_name}
                id="last_name"
              />
            </Grid>
            <Grid item xs={7}>
              <StyledTextField
                disabled
                label={t("profile.my_data.field.birth_date").replace(/<[^>]*>?/gm, '')}
                value={DateTime.fromFormat(this.state.user.birth_date, 'dd/MM/yyyy').toFormat("dd/MM/yyyy")}
                id="birth_date"
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel id="gender_label">{t("profile.my_data.field.gender").replace(/<[^>]*>?/gm, '')}</InputLabel>
                <Select
                  labelId="gender_label"
                  disabled
                  id="gender"
                  value={this.state.user.gender}
                  label={t("profile.my_data.field.gender").replace(/<[^>]*>?/gm, '')}
                >
                  <MenuItem value={"M"}>{t("profile.my_data.field.genders.male").replace(/<[^>]*>?/gm, '')}</MenuItem>
                  <MenuItem value={"F"}>{t("profile.my_data.field.genders.female").replace(/<[^>]*>?/gm, '')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!!errors.age && <Grid item xs={12} sx={{marginTop: 1}}>
              <PurifiedTypo text={t("emission.steps.profile.error_age_too_young")} sx={{lineHeight: '1.5'}} />
            </Grid>}

            <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
              <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.contact_data")}</Typography> {this.its_okay(contact_data)}
            </Grid>
            <Grid item xs={12}>
              <PurifiedTypo text={t("emission.steps.profile.contact_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label={t("profile.my_data.field.email").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.email}
                id="email"
                onChange={(v) => {this.setState({user: {...this.state.user, email: v}});}}
                error={this.state.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label={t("profile.my_data.field.repeat_email").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.repeat_email}
                id="repeat_email"
                onChange={(v) => {this.setState({user: {...this.state.user, repeat_email: v}});}}
                error={this.state.errors.repeat_email}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                disabled
                label={t("profile.my_data.field.phone").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.phone}
                id="phone"
              />
            </Grid>

            <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
              <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.address_data")}</Typography> {this.its_okay(address_data)}
            </Grid>
            <Grid item xs={8}>
              <StyledTextField
                label={t("profile.my_data.field.street").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.street}
                id="street"
                onChange={(v) => {this.setState({user: {...this.state.user, street: v}});}}
                error={this.state.errors.street}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label={t("profile.my_data.field.number").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.number}
                id="number"
                onChange={(v) => {this.setState({user: {...this.state.user, number: v}});}}
                error={this.state.errors.number}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label={t("profile.my_data.field.zip").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.zip}
                id="zip"
                onChange={(v) => {this.setState({user: {...this.state.user, zip: v}});}}
                error={this.state.errors.zip}
              />
            </Grid>
            <Grid item xs={8}>
              <StyledTextField
                label={t("profile.my_data.field.city").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.city}
                id="city"
                onChange={(v) => {this.setState({user: {...this.state.user, city: v}});}}
                error={this.state.errors.city}
              />
            </Grid>

            <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
              <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.banking_data")}</Typography> {this.its_okay(banking_data)}
            </Grid>
            <Grid item xs={12}>
              <PurifiedTypo text={t("emission.steps.profile.title.banking_description")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
            </Grid>
            <Grid item xs={12} sx={{marginTop: -1}}>
              <BankAccount
                onChange={(e, checked) => {this.setState({user: {...this.state.user, zicht_account: e.target.value}, ignore: {...this.state.ignore, bank_checked: checked}});}}
                value={this.state.user.zicht_account}
                label={t("profile.my_data.field.zicht_account").replace(/<[^>]*>?/gm, '')}
                id="zicht_account"
                error={this.state.errors.zicht_account}
              />
            </Grid>

            <Grid item xs={12} sx={{marginTop: 4}}>
              <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main}}>{t("emission.steps.profile.title.confirm_data")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <PurifiedTypo text={t("emission.steps.profile.confirm_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
            </Grid>
            {!!this.state.show_error && <Grid item xs={12}>
              <PurifiedTypo text={t("emission.steps.profile.generic_error")} />
            </Grid>}
            {!this.state.loading && <Grid item xs={12}>
              <StyledButton
                is_red
                disabled={!(!!profile_data && !!contact_data && !!address_data && !!banking_data && !!identity_data)}
                onClick={(e) => {
                  if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                    this.props.helpers.web_log.do_refresh({
                      locale: this.props.i18n.language,
                      callback: (r, full_data) => {
                        window.argenco_uuid = r;
                      },
                      parameters: {
                        id: this.props.login?.id,
                        uuid: window.argenco_uuid,
                        u: window.location.pathname,
                        r: document.referrer,
                        a: 'button_click',
                        d: 'emission.for_me_itsme.new_save',
                        s: window.argenco_source,
                        ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                      }
                    });
                  }
                  this.update_profile(e);
                }}
                text={t("emission.steps.profile.cta.continue")}
              />
            </Grid>}
            {!!this.state.loading && <Grid item xs={12}>
              <CircularProgress />
            </Grid>}

          </React.Fragment>}
        </Grid>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
      </Grid>}


      {this.state.profile_step.use_itsme === 2 && <Grid container spacing="0" sx={{marginTop: 2}}>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
        <Grid container item xs={12} md={6} spacing="8">
          <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.personal_data")}</Typography> {this.its_okay(profile_data)}
          </Grid>
          <Grid item xs={5}>
            <StyledTextField
              label={t("profile.my_data.field.first_name").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.first_name}
              id="first_name"
              onChange={(v) => {this.setState({user: {...this.state.user, first_name: v}});}}
              error={this.state.errors.first_name}
            />
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              label={t("profile.my_data.field.last_name").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.last_name}
              id="last_name"
              onChange={(v) => {this.setState({user: {...this.state.user, last_name: v}});}}
              error={this.state.errors.last_name}
            />
          </Grid>
          <Grid item xs={7}>
            <BirthDate
              onChange={(v) => {this.setState({user: {...this.state.user, birth_date: v.target.value}});}}
              value={this.state.user.birth_date}
              label={t("profile.my_data.field.birth_date").replace(/<[^>]*>?/gm, '')}
              id="birth_date"
              error={this.state.errors.birth_date}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id="gender_label">{t("profile.my_data.field.gender").replace(/<[^>]*>?/gm, '')}</InputLabel>
              <Select
                labelId="gender_label"
                id="gender"
                value={this.state.user.gender}
                label={t("profile.my_data.field.gender").replace(/<[^>]*>?/gm, '')}
                onChange={(v) => {this.setState({user: {...this.state.user, gender: v.target.value}});}}
              >
                <MenuItem value={"M"}>{t("profile.my_data.field.genders.male").replace(/<[^>]*>?/gm, '')}</MenuItem>
                <MenuItem value={"F"}>{t("profile.my_data.field.genders.female").replace(/<[^>]*>?/gm, '')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {!!errors.age && <Grid item xs={12} sx={{marginTop: 1}}>
            <PurifiedTypo text={t("emission.steps.profile.error_age_too_young")} sx={{lineHeight: '1.5'}} />
          </Grid>}

          <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.contact_data")}</Typography> {this.its_okay(contact_data)}
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.profile.contact_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label={t("profile.my_data.field.email").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.email}
              id="email"
              onChange={(v) => {this.setState({user: {...this.state.user, email: v}});}}
              error={this.state.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label={t("profile.my_data.field.repeat_email").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.repeat_email}
              id="repeat_email"
              onChange={(v) => {this.setState({user: {...this.state.user, repeat_email: v}});}}
              error={this.state.errors.repeat_email}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label={t("profile.my_data.field.mobile").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.mobile}
              id="mobile"
              onChange={(v) => {this.setState({user: {...this.state.user, mobile: v}});}}
              error={this.state.errors.mobile}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.address_data")}</Typography> {this.its_okay(address_data)}
          </Grid>
          <Grid item xs={8}>
            <StyledTextField
              label={t("profile.my_data.field.street").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.street}
              id="street"
              onChange={(v) => {this.setState({user: {...this.state.user, street: v}});}}
              error={this.state.errors.street}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledTextField
              label={t("profile.my_data.field.number").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.number}
              id="number"
              onChange={(v) => {this.setState({user: {...this.state.user, number: v}});}}
              error={this.state.errors.number}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledTextField
              label={t("profile.my_data.field.zip").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.zip}
              id="zip"
              onChange={(v) => {this.setState({user: {...this.state.user, zip: v}});}}
              error={this.state.errors.zip}
            />
          </Grid>
          <Grid item xs={8}>
            <StyledTextField
              label={t("profile.my_data.field.city").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.city}
              id="city"
              onChange={(v) => {this.setState({user: {...this.state.user, city: v}});}}
              error={this.state.errors.city}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.banking_data")}</Typography> {this.its_okay(banking_data)}
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.profile.title.banking_description")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
          </Grid>
          <Grid item xs={12} sx={{marginTop: -1}}>
            <BankAccount
              onChange={(e, checked) => {this.setState({user: {...this.state.user, zicht_account: e.target.value}, ignore: {...this.state.ignore, bank_checked: checked}});}}
              value={this.state.user.zicht_account}
              label={t("profile.my_data.field.zicht_account").replace(/<[^>]*>?/gm, '')}
              id="zicht_account"
              error={this.state.errors.zicht_account}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.identity_data")}</Typography> {this.its_okay(identity_data)}
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("profile.my_data.field.identity_front")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
          </Grid>
          <Grid item xs={12} sx={{display: {xs: 'none', md: 'flex'}}}>
            <DropzoneArea
              acceptedFiles={['image/*', '.pdf']}
              filesLimit={1}
              dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
              onChange={(files) => {this.setState({user: {...this.state.user, identity: files[0]}});}}
              maxFileSize={3000000}
              previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
              dropzoneParagraphClass={classes.dropZoneText}
              previewGridClasses={{
                item: classes.preview,
                container: classes.preview_grid
              }}
              dropzoneClass={classes.dropzoneMainClass}
            />
          </Grid>
          <Grid item xs={12} sx={{display: {xs: 'flex', md: 'none'}}}>
            <DropzoneArea
              acceptedFiles={['image/*', '.pdf']}
              filesLimit={1}
              dropzoneText={he.decode(t('general.dropzone_text_mobile').replace(/<[^>]*>?/gm, ''))}
              onChange={(files) => {this.setState({user: {...this.state.user, identity: files[0]}});}}
              maxFileSize={3000000}
              previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
              dropzoneParagraphClass={classes.dropZoneText}
              previewGridClasses={{
                item: classes.preview,
                container: classes.preview_grid
              }}
              dropzoneClass={classes.dropzoneMainClassMobile}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 4}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main}}>{t("emission.steps.profile.title.confirm_data")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.profile.confirm_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
          </Grid>
          {!!this.state.show_error && <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.profile.generic_error")} />
          </Grid>}
          {!this.state.loading && <Grid item xs={12}>
            <StyledButton
              is_red
              disabled={!(!!profile_data && !!contact_data && !!address_data && !!banking_data && !!identity_data)}
              onClick={(e) => {
                if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                  this.props.helpers.web_log.do_refresh({
                    locale: this.props.i18n.language,
                    callback: (r, full_data) => {
                      window.argenco_uuid = r;
                    },
                    parameters: {
                      id: this.props.login?.id,
                      uuid: window.argenco_uuid,
                      u: window.location.pathname,
                      r: document.referrer,
                      a: 'button_click',
                      d: 'emission.for_me_no_itsme.new_save',
                      s: window.argenco_source,
                      ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                    }
                  });
                }
                this.create_profile(e);
              }}
              text={t("emission.steps.profile.cta.continue")}
            />
          </Grid>}
          {!!this.state.loading && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}
        </Grid>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
      </Grid>}
    </React.Fragment>);
  }
}

EmissionStepProfileNewFields.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  dropZoneText: {
    fontFamily: theme.typography.fontFamily
  },
  preview: {
    maxWidth: '100%',
    '& img': {
      maxHeight: '150px'
    },

  },
  preview_grid: {
    width: '100%',
    margin: '0px',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropzoneMainClassMobile: {
    minHeight: '175px !important',
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiDropzonePreviewList-removeButton': {
      right: '8px'
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: '8px'
    }
  },
  dropzoneMainClass: {
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiDropzonePreviewList-removeButton': {
      right: '8px'
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: '8px'
    }
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(EmissionStepProfileNewFields))));
