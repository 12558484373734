import WebLog from '../actions/web_log';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function web_log(state = initial, action) {
  return WebLog.state_switch(state, action);
}
