import { connect } from 'react-redux';
import Banner from '../../actions/banner';
import Devise from '../../actions/devise';
// import { toggle_drawer } from '../../actions/settings';
import Header from '../../components/shared/Header';

const map_state_to_props = state => ({
  login: state.devise.data,
  drawer: state.drawer
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      banner: {
        fetch_active: (_params) => {
          dispatch(Banner.fetch_active(_params));
        }
      }
    },
    // toggle_drawer: (_params) => {
    //   dispatch(toggle_drawer(_params));
    // },
    logout: (_params) => {
      dispatch(Devise.sign_out(_params));
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Header);
