import { connect } from 'react-redux';
import Contact from '../../actions/contact';
import Contacts from '../../components/pages/Contact';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      contacts: {
        submit: (_params) => {
          dispatch(Contact.submit(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Contacts);
