// import axios from 'axios';

const is_localhost = Boolean(window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));

export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) { //
    const _url = new URL(process.env.PUBLIC_URL, window.location);
    if (_url.origin !== window.location.origin) {
      console.log("origin error");
      return;
    }

    window.addEventListener('load', () => {
      const _service_worker_url = "service-worker.js"; // _pending

      if (is_localhost) {
        // check_valid(_service_worker_url);
        navigator.serviceWorker.ready.then(() => {
          console.log('KaiBot is being served cache-first by our service.');
        });
      } else {
        register_worker(_service_worker_url);
      }
    });
  }
}

function register_worker(_service_worker_url) {
  navigator.serviceWorker.register(_service_worker_url).then(_registration => {
    _registration.onupdatefound = () => {
      const _installer = _registration.installing;
      _installer.onstatechange = () => {
        if (_installer.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            console.log('New content is available; please refresh.');
          } else {
            console.log('Content is cached for offline use.');
          }
        }
      };
    };
  }).catch(error => {
    console.error('Error during service worker registration:', error);
  });
}

// function check_valid(_service_worker_url) {
//   fetch(_service_worker_url, {credentials: "include"}).then(_response => {
//     if (_response.status === 404 || _response.headers.get('content-type').indexOf('javascript') === -1) {
//       navigator.serviceWorker.ready.then(_registration => {
//         _registration.unregister().then(() => {
//           window.location.reload();
//         });
//       });
//     } else {
//       register_worker(_service_worker_url);
//     }
//   }).catch(() => {
//     console.log('No internet connection found. App is running in offline mode.');
//   });
// }

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
