import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid
} from '@mui/material';

import PersonOffIcon from '@mui/icons-material/PersonOff';

import {DateTime} from "luxon";
import {DropzoneArea} from 'material-ui-dropzone';

import Authenticated from "../../containers/shared/parts/Authenticated";
import BankAccount from "../shared/parts/BankAccount";
import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import ShareShower from "../shared/parts/ShareShower";
import StyledButton from "../shared/parts/StyledButton";
import StyledTextField from "../shared/parts/StyledTextField";
import StyledWideButton from "../shared/parts/StyledWideButton";
import Typography from "../shared/parts/Typography";

var he = require('he');

class Exit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      step_loading: false,
      exit_information: {},
      user: props.login || {},
      changes: props.login || {},
      identity: null,
      item: null,
      show_step: false,
      feedback: {
        choice: [],
        comment: ''
      },
      digital: 0,
      correct_data: 0
    };

    this.scrollable_ref = React.createRef();
  }

  componentDidMount() {
    if (!!this.props.login) {
      this.props.renew_sign_in({
        locale: this.props.i18n.language,
        login: this.props.login,
        parameters: {
          fields: ['id', 'first_name', 'last_name', 'email', 'auth_token', "type", "is_admin", "vennoot_number", 'address', 'stock_number', 'share_type|tag', 'birth_date', 'stock_id', 'phone', 'mobile', 'zicht_account', 'street', 'number', 'zip', 'city']
        },
        callback: (r, full_data) => {
          this.setState({user: r, changes: r});
        }
      });

      this.fetch_exit_information(null, null);
    }
  }
  componentDidUpdate(prevProps, prevState) {}

  fetch_exit_information = (vennr, birth_date) => {
    this.setState({loading: true}, () => {
      this.props.helpers.users.exit_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({exit_information: r, loading: false});
        },
        parameters: {
          vennr: vennr,
          birth_date: birth_date
        }
      });
    });
  }

  send_feedback = (vennr, birth_date) => {
    this.setState({step_loading: true}, () => {
      this.props.helpers.users.exit_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({exit_information: r, step_loading: false});
          window.scrollTo(0, 0);
        },
        parameters: {
          vennr: vennr,
          birth_date: birth_date,
          step: 'send_feedback',
          feedback: this.state.feedback
        }
      });
    });
  }

  start_exit = (vennr, birth_date, has_changes) => {
    this.setState({step_loading: true}, () => {
      this.props.helpers.users.exit_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({exit_information: r, step_loading: false});
          window.scrollTo(0, 0);
        },
        parameters: {
          vennr: vennr,
          birth_date: birth_date,
          step: 'start_exit',
          changes: (has_changes ? {
            first_name: this.state.changes.first_name,
            last_name: this.state.changes.last_name,
            email: this.state.changes.email,
            street: this.state.changes.street,
            number: this.state.changes.number,
            zip: this.state.changes.zip,
            city: this.state.changes.city,
            zicht_account: this.state.changes.zicht_account
          } : null)
        }
      });
    });
  }

  start_exit_itsme = (vennr, birth_date, has_changes) => {
    this.setState({step_loading: true}, () => {
      this.props.helpers.users.exit_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          window.location = r.itsme_url;
        },
        parameters: {
          vennr: vennr,
          birth_date: birth_date,
          step: 'start_exit_itsme',
          changes: (has_changes ? {
            first_name: this.state.changes.first_name,
            last_name: this.state.changes.last_name,
            email: this.state.changes.email,
            phone: this.state.changes.phone,
            mobile: this.state.changes.mobile,
            zicht_account: this.state.changes.zicht_account
          } : null)
        }
      });
    });
  }

  upload_docs = (vennr, birth_date) => {
    this.setState({step_loading: true}, () => {
      let formData = new FormData();
      formData.append('vennr', vennr);
      formData.append('birth_date', birth_date);
      formData.append('step', 'docs');
      formData.append('identity', this.state.identity);
      formData.append('item', this.state.item);

      this.props.helpers.users.exit_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({exit_information: r, step_loading: false});
        },
        parameters: formData
      });
    });
  }

  render_step = (text, active, do_bar) => {
    return (<Box sx={{width: '38px', display: 'inline-block', marginRight: 1, position: 'relative'}}>
      <Box sx={{
        backgroundColor: (active ? this.props.theme.palette.primary.main : 'white'),
        border: `1px solid ${this.props.theme.palette.primary.main}`,
        borderRadius: 8,
        width: '30px',
        height: '30px',
        color: (!active ? this.props.theme.palette.primary.main : 'white'),
        textAlign: 'center',
        lineHeight: '30px',
        position: 'relative',
        zIndex: 5
      }}>{text}</Box>
      {do_bar && <Box sx={{backgroundColor: this.props.theme.palette.primary.main, width: '4px', height: '40px', position: 'absolute', left: '13px', top: '30px', zIndex: 4}}></Box>}
    </Box>);
  }

  formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let exit_started = false;
    let docs_uploaded = false;
    let argenco_checked = false;
    if (Object.keys(this.state.exit_information).length > 0) {
      exit_started = this.state.exit_information.exit_active;
      if (!!this.state.exit_information.exit_itsme) {
        exit_started = !!this.state.exit_information.exit_itsme_success
      }

      docs_uploaded = !!this.state.exit_information.item_url && this.state.exit_information.item_url.indexOf("missing.png") < 0;
      if (!!this.state.exit_information.exit_itsme_success) {
        docs_uploaded = true;
      }

      argenco_checked = this.state.exit_information.checked;
    }

    let can_do_exit = DateTime.now().month >= 7;
    if (window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1) {
      can_do_exit = true;
    }

    let show_progress = false;
    if (!!exit_started && !!docs_uploaded) show_progress = true;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.component_title} sx={{textAlign: 'center'}}>{t(`exit.title`)}</Typography>
          </Grid>
          {false && !!can_do_exit && <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
            <PurifiedTypo text={t("exit.seo_paragraph")} />
          </Grid>}
        </Grid>

        <Grid container spacing="2">
          {!can_do_exit && <React.Fragment>
            <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
            <Grid item xs={12} md={8}>
              <PurifiedTypo text={t("exit.period_closed.message")} />
            </Grid>
            <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
          </React.Fragment>}

          {!!can_do_exit && <Grid item container xs={12}>
            <Authenticated login={this.props.login} t={this.props.t} onAuthenticate={(user, authenticated) => {
              if (authenticated) this.setState({user: user, changes: user}, () => {
                this.fetch_exit_information(user.vennr, user.birth_date);
              });
            }} fields={[
              'id', 'first_name', 'last_name', 'email', 'auth_token', "type", "is_admin", "vennoot_number", 'address', 'stock_number', 'share_type|tag',
              'birth_date', 'stock_id', 'phone', 'mobile', 'zicht_account', 'street', 'number', 'zip', 'city'
            ]}>
              {!!this.state.loading && <CircularProgress />}
              {!this.state.loading && <React.Fragment>
                {!!show_progress && <Grid item xs={12}>
                  <Box sx={{width: '100%', border: `1px solid ${this.props.theme.palette.primary.main}`, padding: 1}}>
                    <Box sx={{width: '100%'}}>
                      {this.render_step('1', exit_started, true)}
                      <Box sx={{width: 'calc(100% - 46px)', display: 'inline-block'}}>
                        <PurifiedTypo sx={{"& > p": {marginBottom: '4px !important', marginTop: '4px'}}} text={t("exit.overview.exit_started", {status: (exit_started ? t("exit.overview.state_yes").replace(/<[^>]*>?/gm, '') : t("exit.overview.state_no").replace(/<[^>]*>?/gm, ''))})} />
                      </Box>
                      <Divider sx={{margin: 1}} />
                    </Box>
                    <Box sx={{width: '100%'}}>
                      {this.render_step('2', docs_uploaded, true)}
                      <Box sx={{width: 'calc(100% - 46px)', display: 'inline-block'}}>
                        <PurifiedTypo sx={{"& > p": {marginBottom: '4px !important', marginTop: '4px'}}} text={t("exit.overview.docs_uploaded", {status: (docs_uploaded ? t("exit.overview.state_yes").replace(/<[^>]*>?/gm, '') : t("exit.overview.state_no").replace(/<[^>]*>?/gm, ''))})} />
                      </Box>
                      <Divider sx={{margin: 1}} />
                    </Box>
                    <Box sx={{width: '100%'}}>
                      {this.render_step('3', argenco_checked, true)}
                      <Box sx={{width: 'calc(100% - 46px)', display: 'inline-block'}}>
                        <PurifiedTypo sx={{"& > p": {marginBottom: '4px !important', marginTop: '4px'}}} text={t("exit.overview.argenco_validated", {status: (argenco_checked ? t("exit.overview.state_yes").replace(/<[^>]*>?/gm, '') : t("exit.overview.state_no").replace(/<[^>]*>?/gm, ''))})} />
                      </Box>
                      <Divider sx={{margin: 1}} />
                    </Box>
                    <Box sx={{width: '100%'}}>
                      {this.render_step('4', false, false)}
                      <Box sx={{width: 'calc(100% - 46px)', display: 'inline-block'}}>
                        <PurifiedTypo sx={{"& > p": {marginBottom: '4px !important', marginTop: '4px'}}} text={t("exit.overview.paid", {status: t("exit.overview.state_no").replace(/<[^>]*>?/gm, '')})} />
                      </Box>
                    </Box>
                  </Box>
                </Grid>}
                <Grid ref={this.scrollable_ref} xs={12}>&nbsp;</Grid>

                {!exit_started && !this.state.show_step && <Grid container item xs={12}>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                  <Grid container item xs={12} md={8}>
                    <Grid item xs={12}>
                      <PurifiedTypo text={t("exit.intro.exit_started.content")} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{marginTop: 4, textAlign: {xs: 'center', md: 'right'}, paddingRight: {xs: 0, md: 1}}}>
                      {!!this.state.step_loading && <CircularProgress />}
                      {!this.state.step_loading && <StyledButton
                        text={t("exit.cta.back").replace(/<[^>]*>?/gm, '')}
                        as_link
                        theme={this.props.theme}
                        onClick={(e) => {
                          this.props.history.push('/profile');
                          window.scrollTo(0, 0);
                        }}
                      />}
                    </Grid>
                    <Grid item xs={12} md={6} sx={{marginTop: {xs: 2, md: 4}, textAlign: {xs: 'center', md: 'left'}, paddingLeft: {xs: 0, md: 1}}}>
                      {!this.state.step_loading && <StyledButton
                        text={t("exit.cta.start_exit").replace(/<[^>]*>?/gm, '')}
                        theme={this.props.theme}
                        onClick={(e) => {
                          this.setState({show_step: true});
                          window.scrollTo(0, 0);
                        }}
                      />}
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 4}}>
                      <PurifiedTypo text={t("exit.intro.exit_started.transfer_content")} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                </Grid>}

                {!exit_started && !!this.state.show_step && <Grid container item xs={12} sx={{marginTop: 0}}>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                  <Grid container item xs={12} md={8}>
                    {/* <Grid item xs={12} sx={{marginTop: 2}}>
                      <PurifiedTypo text={t("exit.intro.exit_started.choice.content")} />
                    </Grid> */}

                    <Grid item xs={12} sx={{marginTop: 0}}>
                      <PurifiedTypo text={t("exit.intro.exit_started.choice.content_papertrail")} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{marginTop: 1, textAlign: {xs: 'center', md: 'right'}, paddingRight: {xs: 0, md: 1}}}>
                      <StyledButton
                        text={t("exit.intro.exit_started.cta.paper").replace(/<[^>]*>?/gm, '')}
                        theme={this.props.theme}
                        secondary={this.state.digital !== 2}
                        sx={{...(this.state.digital > 0 && this.state.digital !== 2 ? {opacity: 0.5} : {})}}
                        onClick={(e) => {
                          this.setState({digital: 2, correct_data: 0});
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{marginTop: 1, textAlign: {xs: 'center', md: 'left'}, paddingLeft: {xs: 0, md: 1}}}>
                      <StyledButton
                        text={t("exit.intro.exit_started.cta.digital").replace(/<[^>]*>?/gm, '')}
                        theme={this.props.theme}
                        secondary={this.state.digital !== 1}
                        sx={{...(this.state.digital > 0 && this.state.digital !== 1 ? {opacity: 0.5} : {})}}
                        onClick={(e) => {
                          this.setState({digital: 1, correct_data: 0});
                        }}
                      />
                    </Grid>

                    {this.state.digital > 0 && <React.Fragment>
                      <Grid item xs={12} sx={{marginTop: 4}}>
                        <PurifiedTypo text={t("exit.intro.exit_started.choice.content_user_data")} />
                      </Grid>
                      <Grid item xs={6} sx={{marginTop: 1}}>
                        <Typography variant='h4' sx={{fontWeight: 'bold'}}>{t('exit.intro.exit_started.my_data_table.name')}</Typography>
                        <Typography>{this.state.user.first_name} {this.state.user.last_name}</Typography>
                      </Grid>
                      <Grid item xs={6} sx={{marginTop: 1}}>
                        <Typography variant='h4' sx={{fontWeight: 'bold'}}>{t('exit.intro.exit_started.my_data_table.vennr')}</Typography>
                        <Typography>{this.state.user.vennoot_number || this.state.user.vennr}</Typography>
                      </Grid>
                      <Grid item xs={6} sx={{marginTop: 1}}>
                        <Typography variant='h4' sx={{fontWeight: 'bold'}}>{t('exit.intro.exit_started.my_data_table.email')}</Typography>
                        <Typography>{this.state.user.email}</Typography>
                      </Grid>
                      <Grid item xs={6} sx={{marginTop: 1}}>
                        <Typography variant='h4' sx={{fontWeight: 'bold'}}>{t('exit.intro.exit_started.my_data_table.stock_total')}</Typography>
                        <ShareShower amount={this.state.user.stock_number} share={this.state.user.share_type_tag} />
                      </Grid>
                      {this.state.digital === 1 && <Grid item xs={6} sx={{marginTop: 1}}>
                        <Typography variant='h4' sx={{fontWeight: 'bold'}}>{t('exit.intro.exit_started.my_data_table.phone')}</Typography>
                        <Typography>{this.state.user.phone}</Typography>
                      </Grid>}
                      {this.state.digital === 1 && <Grid item xs={6} sx={{marginTop: 1}}>
                        <Typography variant='h4' sx={{fontWeight: 'bold'}}>{t('exit.intro.exit_started.my_data_table.mobile')}</Typography>
                        <Typography>{this.state.user.mobile}</Typography>
                      </Grid>}
                      {this.state.digital === 2 && <Grid item xs={12} sx={{marginTop: 1}}>
                        <Typography variant='h4' sx={{fontWeight: 'bold'}}>{t('exit.intro.exit_started.my_data_table.address')}</Typography>
                        <Typography>{this.state.user.address}</Typography>
                      </Grid>}
                      <Grid item xs={12} sx={{marginTop: 1}}>
                        <Typography variant='h4' sx={{fontWeight: 'bold'}}>{t('exit.intro.exit_started.my_data_table.zicht_account')}</Typography>
                        <Typography>{this.state.user.zicht_account}</Typography>
                      </Grid>
                    </React.Fragment>}

                    {this.state.digital > 0 && this.state.correct_data < 1 && <React.Fragment>
                      <Grid item xs={12} sx={{marginTop: 4}}>
                        <PurifiedTypo text={t("exit.intro.exit_started.choice.content_correct_data")} />
                      </Grid>
                      <Grid item xs={12} md={6} sx={{marginTop: 1, textAlign: {xs: 'center', md: 'right'}, paddingRight: {xs: 0, md: 1}}}>
                        <StyledButton
                          text={t("exit.intro.exit_started.cta.not_correct").replace(/<[^>]*>?/gm, '')}
                          theme={this.props.theme}
                          as_link
                          onClick={(e) => {
                            this.setState({correct_data: 2});
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sx={{marginTop: 1, textAlign: {xs: 'center', md: 'left'}, paddingLeft: {xs: 0, md: 1}}}>
                        {this.state.digital === 1 && this.state.correct_data !== 2 && <StyledWideButton
                          theme={this.props.theme}
                          text={t("exit.cta.start_exit_itsme_correct_data")}
                          itsme
                          onClick={(e) => {
                            this.start_exit_itsme(this.state.user.vennr, this.state.user.birth_date, false);
                            window.scrollTo(0, 0);
                          }}
                        />}
                        {this.state.digital === 2 && this.state.correct_data !== 2 && <StyledButton
                          text={t("exit.intro.exit_started.cta.correct_start_papertrail").replace(/<[^>]*>?/gm, '')}
                          theme={this.props.theme}
                          onClick={(e) => {
                            this.start_exit(this.state.user.vennr, this.state.user.birth_date, false);
                            window.scrollTo(0, 0);
                          }}
                        />}
                      </Grid>
                    </React.Fragment>}

                    {this.state.correct_data === 2 && <React.Fragment>
                      <Grid item xs={12} container spacing="4">
                        <Grid item xs={12} sx={{marginTop: 4}}>
                          <PurifiedTypo text={t("exit.intro.exit_started.choice.content_correcting_data")} />
                        </Grid>
                        <Grid item xs={6} sx={{marginTop: 1}}>
                          <StyledTextField
                            label={t("profile.my_data.field.first_name").replace(/<[^>]*>?/gm, '')}
                            value={this.state.changes.first_name}
                            id="first_name"
                            onChange={(v) => {this.setState({changes: {...this.state.changes, first_name: v}});}}
                          />
                        </Grid>
                        <Grid item xs={6} sx={{marginTop: 1}}>
                          <StyledTextField
                            label={t("profile.my_data.field.last_name").replace(/<[^>]*>?/gm, '')}
                            value={this.state.changes.last_name}
                            id="last_name"
                            onChange={(v) => {this.setState({changes: {...this.state.changes, last_name: v}});}}
                          />
                        </Grid>
                        <Grid item xs={6} sx={{marginTop: 1}}>
                          <StyledTextField
                            label={t("profile.my_data.field.email").replace(/<[^>]*>?/gm, '')}
                            value={this.state.changes.email}
                            id="email"
                            onChange={(v) => {this.setState({changes: {...this.state.changes, email: v}});}}
                          />
                        </Grid>
                        <Grid item xs={6} sx={{marginTop: 1}}>
                          &nbsp;
                        </Grid>
                        {this.state.digital === 1 && <Grid item xs={6} sx={{marginTop: 1}}>
                          <StyledTextField
                            label={t("profile.my_data.field.phone").replace(/<[^>]*>?/gm, '')}
                            value={this.state.changes.phone}
                            id="phone"
                            onChange={(v) => {this.setState({changes: {...this.state.changes, phone: v}});}}
                          />
                        </Grid>}
                        {this.state.digital === 1 && <Grid item xs={6} sx={{marginTop: 1}}>
                          <StyledTextField
                            label={t("profile.my_data.field.mobile").replace(/<[^>]*>?/gm, '')}
                            value={this.state.changes.mobile}
                            id="mobile"
                            onChange={(v) => {this.setState({changes: {...this.state.changes, mobile: v}});}}
                          />
                        </Grid>}
                        {this.state.digital === 2 && <Grid item container xs={12} sx={{marginTop: 0}} spacing="4">
                          <Grid item container xs={8} sx={{marginTop: 1}}>
                            <StyledTextField
                              label={t("profile.my_data.field.street").replace(/<[^>]*>?/gm, '')}
                              value={this.state.changes.street}
                              id="street"
                              onChange={(v) => {this.setState({changes: {...this.state.changes, street: v}});}}
                            />
                          </Grid>
                          <Grid item container xs={4} sx={{marginTop: 1}}>
                            <StyledTextField
                              label={t("profile.my_data.field.number").replace(/<[^>]*>?/gm, '')}
                              value={this.state.changes.number}
                              id="number"
                              onChange={(v) => {this.setState({changes: {...this.state.changes, number: v}});}}
                            />
                          </Grid>
                          <Grid item container xs={4} sx={{marginTop: 1}}>
                            <StyledTextField
                              label={t("profile.my_data.field.zip").replace(/<[^>]*>?/gm, '')}
                              value={this.state.changes.zip}
                              id="zip"
                              onChange={(v) => {this.setState({changes: {...this.state.changes, zip: v}});}}
                            />
                          </Grid>
                          <Grid item container xs={8} sx={{marginTop: 1}}>
                            <StyledTextField
                              label={t("profile.my_data.field.city").replace(/<[^>]*>?/gm, '')}
                              value={this.state.changes.city}
                              id="city"
                              onChange={(v) => {this.setState({changes: {...this.state.changes, city: v}});}}
                            />
                          </Grid>
                        </Grid>}
                        <Grid item xs={12} sx={{marginTop: 1}}>
                          <BankAccount
                            onChange={(v) => {this.setState({changes: {...this.state.changes, zicht_account: v}});}}
                            value={this.state.changes.zicht_account}
                            label={t("profile.my_data.field.zicht_account").replace(/<[^>]*>?/gm, '')}
                            id="zicht_account"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{marginTop: 4, textAlign: {xs: 'center', md: 'right'}, paddingRight: {xs: 0, md: 1}}}>
                          <StyledButton
                            text={t("exit.intro.exit_started.cta.bij_nader_inzien_ben_ik_geen_mierenneuker").replace(/<[^>]*>?/gm, '')}
                            theme={this.props.theme}
                            as_link
                            onClick={(e) => {
                              this.setState({correct_data: 0});
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{marginTop: 4, textAlign: {xs: 'center', md: 'left'}, paddingLeft: {xs: 0, md: 1}}}>
                          {this.state.digital === 1 && <StyledWideButton
                            theme={this.props.theme}
                            disabled={!this.state.changes.first_name || !this.state.changes.last_name || !this.state.changes.email || (!this.state.changes.phone && !this.state.changes.mobile) || !this.state.changes.zicht_account}
                            text={t("exit.cta.start_exit_itsme_save_data")}
                            itsme
                            onClick={(e) => {
                              this.start_exit_itsme(this.state.user.vennr, this.state.user.birth_date, true);
                              window.scrollTo(0, 0);
                            }}
                          />}
                          {this.state.digital === 2 && <StyledButton
                            text={t("exit.intro.exit_started.cta.submit_correct_data").replace(/<[^>]*>?/gm, '')}
                            disabled={!this.state.changes.first_name || !this.state.changes.last_name || !this.state.changes.email || !this.state.changes.street || !this.state.changes.number || !this.state.changes.zip || !this.state.changes.city || !this.state.changes.zicht_account}
                            theme={this.props.theme}
                            onClick={(e) => {
                              this.start_exit(this.state.user.vennr, this.state.user.birth_date, true);
                              window.scrollTo(0, 0);
                            }}
                          />}
                        </Grid>
                      </Grid>

                    </React.Fragment>}

                  </Grid>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                </Grid>}

                {!!exit_started && !docs_uploaded && <Grid container item xs={12} sx={{marginTop: 4}}>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                  <Grid container item xs={12} md={8} spacing={2}>
                    <Grid item xs={12}>
                      <PurifiedTypo text={t("exit.intro.docs_upload.content", {blank_url: this.state.exit_information.blank_url})} />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: -3, marginBottom: -3}}>
                      <StyledButton
                        text={t("exit.cta.blank_document").replace(/<[^>]*>?/gm, '')}
                        theme={this.props.theme}
                        as_link
                        // sx={{marginTop: this.props.theme.spacing(4)}}
                        onClick={(e) => {
                          window.open(this.state.exit_information.blank_url, '_BLANK');
                        }}
                      />
                      <PurifiedTypo sx={{fontSize: '0.8rem', display: 'inline-block'}} text={`<p>(.pdf - ${this.formatBytes(this.state.exit_information.blank_size)}) <img height="18" width="15" src="https://old.argenco.be/support/assets/971" /></p>`} />
                    </Grid>
                    <Grid item xs={12} sx={{marginBottom: -3}}>
                      <PurifiedTypo text={t("exit.intro.docs_upload.content_part2", {blank_url: this.state.exit_information.blank_url})} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PurifiedTypo text={t("exit.fields.signed_document")} sx={{fontSize: '0.75rem'}} />
                      <DropzoneArea
                        acceptedFiles={['image/*', '.pdf']}
                        filesLimit={1}
                        dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
                        onChange={(files) => {this.setState({item: files[0]});}}
                        maxFileSize={3000000}
                        previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                        dropzoneParagraphClass={classes.dropZoneText}
                        previewGridClasses={{
                          item: classes.preview,
                          container: classes.preview_grid
                        }}
                        dropzoneClass={classes.dropzoneMainClass}
                      />
                      {!!this.state.exit_information.item_name && <Typography style={{fontSize: '0.8em'}}>{t("general.current_file", {name: this.state.exit_information.item_name}).replace(/<[^>]*>?/gm, '')}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PurifiedTypo text={t("exit.fields.identity_front")} sx={{fontSize: '0.75rem'}} />
                      <DropzoneArea
                        acceptedFiles={['image/*', '.pdf']}
                        filesLimit={1}
                        dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
                        onChange={(files) => {this.setState({identity: files[0]});}}
                        maxFileSize={3000000}
                        previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                        dropzoneParagraphClass={classes.dropZoneText}
                        previewGridClasses={{
                          item: classes.preview,
                          container: classes.preview_grid
                        }}
                        dropzoneClass={classes.dropzoneMainClass}
                      />
                      {!!this.state.exit_information.id_name && <Typography style={{fontSize: '0.8em'}}>{t("general.current_file", {name: this.state.exit_information.id_name}).replace(/<[^>]*>?/gm, '')}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                      {!!this.state.step_loading && <CircularProgress />}
                      {!this.state.step_loading && <StyledButton
                        text={t("exit.cta.upload_docs").replace(/<[^>]*>?/gm, '')}
                        disabled={!this.state.identity || !this.state.item}
                        theme={this.props.theme}
                        sx={{marginTop: this.props.theme.spacing(4)}}
                        onClick={(e) => {
                          this.upload_docs(this.state.user.vennr, this.state.user.birth_date);
                          window.scrollTo(0, this.scrollable_ref.current.offsetTop);
                        }}
                      />}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                </Grid>}

                {!!exit_started && !!docs_uploaded && !argenco_checked && <Grid container item xs={12} sx={{marginTop: 4}}>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                  <Grid container item xs={12} md={8}>
                    <Grid item xs={12}>
                      <PurifiedTypo text={t("exit.intro.argenco_checked.content")} />
                    </Grid>

                    {!this.state.exit_information.exit_question_asked && <Grid item xs={12} sx={{marginTop: 4}}>
                      <PurifiedTypo text={t("exit.feedback_questions.content")} sx={{marginBottom: 2}} />

                      {(this.state.exit_information?.exit_questions || []).map(exit_q => (<Box><FormControlLabel
                        control={<Checkbox
                          checked={this.state.feedback?.choice?.indexOf(exit_q.id) > -1}
                          onChange={(v) => {
                            let new_choice = [...this.state.feedback.choice];
                            if (v.target.checked) {
                              new_choice.push(exit_q.id);
                            } else {
                              var index = new_choice.indexOf(exit_q.id);
                              if (index !== -1) {
                                new_choice.splice(index, 1);
                              }
                            }
                            this.setState({feedback: {...this.state.feedback, choice: new_choice}});
                          }}
                        />}
                        label={exit_q.name.replace(/<[^>]*>?/gm, '')}
                      /></Box>))}
                      <Box>
                        <FormControlLabel
                          control={<Checkbox
                            checked={this.state.feedback?.choice?.indexOf(0) > -1}
                            onChange={(v) => {
                              let new_choice = [...this.state.feedback.choice];
                              if (v.target.checked) {
                                new_choice.push(0);
                              } else {
                                var index = new_choice.indexOf(0);
                                if (index !== -1) {
                                  new_choice.splice(index, 1);
                                }
                              }
                              this.setState({feedback: {...this.state.feedback, choice: new_choice}});
                            }}
                          />}
                          label={t("exit.reasons.other").replace(/<[^>]*>?/gm, '')}
                        />
                        {this.state.feedback?.choice?.indexOf(0) > -1 && <React.Fragment>
                          <br /><StyledTextField
                            label={""}
                            value={this.state.feedback.comment}
                            id="other"
                            onChange={(v) => {this.setState({feedback: {...this.state.feedback, comment: v}});}}
                            multiline
                          />
                        </React.Fragment>}
                      </Box>
                      <Box>
                        <StyledButton
                          disabled={this.state.feedback?.choice?.length < 1 || (this.state.feedback.choice.indexOf(0) > -1 && this.state.feedback.comment.length < 5)}
                          text={t("exit.cta.send_feedback").replace(/<[^>]*>?/gm, '')}
                          theme={this.props.theme}
                          sx={{marginTop: this.props.theme.spacing(4)}}
                          onClick={(e) => {
                          this.send_feedback();
                          }}
                        />
                      </Box>
                    </Grid>}
                  </Grid>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                </Grid>}

                {!!exit_started && !!docs_uploaded && !!argenco_checked && <Grid container item xs={12} sx={{marginTop: 4}}>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                  <Grid container item xs={12} md={8}>
                    <Grid item xs={12}>
                      <PurifiedTypo text={t("exit.intro.paid.content")} />
                    </Grid>
                    
                    {!this.state.exit_information.exit_question_asked && <Grid item xs={12} sx={{marginTop: 4}}>
                      <PurifiedTypo text={t("exit.feedback_questions.content")} sx={{marginBottom: 2}} />

                      {(this.state.exit_information?.exit_questions || []).map(exit_q => (<Box><FormControlLabel
                        control={<Checkbox
                          checked={this.state.feedback?.choice?.indexOf(exit_q.id) > -1}
                          onChange={(v) => {
                            let new_choice = [...this.state.feedback.choice];
                            if (v.target.checked) {
                              new_choice.push(exit_q.id);
                            } else {
                              var index = new_choice.indexOf(exit_q.id);
                              if (index !== -1) {
                                new_choice.splice(index, 1);
                              }
                            }
                            this.setState({feedback: {...this.state.feedback, choice: new_choice}});
                          }}
                        />}
                        label={exit_q.name.replace(/<[^>]*>?/gm, '')}
                      /></Box>))}
                      <Box>
                        <FormControlLabel
                          control={<Checkbox
                            checked={this.state.feedback?.choice?.indexOf(0) > -1}
                            onChange={(v) => {
                              let new_choice = [...this.state.feedback.choice];
                              if (v.target.checked) {
                                new_choice.push(0);
                              } else {
                                var index = new_choice.indexOf(0);
                                if (index !== -1) {
                                  new_choice.splice(index, 1);
                                }
                              }
                              this.setState({feedback: {...this.state.feedback, choice: new_choice}});
                            }}
                          />}
                          label={t("exit.reasons.other").replace(/<[^>]*>?/gm, '')}
                        />
                        {this.state.feedback?.choice?.indexOf(0) > -1 && <React.Fragment>
                          <br /><StyledTextField
                            label={""}
                            value={this.state.feedback.comment}
                            id="other"
                            onChange={(v) => {this.setState({feedback: {...this.state.feedback, comment: v}});}}
                            multiline
                          />
                        </React.Fragment>}
                      </Box>
                      <Box>
                        <StyledButton
                          disabled={this.state.feedback?.choice?.length < 1 || (this.state.feedback.choice.indexOf(0) > -1 && this.state.feedback.comment.length < 5)}
                          text={t("exit.cta.send_feedback").replace(/<[^>]*>?/gm, '')}
                          theme={this.props.theme}
                          sx={{marginTop: this.props.theme.spacing(4)}}
                          onClick={(e) => {
                          this.send_feedback();
                          }}
                        />
                      </Box>
                    </Grid>}
                  </Grid>
                  <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                </Grid>}
              </React.Fragment>}
            </Authenticated>
          </Grid>}
        </Grid>
      </Container>
    </Page>);
  }
}

Exit.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  },
  dropZoneText: {
    fontFamily: theme.typography.fontFamily
  },
  preview: {
    maxWidth: '100%',
    '& img': {
      maxHeight: '150px'
    },

  },
  preview_grid: {
    width: '100%',
    margin: '0px',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropzoneMainClass: {
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiDropzonePreviewList-removeButton': {
      right: '8px'
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: '8px'
    }
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Exit))));
