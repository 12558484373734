import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  CircularProgress,
  Container,
  Grid
} from '@mui/material';


import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import StyledTextField from "../shared/parts/StyledTextField";
import Typography from "../shared/parts/Typography";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      contact: {
        first_name: !!this.props.login ? this.props.login.first_name : '',
        last_name: !!this.props.login ? this.props.login.last_name : '',
        email: !!this.props.login ? this.props.login.email : '',
        reason: '',
        message: '',
        number: !!this.props.login ? this.props.login.vennoot_number : null
      }
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {}

  send_contact = (event) => {
    event.preventDefault();

    if (!this.state.loading) {
      this.setState({loading: true, success: false, show_error: false}, () => {
        let errors = {};
        if (typeof(this.state.contact.first_name) === 'undefined' || this.state.contact.first_name === null || this.state.contact.first_name.trim() === "") errors.first_name = true;
        if (typeof(this.state.contact.last_name) === 'undefined' || this.state.contact.last_name === null || this.state.contact.last_name.trim() === "") errors.last_name = true;
        if (typeof(this.state.contact.email) === 'undefined' || this.state.contact.email === null || this.state.contact.email.trim() === "") errors.email = true;
        if (typeof(this.state.contact.reason) === 'undefined' || this.state.contact.reason === null || this.state.contact.reason.trim() === "") errors.reason = true;
        if (typeof(this.state.contact.message) === 'undefined' || this.state.contact.message === null || this.state.contact.message.trim() === "") errors.message = true;

        if (Object.keys(errors).length === 0) {
          this.props.helpers.contacts.submit({
            locale: this.props.i18n.language,
            login: this.props.login,
            callback: (r) => {
              this.setState({loading: false, success: true, contact: {
                first_name: !!this.props.login ? this.props.login.first_name : '',
                last_name: !!this.props.login ? this.props.login.last_name : '',
                email: !!this.props.login ? this.props.login.email : '',
                reason: '',
                message: '',
                number: !!this.props.login ? this.props.login.vennoot_number : null
              }});
            },
            error_callback: (r) => {
              this.setState({loading: false, success: false, show_error: true});
            },
            parameters: {
              contact: this.state.contact
            }
          });
        } else {
          this.setState({errors: errors});
        }
      });
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.component_title} sx={{textAlign: 'center'}}>
              {t(`contact.title`).replace(/<[^>]*>?/gm, '')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
            <PurifiedTypo text={t("contact.seo_paragraph")} />
          </Grid>
          <Grid item xs={12} sx={{marginTop: 2}}>&nbsp;</Grid>
        </Grid>

        <Grid container spacing="2">
          <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 100}}>
            {this.props.login && this.props.login.is_exception && <Typography>{t("contact_argenta_message")}</Typography>}
            {(!this.props.login || (this.props.login && !this.props.login.is_exception)) && <Grid container sx={{marginTop: 2}} spacing={2}>
              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
              <Grid item xs={12} sm={6} md={3}>
                <StyledTextField
                  disabled={this.state.loading}
                  label={t("contact_field_first_name").replace(/<[^>]*>?/gm, '')}
                  value={this.state.contact.first_name}
                  onChange={(v) => {this.setState({contact: {...this.state.contact, first_name: v}});}}
                 />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StyledTextField
                  disabled={this.state.loading}
                  label={t("contact_field_last_name").replace(/<[^>]*>?/gm, '')}
                  value={this.state.contact.last_name}
                  onChange={(v) => {this.setState({contact: {...this.state.contact, last_name: v}});}}
                 />
              </Grid>
              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>

              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  disabled={this.state.loading}
                  label={t("contact_field_email").replace(/<[^>]*>?/gm, '')}
                  value={this.state.contact.email}
                  onChange={(v) => {this.setState({contact: {...this.state.contact, email: v}});}}
                 />
              </Grid>
              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>

              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  disabled={this.state.loading}
                  label={t("contact_field_reason").replace(/<[^>]*>?/gm, '')}
                  value={this.state.contact.reason}
                  onChange={(v) => {this.setState({contact: {...this.state.contact, reason: v}});}}
                 />
              </Grid>
              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>

              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  disabled={this.state.loading}
                  label={t("contact_field_message").replace(/<[^>]*>?/gm, '')}
                  value={this.state.contact.message}
                  onChange={(v) => {this.setState({contact: {...this.state.contact, message: v}});}}
                  multiline
                  rows={4}
                 />
              </Grid>
              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>

              {(this.state.success || this.state.show_error) && <Grid item xs={12} sx={{textAlign: 'center'}}>
                {this.state.success && <Typography sx={{textAlign: 'center', color: this.props.theme.palette.primary.main}}>{t(`contact.submitted.success`)}</Typography>}
                {this.state.show_error && <Typography sx={{textAlign: 'center', color: 'red'}}>{t(`contact.submitted.error`)}</Typography>}
              </Grid>}

              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
              <Grid item xs={12} md={6} sx={{textAlign: 'right'}}>
                {!this.state.loading && <StyledButton text={t("contact_cta_send").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} disabled={this.state.contact.first_name.length < 1 || this.state.contact.last_name.length < 1 || this.state.contact.email.length < 1 || this.state.contact.reason.length < 5 || this.state.contact.message.length < 10} onClick={(e) => {
                  this.send_contact(e);
                }} />}
                {!!this.state.loading && <CircularProgress />}
                <Box display={{xs: "block", md: 'none'}} sx={{marginTop: 2}}>
                  <StyledButton text={t("menu.death").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} secondary />
                </Box>
              </Grid>
              <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}>
                {/* <StyledButton text={t("menu.death").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} secondary onClick={() => {this.props.history.push({pathname: '/overlijden'}); window.scrollTo(0, 0);}} /> */}
              </Box>
            </Grid>}
          </Grid>
        </Grid>
      </Container>
    </Page>);
  }
}

Contact.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Contact))));
