import { connect } from 'react-redux';
import NewsItem from '../../actions/news';
import NewsTag from '../../actions/news_tag';
import News from '../../components/pages/News';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      news: {
        fetch_all: (_params) => {
          dispatch(NewsItem.fetch_all(_params));
        }
      },
      news_tag: {
        fetch_all: (_params) => {
          dispatch(NewsTag.fetch_all(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(News);
