import { connect } from 'react-redux';
import Investment from '../../actions/investment';
import InvestmentItem from '../../components/pages/InvestmentItem';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      investments: {
        fetch_all: (_params) => {
          dispatch(Investment.fetch_all(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(InvestmentItem);
