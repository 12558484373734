import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import {DropzoneArea} from 'material-ui-dropzone';

import BirthDate from '../shared/parts/onboarding/BirthDate';
import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import StyledTextField from "../shared/parts/StyledTextField";
import Typography from "../shared/parts/Typography";

var he = require('he');

class ReportDeath extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      mode: null,
      user: {
        first_name: '',
        last_name: '',
        street: '',
        number: '',
        zip: '',
        city: '',
        phone: '',
        mobile: '',
        email: '',
        office_number: '',
        office_name: '',
        employee_name: ''
      },
      deceased: {
        first_name: '',
        last_name: '',
        birth_date: '',
        birth_place: '',
        death_date: '',
        death_place: '',
        marital_status: null,
        spouse: '',
        notary_status: '',
        notary: '',
        attest: null
      },
      errors: {},
      success: false,
      no_success: false
    };
  }

  componentDidMount() {}

  save_death(event) {
    event.preventDefault();

    let errors = {};
    if (this.state.mode === 0 || this.state.mode === 3) {
      if (typeof(this.state.user.first_name) === 'undefined' || this.state.user.first_name === null || this.state.user.first_name.trim() === "") errors.first_name = true;
      if (typeof(this.state.user.last_name) === 'undefined' || this.state.user.last_name === null || this.state.user.last_name.trim() === "") errors.last_name = true;
      if (typeof(this.state.user.street) === 'undefined' || this.state.user.street === null || this.state.user.street.trim() === "") errors.street = true;
      if (typeof(this.state.user.number) === 'undefined' || this.state.user.number === null || this.state.user.number.trim() === "") errors.number = true;
      if (typeof(this.state.user.zip) === 'undefined' || this.state.user.zip === null || this.state.user.zip.trim() === "") errors.zip = true;
      if (typeof(this.state.user.city) === 'undefined' || this.state.user.city === null || this.state.user.city.trim() === "") errors.city = true;
      if (typeof(this.state.user.email) === 'undefined' || this.state.user.email === null || this.state.user.email.trim() === "") errors.email = true;
      if (typeof(this.state.user.phone) === 'undefined' || this.state.user.phone === null || this.state.user.phone.trim() === "") errors.phone = true;
      if (typeof(this.state.user.mobile) === 'undefined' || this.state.user.mobile === null || this.state.user.mobile.trim() === "") errors.mobile = true;
    }
    if (this.state.mode === 1) {
      if (typeof(this.state.user.office_number) === 'undefined' || this.state.user.office_number === null || this.state.user.office_number.trim() === "") errors.office_number = true;
      if (typeof(this.state.user.employee_name) === 'undefined' || this.state.user.employee_name === null || this.state.user.employee_name.trim() === "") errors.employee_name = true;
      if (typeof(this.state.user.email) === 'undefined' || this.state.user.email === null || this.state.user.email.trim() === "") errors.email = true;
      if (typeof(this.state.user.phone) === 'undefined' || this.state.user.phone === null || this.state.user.phone.trim() === "") errors.phone = true;
    }
    if (this.state.mode === 2) {
      if (typeof(this.state.user.office_name) === 'undefined' || this.state.user.office_name === null || this.state.user.office_name.trim() === "") errors.office_name = true;
      if (typeof(this.state.user.employee_name) === 'undefined' || this.state.user.employee_name === null || this.state.user.employee_name.trim() === "") errors.employee_name = true;
      if (typeof(this.state.user.email) === 'undefined' || this.state.user.email === null || this.state.user.email.trim() === "") errors.email = true;
      if (typeof(this.state.user.phone) === 'undefined' || this.state.user.phone === null || this.state.user.phone.trim() === "") errors.phone = true;
      if (typeof(this.state.user.zip) === 'undefined' || this.state.user.zip === null || this.state.user.zip.trim() === "") errors.zip = true;
    }
    if (this.state.mode === 3) {
      if (typeof(this.state.user.mode_name) === 'undefined' || this.state.user.mode_name === null || this.state.user.mode_name.trim() === "") errors.mode_name = true;
    }
    if (typeof(this.state.deceased.first_name) === 'undefined' || this.state.deceased.first_name === null || this.state.deceased.first_name.trim() === "") errors.deceased_first_name = true;
    if (typeof(this.state.deceased.last_name) === 'undefined' || this.state.deceased.last_name === null || this.state.deceased.last_name.trim() === "") errors.deceased_last_name = true;
    if (typeof(this.state.deceased.birth_date) === 'undefined' || this.state.deceased.birth_date === null || this.state.deceased.birth_date.trim() === "") errors.deceased_birth_date = true;
    if (typeof(this.state.deceased.birth_place) === 'undefined' || this.state.deceased.birth_place === null || this.state.deceased.birth_place.trim() === "") errors.deceased_birth_place = true;
    if (typeof(this.state.deceased.death_date) === 'undefined' || this.state.deceased.death_date === null || this.state.deceased.death_date.trim() === "") errors.deceased_death_date = true;
    if (typeof(this.state.deceased.death_place) === 'undefined' || this.state.deceased.death_place === null || this.state.deceased.death_place.trim() === "") errors.deceased_death_place = true;
    if (typeof(this.state.deceased.attest) === 'undefined' || this.state.deceased.attest === null) errors.deceased_attest = true;

    if (this.state.deceased.marital_status.toString() === '1' && (typeof(this.state.deceased.spouse) === 'undefined' || this.state.deceased.spouse === null || this.state.deceased.spouse.trim() === "")) errors.spouse = true;
    if (this.state.deceased.notary_status.toString() === '1' && (typeof(this.state.deceased.notary) === 'undefined' || this.state.deceased.notary === null || this.state.deceased.notary.trim() === "")) errors.deceased_notary = true;

    if (Object.keys(errors).length === 0) {
      let data = new FormData();
      data.append('mode', this.state.mode);
      data.append('item', this.state.deceased.attest);
      Object.keys(this.state.user).forEach(key => data.append(`user[${key}]`, this.state.user[key]));
      Object.keys(this.state.deceased).forEach(key => data.append(`deceased[${key}]`, this.state.deceased[key]));

      this.props.helpers.death_messages.submit({
        locale: this.props.i18n.language,
        callback: (r) => {
          if (!!r.success) {
            this.setState({return_value: r, success: true});
          } else {
            this.setState({return_value: r, no_success: true});
          }
        },
        parameters: data
        // parameters: {
        //   mode: this.state.mode,
        //   user: this.state.user,
        //   deceased: this.state.deceased,
        //   item: this.state.deceased.attest
        // }
      });
    } else {
      this.setState({errors: errors});
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.component_title} sx={{textAlign: 'center'}}>
              {t(`report_death.title`).replace(/<[^>]*>?/gm, '')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
            <PurifiedTypo text={t("report_death.explanation_general")} />
          </Grid>
          <Grid item xs={12} sx={{marginTop: 2}}>&nbsp;</Grid>
        </Grid>

        {!!this.state.success && <Grid container spacing={2} style={{marginTop: this.props.theme.spacing(2)}}>
          <Grid item xs={12} align="center">
            <PurifiedTypo text={t("report_death.explanation_success").replace(/<[^>]*>?/gm, '')} />
            <StyledButton text={t("general.actions.back_to_root").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {
              this.props.history.push({pathname: "/", state: {}});
            }} />
          </Grid>
        </Grid>}

        {!!this.state.no_success && <Grid container spacing={2} style={{marginTop: this.props.theme.spacing(2)}}>
          <Grid item xs={12} align="center">
            <PurifiedTypo text={t("report_death.explanation_error").replace(/<[^>]*>?/gm, '')} />
            <StyledButton text={t("general.actions.back_to_root").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {
              this.props.history.push({pathname: "/", state: {}});
            }} />
          </Grid>
        </Grid>}

        {!this.state.success && !this.state.no_success && <Grid container spacing={2} style={{marginTop: this.props.theme.spacing(2)}}>
          <Grid container xs={12} md={6}>

            <Container sx={{paddingLeft: 0, paddingRight: 0}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">{t("report_death.my_data.title").replace(/<[^>]*>?/gm, '')}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="report_death_mode">{he.decode(t("report_death.my_data.mode").replace(/<[^>]*>?/gm, ''))}</InputLabel>
                    <Select
                      labelId="report_death_mode"
                      id="report_death_mode_select"
                      value={this.state.mode}
                      label={he.decode(t("report_death.my_data.mode").replace(/<[^>]*>?/gm, ''))}
                      onChange={(e) => {this.setState({mode: e.target.value});}}
                    >
                      <MenuItem value={0}>{he.decode(t("report_death.my_data.modes.heir").replace(/<[^>]*>?/gm, ''))}</MenuItem>
                      {/* <MenuItem value={1}>{t("report_death.my_data.modes.office").replace(/<[^>]*>?/gm, '')}</MenuItem> */}
                      <MenuItem value={2}>{he.decode(t("report_death.my_data.modes.notary").replace(/<[^>]*>?/gm, ''))}</MenuItem>
                      <MenuItem value={3}>{he.decode(t("report_death.my_data.modes.other").replace(/<[^>]*>?/gm, ''))}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[3].includes(this.state.mode) && <StyledTextField
                    label={t("report_death.my_data.field.mode_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.mode_name}
                    id="mode_name"
                    onChange={(v) => {this.setState({user: {...this.state.user, mode_name: v}});}}
                    error={this.state.errors.mode_name}
                  />}
                </Grid>

                {[0, 3].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.first_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.first_name}
                    id="first_name"
                    onChange={(v) => {this.setState({user: {...this.state.user, first_name: v}});}}
                    error={this.state.errors.first_name}
                  />
                </Grid>}
                {[0, 3].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.last_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.last_name}
                    id="last_name"
                    onChange={(v) => {this.setState({user: {...this.state.user, last_name: v}});}}
                    error={this.state.errors.last_name}
                  />
                </Grid>}

                {[2].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.office_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.office_name}
                    id="office_name"
                    onChange={(v) => {this.setState({user: {...this.state.user, office_name: v}});}}
                    error={this.state.errors.office_name}
                  />
                </Grid>}
                {[1].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.office_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.office_number}
                    id="office_number"
                    onChange={(v) => {this.setState({user: {...this.state.user, office_number: v}});}}
                    error={this.state.errors.office_number}
                  />
                </Grid>}
                {[1, 2].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.contact").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.employee_name}
                    id="contact"
                    onChange={(v) => {this.setState({user: {...this.state.user, employee_name: v}});}}
                    error={this.state.errors.employee_name}
                  />
                </Grid>}

                {[0, 3].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.street").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.street}
                    id="street"
                    onChange={(v) => {this.setState({user: {...this.state.user, street: v}});}}
                    error={this.state.errors.street}
                  />
                </Grid>}
                {[0, 3].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.number").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.number}
                    id="number"
                    onChange={(v) => {this.setState({user: {...this.state.user, number: v}});}}
                    error={this.state.errors.number}
                  />
                </Grid>}

                {[0, 2, 3].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.zip").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.zip}
                    id="zip"
                    onChange={(v) => {this.setState({user: {...this.state.user, zip: v}});}}
                    error={this.state.errors.zip}
                  />
                </Grid>}
                {[0, 3].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.city").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.city}
                    id="city"
                    onChange={(v) => {this.setState({user: {...this.state.user, city: v}});}}
                    error={this.state.errors.city}
                  />
                </Grid>}
                {[2].includes(this.state.mode) && <Grid item xs={12} md={6}></Grid>}

                {[0, 1, 2, 3].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.email").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.email}
                    id="email"
                    onChange={(v) => {this.setState({user: {...this.state.user, email: v}});}}
                    error={this.state.errors.email}
                    type="email"
                  />
                </Grid>}
                {[0, 3].includes(this.state.mode) && <Grid item xs={12} md={6}></Grid>}

                {[0, 1, 2, 3].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.phone").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.phone}
                    id="phone"
                    onChange={(v) => {this.setState({user: {...this.state.user, phone: v}});}}
                    error={this.state.errors.phone}
                  />
                </Grid>}
                {[0, 3].includes(this.state.mode) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.my_data.field.mobile").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.mobile}
                    id="mobile"
                    onChange={(v) => {this.setState({user: {...this.state.user, mobile: v}});}}
                    error={this.state.errors.mobile}
                  />
                </Grid>}
              </Grid>
            </Container>
          </Grid>

          <Grid container xs={12} md={6}>

            <Container sx={{paddingLeft: 0, paddingRight: 0}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">{t("report_death.deceased_data.title").replace(/<[^>]*>?/gm, '')}</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.deceased_data.field.first_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.deceased.first_name}
                    id="deceased_first_name"
                    onChange={(v) => {this.setState({deceased: {...this.state.deceased, first_name: v}});}}
                    error={this.state.errors.deceased_first_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.deceased_data.field.last_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.deceased.last_name}
                    id="deceased_last_name"
                    onChange={(v) => {this.setState({deceased: {...this.state.deceased, last_name: v}});}}
                    error={this.state.errors.deceased_last_name}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <BirthDate
                    label={he.decode(t("report_death.deceased_data.field.birth_date").replace(/<[^>]*>?/gm, ''))}
                    value={this.state.deceased.birth_date}
                    id="deceased_birth_date"
                    onChange={(e) => {this.setState({deceased: {...this.state.deceased, birth_date: e.target.value}});}}
                    error={this.state.errors.deceased_birth_date}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.deceased_data.field.birth_place").replace(/<[^>]*>?/gm, '')}
                    value={this.state.deceased.birth_place}
                    id="deceased_birth_place"
                    onChange={(v) => {this.setState({deceased: {...this.state.deceased, birth_place: v}});}}
                    error={this.state.errors.deceased_birth_place}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <BirthDate
                    label={he.decode(t("report_death.deceased_data.field.death_date").replace(/<[^>]*>?/gm, ''))}
                    value={this.state.deceased.death_date}
                    id="deceased_death_date"
                    onChange={(e) => {this.setState({deceased: {...this.state.deceased, death_date: e.target.value}});}}
                    error={this.state.errors.deceased_death_date}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.deceased_data.field.death_place").replace(/<[^>]*>?/gm, '')}
                    value={this.state.deceased.death_place}
                    id="deceased_death_place"
                    onChange={(v) => {this.setState({deceased: {...this.state.deceased, death_place: v}});}}
                    error={this.state.errors.deceased_death_place}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="report_death_deceased_data_field_marital_status">{he.decode(t("report_death.deceased_data.field.marital_status").replace(/<[^>]*>?/gm, ''))}</InputLabel>
                    <Select
                      labelId="report_death_deceased_data_field_marital_status"
                      id="report_death_deceased_data_field_marital_status_select"
                      value={this.state.marital_status}
                      label={he.decode(t("report_death.deceased_data.field.marital_status").replace(/<[^>]*>?/gm, ''))}
                      onChange={(e) => {this.setState({deceased: {...this.state.deceased, marital_status: e.target.value}});}}
                    >
                      <MenuItem value={0}>{he.decode(t("report_death.my_data.marital_statusses.unmarried").replace(/<[^>]*>?/gm, ''))}</MenuItem>
                      <MenuItem value={1}>{he.decode(t("report_death.my_data.marital_statusses.married").replace(/<[^>]*>?/gm, ''))}</MenuItem>
                      <MenuItem value={2}>{he.decode(t("report_death.my_data.marital_statusses.widowed").replace(/<[^>]*>?/gm, ''))}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {(!this.state.deceased.marital_status || [0, 2].includes(this.state.deceased.marital_status)) && <Grid item xs={12} md={6}></Grid>}
                {[1].includes(this.state.deceased.marital_status) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.deceased_data.field.spouse_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.deceased.spouse}
                    id="deceased_spouse"
                    onChange={(v) => {this.setState({deceased: {...this.state.deceased, spouse: v}});}}
                    error={this.state.errors.spouse}
                  />
                </Grid>}

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="report_death_deceased_data_field_notary_status">{he.decode(t("report_death.deceased_data.field.notary_status").replace(/<[^>]*>?/gm, ''))}</InputLabel>
                    <Select
                      labelId="report_death_deceased_data_field_notary_status"
                      id="report_death_deceased_data_field_notary_status_select"
                      value={this.state.notary_status}
                      label={he.decode(t("report_death.deceased_data.field.notary_status").replace(/<[^>]*>?/gm, ''))}
                      onChange={(e) => {this.setState({deceased: {...this.state.deceased, notary_status: e.target.value}});}}
                    >
                      <MenuItem value={1}>{he.decode(t("report_death.my_data.notary_statusses.yes").replace(/<[^>]*>?/gm, ''))}</MenuItem>
                      <MenuItem value={0}>{he.decode(t("report_death.my_data.notary_statusses.no").replace(/<[^>]*>?/gm, ''))}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {(!this.state.deceased.notary_status || [0].includes(this.state.deceased.notary_status)) && <Grid item xs={12} md={6}></Grid>}
                {[1].includes(this.state.deceased.notary_status) && <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("report_death.deceased_data.field.notary_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.deceased.notary}
                    id="deceased_notary"
                    onChange={(v) => {this.setState({deceased: {...this.state.deceased, notary: v}});}}
                    error={this.state.errors.deceased_notary}
                  />
                </Grid>}

                <Grid item xs={12}>
                  {/* <Typography variant="caption">{t("report_death.deceased_data.field.attest").replace(/<[^>]*>?/gm, '')}</Typography> */}
                  <PurifiedTypo text={t("report_death.deceased_data.field.attest")} sx={{fontSize: '0.75rem'}} />
                  <DropzoneArea
                    acceptedFiles={['image/*', '.pdf']}
                    filesLimit={1}
                    dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
                    onChange={(files) => {this.setState({deceased: {...this.state.deceased, attest: files[0]}});}}
                    maxFileSize={3000000}
                    previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                    dropzoneParagraphClass={classes.dropZoneText}
                    previewGridClasses={{
                      item: classes.preview,
                      container: classes.preview_grid
                    }}
                    dropzoneClass={classes.dropzoneMainClass}
                  />
                  {!!this.state.deceased.attest && this.state.deceased.attest.name && <Typography style={{fontSize: '0.8em'}}>{t("general.current_file", {name: this.state.deceased.attest.name}).replace(/<[^>]*>?/gm, '')}</Typography>}
                </Grid>

                <Grid item xs={12} align="right">
                  <StyledButton text={t("report_death.actions.save").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {this.save_death(e);}} />
                </Grid>

              </Grid>
            </Container>
          </Grid>
        </Grid>}
      </Container>
    </Page>);
  }
}

ReportDeath.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  },
  dropZoneText: {
    fontFamily: theme.typography.fontFamily
  },
  preview: {
    maxWidth: '100%',
    '& img': {
      maxHeight: '150px'
    },

  },
  preview_grid: {
    width: '100%',
    margin: '0px',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropzoneMainClass: {
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiDropzonePreviewList-removeButton': {
      right: '8px'
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: '8px'
    }
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(ReportDeath))));
