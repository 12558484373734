import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Button,
  ButtonGroup
} from '@mui/material';

var he = require('he');

class StyledButtonGroup extends Component {
  render() {
    const {theme, options, onClick, selected, selected_colours, sx} = this.props;

    return (<ButtonGroup variant="outlined" sx={{
      marginTop: 2,
      ...sx
    }}>
      {options.map((option, i) => (<Button
        onClick={() => {onClick(option.id);}}
        variant={selected === option.id ? 'contained' : 'outlined'}
        color="tertiary"
        sx={{
          color: selected === option.id ? 'white' : theme.palette.tertiary.main,
          // backgroundColor: theme.palette.tertiary.main,
          // borderColor: selected === option.id ? `${(!!selected_colours ? selected_colours[i] : theme.palette.tertiary.main)} !important` : theme.palette.tertiary.main,
          ...(i === 0 ? {
            // borderRadius: '20px 0px 0px 20px',
            marginRight: '1px'
          } : {}),
          ...(i === options.length - 1 ? {
            // borderRadius: '0px 20px 20px 0px'
          } : {})
        }}
      >
        {he.decode(option.label)}
      </Button>))}
    </ButtonGroup>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(StyledButtonGroup));
