import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Container,
  Grid
} from '@mui/material';


import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import Typography from "../shared/parts/Typography";

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: !!props.location.state && !!props.location.state.active ? props.location.state.active : 'statutes'
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    if (prevState.active === this.state.active && this.props.location.state.active !== this.state.active) {
      this.setState({active: this.props.location.state.active});
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Typography variant="h3" className={classes.component_title}>
          {t(`about.${this.state.active}.title`).replace(/<[^>]*>?/gm, '')}
        </Typography>

        <Grid container spacing="2" style={{marginTop: this.props.theme.spacing(2)}}>
          <Grid item xs={12} md={9} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 100}}>
            <PurifiedTypo text={t(`faq.${this.state.active}.content`)} style={{}} />
          </Grid>
          <Grid item xs={12} md={3} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 100}}>
            <StyledButton text={t("about.statutes.title").replace(/<[^>]*>?/gm, '')} as_link={this.state.active !== 'statutes'} transform_none theme={this.props.theme} style={{}} onClick={(e) => {this.setState({active: 'statutes'});}} />
            <StyledButton text={t("about.internal_rules.title").replace(/<[^>]*>?/gm, '')} as_link={this.state.active !== 'internal_rules'} transform_none theme={this.props.theme} style={{}} onClick={(e) => {this.setState({active: 'internal_rules'});}} />
            <StyledButton text={t("about.year_report.title").replace(/<[^>]*>?/gm, '')} as_link={this.state.active !== 'year_report'} transform_none theme={this.props.theme} style={{}} onClick={(e) => {this.setState({active: 'year_report'});}} />
            <StyledButton text={t("about.previous_emissions.title").replace(/<[^>]*>?/gm, '')} as_link={this.state.active !== 'previous_emissions'} transform_none theme={this.props.theme} style={{}} onClick={(e) => {this.setState({active: 'previous_emissions'});}} />
          </Grid>
        </Grid>
      </Container>
    </Page>);
  }
}

About.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(About))));
