import { connect } from 'react-redux';
import Benefit from '../../actions/benefit';
import Devise from '../../actions/devise';
import News from '../../actions/news';
import Footer from '../../components/shared/Footer';

const map_state_to_props = state => ({
  login: state.devise.data,
  drawer: state.drawer
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      benefits: {
        fetch_all: (_params) => {
          dispatch(Benefit.fetch_all(_params));
        }
      },
      news: {
        fetch_all: (_params) => {
          dispatch(News.fetch_all(_params));
        }
      }
    },
    logout: (_params) => {
      dispatch(Devise.sign_out(_params));
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Footer);
