import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';
import InputMask from 'react-input-mask';

import {
  CircularProgress,
  Grid
} from '@mui/material';

import BirthDate from './onboarding/BirthDate';
import OtpField from './onboarding/OtpField';
import PurifiedTypo from './PurifiedTypo';
import StyledButton from './StyledButton';
import Vennr from './onboarding/Vennr';

var he = require('he');

class Authenticated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      authenticated: false,
      user: {},
      vennr: '',
      birth_date: '',
      errors: {},
      show_error: false,
      otp_code: '',
      show_otp_code: false
    };
  }

  validate_simple = (event) => {
    this.setState({loading: true}, () => {
      event.preventDefault();

      let errors = {};
      if (typeof(this.state.vennr) === 'undefined' || this.state.vennr === null || this.state.vennr.trim() === "") errors.vennr = true;
      if (typeof(this.state.birth_date) === 'undefined' || this.state.birth_date === null || this.state.birth_date.toString().trim() === "") errors.birth_date = true;

      if (Object.keys(errors).length === 0) {
        this.props.helpers.users.validate_simple({
          locale: this.props.i18n.language,
          login: this.props.login,
          callback: (r, full_data) => {
            if (r.data === "mfa_status_0_verification") {
              this.setState({loading: false, show_otp_code: true});
            } else {
              this.setState({user: {vennr: this.state.vennr, birth_date: this.state.birth_date, ...r}, authenticated: !!r.first_name, loading: false}, () => {
                if (!!this.props.onAuthenticate) this.props.onAuthenticate(this.state.user, this.state.authenticated);
              });
            }
          },
          error_callback: (r) => {
            this.setState({show_error: true, loading: false});
          },
          parameters: {
            vennr: this.state.vennr,
            birth_date: this.state.birth_date,
            otp_code: this.state.otp_code,
            fields: (this.props.fields ? this.props.fields : ['first_name', 'last_name', 'stock_number', 'share_type|tag', 'mfa_status'])
          }
        });
      } else {
        this.setState({loading: false, errors: errors});
      }
    });
  }

  render() {
    const {login, forceAuth, t} = this.props;
    let custom_vennr_field = t("authentication.vennr");
    if (!!this.props.vennr_label) custom_vennr_field = this.props.vennr_label;

    return (<React.Fragment>
      {((!forceAuth && !!this.props.login) || !!this.state.authenticated) && this.props.children}
      {(!!forceAuth || !this.props.login) && !this.state.authenticated && <React.Fragment>
        <Grid xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
        <Grid xs={12} md={6}>
          <Vennr
            onChange={(e) => {this.setState({vennr: e.target.value});}}
            value={this.state.vennr}
            label={he.decode(custom_vennr_field.replace(/<[^>]*>?/gm, ''))}
            id="vennr"
            style={{marginTop: this.props.theme.spacing(4)}}
            error={this.state.errors.vennr}
          />
          <BirthDate
            onChange={(e) => {this.setState({birth_date: e.target.value});}}
            value={this.state.birth_date}
            label={he.decode(t("authentication.birth_date").replace(/<[^>]*>?/gm, ''))}
            id="birth_date"
            style={{marginTop: this.props.theme.spacing(4)}}
            error={this.state.errors.birth_date}
          />
          {this.state.show_otp_code && <React.Fragment>
            <PurifiedTypo text={t("authentication.mfa.auth_short_intro")} sx={{fontSize: '0.9rem', marginTop: 1, '& > p': {marginBottom: '-8px !important'}}} />
            <OtpField
              {...this.props}
              no_verification={true}
              onKeyPress={(event) => {if (event.key === 'Enter' && !(this.state.vennr.length === 0 || this.state.birth_date.length === 0)) this.validate_simple(event);}}
              onSubmit={(code) => {
                // saved upon verification
                this.setState({otp_code: code});
              }}
              sx={{marginTop: 1}}
            /><br />
          </React.Fragment>}
          {!this.state.loading && <StyledButton
            disabled={this.state.vennr.length === 0 || this.state.birth_date.length === 0}
            text={t("authentication.authenticate").replace(/<[^>]*>?/gm, '')}
            theme={this.props.theme}
            sx={{alignSelf: 'flex-end', marginTop: this.props.theme.spacing(4)}}
            onClick={(e) => {this.validate_simple(e);}}
          />}
          {!!this.state.loading && <CircularProgress sx={{marginTop: 1}} />}
          {!this.state.loading && this.state.show_error && <PurifiedTypo text={t("authentication.errors.general")} sx={{marginTop: 2}} />}
        </Grid>
        <Grid xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
      </React.Fragment>}
    </React.Fragment>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(Authenticated));
