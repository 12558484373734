import NewsTag from '../actions/news_tag';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function news_tag(state = initial, action) {
  return NewsTag.state_switch(state, action);
}
