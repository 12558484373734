import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import BirthDate from '../../shared/parts/onboarding/BirthDate';
import OtpField from '../../shared/parts/onboarding/OtpField';
import PurifiedTypo from '../../shared/parts/PurifiedTypo';
import StyledButton from '../../shared/parts/StyledButton';
import Typography from '../../shared/parts/Typography';
import Vennr from '../../shared/parts/onboarding/Vennr';

class StepAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state !== prevProps.state) {
      this.setState(this.props.state);
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Grid container>
      {/* <Grid item sm={1} md={2} sx={{display: {sm: 'block', xs: 'none'}}}></Grid>
      <Grid item xs={12} sm={5} md={4} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingRight: 1.5, paddingBottom: {xs: 2, md: 0}}}>
        <Typography variant="h4">{t("onboarding.register.explanation").replace(/<[^>]*>?/gm, '')}</Typography>
        <StyledButton text={t("onboarding.register.back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{alignSelf: 'flex-start', marginTop: this.props.theme.spacing(4)}} onClick={(e) => {this.setState({show_first_time: false});}} />
      </Grid> */}
      <Grid item xs={1} sm={2} md={4}></Grid>
      <Grid item container xs={10} sm={8} md={4} sx={{minHeight: '300px', paddingLeft: {xs: 0, md: 1.5}}}>
        <Grid item xs={12} sx={{marginTop: 4, marginBottom: 1}}>
          <Typography variant="h3">{t("onboarding.register.step_authentication.title").replace(/<[^>]*>?/gm, '')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <PurifiedTypo text={t("onboarding.register.step_authentication.body").replace(/<[^>]*>?/gm, '')} />
        </Grid>
        <Grid item xs={12}>
          <Vennr
            onChange={(e) => {this.setState({vennr: e.target.value}, () => {this.props.onChangeState(this.state)});}}
            value={this.state.vennr}
            label={t("onboarding.vennr").replace(/<[^>]*>?/gm, '')}
            id="vennr"
            sx={{marginTop: 4}}
            error={this.state.errors.vennr}
          />
        </Grid>
        <Grid item xs={12}>
          <BirthDate
            onChange={(e) => {this.setState({birth_date: e.target.value}, () => {this.props.onChangeState(this.state)});}}
            value={this.state.birth_date}
            label={t("onboarding.birth_date").replace(/<[^>]*>?/gm, '')}
            id="birth_date"
            sx={{marginTop: 2}}
            error={this.state.errors.birth_date}
          />
        </Grid>
        <Grid item xs={12}>
          {this.state.show_ft_otp_code && <React.Fragment>
            <PurifiedTypo text={t("authentication.mfa.auth_short_intro")} sx={{fontSize: '0.9rem', marginTop: 1, '& > p': {marginBottom: '-8px !important'}}} />
            <OtpField
              {...this.props}
              no_verification={true}
              onKeyPress={(event) => {if (event.key === 'Enter' && !(this.state.vennr.length === 0 || this.state.birth_date.length === 0)) this.check_vennr(event);}}
              onSubmit={(code) => {
                // saved upon verification
                this.setState({otp_code: code}, () => {this.props.onChangeState(this.state)});
              }}
              sx={{marginTop: 1}}
            />
          </React.Fragment>}
        </Grid>
        <Grid item xs={12}>
          <StyledButton disabled={this.state.vennr.length === 0 || this.state.birth_date.length === 0} text={t("onboarding.register.cta").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{float: 'right', marginTop: 4}} onClick={(e) => {this.props.OnCheckVennr(e);}} />
        </Grid>
        <Grid item xs={12}>
          <StyledButton text={t("first_time.step_05.buttons.back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{float: 'right', marginTop: this.props.theme.spacing(2)}} secondary onClick={(e) => {this.setState({show_first_time: false}, () => {window.scrollTo(0, 0); this.props.onChangeState(this.state);});}} />
        </Grid>
      </Grid>
      <Grid item xs={1} sm={2} md={4}></Grid>
      {/* <Grid item sm={1} md={2} sx={{display: {sm: 'block', xs: 'none'}}}></Grid> */}
    </Grid>);
  }
}

StepAuth.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(StepAuth))));
