import Frontpager from '../actions/frontpager';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function frontpager(state = initial, action) {
  return Frontpager.state_switch(state, action);
}
