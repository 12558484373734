import { connect } from 'react-redux';
import Devise from '../../actions/devise';
import Otp from '../../actions/otp';
import SecurityQuestion from '../../actions/security_question';
// import Frontpager from '../../actions/frontpager';
import Profile from '../../components/pages/Profile';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      otp: {
        fetch_mfa_status: _params => {
          dispatch(Otp.fetch_mfa_status(_params));
        }
      },
      security_questions: {
        fetch_all: _params => {
          dispatch(SecurityQuestion.fetch_all(_params));
        }
      },
      user: {
        fetch_data: _params => {
          dispatch(Devise.fetch_data(_params));
        },
        save: _params => {
          dispatch(Devise.save(_params));
        }
      }
    },
    renew_sign_in: _params => {
      dispatch(Devise.renew_sign_in(_params));
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Profile);
