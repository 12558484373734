import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import {DateTime} from "luxon";
import {DropzoneArea} from 'material-ui-dropzone';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import BankAccount from "../../../shared/parts/BankAccount";
import BirthDate from "../../../shared/parts/onboarding/BirthDate";
import PurifiedTypo from "../../../shared/parts/PurifiedTypo";
import StyledButton from "../../../shared/parts/StyledButton";
import StyledTextField from "../../../shared/parts/StyledTextField";
import StyledWideButton from "../../../shared/parts/StyledWideButton";
import Typography from "../../../shared/parts/Typography";

var he = require('he');

class EmissionStepProfileVoogdNewFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    ['state'].map(p => {
      if (this.props[p] !== prevProps[p]) {
        this.setState({...this.props[p]});
      }
      return null;
    });
  }

  check_guardian_field = (errors, field) => {
    if (typeof(this.state.guardian[field]) === 'undefined' || this.state.guardian[field] === null || this.state.guardian[field].toString().trim() === "" || (field === 'birth_date' && !DateTime.fromFormat(this.state.user[field], 'dd/MM/yyyy').isValid)) errors[`g_${field}`] = true;
    return errors;
  }

  check_field = (errors, field) => {
    if (typeof(this.state.user[field]) === 'undefined' || this.state.user[field] === null || this.state.user[field].toString().trim() === "" || (field === 'birth_date' && !DateTime.fromFormat(this.state.user[field], 'dd/MM/yyyy').isValid)) errors[field] = true;
    return errors;
  }

  its_okay = (okay) => {
    if (okay) {
      return (<CheckIcon sx={{color: this.props.theme.palette.tertiary.main, position: 'absolute', top: {xs: '17px', md: '17px'}, left: 0}} />);
    } else {
      return (<CloseIcon sx={{color: this.props.theme.palette.secondary.main, position: 'absolute', top: {xs: '17px', md: '17px'}, left: 0}} />);
    }
  }

  create_profile = (event) => {
    this.setState({loading: true}, () => {
      event.preventDefault();

      let formData = new FormData();
      formData.append('user[first_name]', this.state.user.first_name);
      formData.append('user[last_name]', this.state.user.last_name);
      formData.append('user[birth_date]', this.state.user.birth_date);
      formData.append('user[gender]', this.state.user.gender);
      formData.append('user[email]', this.state.user.email);
      // formData.append('user[repeat_email]', this.state.user.repeat_email);
      formData.append('user[mobile]', this.state.user.mobile);
      formData.append('user[street]', this.state.user.street);
      formData.append('user[number]', this.state.user.number);
      formData.append('user[zip]', this.state.user.zip);
      formData.append('user[city]', this.state.user.city);
      formData.append('user[zicht_account]', this.state.user.zicht_account);
      formData.append('user[identity]', this.state.user.identity);
      formData.append('user[vennoot_number]', `B${DateTime.now().toMillis()}`);
      formData.append('step', 'create_guardian_profile');
      formData.append('guardian[first_name]', this.state.guardian.first_name);
      formData.append('guardian[last_name]', this.state.guardian.last_name);
      formData.append('guardian[birth_date]', this.state.guardian.birth_date);
      formData.append('guardian[email]', this.state.guardian.email);
      formData.append('guardian[phone]', this.state.guardian.mobile);
      formData.append('guardian[street]', this.state.guardian.street);
      formData.append('guardian[number]', this.state.guardian.number);
      formData.append('guardian[zip]', this.state.guardian.zip);
      formData.append('guardian[city]', this.state.guardian.city);
      formData.append('guardian[identity]', this.state.guardian.identity);
      formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));

      this.props.helpers.user.emission_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({user: r.user, emission_information: r, step: 2, loading: false, stock_id: r.user.stock_id}, () => {
            this.props.onChangeState(this.state);
            window.scrollTo(0, 0);
          });
        },
        error_callback: (r) => {
          this.setState({show_error: true, loading: false}, () => {
            this.props.onChangeState(this.state);
          });
        },
        parameters: formData
      });
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let errors = {g_age: false};
    [
      'first_name', 'last_name', 'birth_date', 'gender', 'email', 'repeat_email', 'mobile', 'street', 'number', 'zip', 'city', 'zicht_account'
    ].forEach(field => {errors = this.check_field(errors, field);});
    [
      'first_name', 'last_name', 'birth_date', 'email', 'repeat_email', 'mobile', 'street', 'number', 'zip', 'city'
    ].forEach(field => {errors = this.check_guardian_field(errors, field);});

    if (!errors.g_birth_date && !!this.state.guardian.birth_date) {
      if (!!DateTime.fromFormat(this.state.guardian.birth_date, 'dd/MM/yyyy').diffNow('years').years && DateTime.fromFormat(this.state.guardian.birth_date, 'dd/MM/yyyy').diffNow('years').years > -18) {
        errors.g_birth_date = true;
        errors.g_age = true;
      }
    }

    let profile_data = (!errors.first_name && !errors.last_name && !errors.birth_date && !errors.gender && !errors.g_first_name && !errors.g_last_name && !errors.g_birth_date);
    let contact_data = (!errors.email && !errors.repeat_email && !errors.mobile && this.state.user.email === this.state.user.repeat_email);
    if (!!contact_data && !!this.state.guardian.diff_contact) {
      contact_data = (!errors.g_email && !errors.g_repeat_email && !errors.g_mobile && this.state.user.g_email === this.state.user.g_repeat_email);
    }
    let address_data = (!errors.street && !errors.number && !errors.zip && !errors.city);
    if (!!address_data && !!this.state.guardian.diff_address) {
      address_data = (!errors.g_street && !errors.g_number && !errors.g_zip && !errors.g_city);
    }
    let banking_data = (!errors.zicht_account && this.state.ignore?.bank_checked);
    let identity_data = (!!this.state.user.identity && !!this.state.guardian.identity);

    return (<React.Fragment>
      <Grid container spacing="0" sx={{marginTop: 2}}>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
        <Grid container item xs={12} md={6} spacing="8">
          <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.personal_data")}</Typography> {this.its_okay(profile_data)}
          </Grid>
          <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
            <Typography variant="h4" sx={{fontWeight: 'bold', fontFamily: ['Montserrat', 'sans-serif'].join(',')}}>{t("emission.steps.guardian_profile.subtitle.vennoot_data")}</Typography>
          </Grid>
          <Grid item xs={5}>
            <StyledTextField
              label={t("profile.my_data.field.first_name").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.first_name}
              id="first_name"
              onChange={(v) => {this.setState({user: {...this.state.user, first_name: v}});}}
              error={this.state.errors.first_name}
            />
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              label={t("profile.my_data.field.last_name").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.last_name}
              id="last_name"
              onChange={(v) => {this.setState({user: {...this.state.user, last_name: v}});}}
              error={this.state.errors.last_name}
            />
          </Grid>
          <Grid item xs={7}>
            <BirthDate
              onChange={(v) => {this.setState({user: {...this.state.user, birth_date: v.target.value}});}}
              value={this.state.user.birth_date}
              label={t("profile.my_data.field.birth_date").replace(/<[^>]*>?/gm, '')}
              id="birth_date"
              error={this.state.errors.birth_date}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id="gender_label">{t("profile.my_data.field.gender").replace(/<[^>]*>?/gm, '')}</InputLabel>
              <Select
                labelId="gender_label"
                id="gender"
                value={this.state.user.gender}
                label={t("profile.my_data.field.gender").replace(/<[^>]*>?/gm, '')}
                onChange={(v) => {this.setState({user: {...this.state.user, gender: v.target.value}});}}
              >
                <MenuItem value={"M"}>{t("profile.my_data.field.genders.male").replace(/<[^>]*>?/gm, '')}</MenuItem>
                <MenuItem value={"F"}>{t("profile.my_data.field.genders.female").replace(/<[^>]*>?/gm, '')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
            <Typography variant="h4" sx={{fontWeight: 'bold', fontFamily: ['Montserrat', 'sans-serif'].join(',')}}>{t("emission.steps.guardian_profile.subtitle.guardian_data")}</Typography>
          </Grid>
          <Grid item xs={5}>
            <StyledTextField
              label={t("profile.my_data.field.first_name").replace(/<[^>]*>?/gm, '')}
              value={this.state.guardian.first_name}
              id="g_first_name"
              onChange={(v) => {this.setState({guardian: {...this.state.guardian, first_name: v}});}}
              error={this.state.errors.g_first_name}
            />
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              label={t("profile.my_data.field.last_name").replace(/<[^>]*>?/gm, '')}
              value={this.state.guardian.last_name}
              id="g_last_name"
              onChange={(v) => {this.setState({guardian: {...this.state.guardian, last_name: v}});}}
              error={this.state.errors.g_last_name}
            />
          </Grid>
          <Grid item xs={7}>
            <BirthDate
              onChange={(v) => {this.setState({guardian: {...this.state.guardian, birth_date: v.target.value}});}}
              value={this.state.guardian.birth_date}
              label={t("profile.my_data.field.birth_date").replace(/<[^>]*>?/gm, '')}
              id="g_birth_date"
              error={this.state.errors.g_birth_date}
            />
          </Grid>
          {!!errors.g_age && <Grid item xs={12} sx={{marginTop: 1}}>
            <PurifiedTypo text={t("emission.steps.guardian_profile.error_age_too_young")} sx={{lineHeight: '1.5'}} />
          </Grid>}

          <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.contact_data")}</Typography> {this.its_okay(contact_data)}
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.guardian_profile.contact_extra")} sx={{marginTop: '-1em', lineHeight: '1.5', marginBottom: '-2em'}} />
          </Grid>
          <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
            <Typography variant="h4" sx={{fontWeight: 'bold', fontFamily: ['Montserrat', 'sans-serif'].join(',')}}>{t("emission.steps.guardian_profile.subtitle.vennoot_data")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start' }}
              control={<Checkbox
                checked={this.state.user.diff_contact}
                sx={{marginTop: -0.5}}
                onChange={(v) => {this.setState({user: {...this.state.user, diff_contact: v.target.checked, ...(v.target.checked ? {
                  email: '',
                  repeat_email: '',
                  mobile: ''
                } : {
                  email: this.state.guardian.email,
                  repeat_email: this.state.guardian.repeat_email,
                  mobile: this.state.guardian.mobile
                })}});}}
              />}
              label={t("emission.steps.guardian_profile.check.different_contact_data").replace(/<[^>]*>?/gm, '')}
            />
          </Grid>
          {!!this.state.user.diff_contact && <React.Fragment>
            <Grid item xs={12}>
              <StyledTextField
                label={t("profile.my_data.field.email").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.email}
                id="email"
                onChange={(v) => {this.setState({user: {...this.state.user, email: v}});}}
                error={this.state.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label={t("profile.my_data.field.repeat_email").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.repeat_email}
                id="repeat_email"
                onChange={(v) => {this.setState({user: {...this.state.user, repeat_email: v}});}}
                error={this.state.errors.repeat_email}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label={t("profile.my_data.field.mobile").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.mobile}
                id="mobile"
                onChange={(v) => {this.setState({user: {...this.state.user, mobile: v}});}}
                error={this.state.errors.mobile}
              />
            </Grid>
          </React.Fragment>}
          <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
            <Typography variant="h4" sx={{fontWeight: 'bold', fontFamily: ['Montserrat', 'sans-serif'].join(',')}}>{t("emission.steps.guardian_profile.subtitle.guardian_data")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label={t("profile.my_data.field.email").replace(/<[^>]*>?/gm, '')}
              value={this.state.guardian.email}
              id="g_email"
              onChange={(v) => {this.setState({guardian: {...this.state.guardian, email: v}, user: {...this.state.user, ...(this.state.user.diff_contact ? {} : {email: v})}});}}
              error={this.state.errors.g_email}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label={t("profile.my_data.field.repeat_email").replace(/<[^>]*>?/gm, '')}
              value={this.state.guardian.repeat_email}
              id="g_repeat_email"
              onChange={(v) => {this.setState({guardian: {...this.state.guardian, repeat_email: v}, user: {...this.state.user, ...(this.state.user.diff_contact ? {} : {repeat_email: v})}});}}
              error={this.state.errors.g_repeat_email}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label={t("profile.my_data.field.mobile").replace(/<[^>]*>?/gm, '')}
              value={this.state.guardian.mobile}
              id="g_mobile"
              onChange={(v) => {this.setState({guardian: {...this.state.guardian, mobile: v}, user: {...this.state.user, ...(this.state.user.diff_contact ? {} : {mobile: v})}});}}
              error={this.state.errors.g_mobile}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.address_data")}</Typography> {this.its_okay(address_data)}
          </Grid>
          <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
            <Typography variant="h4" sx={{fontWeight: 'bold', fontFamily: ['Montserrat', 'sans-serif'].join(',')}}>{t("emission.steps.guardian_profile.subtitle.vennoot_data")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <StyledTextField
              label={t("profile.my_data.field.street").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.street}
              id="street"
              onChange={(v) => {this.setState({user: {...this.state.user, street: v}, guardian: {...this.state.guardian, street: v}});}}
              error={this.state.errors.street}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledTextField
              label={t("profile.my_data.field.number").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.number}
              id="number"
              onChange={(v) => {this.setState({user: {...this.state.user, number: v}, guardian: {...this.state.guardian, number: v}});}}
              error={this.state.errors.number}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledTextField
              label={t("profile.my_data.field.zip").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.zip}
              id="zip"
              onChange={(v) => {this.setState({user: {...this.state.user, zip: v}, guardian: {...this.state.guardian, zip: v}});}}
              error={this.state.errors.zip}
            />
          </Grid>
          <Grid item xs={8}>
            <StyledTextField
              label={t("profile.my_data.field.city").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.city}
              id="city"
              onChange={(v) => {this.setState({user: {...this.state.user, city: v}, guardian: {...this.state.guardian, city: v}});}}
              error={this.state.errors.city}
            />
          </Grid>
          <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
            <Typography variant="h4" sx={{fontWeight: 'bold', fontFamily: ['Montserrat', 'sans-serif'].join(',')}}>{t("emission.steps.guardian_profile.subtitle.guardian_data")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start' }}
              control={<Checkbox
                checked={this.state.guardian.diff_address}
                sx={{marginTop: -0.5}}
                onChange={(v) => {this.setState({guardian: {...this.state.guardian, diff_address: v.target.checked, ...(!!v.target.checked ? {
                  street: '',
                  number: '',
                  zip: '',
                  city: ''
                } : {
                  street: this.state.user.street,
                  number: this.state.user.number,
                  zip: this.state.user.zip,
                  city: this.state.user.city
                })}});}}
              />}
              label={t("emission.steps.guardian_profile.check.different_address_data").replace(/<[^>]*>?/gm, '')}
            />
          </Grid>
          {!!this.state.guardian.diff_address && <React.Fragment>
            <Grid item xs={8}>
              <StyledTextField
                label={t("profile.my_data.field.street").replace(/<[^>]*>?/gm, '')}
                value={this.state.guardian.street}
                id="g_street"
                onChange={(v) => {this.setState({guardian: {...this.state.guardian, street: v}});}}
                error={this.state.errors.g_street}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label={t("profile.my_data.field.number").replace(/<[^>]*>?/gm, '')}
                value={this.state.guardian.number}
                id="g_number"
                onChange={(v) => {this.setState({guardian: {...this.state.guardian, number: v}});}}
                error={this.state.errors.g_number}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledTextField
                label={t("profile.my_data.field.zip").replace(/<[^>]*>?/gm, '')}
                value={this.state.guardian.zip}
                id="g_zip"
                onChange={(v) => {this.setState({guardian: {...this.state.guardian, zip: v}});}}
                error={this.state.errors.g_zip}
              />
            </Grid>
            <Grid item xs={8}>
              <StyledTextField
                label={t("profile.my_data.field.city").replace(/<[^>]*>?/gm, '')}
                value={this.state.guardian.city}
                id="g_city"
                onChange={(v) => {this.setState({guardian: {...this.state.guardian, city: v}});}}
                error={this.state.errors.g_city}
              />
            </Grid>
          </React.Fragment>}

          <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.banking_data")}</Typography> {this.its_okay(banking_data)}
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.guardian_profile.title.banking_description")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
          </Grid>
          <Grid item xs={12} sx={{marginTop: '-1em'}}>
            <BankAccount
              onChange={(e, checked) => {this.setState({user: {...this.state.user, zicht_account: e.target.value}, ignore: {...this.state.ignore, bank_checked: checked}});}}
              value={this.state.user.zicht_account}
              label={t("profile.my_data.field.zicht_account").replace(/<[^>]*>?/gm, '')}
              id="zicht_account"
              error={this.state.errors.zicht_account}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 3}}>{t("emission.steps.profile.title.identity_data")}</Typography> {this.its_okay(identity_data)}
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("guardian_profile.my_data.field.identity_front_minor")} sx={{lineHeight: '1.5', marginTop: '-1em', marginBottom: '-2em'}} />
          </Grid>
          <Grid item xs={12} sx={{display: {xs: 'none', md: 'flex'}}}>
            <DropzoneArea
              acceptedFiles={['image/*', '.pdf']}
              filesLimit={1}
              dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
              onChange={(files) => {this.setState({user: {...this.state.user, identity: files[0]}});}}
              maxFileSize={3000000}
              previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
              dropzoneParagraphClass={classes.dropZoneText}
              previewGridClasses={{
                item: classes.preview,
                container: classes.preview_grid
              }}
              dropzoneClass={classes.dropzoneMainClass}
            />
          </Grid>
          <Grid item xs={12} sx={{display: {xs: 'flex', md: 'none'}}}>
            <DropzoneArea
              acceptedFiles={['image/*', '.pdf']}
              filesLimit={1}
              dropzoneText={he.decode(t('general.dropzone_text_mobile').replace(/<[^>]*>?/gm, ''))}
              onChange={(files) => {this.setState({user: {...this.state.user, identity: files[0]}});}}
              maxFileSize={3000000}
              previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
              dropzoneParagraphClass={classes.dropZoneText}
              previewGridClasses={{
                item: classes.preview,
                container: classes.preview_grid
              }}
              dropzoneClass={classes.dropzoneMainClassMobile}
            />
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("guardian_profile.my_data.field.identity_front_guardian")} sx={{lineHeight: '1.5', marginTop: '-1em', marginBottom: '-2em'}} />
          </Grid>
          <Grid item xs={12} sx={{display: {xs: 'none', md: 'flex'}}}>
            <DropzoneArea
              acceptedFiles={['image/*', '.pdf']}
              filesLimit={1}
              dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
              onChange={(files) => {this.setState({guardian: {...this.state.guardian, identity: files[0]}});}}
              maxFileSize={3000000}
              previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
              dropzoneParagraphClass={classes.dropZoneText}
              previewGridClasses={{
                item: classes.preview,
                container: classes.preview_grid
              }}
              dropzoneClass={classes.dropzoneMainClass}
            />
          </Grid>
          <Grid item xs={12} sx={{display: {xs: 'flex', md: 'none'}}}>
            <DropzoneArea
              acceptedFiles={['image/*', '.pdf']}
              filesLimit={1}
              dropzoneText={he.decode(t('general.dropzone_text_mobile').replace(/<[^>]*>?/gm, ''))}
              onChange={(files) => {this.setState({guardian: {...this.state.guardian, identity: files[0]}});}}
              maxFileSize={3000000}
              previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
              dropzoneParagraphClass={classes.dropZoneText}
              previewGridClasses={{
                item: classes.preview,
                container: classes.preview_grid
              }}
              dropzoneClass={classes.dropzoneMainClassMobile}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 4}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main}}>{t("emission.steps.profile.title.confirm_data")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.guardian_profile.confirm_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
          </Grid>
          {!!this.state.show_error && <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.profile.generic_error")} />
          </Grid>}
          {!this.state.loading && <Grid item xs={12}>
            <StyledButton
              is_red
              disabled={!(!!profile_data && !!contact_data && !!address_data && !!banking_data && !!identity_data)}
              onClick={(e) => {
                if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                  this.props.helpers.web_log.do_refresh({
                    locale: this.props.i18n.language,
                    callback: (r, full_data) => {
                      window.argenco_uuid = r;
                    },
                    parameters: {
                      id: this.props.login?.id,
                      uuid: window.argenco_uuid,
                      u: window.location.pathname,
                      r: document.referrer,
                      a: 'button_click',
                      d: 'emission.for_child.new_save',
                      s: window.argenco_source,
                      ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                    }
                  });
                }
                this.create_profile(e);
              }}
              text={t("emission.steps.profile.cta.continue")}
            />
          </Grid>}
          {!!this.state.loading && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}
        </Grid>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
      </Grid>
    </React.Fragment>);
  }
}

EmissionStepProfileVoogdNewFields.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  dropZoneText: {
    fontFamily: theme.typography.fontFamily
  },
  preview: {
    maxWidth: '100%',
    '& img': {
      maxHeight: '150px'
    },

  },
  preview_grid: {
    width: '100%',
    margin: '0px',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropzoneMainClassMobile: {
    minHeight: '175px !important',
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiDropzonePreviewList-removeButton': {
      right: '8px'
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: '8px'
    }
  },
  dropzoneMainClass: {
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiDropzonePreviewList-removeButton': {
      right: '8px'
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: '8px'
    }
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(EmissionStepProfileVoogdNewFields))));
