import { connect } from 'react-redux';
import Devise from '../../../actions/devise';
import Authenticated from '../../../components/shared/parts/Authenticated';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      users: {
        validate_simple: (_params) => {
          dispatch(Devise.validate_simple(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Authenticated);
