import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import InputMask from 'react-input-mask';

import {
  CircularProgress,
  InputAdornment,
  TextField
} from '@mui/material';

var he = require('he');

class OtpField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      v: '',
      err: false,
      loading: false
    };
  }

  verify_otp = () => {
    this.setState({loading: true}, () => {
      this.props.helpers.otp.fetch_mfa_status({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r) => {
          if (!!r.success) this.setState({loading: false}, () => {this.props.onSubmit(parseInt(this.state.v.replace(" ", ""), 10).toString());});
          if (!r.success) this.setState({err: true, loading: false});
        },
        parameters: {
          verify_mfa_status: 1,
          otp_code: parseInt(this.state.v.replace(" ", ""), 10).toString()
        }
      });
    });
  }

  render() {
    return (<InputMask
      mask="999 999"
      value={this.state.v}
      disabled={this.state.loading}
      onChange={(e) => {
        this.setState({v: e.target.value, err: false}, () => {
          if (parseInt(e.target.value.replace(" ", ""), 10).toString().length === 6) {
            if (!!this.props.no_verification) {
              this.props.onSubmit(parseInt(this.state.v.replace(" ", ""), 10).toString());
            } else {
              this.verify_otp();
            }
          }
        });
      }}
    >
      <TextField
        variant="outlined"
        {...this.props}
        error={this.state.err}
        label={he.decode(this.props.t("mfa.security_code").replace(/<[^>]*>?/gm, ''))}
        sx={{width: '120px', ...(!!this.props.sx ? this.props.sx : {})}}
        InputProps={this.state.loading ? {
          endAdornment: <InputAdornment position="end"><CircularProgress size="1rem" /></InputAdornment>,
        } : {}}
      />
    </InputMask>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(OtpField));
