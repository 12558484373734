import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Grid
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import BoomTrans from '../../images/boom_trans.png';

import ItemBlock from "../shared/parts/ItemBlock";
import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import Typography from "../shared/parts/Typography";

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frontpager_loading: true,
      frontpager: {},
      benefits: [],
      news: []
    };
  }

  componentDidMount() {
    this.props.helpers.frontpager.fetch_active({
      locale: this.props.i18n.language,
      callback: (r) => {
        this.setState({frontpager: r, frontpager_loading: false});
      },
      error_callback: (r) => {
        this.setState({frontpager_loading: false});
      },
      parameters: {}
    });
    this.fetch_benefits();
    this.fetch_news();
  }

  fetch_benefits() {
    this.props.helpers.benefits.fetch_all({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (r) => {
        this.setState({benefits: r, benefits_loading: false});
      },
      parameters: {
        search: "",
        fields: ['id', 'name', 'field_body', 'image_url', 'created_at', 'tag|field_name'],
        order: 'weight_homepage DESC, weight DESC, created_at DESC',
        page: 0,
        per_page: 4
      }
    });
  }

  fetch_news() {
    this.props.helpers.news.fetch_all({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (r) => {
        this.setState({news: r, news_loading: false});
      },
      parameters: {
        search: "",
        fields: ['id', 'field_title', 'field_body', 'image_url', 'created_at', 'tag|field_name'],
        order: 'created_at DESC',
        page: 0,
        per_page: 4
      }
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let banner_image = {xs: "url(https://old.argenco.be/support/assets/870)"};
    let v2_fade_opacity = 70;
    if (!!this.state.frontpager && !!this.state.frontpager.item_xs && this.state.frontpager.item_xs.indexOf("missing") < 0) {
      banner_image = {
        xs: `url(${this.state.frontpager.item_xs})`,
        sm: `url(${this.state.frontpager.item_sm})`,
        md: `url(${this.state.frontpager.item_md})`,
        lg: `url(${this.state.frontpager.item_lg})`,
        xl: `url(${this.state.frontpager.item_xl})`
      };
      v2_fade_opacity = parseInt(this.state.frontpager.v2_fade_opacity, 10);
    }

    return (<React.Fragment><Page {...other} banner_image={banner_image} style={{backgroundColor: `rgba(255, 255, 255, ${v2_fade_opacity / 100})`, overflow: 'hidden'}}>
      {!this.state.frontpager_loading && !!this.state.frontpager.title && !!this.state.frontpager.has_emission && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container className={classes.banner_container} sx={{marginTop: {xs: '300px', md: '0px'}, alignContent: 'end', paddingBottom: 1}}>
          <Grid item xs={12} sm={6} className={classes.main_title}>
            <PurifiedTypo text={this.state.frontpager.title} sx={{
              color: this.props.theme.palette.primary.main,
              fontSize: "2rem",
              fontFamily: ['Nunito Sans'],
              // textTransform: 'uppercase',
              fontWeight: 700,
              position: 'relative',
              zIndex: 100
            }} />
          </Grid>
          <Grid item xs={12} sm={6} align="right" className={classes.cta}>
            <Box style={{width: '100%', height: '100%', display: 'flex', flex: '1 0 auto', flexDirection: 'column', justifyContent: 'flex-end'}}>
              <StyledButton text={t("frontpager.emissie.intekenen").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{alignSelf: 'flex-end', marginBottom: this.props.theme.spacing(2)}} />
              <StyledButton text={t("frontpager.emissie.nakijken").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{alignSelf: 'flex-end', marginBottom: this.props.theme.spacing(2)}} secondary />
            </Box>
          </Grid>
        </Grid>
      </Container>}
      {!this.state.frontpager_loading && !!this.state.frontpager.title && !this.state.frontpager.has_emission && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container className={classes.banner_container} sx={{marginTop: {xs: '300px', md: '0px'}, alignContent: 'end', paddingBottom: 1}}>
          <Grid item xs={12} className={classes.main_title} sx={{display: 'flex', alignItems: 'start', flexDirection: 'column'}}>
            <Box sx={{width: '100%'}}>
              <PurifiedTypo text={`<h1>${this.state.frontpager.title.replace(/\n/g, '<br />')}</1>`} sx={{
                color: this.props.theme.palette.primary.main,
                // fontSize: "2rem",
                fontFamily: ['Nunito Sans'],
                // textTransform: 'uppercase',
                fontWeight: 700,
                position: 'relative',
                zIndex: 100,
                lineHeight: '1.2'
              }} />
            </Box>
            <Box sx={{width: {xs: '100%', md: '50%', xl: '40%'}, marginTop: 2}}>
              <PurifiedTypo text={this.state.frontpager.body} sx={{
                color: this.props.theme.palette.primary.main,
                fontSize: "1.3rem",
                fontFamily: ['Nunito Sans'],
                position: 'relative',
                zIndex: 100,
                lineHeight: '1.2'
              }} />
            </Box>
            {!!this.state.frontpager.has_cta && <Grid item xs={12} sm={6} align="right" className={classes.cta}>
              <Box style={{width: '100%', height: '100%', display: 'flex', flex: '1 0 auto', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <StyledButton text={this.state.frontpager.cta_text} onClick={() => {
                  window.location = this.state.frontpager.cta_link;
                }} theme={this.props.theme} style={{alignSelf: 'flex-end', marginBottom: this.props.theme.spacing(2)}} />
              </Box>
            </Grid>}
          </Grid>
          {/* <img src={BoomTrans} style={{
            position: 'absolute',
            bottom: '-10%',
            left: '-10%',
            width: '40%',
            zIndex: 99
          }} /> */}
        </Grid>
      </Container>}
      {/* {false && !this.state.frontpager_loading && !this.state.frontpager.title && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container className={classes.banner_container} sx={{marginTop: {xs: '300px', md: '0px'}, alignContent: 'end', paddingBottom: 5}}>
          <Grid item xs={12} sm={6} className={classes.main_title}>
            <Typography variant="h1" className={classes.banner_title}>{t("frontpager.default.title")}</Typography>
            <Typography className={classes.banner_subtitle} sx={{marginTop: 2}}>{t("frontpager.default.text")}</Typography>
          </Grid>
        </Grid>
      </Container>} */}
      {!this.state.frontpager_loading && !this.state.frontpager.title && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container className={classes.banner_container}>
          <Grid item xs={12} className={classes.main_title} sx={{display: 'flex', alignItems: 'start', flexDirection: 'column'}}>
            <Box sx={{width: '100%'}}>
              <PurifiedTypo text={t("v2.frontpager.default.title")} sx={{
                color: this.props.theme.palette.primary.main,
                fontSize: "2rem",
                fontFamily: ['Nunito Sans'],
                // textTransform: 'uppercase',
                fontWeight: 700,
                position: 'relative',
                zIndex: 100
              }} />
            </Box>
            <Box sx={{width: {xs: '100%', md: '50%', xl: '40%'}, marginTop: 2}}>
              <PurifiedTypo text={t("v2.frontpager.default.text")} sx={{
                color: this.props.theme.palette.primary.main,
                fontSize: "1.3rem",
                fontFamily: ['Nunito Sans'],
                position: 'relative',
                zIndex: 100
              }} />
            </Box>
          </Grid>
          {/* <img src={BoomTrans} style={{
            position: 'absolute',
            bottom: '-10%',
            left: '-10%',
            width: '40%',
            zIndex: 99
          }} /> */}
        </Grid>
      </Container>}
    </Page>
    <Page>

    <Container sx={{paddingLeft: 0, paddingRight: 0, display: {xs: 'block', md: 'none'}}}>
      <Grid container sx={{marginBottom: 2}}>
        <Grid item xs={12} align="center" sx={{marginBottom: 2, backgroundColor: 'rgb(235, 235, 235)', padding: 3}}>
          {!this.props.login && <React.Fragment>
            <StyledButton text={t("general.actions.become_vennoot_short").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} is_red onClick={() => {
              this.props.history.push('/emission');
              window.scrollTo(0, 0);
            }} sx={{display: {xs: 'block', md: 'none'}}} />
            <StyledButton text={t("general.actions.become_vennoot_long").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} is_red onClick={() => {
              this.props.history.push('/emission');
              window.scrollTo(0, 0);
            }} sx={{display: {xs: 'none', md: 'block'}}} />
          </React.Fragment>}
          {!!this.props.login && <StyledButton text={t("general.actions.become_vennoot_long_when_logged_in").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} is_red onClick={() => {
            this.props.history.push('/emission');
            window.scrollTo(0, 0);
          }} />}
        </Grid>
      </Grid>
    </Container>

{/* ABOUT */}

      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container sx={{marginTop: {xs: 4, md: 4}}}>
          <Grid item xs={12} align="center" sx={{marginBottom: 2}}>
            <Typography variant="h2" className={classes.banner_title}>{t("components.about.title")}</Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{position: 'relative', marginBottom: 4, minHeight: '200px', display: {xs: 'flex', md: 'none'}}}>
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: {xs: 0, md: '8px'},
              width: '100%',
              height: '100%',
              maxHeight: '400px',
              backgroundImage: `url(${t("components.about.image_top").replace(/<[^>]*>?/gm, '')})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}></Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{paddingRight: {xs: 0, md: 1}, marginBottom: 4}}>
            <PurifiedTypo text={t("components.about.text_top")} />
            <StyledButton text={t("components.about.cta_top").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link onClick={() => {window.location.href = t("components.about.top_cta").replace(/<[^>]*>?/gm, '');}} sx={{padding: 0, fontSize: '1rem'}} />
          </Grid>
          <Grid item xs={12} md={6} sx={{position: 'relative', marginBottom: 2, minHeight: '200px', display: {xs: 'none', md: 'flex'}}}>
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: {xs: 0, md: '8px'},
              width: '100%',
              height: '100%',
              maxHeight: '400px',
              backgroundImage: `url(${t("components.about.image_top").replace(/<[^>]*>?/gm, '')})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}></Box>
          </Grid>

          <Grid item xs={12} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>

          <Grid item xs={12} md={6} sx={{position: 'relative', minHeight: '200px'}}>
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: {xs: '100%', md: 'calc(100% - 8px)'},
              height: '100%',
              maxHeight: '400px',
              backgroundImage: `url(${t("components.about.image_bottom").replace(/<[^>]*>?/gm, '')})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}></Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{paddingLeft: {xs: 0, md: 1}}}>
            <PurifiedTypo text={t("components.about.text_bottom")} />
            <StyledButton text={t("components.about.cta_bottom").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link onClick={() => {window.location.href = t("components.about.bottom_cta").replace(/<[^>]*>?/gm, '');}} sx={{padding: 0, fontSize: '1rem'}} />
          </Grid>
        </Grid>
      </Container>

{/* BENEFITS */}

      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container sx={{marginTop: {xs: 4, md: 12}}}>
          <Grid item xs={12} align="center" sx={{marginBottom: 2}}>
            <Typography variant="h2" className={classes.banner_title}>{t("components.benefits.title")}</Typography>
          </Grid>
          <Grid item xs={12} sx={{display: {xs: 'block', md: 'none'}}}>
            <Carousel
              animation="slide"
              interval="6000"
            >
              {this.state.benefits.map(benefit => (<Grid item xs={12} onClick={(e) => {
                if (e.ctrlKey) {
                  window.open(`/benefits/${benefit.id}`);
                } else {
                  this.props.history.push(`/benefits/${benefit.id}`);
                }
              }}>
                <ItemBlock
                  lines={2}
                  theme={this.props.theme}
                  created_at={benefit.created_at}
                  tag_name={benefit.tag_field_name}
                  image_url={benefit.image_url}
                  name={benefit.name}
                />
              </Grid>))}
            </Carousel>
          </Grid>
          <Grid container item xs={12} sx={{display: {xs: 'none', md: 'flex'}}}>
            {this.state.benefits.map(benefit => (<Grid item xs={12} md={3} sx={{padding: 1}} onClick={(e) => {
              // console.log(e);
              if (e.ctrlKey || e.button === 1) {
                window.open(`/benefits/${benefit.id}`);
              } else {
                this.props.history.push(`/benefits/${benefit.id}`);
              }
            }}>
              <ItemBlock
                lines={2}
                theme={this.props.theme}
                created_at={benefit.created_at}
                tag_name={benefit.tag_field_name}
                image_url={benefit.image_url}
                name={benefit.name}
              />
            </Grid>))}
          </Grid>
          <Grid item xs={12} align="center" sx={{marginTop: 2}}>
            <StyledButton text={t("components.benefits.cta").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link onClick={() => {this.props.history.push(`/benefits/`);}} sx={{fontSize: '1rem'}} />
          </Grid>
        </Grid>
      </Container>

{/* CTA */}

      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container sx={{marginTop: {xs: 4, md: 12}}}>
          <Grid item xs={12} align="center" sx={{marginBottom: 2, backgroundColor: 'rgb(235, 235, 235)', padding: 3}}>
            {!this.props.login && <React.Fragment>
              <StyledButton text={t("general.actions.become_vennoot_short").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} is_red onClick={() => {
                this.props.history.push('/emission');
                window.scrollTo(0, 0);
              }} sx={{display: {xs: 'block', md: 'none'}}} />
              <StyledButton text={t("general.actions.become_vennoot_long").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} is_red onClick={() => {
                this.props.history.push('/emission');
                window.scrollTo(0, 0);
              }} sx={{display: {xs: 'none', md: 'block'}}} />
            </React.Fragment>}
            {!!this.props.login && <StyledButton text={t("general.actions.become_vennoot_long_when_logged_in").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} is_red onClick={() => {
              this.props.history.push('/emission');
              window.scrollTo(0, 0);
            }} />}
          </Grid>
        </Grid>
      </Container>

{/* NEWS */}

      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container sx={{marginTop: {xs: 4, md: 12}, marginBottom: 12}}>
          <Grid item xs={12} align="center" sx={{marginBottom: 2}}>
            <Typography variant="h2" className={classes.banner_title}>{t("components.news.title")}</Typography>
          </Grid>
          <Grid item xs={12} sx={{display: {xs: 'block', md: 'none'}}}>
            <Carousel
              animation="slide"
              interval="6000"
            >
              {this.state.news.map(item => (<Grid item xs={12} onClick={(e) => {
                if (e.ctrlKey) {
                  window.open(`/news/${item.id}`);
                } else {
                  this.props.history.push(`/news/${item.id}`);
                }
              }}>
                <ItemBlock
                  theme={this.props.theme}
                  created_at={item.created_at}
                  tag_name={item.tag_field_name}
                  image_url={item.image_url}
                  name={item.field_title}
                />
              </Grid>))}
            </Carousel>
          </Grid>
          <Grid container item xs={12} sx={{display: {xs: 'none', md: 'flex'}}}>
            {this.state.news.map(item => (<Grid item xs={12} md={3} sx={{padding: 1}} onClick={(e) => {
              if (e.ctrlKey) {
                window.open(`/news/${item.id}`);
              } else {
                this.props.history.push(`/news/${item.id}`);
              }
            }}>
              <ItemBlock
                theme={this.props.theme}
                created_at={item.created_at}
                tag_name={item.tag_field_name}
                image_url={item.image_url}
                name={item.field_title}
              />
            </Grid>))}
          </Grid>
          <Grid item xs={12} align="center" sx={{marginTop: 2}}>
            <StyledButton text={t("components.news.cta").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link onClick={() => {this.props.history.push(`/news/`);}} sx={{fontSize: '1rem'}} />
          </Grid>
        </Grid>
      </Container>

    </Page></React.Fragment>);
  }
}

Homepage.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  banner_container: {
    minHeight: 400,
    height: 'auto'
  },
  banner_title: {
    fontSize: '2.3rem !important',
    fontWeight: '700 !important'
  },
  banner_subtitle: {
    color: theme.palette.secondary.main,
    fontSize: '1.4rem !important'
  },
  main_title: {
    paddingTop: theme.spacing(5)
  },
  cta: {

  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Homepage))));
