import Banner from '../actions/banner';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function banner(state = initial, action) {
  return Banner.state_switch(state, action);
}
