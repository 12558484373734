import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableCell,
  TableRow
} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import BoomTrans from '../../images/boom_filled.png';

import {DateTime} from "luxon";
import {DropzoneArea} from 'material-ui-dropzone';

import Authenticated from "../../containers/shared/parts/Authenticated";
import BankAccount from "../shared/parts/BankAccount";
import BirthDate from "../shared/parts/onboarding/BirthDate";
import CountryPicker from "../shared/parts/CountryPicker";
import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import ShareShower from "../shared/parts/ShareShower";
import StyledButton from "../shared/parts/StyledButton";
import StyledButtonGroup from "../shared/parts/StyledButtonGroup";
import StyledConfirm from "../shared/parts/StyledConfirm";
import StyledTextField from "../shared/parts/StyledTextField";
import Typography from "../shared/parts/Typography";
import Vennr from "../shared/parts/onboarding/Vennr";

var he = require('he');

class Transfer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      step_loading: false,
      submit_loading: false,
      transfer_information: {},
      user: props.login || {},
      current_transfer: null,
      // current_transfer: {
      //   new_user: {
      //     vennr: '',
      //     first_name: '',
      //     last_name: '',
      //     email: '',
      //     phone: '',
      //     mobile: '',
      //     birth_date: '',
      //     gender: 'M',
      //     street: '',
      //     number: '',
      //     zip: '',
      //     city: '',
      //     country: '',
      //     voogd_street: '',
      //     voogd_number: '',
      //     voogd_zip: '',
      //     voogd_city: '',
      //     voogd_country: '',
      //     zicht_account: '',
      //     voogd_first_name: '',
      //     voogd_last_name: '',
      //     voogd_email: '',
      //     voogd_mobile: '',
      //     voogd_birth_date: '',
      //     voogd_comment: ''
      //   },
      //   shares: {
      //     amount: 0
      //   }
      // },
      choice_ven: 2,
      errors: {},
      search: {
        birth_date: '',
        vennr: ''
      },
      existing_info: {},
      confirm: false,
      confirm_status: ''
    };
  }

  componentDidMount() {
    if (!!this.props.login) this.fetch_transfer_information(this.props.login.vennoot_number, this.props.login.birth_date);
  }
  componentDidUpdate(prevProps, prevState) {}

  fetch_transfer_information = (vennr, birth_date) => {
    this.setState({loading: true}, () => {
      this.props.helpers.users.transfer_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({transfer_information: r, loading: false});
        },
        parameters: {
          vennr: vennr,
          birth_date: birth_date
        }
      });
    });
  }

  find_existing = () => {
    this.props.helpers.users.transfer_information({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (r, full_data) => {
        this.setState({existing_info: r.data, current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, vennr: (!!r.data.nr ? r.data.nr : '')}}});
      },
      parameters: {
        check_existing: 1,
        vennr: this.state.search.vennr,
        birth_date: this.state.search.birth_date
      }
    });
  }

  its_okay = (okay) => {
    if (okay) {
      return (<CheckIcon sx={{color: this.props.theme.palette.tertiary.main, position: 'absolute', top: '5px', left: 0}} />);
    } else {
      return (<CloseIcon sx={{color: this.props.theme.palette.secondary.main, position: 'absolute', top: '5px', left: 0}} />);
    }
  }

  submit = () => {
    this.setState({submit_loading: true}, () => {
      this.props.helpers.users.transfer_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          let cur_trans = null;
          r.transfers.forEach(ct => {
            cur_trans = ct.current_transfer;
          });
          this.setState({transfer_information: r, current_transfer: cur_trans, submit_loading: false}, () => {
            window.scrollTo(0, 0);
          });
        },
        parameters: {
          step: 'create_transfer',
          current_transfer: this.state.current_transfer,
          vennr: this.state.user.vennoot_number || this.state.user.vennr,
          birth_date: this.state.user.birth_date
        }
      });
    });
  }

  submit_files = () => {
    this.setState({submit_loading: true}, () => {
      let formData = new FormData();
      formData.append('vennr', this.state.user.vennoot_number || this.state.user.vennr);
      formData.append('birth_date', this.state.user.birth_date);
      formData.append('step', 'docs');
      formData.append('id', this.state.current_transfer.id);
      formData.append('identity', this.state.identity);
      formData.append('identity_new', this.state.identity_new);
      formData.append('identity_voogd', this.state.identity_voogd);
      formData.append('item', this.state.item);

      this.props.helpers.users.transfer_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({transfer_information: r, current_transfer: null, submit_loading: false});
        },
        parameters: formData
      });
    });
  }

  render_step = (text, active, do_bar) => {
    return (<Box sx={{width: '38px', display: 'inline-block', marginRight: 1, position: 'relative'}}>
      <Box sx={{
        backgroundColor: (active ? this.props.theme.palette.primary.main : 'white'),
        border: `1px solid ${this.props.theme.palette.primary.main}`,
        borderRadius: 8,
        width: '30px',
        height: '30px',
        color: (!active ? this.props.theme.palette.primary.main : 'white'),
        textAlign: 'center',
        lineHeight: '30px',
        position: 'relative',
        zIndex: 5
      }}>{text}</Box>
      {do_bar && <Box sx={{backgroundColor: this.props.theme.palette.primary.main, width: '4px', height: '30px', position: 'absolute', left: '13px', top: '30px', zIndex: 4}}></Box>}
    </Box>);
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let exit_started = false;
    let docs_uploaded = false;
    let argenco_checked = false;
    if (Object.keys(this.state.transfer_information).length > 0) {
      exit_started = this.state.transfer_information.exit_active;
      docs_uploaded = !!this.state.transfer_information.item_url && this.state.transfer_information.item_url.indexOf("missing.png") < 0;
      argenco_checked = this.state.transfer_information.checked;
    }

    let pending_stock = 0
    if (!!this.state.transfer_information.transfers) {
      this.state.transfer_information.transfers.forEach(transfer => {
        if (transfer.status !== 'approved' && transfer.status !== 'disapproved') pending_stock += transfer.current_transfer.shares.amount;
      })
    }

    let share_okay = false;
    if (!!this.state.current_transfer && !!this.state.current_transfer.shares && !!this.state.current_transfer.shares.amount) share_okay = this.state.current_transfer.shares.amount > 0 && this.state.current_transfer.shares.amount <= this.state.user.stock_number;
    let venn_okay = false;
    let voogd_okay = false;
    if (!!this.state.current_transfer && !!this.state.current_transfer.new_user) {
      if (this.state.choice_ven === 1) {
        venn_okay = true;
        ['first_name', 'last_name', 'email', 'gender', 'street', 'number', 'zip', 'city', 'country', 'zicht_account'].forEach(field => {
          if (
            !this.state.current_transfer.new_user[field] ||
            (typeof(this.state.current_transfer.new_user[field]) === 'undefined' ||
            this.state.current_transfer.new_user[field] === null ||
            this.state.current_transfer.new_user[field].trim() === "")
          ) venn_okay = false;
        });
        if (
          !this.state.current_transfer.new_user.birth_date ||
          (typeof(this.state.current_transfer.new_user.birth_date) === 'undefined' ||
          this.state.current_transfer.new_user.birth_date === null ||
          this.state.current_transfer.new_user.birth_date.toString().trim() === "")
        ) venn_okay = false;



        let iban = (this.state.current_transfer.new_user.zicht_account || '').toUpperCase().replace(/[^A-Z0-9]/g, '');
        let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
        let digits;
        if (!!code && !!code[1] && !!code[2] && !!code[3]) {
          digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
            return letter.charCodeAt(0) - 55;
          });
          var checksum = digits.slice(0, 2), fragment;
          for (var offset = 2; offset < digits.length; offset += 7) {
            fragment = String(checksum) + digits.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
          }
          if (checksum !== 1) {
            venn_okay = false;
          }
        }

        if (!!this.state.current_transfer.new_user.birth_date && DateTime.fromFormat(this.state.current_transfer.new_user.birth_date, "dd/MM/yyyy").plus({years: 18}) > DateTime.now()) {
          voogd_okay = true;
          ['voogd_first_name', 'voogd_last_name', 'voogd_email', 'voogd_street', 'voogd_number', 'voogd_zip', 'voogd_city', 'voogd_country'].forEach(field => {
            if (
              !this.state.current_transfer.new_user[field] ||
              (typeof(this.state.current_transfer.new_user[field]) === 'undefined' ||
              this.state.current_transfer.new_user[field] === null ||
              this.state.current_transfer.new_user[field].trim() === "")
            ) voogd_okay = false;
          });
          if (
            !this.state.current_transfer.new_user.voogd_birth_date ||
            (typeof(this.state.current_transfer.new_user.voogd_birth_date) === 'undefined' ||
            this.state.current_transfer.new_user.voogd_birth_date === null ||
            this.state.current_transfer.new_user.voogd_birth_date.toString().trim() === "") ||
            DateTime.fromFormat(this.state.current_transfer.new_user.voogd_birth_date, "dd/MM/yyyy").plus({years: 18}) > DateTime.now()
          ) voogd_okay = false;
        } else {
          voogd_okay = true;
        }
      } else {
        venn_okay = !!this.state.current_transfer.new_user.vennr;
        voogd_okay = true;
      }
    }
    let files_okay = false;
    if (!!this.state.current_transfer && !!this.state.current_transfer.files) {
      files_okay = true;
      if (
        !this.state.item ||
        !this.state.identity ||
        !this.state.identity_new ||
        (this.state.current_transfer.new_user.voogd_email && !this.state.identity_voogd)
      ) files_okay = false;
    }

    let hide_for_further_steps = false;
    if (!!this.state.current_transfer && !!this.state.current_transfer.files && !!this.state.current_transfer.files.blank) hide_for_further_steps = true;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8), minHeight: '60vh'}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.component_title} sx={{textAlign: 'center'}}>{t(`transfer.title`)}</Typography>
          </Grid>
          {!this.state.current_transfer && <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
            <PurifiedTypo text={t("transfer.seo_paragraph")} />
          </Grid>}
          <Grid item xs={12} sx={{marginTop: 2}}>&nbsp;</Grid>
        </Grid>

        <Grid container spacing="2">
          <Grid item container xs={12}>
            <Authenticated login={this.props.login} t={this.props.t} onAuthenticate={(user, authenticated) => {
              if (authenticated) this.setState({user: user}, () => {
                this.fetch_transfer_information(user.vennr, user.birth_date);
              });
            }}>
              {!!this.state.loading && <CircularProgress />}
              {!this.state.loading && !this.state.current_transfer && <React.Fragment>
                <Grid container item xs={12} sm={6} md={4} lg={4} xl={3} onClick={(e) => {
                  this.setState({current_transfer: {
                    new_user: {
                      vennr: '',
                      first_name: '',
                      last_name: '',
                      email: '',
                      phone: '',
                      mobile: '',
                      birth_date: '',
                      gender: 'M',
                      street: '',
                      number: '',
                      zip: '',
                      city: '',
                      country: 'BE',
                      voogd_street: '',
                      voogd_number: '',
                      voogd_zip: '',
                      voogd_city: '',
                      voogd_country: 'BE',
                      zicht_account: '',
                      voogd_first_name: '',
                      voogd_last_name: '',
                      voogd_email: '',
                      voogd_mobile: '',
                      voogd_birth_date: '',
                      voogd_comment: ''
                    },
                    shares: {
                      amount: 0
                    }
                  }}, () => {
                    window.scrollTo(0, 0);
                  });
                }}>
                  <Grid item xs={12} sx={{
                    cursor: 'pointer',
                    padding: 1,
                    border: `1px solid ${this.props.theme.palette.primary.main}`,
                    borderRadius: '5px',
                    margin: 1
                  }}>
                    <Grid item xs={12} align="center">
                      <AddCircleIcon sx={{color: this.props.theme.palette.primary.main, fontSize: '80px'}} />
                    </Grid>
                    <Grid item xs={12} align="center" sx={{marginTop: 'auto'}}>
                      <Typography>{t('transfer.block.new.title')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.transfer_information.transfers.map(transfer => (<Grid container item xs={12} sm={6} md={4} lg={4} xl={3} onClick={(e) => {
                  if (!(transfer.status === 'pending' || transfer.status === 'approved' || transfer.status === 'disapproved')) {
                    this.setState({current_transfer: transfer.current_transfer, choice_ven: !!transfer.current_transfer.new_user.vennr ? 2 : 1}, () => {
                      window.scrollTo(0, 0);
                    });
                  } else {
                    this.setState({confirm: true, confirm_status: transfer.status});
                  }
                }}>
                  <Grid item xs={12} sx={{
                    cursor: 'pointer',
                    padding: 1,
                    border: `1px solid ${transfer.get_status_colour}`,
                    borderRadius: '5px',
                    margin: 1,
                    overflow: 'hidden',
                    position: 'relative'
                  }}>
                    <Grid item xs={12} align="right">
                      <Typography sx={{fontSize: '0.7em'}}>{t("transfer.block.receiver")}</Typography>
                      <Typography sx={{fontSize: '1.1em'}}>{transfer.full_name}</Typography>
                    </Grid>
                    <Grid item xs={12} align="right" sx={{marginTop: 1}}>
                      <Typography sx={{fontSize: '0.7em'}}>{t("transfer.block.amount")}</Typography>
                      <ShareShower sx={{fontSize: '1.1em'}} amount={transfer.current_transfer.shares.amount} share={this.state.user.share_type_tag} />
                    </Grid>
                    <Grid item xs={12} align="right" sx={{marginTop: 1}}>
                      <Typography sx={{fontSize: '0.7em'}}>{t("transfer.block.status")}</Typography>
                      <Typography sx={{fontSize: '1.1em', color: transfer.get_status_colour}}>{t(`transfer.statuses.${transfer.status}`)}</Typography>
                    </Grid>
                    <img src={BoomTrans} style={{
                      position: 'absolute',
                      bottom: '-10%',
                      left: '-10%',
                      width: '40%',
                      zIndex: 99
                    }} />
                  </Grid>
                </Grid>))}
              </React.Fragment>}

              {!this.state.loading && !!this.state.current_transfer && <React.Fragment>
                <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                <Grid container item xs={12} md={8} spacing={2}>
                  {!this.state.current_transfer.id && <Grid item xs={12}>
                    <PurifiedTypo text={t("transfer.detail.intro")} />
                  </Grid>}

                  {!!hide_for_further_steps && <React.Fragment>
                    <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                      <Typography variant='h3' sx={{paddingLeft: 3}}>{`1. ${t('transfer.detail.files')}`}</Typography> {this.its_okay(true)}
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 2}}>
                      <PurifiedTypo text={t("transfer.detail.files_intro")} />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledButton
                        text={t("transfer.detail.cta.blank_document").replace(/<[^>]*>?/gm, '')}
                        theme={this.props.theme}
                        sx={{marginTop: 1}}
                        onClick={(e) => {
                          window.open(this.state.current_transfer.files.blank, '_BLANK');
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 4}}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                      <Typography variant='h3' sx={{paddingLeft: 3}}>{`2. ${t('transfer.detail.files_upload')}`}</Typography> {this.its_okay(files_okay)}
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 2}}>
                      <PurifiedTypo text={t("transfer.detail.files_upload_intro")} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{marginTop: 2}}>
                      <PurifiedTypo text={t("transfer.detail.fields.signed_document")} sx={{fontSize: '0.75rem'}} />
                      <DropzoneArea
                        acceptedFiles={['image/*', '.pdf']}
                        filesLimit={1}
                        dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
                        onChange={(files) => {this.setState({item: files[0]});}}
                        maxFileSize={3000000}
                        previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                        dropzoneParagraphClass={classes.dropZoneText}
                        previewGridClasses={{
                          item: classes.preview,
                          container: classes.preview_grid
                        }}
                        dropzoneClass={classes.dropzoneMainClass}
                      />
                      {!!this.state.current_transfer.files.item_name && <Typography style={{fontSize: '0.8em'}}>{t("general.current_file", {name: this.state.current_transfer.files.item_name}).replace(/<[^>]*>?/gm, '')}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={6} sx={{marginTop: 2}}>
                      <PurifiedTypo text={t("transfer.detail.fields.identity_front")} sx={{fontSize: '0.75rem'}} />
                      <DropzoneArea
                        acceptedFiles={['image/*', '.pdf']}
                        filesLimit={1}
                        dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
                        onChange={(files) => {this.setState({identity: files[0]});}}
                        maxFileSize={3000000}
                        previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                        dropzoneParagraphClass={classes.dropZoneText}
                        previewGridClasses={{
                          item: classes.preview,
                          container: classes.preview_grid
                        }}
                        dropzoneClass={classes.dropzoneMainClass}
                      />
                      {!!this.state.current_transfer.files.identity_name && <Typography style={{fontSize: '0.8em'}}>{t("general.current_file", {name: this.state.current_transfer.files.identity_name}).replace(/<[^>]*>?/gm, '')}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={6} sx={{marginTop: 2}}>
                      <PurifiedTypo text={t("transfer.detail.fields.identity_new_front")} sx={{fontSize: '0.75rem'}} />
                      <DropzoneArea
                        acceptedFiles={['image/*', '.pdf']}
                        filesLimit={1}
                        dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
                        onChange={(files) => {this.setState({identity_new: files[0]});}}
                        maxFileSize={3000000}
                        previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                        dropzoneParagraphClass={classes.dropZoneText}
                        previewGridClasses={{
                          item: classes.preview,
                          container: classes.preview_grid
                        }}
                        dropzoneClass={classes.dropzoneMainClass}
                      />
                      {!!this.state.current_transfer.files.identity_new_name && <Typography style={{fontSize: '0.8em'}}>{t("general.current_file", {name: this.state.current_transfer.files.identity_new_name}).replace(/<[^>]*>?/gm, '')}</Typography>}
                    </Grid>
                    {this.state.current_transfer.new_user.voogd_email && <Grid item xs={12} md={6} sx={{marginTop: 2}}>
                      <PurifiedTypo text={t("transfer.detail.fields.identity_voogd_front")} sx={{fontSize: '0.75rem'}} />
                      <DropzoneArea
                        acceptedFiles={['image/*', '.pdf']}
                        filesLimit={1}
                        dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
                        onChange={(files) => {this.setState({identity_voogd: files[0]});}}
                        maxFileSize={3000000}
                        previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                        dropzoneParagraphClass={classes.dropZoneText}
                        previewGridClasses={{
                          item: classes.preview,
                          container: classes.preview_grid
                        }}
                        dropzoneClass={classes.dropzoneMainClass}
                      />
                      {!!this.state.current_transfer.files.identity_voogd_name && <Typography style={{fontSize: '0.8em'}}>{t("general.current_file", {name: this.state.current_transfer.files.identity_voogd_name}).replace(/<[^>]*>?/gm, '')}</Typography>}
                    </Grid>}

                    <Grid item xs={12} sx={{marginTop: 4}}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                      <Typography variant='h3' sx={{paddingLeft: 3}}>{`3. ${t('transfer.detail.after_files_outro_title')}`}</Typography> {this.its_okay(files_okay)}
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 2}}>
                      <PurifiedTypo text={t("transfer.detail.after_files_outro")} />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 4, marginBottom: 8}}>
                      {!this.state.submit_loading && <StyledButton
                        disabled={!!this.state.submit_loading || !files_okay}
                        text={t("transfer.detail.after_files_update_transfer").replace(/<[^>]*>?/gm, '')}
                        theme={this.props.theme}
                        onClick={(e) => {this.submit_files();}}
                      />}
                      {!!this.state.submit_loading && <CircularProgress />}
                      <StyledButton
                        as_link
                        text={t("transfer.detail.stop_transfer").replace(/<[^>]*>?/gm, '')}
                        theme={this.props.theme}
                        onClick={(e) => {this.setState({current_transfer: null}); window.scrollTo(0, 0);}}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 4, marginBottom: -4}}>
                      <Typography variant='h2'>{t('transfer.detail.summary_title')}</Typography>
                    </Grid>
                  </React.Fragment>}

                  <Grid item xs={12} sx={{marginTop: 4}}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                    <Typography variant='h3' sx={{paddingLeft: 3}}>{`1. ${t('transfer.detail.my_data')}`}</Typography> {this.its_okay(true)}
                  </Grid>
                  <Grid item xs={12}>
                    <Paper sx={{
                      border: `1px solid ${this.props.theme.palette.primary.main}`,
                      marginTop: 1,
                      boxShadow: 'none'
                    }}>
                      <Table>
                        <TableRow>
                          <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.name')}</Typography></TableCell>
                          <TableCell sx={{width: '50%'}}><Typography>{this.state.user.first_name} {this.state.user.last_name}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.vennr')}</Typography></TableCell>
                          <TableCell sx={{width: '50%'}}><Typography>{this.state.user.vennoot_number || this.state.user.vennr}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.stock_total')}</Typography></TableCell>
                          <TableCell sx={{width: '50%'}}><ShareShower amount={this.state.user.stock_number} share={this.state.user.share_type_tag} /></TableCell>
                        </TableRow>
                        {pending_stock > 0 && <TableRow>
                          <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.stock_pending')}</Typography></TableCell>
                          <TableCell sx={{width: '50%'}}><ShareShower amount={pending_stock} share={this.state.user.share_type_tag} /></TableCell>
                        </TableRow>}
                      </Table>
                    </Paper>
                  </Grid>

                  {this.state.user.stock_number - pending_stock < 1 && !this.state.current_transfer.id && <React.Fragment>
                    <Grid item xs={12} sx={{marginTop: 4, marginBottom: 4}} align="center">
                      <PurifiedTypo text={t("transfer.detail.error.no_stock_left")} />
                    </Grid>
                    <Grid item xs={12} sx={{marginBottom: 8}} align="center">
                      <StyledButton
                        as_link
                        text={t("transfer.detail.stop_transfer").replace(/<[^>]*>?/gm, '')}
                        theme={this.props.theme}
                        onClick={(e) => {this.setState({current_transfer: null}); window.scrollTo(0, 0);}}
                      />
                    </Grid>
                  </React.Fragment>}

                  {(this.state.user.stock_number - pending_stock > 0 || !!this.state.current_transfer.id) && <React.Fragment>
                    <Grid item xs={12} sx={{marginTop: 4}}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                      <Typography variant='h3' sx={{paddingLeft: 3}}>{`2. ${t('transfer.detail.other_data')}`}</Typography> {this.its_okay(venn_okay)}
                    </Grid>
                    {!hide_for_further_steps && <Grid item xs={12}>
                      <StyledButtonGroup
                        theme={this.props.theme}
                        options={[{id: 2, label: t("transfer.detail.other.exists").replace(/<[^>]*>?/gm, '')}, {id: 1, label: t("transfer.detail.other.new").replace(/<[^>]*>?/gm, '')}]}
                        onClick={(id) => {
                          this.setState({
                            choice_ven: id,
                            current_transfer: {
                              new_user: {
                                vennr: '',
                                first_name: '',
                                last_name: '',
                                email: '',
                                phone: '',
                                mobile: '',
                                birth_date: '',
                                gender: 'M',
                                street: '',
                                number: '',
                                zip: '',
                                city: '',
                                country: 'BE',
                                voogd_street: '',
                                voogd_number: '',
                                voogd_zip: '',
                                voogd_city: '',
                                voogd_country: 'BE',
                                zicht_account: '',
                                voogd_first_name: '',
                                voogd_last_name: '',
                                voogd_email: '',
                                voogd_mobile: '',
                                voogd_birth_date: '',
                                voogd_comment: ''
                              },
                              shares: {
                                amount: 0
                              }
                            }
                          });
                        }}
                        selected={this.state.choice_ven}
                        selected_colours={[this.props.theme.palette.tertiary.main, this.props.theme.palette.tertiary.main]}
                      />
                    </Grid>}
                    {!hide_for_further_steps && this.state.choice_ven === 1 && <Grid container item xs={12} spacing={1}>
                      <Grid item xs={12} sx={{marginTop: 2}}>
                        <PurifiedTypo text={t("transfer.detail.other_new.intro")} />
                      </Grid>
                      {[['first_name', 6], ['last_name', 6], ['email', 12], ['phone', 6], ['mobile', 6]].map(field => (<Grid item xs={12} md={field[1]}>
                        <StyledTextField
                          label={t(`transfer.detail.other_new.field.${field[0]}`).replace(/<[^>]*>?/gm, '')}
                          value={this.state.current_transfer.new_user[field[0]]}
                          id={field[0]}
                          type={field[0] === 'email' ? 'email' : 'text'}
                          onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, [field[0]]: v}}});}}
                          // style={{marginTop: this.props.theme.spacing(0.5)}}
                          error={this.state.errors[field[0]]}
                        />
                      </Grid>))}
                      <Grid item xs={12} md={6}>
                        <BirthDate
                          onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, birth_date: v.target.value}}});}}
                          value={this.state.current_transfer.new_user.birth_date || ''}
                          label={t("transfer.detail.other_new.field.birth_date").replace(/<[^>]*>?/gm, '')}
                          id="birth_date"
                          // style={{marginTop: this.props.theme.spacing(0.5)}}
                          error={this.state.errors.birth_date}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="gender_label">{t("transfer.detail.other_new.field.gender").replace(/<[^>]*>?/gm, '')}</InputLabel>
                          <Select
                            labelId="gender_label"
                            id="gender"
                            value={this.state.current_transfer.new_user.gender}
                            label={t("transfer.detail.other_new.field.gender").replace(/<[^>]*>?/gm, '')}
                            // style={{marginTop: this.props.theme.spacing(0.5)}}
                            onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, gender: v.target.value}}});}}
                          >
                            <MenuItem value={"M"}>{t("transfer.detail.other_new.field.genders.male").replace(/<[^>]*>?/gm, '')}</MenuItem>
                            <MenuItem value={"F"}>{t("transfer.detail.other_new.field.genders.female").replace(/<[^>]*>?/gm, '')}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>&nbsp;</Grid>
                      {[['street', 8], ['number', 4], ['zip', 4], ['city', 8]].map(field => (<Grid item xs={12} md={field[1]}>
                        <StyledTextField
                          label={t(`transfer.detail.other_new.field.${field[0]}`).replace(/<[^>]*>?/gm, '')}
                          value={this.state.current_transfer.new_user[field[0]]}
                          id={field[0]}
                          onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, [field[0]]: v, [`voogd_${field[0]}`]: v}}});}}
                          // style={{marginTop: this.props.theme.spacing(0.5)}}
                          error={this.state.errors[field[0]]}
                        />
                      </Grid>))}
                      <Grid item xs={12}>
                        <CountryPicker
                          label={t("transfer.detail.other_new.field.country").replace(/<[^>]*>?/gm, '')}
                          value={this.state.current_transfer.new_user.country}
                          id="country"
                          onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, country: v.target.value, voogd_country: v.target.value}}});}}
                          error={this.state.errors.country}
                        />
                      </Grid>
                      <Grid item xs={12}>&nbsp;</Grid>
                      <Grid item xs={12}>
                        <BankAccount
                          label={t("transfer.detail.other_new.field.bank_account").replace(/<[^>]*>?/gm, '')}
                          value={this.state.current_transfer.new_user.zicht_account}
                          id="bank_account"
                          onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, zicht_account: v.target.value}}});}}
                          // style={{marginTop: this.props.theme.spacing(0.5)}}
                          error={this.state.errors.country}
                        />
                      </Grid>

                      {!!this.state.current_transfer.new_user.birth_date && DateTime.fromFormat(this.state.current_transfer.new_user.birth_date, "dd/MM/yyyy").plus({years: 18}) > DateTime.now() && <React.Fragment>
                        <Grid item xs={12} sx={{marginTop: 4}}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important', paddingLeft: '0px !important', paddingTop: '0px !important'}}>
                          <Typography variant='h3' sx={{paddingLeft: 3}}>{`3. ${t('transfer.detail.voogd_data')}`}</Typography> {this.its_okay(voogd_okay)}
                        </Grid>
                        {[['voogd_first_name', 6], ['voogd_last_name', 6], ['voogd_email', 12], ['voogd_mobile', 6]].map(field => (<Grid item xs={12} md={field[1]}>
                          <StyledTextField
                            label={t(`transfer.detail.other_new.field.${field[0]}`).replace(/<[^>]*>?/gm, '')}
                            value={this.state.current_transfer.new_user[field[0]]}
                            id={field[0]}
                            type={field[0] === 'email' ? 'email' : 'text'}
                            onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, [field[0]]: v}}});}}
                            // style={{marginTop: this.props.theme.spacing(0.5)}}
                            error={this.state.errors[field[0]]}
                          />
                        </Grid>))}
                        <Grid item xs={12} md={6}>
                          <BirthDate
                            onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, voogd_birth_date: v.target.value}}});}}
                            value={this.state.current_transfer.new_user.voogd_birth_date || ''}
                            label={t("transfer.detail.other_new.field.voogd_birth_date").replace(/<[^>]*>?/gm, '')}
                            id="voogd_birth_date"
                            // style={{marginTop: this.props.theme.spacing(0.5)}}
                            error={this.state.errors.voogd_birth_date}
                          />
                        </Grid>
                        <Grid item xs={12}>&nbsp;</Grid>
                        {[['voogd_street', 8], ['voogd_number', 4], ['voogd_zip', 4], ['voogd_city', 8]].map(field => (<Grid item xs={12} md={field[1]}>
                          <StyledTextField
                            label={t(`transfer.detail.other_new.field.${field[0]}`).replace(/<[^>]*>?/gm, '')}
                            value={this.state.current_transfer.new_user[field[0]]}
                            id={field[0]}
                            onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, [field[0]]: v}}});}}
                            // style={{marginTop: this.props.theme.spacing(0.5)}}
                            error={this.state.errors[field[0]]}
                          />
                        </Grid>))}
                        <Grid item xs={12}>
                          <CountryPicker
                            label={t("transfer.detail.other_new.field.voogd_country").replace(/<[^>]*>?/gm, '')}
                            value={this.state.current_transfer.new_user.voogd_country}
                            id="voogd_country"
                            onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, voogd_country: v.target.value}}});}}
                            error={this.state.errors.voogd_country}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledTextField
                            label={t(`transfer.detail.other_new.field.voogd_comment`).replace(/<[^>]*>?/gm, '')}
                            value={this.state.current_transfer.new_user.voogd_comment}
                            id='voogd_comment'
                            onChange={(v) => {this.setState({current_transfer: {...this.state.current_transfer, new_user: {...this.state.current_transfer.new_user, voogd_comment: v}}});}}
                            // style={{marginTop: this.props.theme.spacing(0.5)}}
                            multiline
                          />
                        </Grid>
                      </React.Fragment>}
                    </Grid>}
                    {!hide_for_further_steps && this.state.choice_ven === 2 && <Grid container item xs={12} spacing={1}>
                      <Grid item xs={12} sx={{marginTop: 2}}>
                        <PurifiedTypo text={t("transfer.detail.other_existing.intro")} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Vennr
                          onChange={(v) => {this.setState({search: {...this.state.search, vennr: v.target.value}});}}
                          value={this.state.search.vennr}
                          label={t("transfer.detail.other_existing.find.vennr").replace(/<[^>]*>?/gm, '')}
                          id="find_vennr"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <BirthDate
                          onChange={(v) => {this.setState({search: {...this.state.search, birth_date: v.target.value}});}}
                          value={this.state.search.birth_date || ''}
                          label={t("transfer.detail.other_existing.find.birth_date").replace(/<[^>]*>?/gm, '')}
                          id="find_birth_date"
                        />
                      </Grid>
                      <Grid item xs={12} align="right">
                        <StyledButton
                          text={t("transfer.detail.other_existing.find.cta").replace(/<[^>]*>?/gm, '')}
                          theme={this.props.theme}
                          onClick={(e) => {this.find_existing();}}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{marginTop: 2}}>
                        <Paper sx={{
                          border: `1px solid ${this.props.theme.palette.primary.main}`,
                          marginTop: 1,
                          boxShadow: 'none'
                        }}>
                          <Table>
                            <TableRow>
                              <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.other_existing.find_result.name')}</Typography></TableCell>
                              <TableCell sx={{width: '50%'}}><Typography>{this.state.existing_info.name}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.other_existing.find_result.vennr')}</Typography></TableCell>
                              <TableCell sx={{width: '50%'}}><Typography>{this.state.existing_info.nr}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                              {/* <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.other_existing.find_result.max_stock')}</Typography></TableCell> */}
                              <TableCell sx={{width: '100%'}}>
                                <Typography variant='h4'>{t('transfer.detail.other_existing.find_result.max_stock', {amount: `${(this.state.existing_info.max < (this.state.user.stock_number - pending_stock) ? this.state.existing_info.max : (this.state.user.stock_number - pending_stock)) || 0} (${((((this.state.existing_info.max < (this.state.user.stock_number - pending_stock) ? this.state.existing_info.max : (this.state.user.stock_number - pending_stock)) || 0) * {"A": 100, "B": 50}[this.state.user.share_type_tag]))} EUR)`})}</Typography>
                              </TableCell>
                            </TableRow>
                          </Table>
                        </Paper>
                      </Grid>
                    </Grid>}

                    {!!hide_for_further_steps && <Grid item xs={12} sx={{marginTop: 2}}>
                      <Paper sx={{
                        border: `1px solid ${this.props.theme.palette.primary.main}`,
                        marginTop: 1,
                        boxShadow: 'none'
                      }}>
                        <Table>
                          <TableRow>
                            <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.name')}</Typography></TableCell>
                            <TableCell sx={{width: '50%'}}><Typography>{this.state.current_transfer.full_name}</Typography></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.vennr')}</Typography></TableCell>
                            <TableCell sx={{width: '50%'}}><Typography>{this.state.current_transfer.new_user.vennr}</Typography></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.stock_total')}</Typography></TableCell>
                            <TableCell sx={{width: '50%'}}><ShareShower amount={this.state.current_transfer.receiver_stock || 0} share={this.state.user.share_type_tag} /></TableCell>
                          </TableRow>
                        </Table>
                      </Paper>
                    </Grid>}

                    {!!hide_for_further_steps && !!this.state.current_transfer.new_user.birth_date && DateTime.fromFormat(this.state.current_transfer.new_user.birth_date, "dd/MM/yyyy").plus({years: 18}) > DateTime.now() && <React.Fragment>
                      <Grid item xs={12} sx={{marginTop: 4}}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important', paddingLeft: '0px !important', paddingTop: '0px !important'}}>
                        <Typography variant='h3' sx={{paddingLeft: 3}}>{`3. ${t('transfer.detail.voogd_data')}`}</Typography> {this.its_okay(true)}
                      </Grid>
                      <Grid item xs={12} sx={{marginTop: 2}}>
                        <Paper sx={{
                          border: `1px solid ${this.props.theme.palette.primary.main}`,
                          marginTop: 1,
                          boxShadow: 'none'
                        }}>
                          <Table>
                            <TableRow>
                              <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.name')}</Typography></TableCell>
                              <TableCell sx={{width: '50%'}}><Typography>{this.state.current_transfer.new_user.voogd_first_name} {this.state.current_transfer.new_user.voogd_last_name}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.voogd_email')}</Typography></TableCell>
                              <TableCell sx={{width: '50%'}}><Typography>{this.state.current_transfer.new_user.voogd_email}</Typography></TableCell>
                            </TableRow>
                          </Table>
                        </Paper>
                      </Grid>
                    </React.Fragment>}

                    <Grid item xs={12} sx={{marginTop: 4}}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                      <Typography variant='h3' sx={{paddingLeft: 3}}>{`${!!this.state.current_transfer.new_user.birth_date && DateTime.fromFormat(this.state.current_transfer.new_user.birth_date, "dd/MM/yyyy").plus({years: 18}) > DateTime.now() ? '4' : '3'}. ${t('transfer.detail.stock_data')}`}</Typography> {this.its_okay(share_okay)}
                    </Grid>
                    {!hide_for_further_steps && <Grid item xs={12} md={6} sx={{marginTop: 2}}>
                      <StyledTextField
                        label={t(`transfer.detail.stock_data.field.amount`).replace(/<[^>]*>?/gm, '')}
                        value={this.state.current_transfer.shares.amount}
                        id='amount'
                        onChange={(v) => {
                          let a = parseInt(v, 10);
                          if (!a || a < 0 || a > (this.state.user.stock_number - pending_stock)) a = 0;
                          this.setState({current_transfer: {...this.state.current_transfer, shares: {...this.state.current_transfer.shares, amount: a}}});
                        }}
                      />
                    </Grid>}
                    <Grid item xs={12} sx={{marginTop: (!!hide_for_further_steps ? 2 : 0)}}>
                      <Paper sx={{
                        border: `1px solid ${this.props.theme.palette.primary.main}`,
                        marginTop: 1,
                        boxShadow: 'none'
                      }}>
                        <Box sx={{
                          width: '100%',
                          padding: 1,
                          textAlign: 'center',
                          display: 'inline-block'
                        }}><Typography variant='h4'>{t('transfer.detail.stock.table.mine')}</Typography></Box>
                        <Box sx={{
                          width: '100%',
                          padding: 1,
                          textAlign: 'center',
                          display: 'inline-block',
                          borderBottom: `1px solid ${this.props.theme.palette.primary.main}`
                        }}><ShareShower amount={this.state.user.stock_number} share={this.state.user.share_type_tag} /></Box>
                        <Box sx={{
                          width: '100%',
                          padding: 1,
                          textAlign: 'center',
                          display: 'inline-block',
                          marginBottom: -1
                        }}>
                          <Box sx={{
                            width: '40%',
                            padding: 1,
                            textAlign: 'center',
                            display: 'inline-block',
                            float: 'left'
                          }}><Typography variant='h4'>{t('transfer.detail.stock.table.mine_after')}</Typography></Box>
                          <Box sx={{
                            width: '40%',
                            padding: 1,
                            textAlign: 'center',
                            display: 'inline-block',
                            float: 'right'
                          }}><Typography variant='h4'>{t('transfer.detail.stock.table.transferred')}</Typography></Box>
                        </Box>
                        <Box sx={{
                          width: '100%',
                          padding: 1,
                          textAlign: 'center',
                          display: 'inline-block',
                          marginBottom: -1
                        }}>
                          <Box sx={{
                            width: '40%',
                            padding: 1,
                            textAlign: 'center',
                            display: 'inline-block',
                            float: 'left'
                          }}><ShareShower amount={this.state.user.stock_number - this.state.current_transfer.shares.amount} share={this.state.user.share_type_tag} /></Box>
                          <Box sx={{
                            width: '40%',
                            padding: 1,
                            textAlign: 'center',
                            display: 'inline-block',
                            float: 'right'
                          }}><ShareShower amount={this.state.current_transfer.shares.amount} share={this.state.user.share_type_tag} /></Box>
                        </Box>
                      </Paper>
                    </Grid>

                    {!hide_for_further_steps && <React.Fragment>
                      <Grid item xs={12} sx={{marginTop: 4}}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                        <Typography variant='h3' sx={{paddingLeft: 3}}>{`${!!this.state.current_transfer.new_user.birth_date && DateTime.fromFormat(this.state.current_transfer.new_user.birth_date, "dd/MM/yyyy").plus({years: 18}) > DateTime.now() ? '5' : '4'}. ${t('transfer.detail.outro_title')}`}</Typography> {this.its_okay(venn_okay && voogd_okay && share_okay)}
                      </Grid>
                      <Grid item xs={12} sx={{marginTop: 2}}>
                        <PurifiedTypo text={t("transfer.detail.outro")} />
                      </Grid>
                      <Grid item xs={12} sx={{marginTop: 4, marginBottom: 8}}>
                        {!this.state.submit_loading && <StyledButton
                          disabled={!!this.state.submit_loading || !venn_okay || !voogd_okay || !share_okay}
                          text={t("transfer.detail.create_transfer").replace(/<[^>]*>?/gm, '')}
                          theme={this.props.theme}
                          onClick={(e) => {this.submit();}}
                        />}
                        {!!this.state.submit_loading && <CircularProgress />}
                        <StyledButton
                          as_link
                          text={t("transfer.detail.stop_transfer").replace(/<[^>]*>?/gm, '')}
                          theme={this.props.theme}
                          onClick={(e) => {this.setState({current_transfer: null}); window.scrollTo(0, 0);}}
                        />
                      </Grid>
                    </React.Fragment>}

                    <Grid item xs={12} sx={{marginTop: 4}}>
                      {/* <Divider /> */}
                    </Grid>
                  </React.Fragment>}

                </Grid>
                <Grid item xs={12} md={2} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
              </React.Fragment>}

              <StyledConfirm
                theme={this.props.theme}
                t={this.props.t}
                key="transfer_status_msg"
                open={this.state.confirm}
                hide_cancel
                nullify={() => {this.setState({confirm: false});}}
                title={t(`transfer.detail.confirm.status.${this.state.confirm_status}_title`)}
                msg={t(`transfer.detail.confirm.status.${this.state.confirm_status}_body`)}
                onSubmit={() => {

                  this.setState({confirm: false, confirm_status: ''});
                }}
              />
            </Authenticated>
          </Grid>
        </Grid>
      </Container>
    </Page>);
  }
}

Transfer.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  },
  dropZoneText: {
    fontFamily: theme.typography.fontFamily
  },
  preview: {
    maxWidth: '100%',
    '& img': {
      maxHeight: '150px'
    },

  },
  preview_grid: {
    width: '100%',
    margin: '0px',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropzoneMainClass: {
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiDropzonePreviewList-removeButton': {
      right: '8px'
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: '8px'
    }
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Transfer))));
