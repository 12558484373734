import server from '../lib/server.js'

export default function rqst_signed_in({
  url, signout,
  locale, parameters = {}, login, callback
}) {
  return (dispatch) => {
    let h = {};
    if (typeof(login) !== 'undefined' && login !== null) {
      h = {
        "Auth-Token": (login.auth_token || (login.data ? login.data.auth_token : '')),
        "Auth-Email": (login.email || (login.data ? login.data.email : ''))
      };
    }

    return server.post(url, parameters, {
      headers: h
    }).then(_response => {
      var _data = _response.data;
      if (_data.success) {

      } else {
        dispatch({type: signout, payload: null});
      }
    }).catch(_error => {
      dispatch({type: signout, payload: null});
    });
  };
}
