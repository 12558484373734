import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {I18nextProvider} from 'react-i18next';
import {createStore, applyMiddleware, compose} from 'redux';
import {persistStore, persistReducer} from "redux-persist";
import {PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import Main from './containers/Main';
import './css/app.scss';
import i18n from './i18n';
import rootReducer from './reducers';
import {BrowserRouter} from "react-router-dom";
import {unregister} from './registerServiceWorker';
import 'typeface-roboto';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {StyledEngineProvider, createTheme, adaptV4Theme, ThemeProvider} from '@mui/material/styles';
// import MomentUtils from '@date-io/moment';
// import HttpsRedirect from 'react-https-redirect';

import { LocalizedMoment } from "./localizationContext";
import { LocalizedNumeral } from "./localizationNumeralContext";

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightMedium: 700,

    h1: {
      fontSize: "2rem",
      fontFamily: ['Nunito Sans'],
      color: '#5D6071',
      // textTransform: 'uppercase',
      fontWeight: 700
    },
    h2: {
      fontSize: "1.5rem",
      fontFamily: ['Nunito Sans'],
      color: '#5D6071',
      // textTransform: 'uppercase'
    },
    h3: {
      fontSize: "1.25rem",
      fontFamily: ['Nunito Sans'],
      color: '#5D6071',
      // textTransform: 'uppercase'
    },
    h4: {
      fontSize: "1rem",
      fontFamily: ['Nunito Sans'],
      color: '#5D6071',
      // textTransform: 'uppercase'
    },
    h5: {
      fontSize: "0.8rem"
    },
    h6: {
      color: '#5D6071'
    },
    body1: {
      color: '#5D5F71'
    },
    body2: {
      color: '#5D5F71'
    }
  },
  palette: {
    primary: {
      light: '#447d83',
      // main: '#D49E32',
      main: 'rgb(16, 81, 86)', // dark green
      main_p5: 'rgba(16, 81, 86, 0.5)',
      main_p12: 'rgba(16, 81, 86, 0.12)',
      // dark: '#9f7000'
      dark: '#00282d'
    },
    primary_grey: {
      main: '#5D6071',
      light: '#aeb0b8'
    },
    secondary: {
      // main: '#5D5F71',
      main: 'rgb(255, 16, 84)', // red
      // contrastText: '#FFFFFF',
      light: '#ff5f80',
      dark: '#c4002c'
    },
    tertiary: {
      main: 'rgb(92, 191, 141)' // light green
    },
    quaternary: {
      main: '#98B6B4'
    },
    text: {
      main: '#5D5F71'
    },
    warning: {
      main: '#ff9800',
      alpha: 'rgba(255, 152, 0, 0.1)'
    },
    danger: {
      main: '#b80018',
      alpha: 'rgba(184, 0, 24, 0.1)'
    },
    mode: 'light',
    background: {
      default: '#ffffff'
    }
  },
  drawer_width: 240,
  overrides: {
    MuiTableRow: {
      root: {
        '&.Mui-selected': {
          backgroundColor: "rgba(224, 224, 227, 0.25)"
        }
      }
    }
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#aeb0b8',
          '& .MuiTableCell-root': {
            color: 'white'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: 4,
          paddingBottom: 4,
          whiteSpace: 'nowrap'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          textTransform: 'none',
          ...(!ownerState.no_lowercase && {
            // textTransform: 'lowercase'
            textTransform: 'none'
          }), ...(!!ownerState.capitalise && {
            // textTransform: 'capitalize'
            textTransform: 'none'
          }), ...(!!ownerState.transform_none && {
            textTransform: 'none'
          })
        })
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.8
        }
      }
    }
  }
});

const initial_state = {};
const enhancers = [];
const middleware = [thunk];

const persist_config = {
  key: 'root',
  storage,
  blacklist: ["drawer", "register"]
};

const persisted_reducer = persistReducer(persist_config, rootReducer);
const composed_enhancers = compose(applyMiddleware(...middleware), ...enhancers);
const store = createStore(persisted_reducer, initial_state, composed_enhancers);
const persistor = persistStore(store);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    // <HttpsRedirect>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Suspense fallback={(<span>Please wait...</span>)}>
          <I18nextProvider i18n={i18n()}>
            <LocalizedMoment i18n={i18n()}>
              <LocalizedNumeral i18n={i18n()}>
                <BrowserRouter>
                  <ThemeProvider theme={theme}>
                    {/* <StyledEngineProvider injectFirst theme={theme}> */}
                      {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
                        <Main />
                      {/* </MuiPickersUtilsProvider> */}
                    {/* </StyledEngineProvider> */}
                  </ThemeProvider>
                </BrowserRouter>
              </LocalizedNumeral>
            </LocalizedMoment>
          </I18nextProvider>
          </React.Suspense>
        </PersistGate>
      </Provider>,
    // </HttpsRedirect>,
    document.body.appendChild(document.createElement('div')),
  )
})

unregister();
