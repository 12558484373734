import { connect } from 'react-redux';
import Landing from '../../actions/landing';
import WebLog from '../../actions/web_log';
import LandingPage from '../../components/pages/LandingPage';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      landing: {
        fetch_all: _params => {
          dispatch(Landing.fetch_all(_params));
        }
      },
      web_log: {
        do_refresh: _params => {
          dispatch(WebLog.do_refresh(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(LandingPage);
