import axios from 'axios';

const KEY = "nwuziog6JX0J9K";

// const api_url = 'http://localhost:3050';
// const api_url = 'https://argenco-webapps.kangacoders.com';
const api_url = 'https://old.argenco.be';

const instance = axios.create({
  baseURL: api_url,
  timeout: 60000,
  headers: {
    'X-KANGA-Key': KEY
  }
});

export default instance;
