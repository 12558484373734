import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';

import {DateTime} from "luxon";

import Authenticated from "../../containers/shared/parts/Authenticated";
import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import StyledTextField from "../shared/parts/StyledTextField";
import Typography from "../shared/parts/Typography";

var he = require('he');

class Raffle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.match.params.id === '8' ? null : props.login,
      raffle: null,
      loader: true,
      id: props.match.params.id,
      success: false,
      error: null,
      tel: '',
      repeat_tel: '',
      schifting: '',
      multi_option_1: ''
    };
  }

  componentDidMount() {
    this.fetch_raffle();
  }
  componentDidUpdate(prevProps, prevState) {}

  fetch_raffle = () => {
    this.setState({loader: true}, () => {
      this.props.helpers.raffles.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({raffle: r, loader: false});
        },
        parameters: {
          fields: ['id', 'field_name', 'visible_start', 'visible_end', 'field_description', 'raffle_start', 'raffle_end', 'is_schifting', 'field_schiftingsvraag', 'is_multi_option_1', 'field_multi_option_1_question', 'field_multi_option_1'],
          id: this.state.id
        }
      });
    });
  }

  subscribe_raffle = (e) => {
    if (this.state.id !== "8" || (this.state.id === "8" && this.state.tel === this.state.repeat_tel)) {
      this.props.helpers.devise.subscribe_raffle({
        locale: this.props.i18n.language,
        login: this.state.user,
        callback: (r, full_data) => {
          this.setState({success: r.success, error: r.message});
        },
        error_callback: (r) => {
          this.setState({success: r.success, error: r.data});
        },
        parameters: {
          id: this.state.id,
          user_tel: this.state.tel,
          schifting: this.state.schifting,
          multi_option_1: this.state.multi_option_1,
          vennr: this.state.user.vennr,
          birth_date: this.state.user.birth_date
        }
      });
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let valid = false;
    let visible = false;
    if (!!this.state.raffle) {
      valid = DateTime.fromISO(this.state.raffle.visible_start).startOf('day') <= DateTime.now() && DateTime.fromISO(this.state.raffle.visible_end).endOf('day') >= DateTime.now();
      visible = DateTime.fromISO(this.state.raffle.raffle_start).startOf('day') <= DateTime.now() && DateTime.fromISO(this.state.raffle.raffle_end).endOf('day') >= DateTime.now();
    }

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{maxWidth: '800px !important', '& p p': {
        // marginBottom: '0px !important'
      }}}>
        {!this.state.raffle && <Grid container spacing="2">
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>}
        {!!this.state.raffle && <Grid container>
          <Grid item xs={12} align="center">
            <Typography variant="h1">{this.state.raffle.field_name}</Typography>
          </Grid>

          {valid && <Grid container item xs={12}>
            <Grid item xs={12}>
              <PurifiedTypo text={this.state.raffle.field_description} />
            </Grid>

            {visible && <Grid container item xs={12}>
              <Grid item xs={12} sx={{marginTop: 3}}>
                {!this.state.user && <PurifiedTypo text={t("raffles_subscribe_description")} />}
                {!!this.state.user && <PurifiedTypo text={t(
                  this.state.id !== "4" ?
                    (this.state.id !== "8" ?
                      "raffles_subscribe_description_validated" :
                      "raffles_custom_8_subscribe_description_validated") :
                    "raffles_subscribe_description_validated_name"
                  )} />}
              </Grid>
              {!!this.state.success && <Grid item xs={12} sx={{marginTop: 4}}>
                <PurifiedTypo text={t("raffles_subscribe_success")} />
              </Grid>}
              {!!this.state.error && this.state.error === 'invisible' && <Grid item xs={12} sx={{marginTop: 4}}>
                <PurifiedTypo text={t("raffles_error_not_found")} />
              </Grid>}
              {!!this.state.error && this.state.error === 'closed' && <Grid item xs={12} sx={{marginTop: 4}}>
                <PurifiedTypo text={t("raffles_error_not_active")} />
              </Grid>}
              {!!this.state.error && this.state.error === 'exists' && <Grid item xs={12} sx={{marginTop: 4}}>
                {this.state.id !== '8' && <PurifiedTypo text={t("raffles_error_already_subscribed")} />}
                {this.state.id === '8' && <PurifiedTypo text={t("raffles_custom_8_error_already_subscribed")} />}
              </Grid>}
              {!!this.state.error && this.state.error === 'age' && <Grid item xs={12} sx={{marginTop: 4}}>
                <PurifiedTypo text={t("raffles_error_age")} />
              </Grid>}
              {!!this.state.error && this.state.error === 'user_not_found' && <Grid item xs={12} sx={{marginTop: 4}}>
                <PurifiedTypo text={t("raffles_error_user_not_found")} />
              </Grid>}
              {!!this.state.error && this.state.error === 'tel' && <Grid item xs={12} sx={{marginTop: 4}}>
                {this.state.id !== '4' && this.state.id !== '8' && <PurifiedTypo text={t("raffles_error_telnr_blank")} />}
                {this.state.id === '4' && <PurifiedTypo text={t("raffles_error_name_blank")} />}
                {this.state.id === '8' && <PurifiedTypo text={t("raffles_error_email_blank")} />}
              </Grid>}
              <Authenticated forceAuth login={this.props.login} t={this.props.t} onAuthenticate={(user, authenticated) => {
                if (authenticated) this.setState({user: user});
              }} vennr_label={t("raffle.child_vennr")}>
                {(!this.state.success && this.state.error !== 'exists') && <Grid container item xs={12}>
                  <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                  <Grid item xs={12} md={6}>
                    <StyledTextField
                      label={t(this.state.id !== "4" ?
                        (this.state.id !== "8" ?
                          "profile.my_data.field.phone" :
                          "profile.my_data.field.email") :
                        "profile.my_data.field.full_name"
                      ).replace(/<[^>]*>?/gm, '')}
                      value={this.state.tel}
                      id="phone"
                      onChange={(v) => {this.setState({tel: v});}}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                  {this.state.id === "8" && <>
                    <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                    <Grid item xs={12} md={6} sx={{marginTop: 2}}>
                      <StyledTextField
                        label={t("profile.my_data.field.repeat_email").replace(/<[^>]*>?/gm, '')}
                        value={this.state.repeat_tel}
                        id="repeat_phone"
                        onChange={(v) => {this.setState({repeat_tel: v});}}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                  </>}

                  {!!this.state.raffle.is_multi_option_1 && <Grid item xs={12} sx={{marginTop: 2}}>
                    <PurifiedTypo text={this.state.raffle.field_multi_option_1_question} />
                  </Grid>}
                  {!!this.state.raffle.is_multi_option_1 && <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>}
                  {!!this.state.raffle.is_multi_option_1 && <Grid item container xs={12} md={6}>
                    <RadioGroup value={this.state.multi_option_1} sx={{width: '100%', display: 'block'}} onChange={(e) => {
                      this.setState({multi_option_1: e.target.value});
                    }}>
                    <Grid item container xs={12}>
                      {this.state.raffle.field_multi_option_1.split("\n").map(answer => (<Grid item xs={12} md={6}><FormControlLabel value={he.decode(answer.trim().replace(/<[^>]*>?/gm, ''))} control={<Radio />} label={he.decode(answer.trim().replace(/<[^>]*>?/gm, ''))} /></Grid>))}
                    </Grid>
                    </RadioGroup>
                  </Grid>}
                  {!!this.state.raffle.is_multi_option_1 && <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>}

                  {!!this.state.raffle.is_schifting && <Grid item xs={12} sx={{marginTop: 2}}>
                    <PurifiedTypo text={this.state.raffle.field_schiftingsvraag} />
                  </Grid>}
                  {!!this.state.raffle.is_schifting && <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>}
                  {!!this.state.raffle.is_schifting && <Grid item xs={12} md={6}>
                    <StyledTextField
                      label={t("raffles.schiftingsvraag.answer").replace(/<[^>]*>?/gm, '')}
                      value={this.state.schifting}
                      id="schifting"
                      onChange={(v) => {this.setState({schifting: v});}}
                    />
                  </Grid>}
                  {!!this.state.raffle.is_schifting && <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>}
                  <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                  <Grid item xs={12} md={6} sx={{marginTop: 2}} align="center">
                    <StyledButton
                      text={t("raffles_subscribe_cta").replace(/<[^>]*>?/gm, '')}
                      theme={this.props.theme}
                      style={{marginTop: this.props.theme.spacing(2)}}
                      onClick={(e) => {this.subscribe_raffle(e);}}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                </Grid>}
              </Authenticated>
            </Grid>}
            {!visible && <Grid item xs={12} align="center">
              <PurifiedTypo text={t("raffles_error_not_active")} />
            </Grid>}
          </Grid>}
          {!valid && <Grid item xs={12} align="center">
            <PurifiedTypo text={t("raffles_error_not_found")} />
          </Grid>}

        </Grid>}
      </Container>
    </Page>);
  }
}

Raffle.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Raffle))));
