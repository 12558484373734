import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  CircularProgress,
  Container,
  Grid
} from '@mui/material';

import {DateTime} from "luxon";

import Page from "../../shared/Page";
import PurifiedTypo from "../../shared/parts/PurifiedTypo";
import SlideNormal from "../meeting/slides/Normal";
import SlideProfile from "../meeting/slides/Profile";
import Stepper from "../../shared/parts/Stepper";
import StyledButton from "../../shared/parts/StyledButton";
import Typography from "../../shared/parts/Typography";

class Voting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      user: !!this.props.location && !!this.props.location.state && !!this.props.location.state.user ? this.props.location.state.user : {},
      meeting: !!this.props.location && !!this.props.location.state && !!this.props.location.state.meeting ? this.props.location.state.meeting : {
        name: 'placeholder'
      },
      step: 0,
      answers: [],
      loading: false
    };
  }

  componentDidMount() {
    // if (!this.state.meeting.id) {
    this.props.helpers.meetings.fetch_all({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (r) => {
        let answers = [];
        r.voting_data.forEach((i) => {
          answers.push(999);
        });
        this.setState({meeting: r, answers: answers});
      },
      parameters: {
        vennr: this.state.user.vennoot_number,
        birth_date: this.state.user.birth_date,
        fields: [
          'id', 'name', 'field_intro', 'is_mandatory', 'field_location', 'meeting_day', 'last_day', 'last_day_vote', 'attendance',
          'field_voting_intro', 'field_voting_documents', 'voting_data', 'is_help_visible'
        ]
      }
    });
    // }
  }

  cast_vote(event) {
    event.preventDefault();
    this.setState({loading: true});

    this.props.helpers.meetings.do_vote({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (r) => {
        this.setState({loading: false});
        this.props.history.push({pathname: '/success', state: {success_type: 'online'}});
        window.scrollTo(0, 0);
      },
      parameters: {
        vennr: this.state.user.vennoot_number,
        birth_date: this.state.user.birth_date,
        fields: [],
        answers: this.state.answers,
        meeting_id: this.state.meeting.id
      }
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let lang = 'nl';
    if (this.props.i18n.language.startsWith("fr")) lang = 'fr';

    let active = DateTime.fromISO(this.state.meeting.last_day_vote).set({hour: 12, minute: 0, second: 0}) >= DateTime.now();
    let already_registered = (this.state.meeting && this.state.meeting.attendance && this.state.meeting.attendance.physical && this.state.meeting.attendance.physical.enabled);
    let already_voted = (this.state.meeting && this.state.meeting.attendance && this.state.meeting.attendance.online && this.state.meeting.attendance.online.enabled);
    let gave_volmacht = (this.state.meeting && this.state.meeting.attendance && this.state.meeting.attendance.physical && !!this.state.meeting.attendance.physical.volmacht);

    let steps = [t("meeting.online.steps.intro").replace(/<[^>]*>?/gm, '')];
    if (this.state.meeting && this.state.meeting.voting_data && this.state.meeting.voting_data.length > 0) {
      this.state.meeting.voting_data.forEach((i) => {
        steps.push("<span style='opacity: 0;'>.</span>");
      });
    }
    steps.push(t("meeting.online.steps.outro").replace(/<[^>]*>?/gm, ''));

    let empty_answers = this.state.answers.filter(i => i === 999).length > 0;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        {/* <Typography variant="h3">{t("meeting.online.title").replace(/<[^>]*>?/gm, '')}</Typography> */}

        {/* ook nog checken voor - volmacht */}

        <Grid container spacing="2" style={{marginTop: this.props.theme.spacing(2)}}>
          {!active && <Grid item xs={12} align="center">
            <Typography className={classes.grey_error} variant="h5">{t("meetings.notification.error.after_physical_last_day").replace(/<[^>]*>?/gm, '')}</Typography>
          </Grid>}
          {!!active && <React.Fragment>
            {!!already_voted && <Grid xs={12}>
              <Typography>{t("meetings.physical.attendance.already_voted").replace(/<[^>]*>?/gm, '')}</Typography>
            </Grid>}
            {!already_voted && <React.Fragment>
              {!!gave_volmacht && <Grid xs={12}>
                <Typography>{t("meetings.physical.attendance.gave_volmacht").replace(/<[^>]*>?/gm, '')}</Typography>
              </Grid>}
              {!gave_volmacht && <React.Fragment>
                {!!already_registered && <Grid xs={12}>
                  <Typography>{t("meetings.physical.attendance.already_registered").replace(/<[^>]*>?/gm, '')}</Typography>
                </Grid>}
                {!already_registered && <React.Fragment>
                  <Grid xs={12}>
                    <Stepper steps={steps} current={this.state.step} theme={this.props.theme} />
                  </Grid>
                  {this.state.step === 0 && <Grid xs={12}>
                    <PurifiedTypo text={this.state.meeting.field_voting_intro} />
                    <PurifiedTypo text={this.state.meeting.field_voting_documents} />
                  </Grid>}

                  {this.state.meeting && this.state.meeting.voting_data && this.state.meeting.voting_data.length > 0 && this.state.meeting.voting_data.map((question, i) => {return (<React.Fragment>
                    {question.type === 'profile' && <SlideProfile question={question} t={t} hidden={(this.state.step - 1) !== i} locale={lang} />}
                    {question.type !== 'profile' && <SlideNormal
                      question={question}
                      is_help_visible={this.state.meeting.is_help_visible}
                      t={t}
                      hidden={(this.state.step - 1) !== i}
                      locale={lang}
                      value={this.state.answers[i]}
                      onChange={(e) => {
                        let answers = [...this.state.answers];
                        answers[i] = parseInt(e.target.value, 10);
                        this.setState({answers: answers});
                      }}
                    />}
                  </React.Fragment>);})}

                  {this.state.step === steps.length - 1 && <Grid xs={12}>
                    {!empty_answers && <PurifiedTypo text={t("meeting.online.outro")} />}
                    {!!empty_answers && <PurifiedTypo text={t("meeting.online.outro_warning")} />}
                  </Grid>}

                  <Grid xs={12} sx={{display: 'flex', justifyContent: 'center', marginTop: 2}}>
                    {this.state.step !== 0 && !this.state.loading && <StyledButton text={t("meeting.online.btn.back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} onClick={(e) => {
                      this.setState({step: this.state.step - 1}, () => {window.scrollTo(0, 0);});
                    }} style={{margin: this.props.theme.spacing(1)}} />}
                    {/* disabled als vraag leeg is */}
                    {this.state.step !== steps.length - 2 && this.state.step !== steps.length - 1 && <StyledButton disabled={!!this.state.answers[this.state.step - 1] && this.state.answers[this.state.step - 1] === 999} text={t("meeting.online.btn.next").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} onClick={(e) => {
                      this.setState({step: this.state.step + 1}, () => {window.scrollTo(0, 0);});
                    }} style={{margin: this.props.theme.spacing(1)}} />}
                    {this.state.step === steps.length - 2 && <StyledButton disabled={!!this.state.answers[this.state.step - 1] && this.state.answers[this.state.step - 1] === 999} text={t("meeting.online.btn.confirm").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} onClick={(e) => {
                      this.setState({step: this.state.step + 1}, () => {window.scrollTo(0, 0);});
                    }} style={{margin: this.props.theme.spacing(1)}} />}
                    {this.state.step === steps.length - 1 && !this.state.loading && <StyledButton text={t("meeting.online.btn.confirm").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} onClick={(e) => {
                      this.cast_vote(e);
                    }} style={{margin: this.props.theme.spacing(1)}} />}
                    {this.state.step === steps.length - 1 && !!this.state.loading && <CircularProgress />}
                  </Grid>
                </React.Fragment>}
              </React.Fragment>}
            </React.Fragment>}
          </React.Fragment>}
        </Grid>
      </Container>
    </Page>);
  }
}

Voting.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  grey_error: {
    fontStyle: 'italic',
    opacity: 0.7
  },
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Voting))));
