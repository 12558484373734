import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import {

} from '@mui/material';

import BlockCol2TitleText from './Col2TitleText';
// import BlockCol2ImageTitleText from './Col2ImageTitleText';
import BlockImageLeftTitleText from './ImageLeftTitleText';
import BlockImageRightTitleText from './ImageRightTitleText';
import BlockImageTopTitleText from './ImageTopTitleText';
import BlockSeparator from './Separator';
import BlockSingleImage from './SingleImage';
import BlockText from './Text';
import BlockTitleText from './TitleText';

class BlockBase extends Component {
  render() {
    const {block, theme} = this.props;

    return (<React.Fragment>
      {block.internal_key === "image_top_title_text" && <BlockImageTopTitleText {...this.props} />}
      {block.internal_key === "separator" && <BlockSeparator {...this.props} />}
      {block.internal_key === "single_image" && <BlockSingleImage {...this.props} />}
      {block.internal_key === "text" && <BlockText {...this.props} />}
      {block.internal_key === "title_text" && <BlockTitleText {...this.props} />}
      {/* {block.internal_key === "col2_image_title_text" && <BlockCol2ImageTitleText {...this.props} />} */}
      {block.internal_key === "col2_title_text" && <BlockCol2TitleText {...this.props} />}
      {block.internal_key === "image_left_title_text" && <BlockImageLeftTitleText {...this.props} />}
      {block.internal_key === "image_right_title_text" && <BlockImageRightTitleText {...this.props} />}
    </React.Fragment>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(BlockBase));
