import Otp from '../actions/otp';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function otp(state = initial, action) {
  return Otp.state_switch(state, action);
}
