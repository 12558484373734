import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import StyledButton from '../../shared/parts/StyledButton';
import Typography from '../../shared/parts/Typography';

class ErrorStep2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state !== prevProps.state) {
      this.setState(this.props.state);
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Grid container>
      <Grid item xs={1} sm={2} md={4}></Grid>
      <Grid item xs={10} sm={8} md={4} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '200px'}}>
        <Typography sx={{color: 'red'}}>{t("first_time_step_step_1_error").replace(/<[^>]*>?/gm, '')}</Typography>

        <StyledButton text={t("first_time_step_2_button_back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{alignSelf: 'center', marginTop: this.props.theme.spacing(2)}} onClick={(e) => {this.setState({first_time_step: 1, show_email_code_error: false, show_email_code: false}, () => {this.props.onChangeState(this.state)});}} />
      </Grid>
      <Grid item xs={1} sm={2} md={4}></Grid>
    </Grid>);
  }
}

ErrorStep2.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(ErrorStep2))));
