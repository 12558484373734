import Devise from '../actions/devise';

const initial = {
  data: null,
  loading: false,
  error: null,
  forgot_data: [],
  forgot_loading: false,
  forgot_error: null,
  register_data: [],
  register_loading: false,
  register_error: null,
  fetch_loading: false,
  fetch_data: null,
  fetch_success: false,
  fetch_error: null
};

export default function devise(state = initial, action) {
  return Devise.state_switch(state, action);
}
