import WaitingList from '../actions/waiting_list';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function waiting_list(state = initial, action) {
  return WaitingList.state_switch(state, action);
}
