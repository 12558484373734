import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from '../../shared/parts/PurifiedTypo';
import StyledButton from '../../shared/parts/StyledButton';
import StyledTextField from '../../shared/parts/StyledTextField';
import Typography from '../../shared/parts/Typography';

class StepCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state !== prevProps.state) {
      this.setState(this.props.state);
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Grid container>
      <Grid item xs={1} sm={2} md={4}></Grid>
      <Grid item container xs={10} sm={8} md={4} sx={{minHeight: '300px', paddingLeft: {xs: 0, md: 1.5}}}>
        <Grid item xs={12} sx={{marginTop: 4, marginBottom: 1}}>
          <Typography variant="h3">{t("onboarding.register_email_code.title_step_2").replace(/<[^>]*>?/gm, '')}</Typography>
        </Grid>
        {!this.state.show_email_code_error && <Grid item xs={12}>
          <PurifiedTypo text={t("onboarding.register_email_code.title", {email: this.state.user.email})} />
        </Grid>}
        {!!this.state.show_email_code_error && <Grid item xs={12}>
          <PurifiedTypo text={t("onboarding.register_email_code_error.content", {email: this.state.user.email}).replace(/<[^>]*>?/gm, '')} />
        </Grid>}
        <Grid item xs={12}>
          <StyledTextField
            onChange={(v) => {this.setState({email_code: v}, () => {this.props.onChangeState(this.state)});}}
            value={this.state.email_code}
            label={t("onboarding.email_code").replace(/<[^>]*>?/gm, '')}
            id="email_code"
            sx={{marginTop: 4}}
            error={this.state.errors.email_code}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledButton disabled={this.state.email_code.length === 0} text={t("onboarding.register_email_code.cta").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{float: 'right', marginTop: 4}} onClick={(e) => {this.props.OnCheckVennr(e);}} />
        </Grid>
      </Grid>
      <Grid item xs={1} sm={2} md={4}></Grid>
    </Grid>);
  }
}

StepCode.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(StepCode))));
