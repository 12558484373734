import React from "react";

// import 'moment/locale/nl';
// import * as moment from 'moment-timezone';

const { DateTime } = require("luxon");

const Context = React.createContext();

// create Context.Provider : the value prop is our toolbox
export const LocalizedMoment = props => {
  // moment.tz.setDefault("Europe/Brussels");

  return (
    <Context.Provider value={(...args) => {
      var dt = DateTime.now();
      dt.setLocale('nl');
      dt.setZone("Europe/Brussels");
      return dt;
      // moment(...args).locale(props.i18n ? props.i18n.language.split("-")[0] : 'nl')
    }}>
      {props.children}
    </Context.Provider>
  );
};

// a higher order component to save us the hassle of calling Context.Consumer
export function withLocalization(Component) {
  return function LocalizedComponent(props) {
    return (
      <Context.Consumer>
        {context => {
          return (<Component {...props} moment={context}/>);
        }}
      </Context.Consumer>
    );
  };
}
