import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  CircularProgress,
  Container,
  Grid
} from '@mui/material';

import Page from "../shared/Page";
import StyledButton from '../shared/parts/StyledButton';
import StyledTextField from '../shared/parts/StyledTextField';
import Typography from '../shared/parts/Typography';

import ErrorStep1 from './onboarding/ErrorStep1';
import ErrorStep2 from './onboarding/ErrorStep2';
import ErrorStep2Unique from './onboarding/ErrorStep2Unique';
import ModLogin from './onboarding/Login';
import ModStepAuth from './onboarding/StepAuth';
import ModStepAuthEdit from './onboarding/StepAuthEdit';
import ModStepCode from './onboarding/StepCode';
import ModStepContact from './onboarding/StepContact';
import ModStepEmail from './onboarding/StepEmail';
import ModStepSecQ from './onboarding/StepSecQ';

class Onboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      repeat_password: '',
      vennr: '',
      birth_date: '',
      errors: {},
      first_time_step: 0,
      usrname: '',
      pword: '',
      user: {exists: false},
      edit_address: false,
      edit_cor: false,
      edit_phones: false,
      show_first_time: false,
      logging_in: false,
      show_otp_code: false,
      show_ft_otp_code: false,
      show_email_code: false,
      show_email_code_error: false,
      otp_code: '',
      email_code: '',
      sec_q: {
        answer_1: '',
        answer_2: '',
        answer_3: ''
      },
      show_password: false
    };
  }

  componentDidMount() {}

  login(event) {
    event.preventDefault();

    this.setState({logging_in: true}, () => {
      let errors = {};
      if (typeof(this.state.username) === 'undefined' || this.state.username === null || this.state.username.trim() === "") errors.username = true;
      if (typeof(this.state.password) === 'undefined' || this.state.password === null || this.state.password.toString().trim() === "") errors.password = true;

      if (Object.keys(errors).length === 0) {
        this.props.do_login({
          locale: this.props.i18n.language,
          callback: (r) => {
            this.setState({logging_in: false});
            this.props.history.push({pathname: "/profile", state: {}});
          },
          error_callback: (r) => {
            if (r.data === "mfa_status_0_verification") {
              this.setState({logging_in: false, show_otp_code: true});
            } else {
              this.setState({logging_in: false, errors: {username: true, password: true}});
            }
          },
          parameters: {
            fields: ['id', 'first_name', 'last_name', 'email', 'auth_token', "type", "is_admin", "vennoot_number", 'address', 'stock_number', 'share_type|tag', 'birth_date', 'stock_id', 'stock_number', 'share_type|tag'],
            username: this.state.username,
            password: this.state.password,
            otp_code: this.state.otp_code
          }
        });
      } else {
        this.setState({errors: errors, logging_in: false});
      }
    });
  }

  check_vennr(event) {
    event.preventDefault();

    let errors = {};
    if (typeof(this.state.vennr) === 'undefined' || this.state.vennr === null || this.state.vennr.trim() === "") errors.vennr = true;
    if (typeof(this.state.birth_date) === 'undefined' || this.state.birth_date === null || this.state.birth_date.toString().trim() === "") errors.birth_date = true;

    if (Object.keys(errors).length === 0) {
      this.props.check_vennr({
        locale: this.props.i18n.language,
        callback: (r) => {
          if (r.mfa_status_0_verification) {
            this.setState({show_ft_otp_code: true});
          } else {
            if (r.email_verification) {
              this.setState({show_email_code: true, user: r});
            } else {
              if (r.email_verification_error) {
                this.setState({show_email_code_error: true});
              } else {
                if (r.exists && r.sec_q_status > 0) {
                  this.setState({first_time_step: 0.5, user: r});
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                } else {
                  if (r.exists) {
                    this.setState({first_time_step: 1, user: r});
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                  } else {
                    this.setState({first_time_step: 1, user: {exists: false}, show_email_code: false, show_email_code_error: false});
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                  }
                }
              }
            }
          }
        },
        parameters: {
          testing: 1,
          vennr: this.state.vennr,
          birth_date: this.state.birth_date,
          otp_code: this.state.otp_code,
          email_code: this.state.email_code
        }
      });
    } else {
      this.setState({errors: errors});
    }
  }

  check_sec_q(event) {
    event.preventDefault();

    let errors = {};
    if (typeof(this.state.sec_q.answer_1) === 'undefined' || this.state.sec_q.answer_1 === null || this.state.sec_q.answer_1.trim() === "") errors.sq1 = true
    if (typeof(this.state.sec_q.answer_2) === 'undefined' || this.state.sec_q.answer_2 === null || this.state.sec_q.answer_2.trim() === "") errors.sq2 = true;
    if (typeof(this.state.sec_q.answer_3) === 'undefined' || this.state.sec_q.answer_3 === null || this.state.sec_q.answer_3.trim() === "") errors.sq3 = true;

    if (Object.keys(errors).length === 0) {
      this.props.check_vennr({
        locale: this.props.i18n.language,
        callback: (r) => {
          if (r.sec_q_error) {
            this.setState({sec_q_error: true});
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          } else {
            this.setState({first_time_step: 1, user: r, sec_q_error: false});
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          }
        },
        parameters: {
          vennr: this.state.vennr,
          birth_date: this.state.birth_date,
          sec_q: this.state.sec_q
        }
      });
    } else {
      this.setState({errors: errors});
    }
  }

  check_login(event) {
    event.preventDefault();

    let errors = {};
    if (typeof(this.state.vennr) === 'undefined' || this.state.vennr === null || this.state.vennr.trim() === "") errors.vennr = true;
    if (typeof(this.state.birth_date) === 'undefined' || this.state.birth_date === null || this.state.birth_date.toString().trim() === "") errors.birth_date = true;
    if (typeof(this.state.user.username) === 'undefined' || this.state.user.username === null || this.state.user.username.toString().trim() === "") errors.username = true;
    if (typeof(this.state.user.password) === 'undefined' || this.state.user.password === null || this.state.user.password.toString().trim() === "" || this.state.user.password.toString().trim().length < 8) errors.password = true;
    if (typeof(this.state.user.repeat_password) === 'undefined' || this.state.user.repeat_password === null || this.state.user.repeat_password.toString().trim() === "") errors.repeat_password = true;
    if (this.state.user.repeat_password !== this.state.user.password) errors.repeat_password = true;

    if (Object.keys(errors).length === 0) {
      this.props.first_time_set_login({
        locale: this.props.i18n.language,
        callback: (r) => {
          // this.setState({first_time_step: 2, user: {...this.state.user, ...r}});
          // document.body.scrollTop = document.documentElement.scrollTop = 0;
          if (r.success) {
            this.setState({user: {...this.state.user, ...r}}, () => {
              this.props.do_login({
                locale: this.props.i18n.language,
                callback: (r) => {
                  setTimeout(() => {
                    this.props.history.push({pathname: "/profile", state: {}});
                    document.location.reload();
                  }, 500);
                },
                parameters: {
                  fields: ['id', 'first_name', 'last_name', 'email', 'auth_token', "type", "is_admin", "vennoot_number", 'address', 'stock_number', 'share_type|tag', 'birth_date', 'stock_id', 'stock_number', 'share_type|tag'],
                  username: this.state.user.username,
                  password: this.state.user.password
                }
              });
            });
          } else {
            this.setState({user: {...this.state.user, success: false}, errors: (r.message === "invalid_username" ? {username_exists: true} : {})});
          }
        },
        parameters: {
          vennr: this.state.vennr,
          birth_date: this.state.birth_date,
          username: this.state.user.username,
          password: this.state.user.password,
          password_repeat: this.state.user.repeat_password
        }
      });
    } else {
      this.setState({errors: errors}, () => {
        setTimeout(() => {
          this.setState({errors: {}});
        }, 6000);
      });
    }
  }

  check_address(event) {
    event.preventDefault();

    // let errors = {};
    // if (typeof(this.state.vennr) === 'undefined' || this.state.vennr === null || this.state.vennr.trim() === "") errors.vennr = true;
    // if (typeof(this.state.birth_date) === 'undefined' || this.state.birth_date === null || this.state.birth_date.toString().trim() === "") errors.birth_date = true;
    //
    // if (Object.keys(errors).length === 0) {
    //   this.props.first_time_set_contact({
    //     locale: this.props.i18n.language,
    //     callback: (r) => {
    //       this.setState({first_time_step: 3, user: {...this.state.user, ...r}});
    //       document.body.scrollTop = document.documentElement.scrollTop = 0;
    //     },
    //     parameters: {
    //       vennr: this.state.vennr,
    //       birth_date: this.state.birth_date,
    //       street: this.state.user.street,
    //       number: this.state.user.number,
    //       zip: this.state.user.zip,
    //       city: this.state.user.city,
    //       country: this.state.user.country,
    //       cor_street_2: this.state.user.cor_street_2,
    //       cor_street: this.state.user.cor_street,
    //       cor_number: this.state.user.cor_number,
    //       cor_zip: this.state.user.cor_zip,
    //       cor_city: this.state.user.cor_city,
    //       cor_country: this.state.user.cor_country,
    //       phone: this.state.user.phone,
    //       mobile: this.state.user.mobile
    //     }
    //   });
    // } else {
    //   this.setState({errors: errors});
    // }
  }

  check_email(event) {
    event.preventDefault();

    // let errors = {};
    // if (typeof(this.state.vennr) === 'undefined' || this.state.vennr === null || this.state.vennr.trim() === "") errors.vennr = true;
    // if (typeof(this.state.birth_date) === 'undefined' || this.state.birth_date === null || this.state.birth_date.toString().trim() === "") errors.birth_date = true;
    //
    // if (Object.keys(errors).length === 0) {
    //   this.props.first_time_set_email({
    //     locale: this.props.i18n.language,
    //     callback: (r) => {
    //       if (r.success) {
    //         this.props.do_login({
    //           locale: this.props.i18n.language,
    //           callback: (r) => {
    //             this.props.history.push({pathname: "/profile", state: {}});
    //             document.location.reload();
    //           },
    //           parameters: {
    //             fields: ['id', 'first_name', 'last_name', 'email', 'auth_token', "type", "is_admin", "vennoot_number"],
    //             username: this.state.user.username,
    //             password: this.state.user.password
    //           }
    //         });
    //       } else {
    //         this.setState({user: {...this.state.user, success: false}});
    //       }
    //     },
    //     parameters: {
    //       vennr: this.state.vennr,
    //       birth_date: this.state.birth_date,
    //       email: this.state.user.email,
    //       email_repeat: this.state.user.email_repeat
    //     }
    //   });
    // } else {
    //   this.setState({errors: errors});
    // }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      {!this.state.show_first_time && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ModLogin
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
          onLogin={(e) => {this.login(e);}}
        />
      </Container>}

      {!!this.state.show_first_time && this.state.first_time_step === 0 && !this.state.show_email_code && !this.state.show_email_code_error && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ModStepAuth
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
          OnCheckVennr={(e) => {this.check_vennr(e);}}
        />
      </Container>}

      {!!this.state.show_first_time && this.state.first_time_step === 0 && (this.state.show_email_code || this.state.show_email_code_error) && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ModStepCode
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
          OnCheckVennr={(e) => {this.check_vennr(e);}}
        />
      </Container>}

      {!!this.state.show_first_time && this.state.first_time_step === 0.5 && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ModStepSecQ
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
          OnCheckSecQ={(e) => {this.check_sec_q(e);}}
        />
      </Container>}

      {!!this.state.show_first_time && this.state.first_time_step === 1 && !this.state.user.exists && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ErrorStep1
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
        />
      </Container>}

      {!!this.state.show_first_time && this.state.first_time_step === 1 && !!this.state.user.exists && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ModStepAuthEdit
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
          OnCheckLogin={(e) => {this.check_login(e);}}
        />
      </Container>}

      {!!this.state.show_first_time && this.state.first_time_step === 2 && !this.state.user.success && this.state.user.message === "invalid_username" && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ErrorStep2Unique
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
        />
      </Container>}

      {!!this.state.show_first_time && this.state.first_time_step === 2 && !this.state.user.success && this.state.user.message !== "invalid_username" && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ErrorStep2
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
        />
      </Container>}

      {false && !!this.state.show_first_time && this.state.first_time_step === 2 && this.state.user.success && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ModStepContact
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
          OnCheckAddress={(e) => {this.check_address(e);}}
        />
      </Container>}

      {false && !!this.state.show_first_time && this.state.first_time_step === 3 && this.state.user.success && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <ModStepEmail
          {...this.props}
          state={this.state}
          onChangeState={(s) => {this.setState(s);}}
          OnCheckEmail={(e) => {this.check_email(e);}}
        />
      </Container>}
    </Page>);
  }
}

Onboarding.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Onboarding))));
