import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';

import {
  // Typography as Typo
} from '@mui/material';

import Typography from "./Typography";

var he = require('he');

class ShareShower extends Component {
  render() {
    let unit_price = {
      "A": 100,
      "B": 50
    }[this.props.share]

    return (!!this.props.as_string ? he.decode(`${(this.props.amount || 0)} (${(((this.props.amount || 0) * unit_price))} EUR)`) : (<Typography {...this.props}>
      {he.decode(`${(this.props.amount || 0)} (${(((this.props.amount || 0) * unit_price))} EUR)`)}
    </Typography>));
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(ShareShower));
