import rqst_post from '../lib/server_helper.js';

export default class DeathMessage {
  static SUBMIT = 'DeathMessage.SUBMIT';
  static SUBMIT_BEGIN = 'DeathMessage.SUBMIT_BEGIN';
  static SUBMIT_SUCCESS = 'DeathMessage.SUBMIT_SUCCESS';
  static SUBMIT_ERROR = 'DeathMessage.SUBMIT_ERROR';

  static submit = ({
    locale, parameters = {}, login, callback, error_callback
  }) => {
    return rqst_post({
      url: `/${locale}/v2/death_messages/submit`,
      begin: DeathMessage.SUBMIT_BEGIN,
      succes: DeathMessage.SUBMIT_SUCCESS,
      error: DeathMessage.SUBMIT_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback,
      error_callback: error_callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case DeathMessage.SUBMIT:
        return {...state, loading: false, data: null};
      case DeathMessage.SUBMIT_BEGIN:
        return {...state, loading: true, error: null};
      case DeathMessage.SUBMIT_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case DeathMessage.SUBMIT_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
