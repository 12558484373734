import { connect } from 'react-redux';
import Devise from '../../actions/devise';
import Raffle from '../../actions/raffle';
import RafflePage from '../../components/pages/Raffle';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      devise: {
        subscribe_raffle: (_params) => {
          dispatch(Devise.subscribe_raffle(_params));
        }
      },
      raffles: {
        fetch_all: (_params) => {
          dispatch(Raffle.fetch_all(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(RafflePage);
