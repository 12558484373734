import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from "../parts/PurifiedTypo";
import Typography from "../parts/Typography";

class BlockTitleText extends Component {
  render() {
    const {block, theme, locale} = this.props;

    return (<React.Fragment>
      {!!block.extra_data && !!block.extra_data.text && !!block.extra_data.text.title && block.extra_data.text.title[locale] && <Grid item xs={12}>
        <Typography variant="h2">{block.extra_data.text.title[locale]}</Typography>
      </Grid>}
      {!!block.extra_data && !!block.extra_data.text && !!block.extra_data.text.content && block.extra_data.text.content[locale] && <Grid item xs={12}>
        <PurifiedTypo text={block.extra_data.text.content[locale]} />
      </Grid>}
    </React.Fragment>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(BlockTitleText));
