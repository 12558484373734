import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  CircularProgress,
  Container,
  Grid
} from '@mui/material';


import BlockBase from "../shared/blocks/Base";
import Page from "../shared/Page";

class LandingPage extends Component {
  constructor(props) {
    super(props);

    let lang = 'nl';
    if (this.props.i18n.language.startsWith("fr")) lang = 'fr';

    this.state = {
      landing: {},
      loader: true,
      active_iso: lang,
      key: props.match.params.key
    };
  }

  componentDidMount() {
    this.fetch_landing();
  }
  componentDidUpdate(prevProps, prevState) {}

  fetch_landing = () => {
    this.setState({loader: true}, () => {
      this.props.helpers.landing.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({landing: r, loader: false});
        },
        parameters: {
          fields: ['id', 'block_list'],
          utm_key: this.state.key
        }
      });
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{maxWidth: '800px !important', '& p p': {
        // marginBottom: '0px !important'
      }}}>
        {!this.state.landing && <Grid container spacing="2">
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>}
        {!!this.state.landing && <Grid container>
          {this.state.landing.block_list?.length > 0 && <React.Fragment>
            {this.state.landing.block_list.map(block => (<React.Fragment>
              {!!block.extra_data && <BlockBase block={block} theme={this.props.theme} locale={this.state.active_iso} />}
            </React.Fragment>))}
          </React.Fragment>}
        </Grid>}
      </Container>
    </Page>);
  }
}

LandingPage.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(LandingPage))));
