import { connect } from 'react-redux';
import Faq from '../../actions/faq';
import Faqs from '../../components/pages/Faq';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      faqs: {
        fetch_all: (_params) => {
          dispatch(Faq.fetch_all(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Faqs);
