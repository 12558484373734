import Landing from '../actions/landing';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function landing(state = initial, action) {
  return Landing.state_switch(state, action);
}
