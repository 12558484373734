import server from '../lib/server.js'

export default function rqst_sign_out({
  url, signout,
  locale, parameters = {}, login, callback
}) {
  return (dispatch) => {
    let h = {};
    if (typeof(login) !== 'undefined' && login !== null) {
      h = {
        "Auth-Token": (login.auth_token || (login.data ? login.data.auth_token : '')),
        "Auth-Email": (login.email || (login.data ? login.data.email : ''))
      };
    }

    return server.get(url, {
      headers: h
    }).then(_response => {
      dispatch({type: signout, payload: null});
      if (callback !== undefined) callback();
    }).catch(_error => {
      dispatch({type: signout, payload: null});
    });
  };
}
