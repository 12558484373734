import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Grid
} from '@mui/material';

import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";

class Security extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state !== prevProps.state) {
      this.setState(this.props.state);
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return !(parseInt(this.state.user.mfa_status, 10) === 0 && parseInt(this.state.user.sec_q_status, 10) === 0) ? (<span></span>) : (<Container sx={{paddingLeft: 0, paddingRight: 0, marginTop: 2}}>
      <Grid container spacing="2" style={{marginBottom: this.props.theme.spacing(4)}}>
        <Grid item xs={12}>
          <Box
            className={classes.orange_box_dense}
            onClick={(e) => {this.props.scrollRef.current.scrollIntoView()}}
            sx={{borderColor: this.props.theme.palette.tertiary.main, backgroundColor: "rgba(92, 191, 141, 0.4)"}}
          >
            <PurifiedTypo text={t("profile.notification.security.content")} />
          </Box>
        </Grid>
      </Grid>
    </Container>);
  }
}

Security.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  orange_box: {
    border: `4px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(3),
    position: 'relative'
  },
  orange_box_dense: {
    border: `4px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'relative',
    cursor: 'pointer'
  },
  grey_error: {
    fontStyle: 'italic',
    opacity: 0.7
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Security))));
