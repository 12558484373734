import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';

import {
  Box
} from '@mui/material';

class VisualAmount extends Component {
  render() {
    const {amount, t, minimumFractionDigits} = this.props;
    let min_frac_dig = minimumFractionDigits;
    if (!minimumFractionDigits) min_frac_dig = 0;

    return (<Box>
      {amount.toLocaleString('nl-BE', {minimumFractionDigits: min_frac_dig})} {t("general.terms.eur").replace(/<[^>]*>?/gm, '')}
    </Box>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(VisualAmount));
