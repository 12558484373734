import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Container,
  Grid
} from '@mui/material';


import Authenticated from "../../containers/shared/parts/Authenticated";
import Meeting from "../../containers/notifications/Meeting";
import Page from "../shared/Page";
import Typography from "../shared/parts/Typography";

class Av extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.login,
      meeting: {
        name: ''
      }
    };
  }

  componentDidMount() {
    this.props.helpers.meetings.fetch_all({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (r) => {
        this.setState({meeting: r});
      },
      parameters: {
        fields: [
          'id', 'field_backstage_name'
        ]
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>

        {!this.state.user && <Grid item xs={12} sx={{marginTop: 2}}>
          <Typography variant="h3" align="center">{this.state.meeting.field_backstage_name}</Typography>
        </Grid>}
        <Grid item container xs={12}>
          <Authenticated login={this.props.login} t={this.props.t} onAuthenticate={(user, authenticated) => {
            if (authenticated) this.setState({user: user});
          }} fields={[
            'id', 'first_name', 'last_name', 'email', 'auth_token', "type", "is_admin", "vennoot_number", 'address', 'stock_number', 'share_type|tag',
            'birth_date', 'stock_id', 'phone', 'mobile', 'zicht_account', 'street', 'number', 'zip', 'city'
          ]}>
            {!!this.state.user && <Meeting user={this.state.user} as_page={true} open={true} />}
          </Authenticated>
        </Grid>
      </Container>
    </Page>);
  }
}

Av.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Av))));
