import { connect } from 'react-redux';
import News from '../../actions/news';
import NewsItem from '../../components/pages/NewsItem';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      news: {
        fetch_all: (_params) => {
          dispatch(News.fetch_all(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(NewsItem);
