import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Box,
  Button,
  Grid,
  Popover
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import Typography from "./Typography";

class StyledConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      title: props.title,
      msg: props.msg
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    ['open', 'msg', 'title'].map(p => {
      if (this.props[p] !== prevProps[p]) {
        this.setState({[p]: this.props[p]});
      }
      return null;
    });
  }

  render() {
    const {theme, t} = this.props

    return (<Popover
      id={`confirm_${this.props.key}`}
      key={`confirm_${this.props.key}`}
      open={this.state.open}
      anchorReference={"none"}
      onClose={() => {this.props.nullify();}}
      transformOrigin={{
        vertical: 'middle',
        horizontal: 'center',
      }}
      PaperProps={{sx: {backgroundColor: theme.palette.primary.main, padding: 2, borderRadius: '20px'}}}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      disableScrollLock
    >
      <Box sx={{width: 600, maxWidth: '100%', height: 20, display: 'flex'}}>
        <Typography variant="h3" sx={{lineHeight: '20px', display: 'inline-block', color: 'white', paddingLeft: 2}}>{this.state.title || t("confirm.title")}</Typography>
        <CloseIcon sx={{maxHeight: '20px', marginLeft: 'auto', cursor: 'pointer', color: 'white'}} onClick={(e) => {e.stopPropagation(); this.props.nullify();}} />
      </Box>
      <Box sx={{width: 600, maxWidth: '100%', display: 'flex', marginTop: 1, padding: 2}}>
        <Grid container spacing="2">
          <Grid item xs={12} sx={{color: 'white', marginTop: 2, textAlign: 'left'}}>
            <Typography sx={{lineHeight: '20px', display: 'inline-block', color: 'white'}}>{this.state.msg || t("confirm.message")}</Typography>
            {!!this.props.qr && <Box sx={{marginTop: 2, textAlign: 'center'}}>
              <Box sx={{padding: 2, backgroundColor: 'white', width: 'auto', display: 'inline-block'}}>
                {this.props.qr}
              </Box>
            </Box>}
          </Grid>
          <Grid item xs={12} sx={{color: 'white', marginTop: 2, textAlign: 'right'}}>
            {!this.props.hide_cancel && <Button
              as_link
              color="tertiary"
              onClick={() => {
                if (!!this.props.onCancel) this.props.onCancel();
                this.props.nullify();
              }}
              sx={{marginRight: 2}}
            >{this.props.cancel || t("confirm.cancel").replace(/<[^>]*>?/gm, '')}</Button>}
            <Button
              variant="contained"
              color="tertiary"
              onClick={() => {
                if (!!this.props.onSubmit) this.props.onSubmit();
              }}
            >{this.props.confirm || t("confirm.confirm").replace(/<[^>]*>?/gm, '')}</Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(StyledConfirm));
