import rqst_post from '../lib/server_helper.js';

export default class WaitingList {
  static SAVE = 'WaitingList.SAVE';
  static SAVE_BEGIN = 'WaitingList.SAVE_BEGIN';
  static SAVE_SUCCESS = 'WaitingList.SAVE_SUCCESS';
  static SAVE_ERROR = 'WaitingList.SAVE_ERROR';

  static do_save = ({
    locale, parameters = {}, login, callback, error_callback
  }) => {
    return rqst_post({
      url: `/${locale}/v2/waiting_lists/do_save`,
      begin: WaitingList.SAVE_BEGIN,
      succes: WaitingList.SAVE_SUCCESS,
      error: WaitingList.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback,
      error_callback: error_callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case WaitingList.SAVE:
        return {...state, loading: false, data: null};
      case WaitingList.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case WaitingList.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case WaitingList.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
