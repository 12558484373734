import { connect } from 'react-redux';
import Devise from '../../actions/devise';
import Transfer from '../../components/pages/Transfer';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      users: {
        transfer_information: (_params) => {
          dispatch(Devise.transfer_information(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Transfer);
