import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import InputMask from 'react-input-mask';

import {
  TextField
} from '@mui/material';

var he = require('he');

class Vennr extends Component {
  render() {
    const {onChange, value} = this.props;

    return (<InputMask
      mask={[/[AB]/i, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/]}
      value={value}
      onChange={onChange}
    >
      <TextField
        variant="outlined"
        {...this.props}
        label={he.decode(this.props.label)}
        sx={{width: '100%', ...(!!this.props.sx ? this.props.sx : {})}}
      />
    </InputMask>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(Vennr));
