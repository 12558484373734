import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Pagination
} from '@mui/material';

import {DateTime} from "luxon";

import ItemBlock from "../shared/parts/ItemBlock";
import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import Typography from "../shared/parts/Typography";

class Benefits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: null,
      loader: true,
      loader_tags: true,
      items: [],
      tags: [],
      filters: (!!props.location && !!props.location.state ? (props.location.state.filters || []) : []),
      items_count: 0,
      page: 1,
      per_page: 12
    };
  }

  componentDidMount() {
    this.fetch_benefits();
    this.fetch_benefits_tags();
  }
  componentDidUpdate(prevProps, prevState) {}

  fetch_benefits = () => {
    this.setState({loader: true}, () => {
      this.props.helpers.benefits.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({items: r, items_count: full_data.data.count, page: full_data.data.count < (this.state.page - 1) * this.state.per_page ? 1 : this.state.page, loader: false});
        },
        parameters: {
          search: "",
          fields: ['id', 'field_name', 'field_body', 'image_url', 'created_at', 'tag|field_name'],
          order: 'weight DESC, id DESC',
          page: this.state.page - 1,
          per_page: this.state.per_page,
          filter: {
            tag_ids: this.state.filters
          }
        }
      });
    });
  }

  fetch_benefits_tags = () => {
    this.setState({loader_tags: true}, () => {
      this.props.helpers.benefits_tag.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({tags: r.sort((a, b) => a.field_name - b.field_name), loader_tags: false});
        },
        parameters: {
          search: "",
          fields: ['id', 'field_name'],
          order: 'id ASC',
          page: 0,
          per_page: 600
        }
      });
    });
  }

  click_event = (item, e) => {
    if (e.ctrlKey || (e.nativeEvent && e.nativeEvent.which === 2) || (e.button === 1)) {
      window.open(`/benefits/${item.id}`);
    } else {
      window.scrollTo(0, 0);
      this.props.history.push(`/benefits/${item.id}`, {filters: this.state.filters});
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.component_title} sx={{textAlign: 'center'}}>
              {t(`benefits.title`).replace(/<[^>]*>?/gm, '')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
            <PurifiedTypo text={t("benefits.seo_paragraph")} />
          </Grid>
          <Grid item xs={12} sx={{marginTop: 2}}>&nbsp;</Grid>
          <Grid item xs={12}>
            <Typography>{this.state.items_count} {t("benefits.item_count").replace(/<[^>]*>?/gm, '')}</Typography>
          </Grid>
          <Grid item xs={12} sx={{marginTop: 1, marginBottom: 1}}><Divider /></Grid>
          <Grid item xs={12} sx={{textAlign: 'right'}}>
            {this.state.tags.length > 0 && this.state.tags.map(tag => (<Chip color="primary" label={tag.field_name} variant="outlined" onClick={() => {
              if (this.state.filters.indexOf(tag.id) > -1) {
                let fltr = this.state.filters;
                fltr.splice(this.state.filters.indexOf(tag.id), 1);
                this.setState({filters: fltr}, () => {
                  this.fetch_benefits();
                });
              } else {
                let fltr = this.state.filters;
                fltr.push(tag.id);
                this.setState({filters: fltr}, () => {
                  this.fetch_benefits();
                });
              }
            }} sx={{
              marginLeft: 1,
              marginBottom: 1,
              ...(this.state.filters.indexOf(tag.id) > -1 ? {
                color: this.props.theme.palette.primary.main,
                border: `1px solid ${this.props.theme.palette.primary.main_p5}`,
                backgroundColor: this.props.theme.palette.primary.main_p12
              } : {

              })
            }} />))}
          </Grid>
          <Grid item xs={12} sx={{marginBottom: 1}}>&nbsp;</Grid>

          <Grid item container xs={12}>
            {this.state.items.map(item => (<Grid item xs={12} sm={6} md={4} lg={4} xl={3} sx={{padding: 1}} onMouseDown={(e) => {if (e.button == 1) this.click_event(item, e);}} onAuxClick={(e) => {this.click_event(item, e);}} onClick={(e) => {this.click_event(item, e);}}>
              <ItemBlock
                lines={2}
                theme={this.props.theme}
                created_at={item.created_at}
                tag_name={item.tag_field_name}
                image_url={item.image_url}
                name={item.field_name}
              />
            </Grid>))}
          </Grid>
          <Grid item xs={12}>
            {this.state.items_count > this.state.per_page && <Pagination
              sx={{marginBottom: 2, marginTop: 2, display: 'flex', justifyContent: 'center'}}
              color="primary" count={parseInt((this.state.items_count / this.state.per_page) + (this.state.items_count % this.state.per_page > 0 ? 1 : 0), 10)}
              boundaryCount={2}
              variant="outlined"
              page={this.state.page}
              onChange={(ev, val) => {
                this.setState({page: val}, () => {this.fetch_benefits();});
                window.scrollTo(0, 0);
              }}
            />}
          </Grid>
        </Grid>
      </Container>
    </Page>);
  }
}

Benefits.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Benefits))));
