import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

const options = {
  fallbackLng: (code) => {
    let fallback = 'nl';

    if (!!code && code.startsWith('xx')) {
      fallback = 'xx';
    }

    if (!!code && code.startsWith('fr')) {
      fallback = 'fr';
    }

    return fallback;
  },
  // fallbackLng: 'nl',
  supportedLngs: ['nl', 'fr', 'xx'],
  nonExplicitSupportedLngs: true,

  detection: {
    lookupQuerystring: 'locale'
  },

  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false,
  nsSeparator: false,

  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },

  react: {
    wait: true
  }
};

//
options.backend = {
  loadPath: 'https://old.argenco.be/locales/{{lng}}/{{ns}}.json',
  crossDomain: true
};
i18n.use(XHR);

export default() => {
  i18n.use(LanguageDetector).init(options);
  return i18n;
};
