import { connect } from 'react-redux';
// import Devise from '../../actions/devise';
import Content from '../../components/pages/Content';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {

    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Content);
