import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from "../parts/PurifiedTypo";

class BlockSeparator extends Component {
  render() {
    const {block, theme, locale} = this.props;

    return (<React.Fragment>
      <Grid item xs={12} sx={{
        height: '1px',
        backgroundColor: theme.palette.primary.main,
        marginTop: block.settings?.mtop || 1,
        marginBottom: block.settings?.mbot || 1
      }}>
        &nbsp;
      </Grid>
    </React.Fragment>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(BlockSeparator));
