import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';
import InputMask from 'react-input-mask';

import {
  InputAdornment,
  TextField
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

class BankAccount extends Component {
  constructor(props) {
    super(props);

    let checked = false;
    if (!!props.value) {
      let iban = this.props.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
      let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
      let digits;

      if (!!code && !!code[1] && !!code[2] && !!code[3]) {
        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
          return letter.charCodeAt(0) - 55;
        });
        var checksum = digits.slice(0, 2), fragment;
        for (var offset = 2; offset < digits.length; offset += 7) {
          fragment = String(checksum) + digits.substring(offset, offset + 7);
          checksum = parseInt(fragment, 10) % 97;
        }
        if (checksum === 1) checked = true;
      }
    }

    this.state = {
      okay: checked,
      value: props.value
    };
  }

  render() {
    const {onChange, value, ...other} = this.props;

    return (<InputMask
      mask={["B", "E", /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      value={this.state.value}
      onChange={(v) => {
        let iban = v.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
        let digits;
        let checked = false;

        if (!!code && !!code[1] && !!code[2] && !!code[3]) {
          digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
            return letter.charCodeAt(0) - 55;
          });
          var checksum = digits.slice(0, 2), fragment;
          for (var offset = 2; offset < digits.length; offset += 7) {
            fragment = String(checksum) + digits.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
          }
          if (checksum === 1) checked = true;
        }
        this.setState({okay: checked, value: v.target.value}, () => {
          if (!!onChange) onChange(v, checked);
        });
      }}
    >
      <TextField
        variant="outlined"
        {...other}
        sx={{width: '100%'}}
        InputProps={{
          endAdornment: (<InputAdornment position="end">
            {!this.state.okay && <CloseIcon sx={{color: this.props.theme.palette.secondary.main}} />}
            {!!this.state.okay && <CheckIcon sx={{color: this.props.theme.palette.tertiary.main}} />}
          </InputAdornment>)
        }}
      />
    </InputMask>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(BankAccount));
