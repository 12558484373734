import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';
import InputMask from 'react-input-mask';

import {
  TextField
} from '@mui/material';

class BirthDate extends Component {
  render() {
    const {onChange, value} = this.props;

    return (<InputMask
      mask="99/99/9999"
      maskPlaceholder="dd/mm/yyyy"
      value={value}
      onChange={onChange}
    >
      <TextField
        variant="outlined"
        {...this.props}
        sx={{width: '100%', ...(!!this.props.sx ? this.props.sx : {})}}
      />
    </InputMask>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(BirthDate));
