import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Pagination
} from '@mui/material';

import {DateTime} from "luxon";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import BlockBase from "../shared/v2_blocks/Base";
import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import Typography from "../shared/parts/Typography";

class NewsItem extends Component {
  constructor(props) {
    super(props);

    let lang = 'nl';
    if (this.props.i18n.language.startsWith("fr")) lang = 'fr'

    this.state = {
      news: null,
      loader: true,
      id: props.match.params.id,
      filters: (!!props.location && !!props.location.state ? (props.location.state.filters || []) : []),
      active_iso: lang
    };
  }

  componentDidMount() {
    this.fetch_news();
  }
  componentDidUpdate(prevProps, prevState) {}

  fetch_news = () => {
    this.setState({loader: true}, () => {
      this.props.helpers.news.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({news: r, loader: false});
        },
        parameters: {
          fields: ['id', 'field_title', 'field_body', 'field_v2_body', 'image_url', 'created_at', 'updated_at', 'tag|field_name', 'block_list'],
          id: this.state.id
        }
      });
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      {/* <Helmet>
        <link rel="opengraph" href="https://old.argenco.be/news_items/"/>
      </Helmet> */}

      <Container sx={{maxWidth: '800px !important', '& p p': {
        // marginBottom: '0px !important'
      }}}>
        {!this.state.news && <Grid container spacing="2">
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>}
        {!!this.state.news && <Grid container>
          <Grid item xs={12} sx={{marginBottom: 2}}>
            <StyledButton text={t("newsitem.back_to_news").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link startIcon={<ArrowBackIcon />} onClick={() => {
              this.props.history.push("/news", {filters: this.state.filters});
              window.scrollTo(0, 0);
            }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.component_title} sx={{textAlign: 'center'}}>
              {this.state.news.field_title}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={6} sx={{marginTop: 2, paddingRight: {xs: 0, md: 1}}}>
            <img src={this.state.news.image_url} style={{maxWidth: '100%'}} />
          </Grid> */}
          <Grid item xs={12} sx={{textAlign: 'left', marginTop: 2, paddingLeft: {xs: 0, md: '8px !important'}}}>
            <Box sx={{}}>
              <Box sx={{
                border: `1px solid ${this.props.theme.palette.primary.main}`,
                color: this.props.theme.palette.primary.main,
                padding: 1,
                width: 'auto',
                display: 'inline-block'
              }}>
                {DateTime.fromISO(this.state.news.updated_at).toLocaleString(DateTime.DATE_SHORT)}
              </Box>
              {!!this.state.news.tag_field_name && <Box sx={{
                border: `1px solid ${this.props.theme.palette.primary.main}`,
                color: this.props.theme.palette.primary.main,
                padding: 1,
                width: 'auto',
                display: 'inline-block',
                backgroundColor: `rgba(16, 81, 86, 0.6)`,
                color: 'white',
                marginLeft: 2,
                fontWeight: 700
              }}>{this.state.news.tag_field_name}</Box>}
            </Box>
            {/* {!this.state.news.field_v2_body && <PurifiedTypo text={this.state.news.field_body} />}
            {!!this.state.news.field_v2_body && <PurifiedTypo text={this.state.news.field_v2_body} />} */}
          </Grid>

          {this.state.news.block_list.length > 0 && <React.Fragment>
            {this.state.news.block_list.map(block => (<React.Fragment>
              {!!block.extra_data && <BlockBase block={block} theme={this.props.theme} locale={this.state.active_iso} />}
            </React.Fragment>))}
          </React.Fragment>}

          <Grid item xs={12} sx={{marginBottom: 2, marginTop: 5}}>
            <StyledButton text={t("newsitem.back_to_news").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link startIcon={<ArrowBackIcon />} onClick={() => {
              this.props.history.push("/news", {filters: this.state.filters});
              window.scrollTo(0, 0);
            }} />
          </Grid>
        </Grid>}
      </Container>
    </Page>);
  }
}

NewsItem.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(NewsItem))));
