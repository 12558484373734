import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import StepVoogdChoice from "./guardian_profile/Choice";
import StepVoogdExistingValidation from "./guardian_profile/ExistingValidation";
import StepVoogdNewFields from "./guardian_profile/NewFields";

import BirthDate from "../../shared/parts/onboarding/BirthDate";
import PurifiedTypo from "../../shared/parts/PurifiedTypo";
import StyledButton from "../../shared/parts/StyledButton";
import Typography from "../../shared/parts/Typography";
import Vennr from "../../shared/parts/onboarding/Vennr";

var he = require('he');

class EmissionStepProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      profile_step: {
        picker_step: (props.state.profile_step?.picker_step || 0),
        user_mode: (props.state.profile_step?.user_mode || 0),
        use_itsme: (props.state.itsme_loader ? 1 : 0)
      },
      login: {
        vennr: '',
        birth_date: ''
      },
      show_error: false,
      errors: {},
      loading:  false
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    ['state'].map(p => {
      if (this.props[p] !== prevProps[p]) {
        this.setState({...this.props[p]});
      }
      return null;
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (!!this.props.visible ? (<React.Fragment>
      <Grid item xs={12} sx={{marginTop: 2, textAlign: {xs: 'left', md: 'center'}}}>
        {this.state.profile_step.picker_step === 0 && <PurifiedTypo text={t("emission.steps.guardian_profile.intro")} />}
        {((this.state.profile_step.picker_step === 1 && this.state.profile_step.user_mode === 1) || (this.state.profile_step.picker_step === 2)) && <PurifiedTypo text={t("emission.steps.guardian_profile.intro_logged_in")} sx={{"p": {textAlign: 'center'}}} />}
      </Grid>

      {this.state.profile_step.picker_step === 0 && <StepVoogdChoice {...this.props} state={this.state} onChangeState={(new_state) => {this.setState(new_state, () => {this.props.onChangeState(new_state);});}} />}
      {this.state.profile_step.picker_step === 1 && this.state.profile_step.user_mode === 1 && <StepVoogdExistingValidation {...this.props} state={this.state} onChangeState={(new_state) => {this.setState(new_state, () => {this.props.onChangeState(new_state);});}} />}
      {this.state.profile_step.picker_step === 1 && this.state.profile_step.user_mode === 2 && <StepVoogdNewFields {...this.props} state={this.state} onChangeState={(new_state) => {this.setState(new_state, () => {this.props.onChangeState(new_state);});}} />}

      {!(this.state.profile_step.picker_step === 2 || this.state.profile_step.picker_step === 0) && <Grid item xs={12} sx={{marginTop: 7}}>
        <StyledButton
          as_link
          onClick={() => {
            this.setState({
              ignore: {
                bank_checked: false
              },
              profile_step: {
                picker_step: (!!this.props.login ? 2 : 0),
                user_mode: 0,
                use_itsme: 0
              },
              user: (this.props.login || {
                first_name: '',
                last_name: '',
                birth_date: '',
                gender: '',
                vennoot_number: '',
                stock_number: 0,
                email: '',
                repeat_email: '',
                mobile: '',
                street: '',
                city: '',
                zip: '',
                city: '',
                country: 'BE',
                zicht_account: ''
              }),
              errors: {}
            }, () => {
              this.props.onChangeState(this.state);
              if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                this.props.helpers.web_log.do_refresh({
                  locale: this.props.i18n.language,
                  callback: (r, full_data) => {
                    window.argenco_uuid = r;
                  },
                  parameters: {
                    id: this.props.login?.id,
                    uuid: window.argenco_uuid,
                    u: window.location.pathname,
                    r: document.referrer,
                    a: 'button_click',
                    d: 'emission.steps.reset',
                    s: window.argenco_source,
                    ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                  }
                });
              }
              window.scrollTo(0, 0);
            });
          }}
          text={t("emission.landing.cta.reset")}
          sx={{marginTop: 1, marginBottom: 1}}
        />
      </Grid>}

    </React.Fragment>) : '');
  }
}

EmissionStepProfile.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(EmissionStepProfile))));
