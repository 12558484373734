import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid
} from '@mui/material';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import Typography from "../shared/parts/Typography";

class Faq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: !!props.location.state && !!props.location.state.active ? props.location.state.active : null,
      faqs: []
    };
  }

  componentDidMount() {
    this.fetch_faqs();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.active === this.state.active && !!this.props.location.state && this.props.location.state.active !== this.state.active) {
      this.setState({active: this.props.location.state.active});
    }
  }

  fetch_faqs = () => {
    this.setState({loader: true}, () => {
      this.props.helpers.faqs.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({faqs: r, loader: false});
        },
        parameters: {}
      });
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{padding: {xs: 0, md: 2}}}>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.component_title} sx={{textAlign: 'center'}}>
              {t(`faqs.title`).replace(/<[^>]*>?/gm, '')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
            <PurifiedTypo text={t("faqs.seo_paragraph")} />
          </Grid>
          <Grid item xs={12} sx={{marginTop: 2}}>&nbsp;</Grid>
        </Grid>

        {/* {this.state.faqs.map(category => (<Box component="section" sx={{
          border: `1px solid ${this.props.theme.palette.primary.main}`,
          marginBottom: 8
        }}>
          <Container maxWidth="lg" sx={{ py: 4 }}>
            <Box sx={{ mb: 4 }}>
              <Typography gutterBottom variant="h1" sx={{color: this.props.theme.palette.primary.main}}>{category.name}</Typography>
            </Box> */}
            {this.state.faqs.map((question) => (
              <Accordion
                key={question.id}
                disableGutters={true}
                elevation={0}
                sx={{
                  "&.Mui-expanded:before": {
                    opacity: 1,
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${question.id}-content`}
                  id={`${question.id}-header`}
                  sx={{ "& .MuiAccordionSummary-content": { my: 3 } }}
                >
                  <Typography
                    component="div"
                    sx={{fontSize: "1rem", color: this.props.theme.palette.primary.main}}
                    variant="h6"
                  >
                    {question.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{pb: 3, marginTop: -3}}>
                  <PurifiedTypo color="text.secondary" text={question.answer} />
                </AccordionDetails>
              </Accordion>
            ))}
          {/* </Container>
        </Box>))} */}
      </Container>
    </Page>);
  }
}

Faq.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Faq))));
