import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import BirthDate from "../../../shared/parts/onboarding/BirthDate";
import PurifiedTypo from "../../../shared/parts/PurifiedTypo";
import StyledButton from "../../../shared/parts/StyledButton";
import Vennr from "../../../shared/parts/onboarding/Vennr";

var he = require('he');

class EmissionStepProfileExistingValidation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {}

  validate_simple = (event) => {
    this.setState({loading: true}, () => {
      event.preventDefault();

      let errors = {};
      if (typeof(this.state.login.vennr) === 'undefined' || this.state.login.vennr === null || this.state.login.vennr.trim() === "") errors.vennr = true;
      if (typeof(this.state.login.birth_date) === 'undefined' || this.state.login.birth_date === null || this.state.login.birth_date.toString().trim() === "") errors.birth_date = true;

      if (Object.keys(errors).length === 0) {
        this.props.helpers.user.validate_simple({
          locale: this.props.i18n.language,
          login: this.props.login,
          callback: (r, full_data) => {
            this.props.history.push(`/emissions/stocks/${r.stock_id}`, {});
            // this.setState({user: {vennr: this.state.login.vennr, birth_date: this.state.login.birth_date, ...r}, profile_step: {...this.state.profile_step, picker_step: 2}, loading: false}, () => {
            //   this.props.onChangeState(this.state);
            // });
          },
          error_callback: (r) => {
            this.setState({show_error: true, loading: false}, () => {
              this.props.onChangeState(this.state);
            });
          },
          parameters: {
            vennr: this.state.login.vennr,
            birth_date: this.state.login.birth_date,
            fields: ['first_name', 'last_name', 'stock_number', 'share_type|tag', 'stock_id']
          }
        });
      } else {
        this.setState({loading: false, errors: errors}, () => {
          this.props.onChangeState(this.state);
        });
      }
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<React.Fragment>
      <Grid item xs={12} sx={{marginTop: 2, textAlign: {xs: 'left', md: 'center'}}}>
        <PurifiedTypo sx={{marginBottom: 1}} text={t("emission.steps.guardian_profile.cta_step_1_existing.intro")} />
        <Grid container>
          <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
          <Grid item xs={12} md={6}>
            <Vennr
              onChange={(e) => {this.setState({login: {...this.state.login, vennr: e.target.value}});}}
              value={this.state.login.vennr}
              label={he.decode(t("authentication.vennr").replace(/<[^>]*>?/gm, ''))}
              id="vennr"
              style={{marginTop: this.props.theme.spacing(3)}}
              error={this.state.errors.vennr}
            />
            <BirthDate
              onChange={(e) => {this.setState({login: {...this.state.login, birth_date: e.target.value}});}}
              value={this.state.login.birth_date}
              label={he.decode(t("authentication.birth_date").replace(/<[^>]*>?/gm, ''))}
              id="birth_date"
              style={{marginTop: this.props.theme.spacing(3)}}
              error={this.state.errors.birth_date}
            />
            {!this.state.loading && <StyledButton
              disabled={this.state.login.vennr.length === 0 || this.state.login.birth_date.length === 0}
              text={t("authentication.authenticate").replace(/<[^>]*>?/gm, '')}
              theme={this.props.theme}
              sx={{alignSelf: 'flex-end', marginTop: this.props.theme.spacing(4)}}
              onClick={(e) => {this.validate_simple(e);}}
            />}
            {!this.state.loading && this.state.show_error && <PurifiedTypo text={t("authentication.errors.general")} sx={{marginTop: 2}} />}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>);
  }
}

EmissionStepProfileExistingValidation.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(EmissionStepProfileExistingValidation))));
