import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Pagination
} from '@mui/material';

import {DateTime} from "luxon";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import BlockBase from "../shared/v2_blocks/Base";
import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import Typography from "../shared/parts/Typography";

class BenefitsItem extends Component {
  constructor(props) {
    super(props);

    let lang = 'nl';
    if (this.props.i18n.language.startsWith("fr")) lang = 'fr';

    let always_show_button = false;
    // if (parseInt(props.match.params.id.toString(), 10) === 100 || parseInt(props.match.params.id.toString(), 10) === 101) always_show_button = true;
    if (parseInt(props.match.params.id.toString(), 10) === 8 || parseInt(props.match.params.id.toString(), 10) === 16) always_show_button = true;

    this.state = {
      benefits: null,
      loader: true,
      id: props.match.params.id,
      filters: (!!props.location && !!props.location.state ? (props.location.state.filters || []) : []),
      active_iso: lang,
      link_loading: false,
      show_button: always_show_button
    };
  }

  componentDidMount() {
    this.fetch_benefits();
  }
  componentDidUpdate(prevProps, prevState) {}

  fetch_benefits = () => {
    this.setState({loader: true}, () => {
      this.props.helpers.benefits.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({benefits: r, loader: false});
        },
        parameters: {
          fields: ['id', 'field_name', 'field_body', 'field_v2_body', 'image_url', 'created_at', 'tag|field_name', 'block_list', 'field_discount_line_1', 'field_discount_line_2', 'is_hidden', 'has_codes', 'linked_code'],
          id: this.state.id
        }
      });
    });
  }

  link_benefit = () => {
    this.setState({loader: true}, () => {
      this.props.helpers.benefits.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({benefits: r, loader: false, show_button: false});
        },
        parameters: {
          fields: ['id', 'field_name', 'field_body', 'field_v2_body', 'image_url', 'created_at', 'tag|field_name', 'block_list', 'field_discount_line_1', 'field_discount_line_2', 'is_hidden', 'has_codes', 'linked_code'],
          id: this.state.id,
          link_to: 1
        }
      });
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let show_code_stuff = !!this.state.benefits && (!this.state.benefits.linked_code || !!this.state.show_button);

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{maxWidth: '800px !important', '& p p': {
        // marginBottom: '0px !important'
      }}}>
        {!this.state.benefits && <Grid container spacing="2">
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>}
        {!!this.state.benefits && <Grid container>
          <Grid item xs={12} sx={{marginBottom: 2}}>
            <StyledButton text={t("benefit.back_to_benefits").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link startIcon={<ArrowBackIcon />} onClick={() => {
              this.props.history.push("/benefits", {filters: this.state.filters});
              window.scrollTo(0, 0);
            }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.component_title} sx={{textAlign: 'center'}}>
              {this.state.benefits.field_name}
            </Typography>
          </Grid>
          {!!this.props.login && <Grid item xs={12}>
          {/* {!!this.props.login && <Grid item xs={12} sx={{backgroundColor: 'rgba(92, 191, 141, 0.5)'}}> */}
            <Typography variant="h2" className={classes.component_subtitle} sx={{textAlign: 'center'}}>
              {this.state.benefits.field_discount_line_1}
            </Typography>
            <Typography variant="h3" className={classes.component_subtitle} sx={{textAlign: 'center'}}>
              {this.state.benefits.field_discount_line_2}
            </Typography>
          </Grid>}
          {!(!this.state.benefits.is_hidden || !!this.state.benefits.is_hidden && !!this.props.login) && <React.Fragment>
            <Grid item xs={12} sx={{marginTop: 2}}>
              <PurifiedTypo text={t("benefit.hidden_message")} />
            </Grid>
          </React.Fragment>}
          {(!this.state.benefits.is_hidden || !!this.state.benefits.is_hidden && !!this.props.login) && <React.Fragment>
            {/* <Grid item xs={12} md={6} sx={{marginTop: 2, paddingRight: {xs: 0, md: 1}}}>
              <img src={this.state.benefits.image_url} style={{maxWidth: '100%'}} />
            </Grid>
            <Grid item xs={12} md={6} sx={{textAlign: 'left', marginTop: 2, paddingLeft: {xs: 0, md: '8px !important'}}}>
              <Box sx={{}}>
                <Box sx={{
                  border: `1px solid ${this.props.theme.palette.primary.main}`,
                  color: this.props.theme.palette.primary.main,
                  padding: 1,
                  width: 'auto',
                  display: 'inline-block'
                }}>
                  {DateTime.fromISO(this.state.benefits.created_at).toLocaleString(DateTime.DATE_SHORT)}
                </Box>
                {!!this.state.benefits.tag_field_name && <Box sx={{
                  border: `1px solid ${this.props.theme.palette.primary.main}`,
                  color: this.props.theme.palette.primary.main,
                  padding: 1,
                  width: 'auto',
                  display: 'inline-block',
                  backgroundColor: `rgba(16, 81, 86, 0.6)`,
                  color: 'white',
                  marginLeft: 2,
                  fontWeight: 700
                }}>{this.state.benefits.tag_field_name}</Box>}
              </Box>
              {!this.state.benefits.field_v2_body && <PurifiedTypo text={this.state.benefits.field_body} />}
              {!!this.state.benefits.field_v2_body && <PurifiedTypo text={this.state.benefits.field_v2_body} />}
            </Grid> */}
            <Grid item xs={12} md={6} sx={{textAlign: 'left', marginTop: 2, paddingLeft: {xs: 0, md: '8px !important'}}}>
              <Box sx={{}}>
                <Box sx={{
                  border: `1px solid ${this.props.theme.palette.primary.main}`,
                  color: this.props.theme.palette.primary.main,
                  padding: 1,
                  width: 'auto',
                  display: 'inline-block'
                }}>
                  {DateTime.fromISO(this.state.benefits.created_at).toLocaleString(DateTime.DATE_SHORT)}
                </Box>
                {!!this.state.benefits.tag_field_name && <Box sx={{
                  border: `1px solid ${this.props.theme.palette.primary.main}`,
                  color: this.props.theme.palette.primary.main,
                  padding: 1,
                  width: 'auto',
                  display: 'inline-block',
                  backgroundColor: `rgba(16, 81, 86, 0.6)`,
                  color: 'white',
                  marginLeft: 2,
                  fontWeight: 700
                }}>{this.state.benefits.tag_field_name}</Box>}
              </Box>
            </Grid>

            {this.state.benefits.block_list.length > 0 && <React.Fragment>
              {this.state.benefits.block_list.map(block => (<React.Fragment>
                {!!block.extra_data && <BlockBase block={block} theme={this.props.theme} locale={this.state.active_iso} />}
              </React.Fragment>))}
            </React.Fragment>}

            {!!this.props.login && (!!this.state.benefits.has_codes || !!this.state.benefits.linked_code) && <Grid item xs={12} sx={{marginTop: 2}}>
              <Box sx={{backgroundColor: this.props.theme.palette.primary.main_p12, padding: 2}}>
                {show_code_stuff && <Typography variant="h3" sx={{color: this.props.theme.palette.primary.main, marginBottom: 2}}>{t("benefit.benefit_code.title.not_linked")}</Typography>}
                {!show_code_stuff && <Typography variant="h3" sx={{color: this.props.theme.palette.primary.main, marginBottom: 2}}>{t("benefit.benefit_code.title.linked")}</Typography>}

                {show_code_stuff && <React.Fragment>
                  {!this.state.link_loading && <StyledButton
                    onClick={() => {
                      this.setState({link_loading: true}, () => {
                        this.link_benefit();
                      });
                    }}
                    text={t("benefit.benefit_code.cta.link")}
                    sx={{marginTop: 1, marginBottom: 1}}
                  />}
                  {!!this.state.link_loading && <CircularProgress />}
                </React.Fragment>}
                {!show_code_stuff && <Typography variant="h3" sx={{color: this.props.theme.palette.primary.main, fontWeight: 'bold'}}>{this.state.benefits.linked_code}</Typography>}
              </Box>
            </Grid>}
          </React.Fragment>}

          <Grid item xs={12} sx={{marginBottom: 2, marginTop: 5}}>
            <StyledButton text={t("benefit.back_to_benefits").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link startIcon={<ArrowBackIcon />} onClick={() => {
              this.props.history.push("/benefits", {filters: this.state.filters});
              window.scrollTo(0, 0);
            }} />
          </Grid>
        </Grid>}
      </Container>
    </Page>);
  }
}

BenefitsItem.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  },
  component_subtitle: {
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(BenefitsItem))));
