import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Container,
  Grid
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import StepProfile from "./StepProfile";
import StepStock from "./StepStock";
import StepVoogdProfile from "./StepVoogdProfile";

import Page from "../../shared/Page";
import PurifiedTypo from "../../shared/parts/PurifiedTypo";
import StyledButton from "../../shared/parts/StyledButton";
import StyledTextField from "../../shared/parts/StyledTextField";
import Typography from "../../shared/parts/Typography";

class EmissionLanding extends Component {
  constructor(props) {
    super(props);

    let testing = false;
    testing = window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1;

    this.state = {
      testing: testing,
      testing_id: 6,
      loader: true,
      emission: {},
      emission_information: null,
      waitlist_entry: {
        phone: '',
        email: ''
      },
      waitlist_loading: false,
      waitlist_response: null,
      step: (!!props?.match?.params?.id ? 2 : 0),
      voogd: false,
      itsme_loader: false,
      user: (props.login || {
        first_name: '',
        last_name: '',
        birth_date: '',
        gender: '',
        vennoot_number: '',
        stock_number: 0,
        email: '',
        repeat_email: '',
        mobile: '',
        street: '',
        city: '',
        zip: '',
        city: '',
        country: 'BE',
        zicht_account: ''
      }),
      guardian: {
        first_name: '',
        last_name: '',
        birth_date: '',
        email: '',
        repeat_email: '',
        mobile: '',
        street: '',
        city: '',
        zip: '',
        city: '',
        country: 'BE',
        comment: '',
        diff_contact: false,
        diff_contact: false
      },
      ignore: {},
      stock_id: (!!props?.match?.params?.id ? props.match.params.id : null),
      ...props.location?.state,
      forgot_loading: false,
      forgot_link: false,
      forgot_email: '',
      forgot_message: ''
    };
  }

  componentDidMount() {
    if (this.props.location?.search?.indexOf("uuid=") > -1) {
      let qr = new URLSearchParams(this.props.location.search);
      window.argenco_uuid = qr.get('uuid');
    }

    if (!!this.props?.match?.params?.sub) {
      this.props.history.push('/emission', {itsme_loader: true, step: 1, voogd: false, sub: this.props.match.params.sub});
    } else {
      this.fetch_emission();
    }
  }
  componentDidUpdate(prevProps, prevState) {}

  fetch_emission = () => {
    this.setState({loader: true}, () => {
      this.props.helpers.emissions.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          if (!!this.state.testing || !!r[0]?.id) {
            this.props.helpers.emissions.fetch_all({
              locale: this.props.i18n.language,
              login: this.props.login,
              callback: (rr, full_dataa) => {
                this.setState({emission: rr, loader: false}, () => {
                  setTimeout(() => {
                    this.props.helpers.user.emission_information({
                      locale: this.props.i18n.language,
                      login: this.props.login,
                      callback: (rrr, full_dataa) => {
                        let checked = false;
                        if (!!this.state.sub) {
                          let iban = rrr.user.zicht_account.toUpperCase().replace(/[^A-Z0-9]/g, '');
                          let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
                          let digits;

                          if (!!code && !!code[1] && !!code[2] && !!code[3]) {
                            digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
                              return letter.charCodeAt(0) - 55;
                            });
                            var checksum = digits.slice(0, 2), fragment;
                            for (var offset = 2; offset < digits.length; offset += 7) {
                              fragment = String(checksum) + digits.substring(offset, offset + 7);
                              checksum = parseInt(fragment, 10) % 97;
                            }
                            if (checksum === 1) checked = true;
                          }
                        }

                        this.setState({emission_information: rrr, user: {...this.state.user, ...(!!this.state.sub ? rrr.user : {}), repeat_email: (!!rrr.user.email ? rrr.user.email : '')}, ignore: {...this.state.ignore, bank_checked: checked}});
                      },
                      parameters: {
                        // id: r[0].id,
                        emission_id: (!!this.state.testing ? this.state.testing_id : r[0].id),
                        testing: (!!this.state.testing ? 1 : 0),
                        sub: this.state.sub,
                        stock_id: this.state.stock_id
                      }
                    });
                  }, 2000)
                });
              },
              parameters: {
                // id: r[0].id,
                id: (!!this.state.testing ? this.state.testing_id : r[0].id),
                testing: (!!this.state.testing ? 1 : 0),
                fields: ['id', 'name', 'start_date', 'enabled', 'is_active?', 'available_budget', 'emission_keys|count', 'free_key_count']
              }
            });
          } else {
            this.setState({emission: {}, loader: false});
          }
        },
        parameters: {
          fields: ['id', 'start_date', 'end_date'],
          page: 0,
          per_page: 600
        }
      });
    });
  }

  register_waiting_list = () => {
    this.setState({waitlist_loading: true}, () => {
      this.props.helpers.waiting_lists.do_save({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({waitlist_response: r, waitlist_entry: {email: '', phone: ''}}, () => {
            setTimeout(() => {this.setState({waitlist_loading: false});}, 1000)
          });
        },
        parameters: {
          waiting_list: {
            phone: this.state.waitlist_entry.phone,
            email: this.state.waitlist_entry.email
          }
        }
      });
    });
  }

  send_forgot_link = () => {
    this.setState({forgot_loading: true}, () => {
      this.props.helpers.user.emission_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({forgot_message: r, forgot_email: ''}, () => {
            setTimeout(() => {this.setState({forgot_message: ''});}, 5000)
          });
        },
        parameters: {
          forgot_email: this.state.forgot_email
        }
      });
    });
  }

  render_forgot_module = () => {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Container sx={{paddingLeft: 0, paddingRight: 0}}>
      <Grid container spacing="2" style={{marginTop: this.props.theme.spacing(2)}}>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
        <Grid container item xs={12} md={6}>
          <Grid item xs={12}>
            <PurifiedTypo text={t("emission.landing.forgot_link.heading")} />
          </Grid>
          {!!this.state.forgot_message && <Grid item xs={12} sx={{marginTop: 1}}>
            <PurifiedTypo text={t(`emission.landing.forgot_link.message_${this.state.forgot_message}`)} />
          </Grid>}
          {!this.state.forgot_message && <Grid item xs={12} sx={{marginTop: 4}}>
            <StyledTextField
              label={t("emission.landing.forgot_link.field.email").replace(/<[^>]*>?/gm, '')}
              value={this.state.forgot_email}
              id="forgot_email"
              onChange={(v) => {this.setState({forgot_email: v});}}
            />
          </Grid>}
          <Grid item xs={12} sx={{marginTop: 2}}>
            {!this.state.forgot_message && <StyledButton
              is_red
              disabled={this.state.forgot_email.indexOf("@") < 0}
              onClick={(e) => {
                if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                  this.props.helpers.web_log.do_refresh({
                    locale: this.props.i18n.language,
                    callback: (r, full_data) => {
                      window.argenco_uuid = r;
                    },
                    parameters: {
                      id: this.props.login?.id,
                      uuid: window.argenco_uuid,
                      u: window.location.pathname,
                      r: document.referrer,
                      a: 'button_click',
                      d: 'emission.forgot_link.send',
                      s: window.argenco_source,
                      ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                    }
                  });
                }
                this.send_forgot_link();
              }}
              text={t("emission.landing.forgot_link.cta.submit")}
              sx={{marginTop: 2, marginRight: 2}}
            />}
            <StyledButton
              as_link
              onClick={(e) => {
                this.setState({forgot_email: '', forgot_link: false}, () => {
                  if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                    this.props.helpers.web_log.do_refresh({
                      locale: this.props.i18n.language,
                      callback: (r, full_data) => {
                        window.argenco_uuid = r;
                      },
                      parameters: {
                        id: this.props.login?.id,
                        uuid: window.argenco_uuid,
                        u: window.location.pathname,
                        r: document.referrer,
                        a: 'button_click',
                        d: 'emission.forgot_link.cancel',
                        s: window.argenco_source,
                        ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                      }
                    });
                  }
                });
              }}
              text={t("emission.landing.forgot_link.cta.back")}
              sx={{marginTop: 2}}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
      </Grid>
    </Container>);
  }

  render_landing = () => {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Container sx={{paddingLeft: 0, paddingRight: 0}}>
      <Grid container spacing="2" style={{marginTop: this.props.theme.spacing(2)}}>
        <Grid item xs={12}>
          <PurifiedTypo text={t("emission.landing.fsma_disclaimer")} />
        </Grid>
        {false && !!this.state.emission.id && <Grid item xs={12} sx={{marginTop: 2}}>
          Testing: {this.state.testing ? 'yes' : 'no'}<br />
          Enabled: {this.state.emission.enabled ? 'yes' : 'no'}<br />
          Active: {this.state.emission['is_active?'] ? 'yes' : 'no'}<br />
          Budget: {this.state.emission.available_budget}<br />
          Total keys: {this.state.emission.emission_keys_count}<br />
          Free keys: {this.state.emission.free_key_count}<br />
        </Grid>}
        {!!this.state.emission.id && <Grid item xs={12} sx={{marginTop: 2}}>
          <Box sx={{backgroundColor: this.props.theme.palette.primary.main_p12, padding: 2}}>
            <Typography variant="h3" sx={{color: this.props.theme.palette.primary.main, marginBottom: 2}}>{t("emission.landing.cta.buy_stock_title")}</Typography>

            <StyledButton
              is_red
              onClick={() => {
                this.setState({voogd: false, step: 1}, () => {
                  if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                    this.props.helpers.web_log.do_refresh({
                      locale: this.props.i18n.language,
                      callback: (r, full_data) => {
                        window.argenco_uuid = r;
                      },
                      parameters: {
                        id: this.props.login?.id,
                        uuid: window.argenco_uuid,
                        u: window.location.pathname,
                        r: document.referrer,
                        a: 'button_click',
                        d: 'emission.landing.buy_for_me',
                        s: window.argenco_source,
                        ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                      }
                    });
                  }
                  window.scrollTo(0, 0);
                });
              }}
              text={t("emission.landing.cta.buy_stock_for_me")}
              sx={{marginTop: 1, marginBottom: 1}}
            />
            <StyledButton
              is_red
              onClick={() => {
                this.setState({voogd: true, step: 1, user: {
                  first_name: '',
                  last_name: '',
                  birth_date: '',
                  gender: '',
                  vennoot_number: '',
                  stock_number: 0,
                  email: '',
                  repeat_email: '',
                  mobile: '',
                  street: '',
                  city: '',
                  zip: '',
                  city: '',
                  country: 'BE',
                  zicht_account: ''
                }}, () => {
                  if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                    this.props.helpers.web_log.do_refresh({
                      locale: this.props.i18n.language,
                      callback: (r, full_data) => {
                        window.argenco_uuid = r;
                      },
                      parameters: {
                        id: this.props.login?.id,
                        uuid: window.argenco_uuid,
                        u: window.location.pathname,
                        r: document.referrer,
                        a: 'button_click',
                        d: 'emission.landing.buy_for_child',
                        s: window.argenco_source,
                        ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                      }
                    });
                  }
                  window.scrollTo(0, 0);
                });
              }}
              text={t("emission.landing.cta.buy_stock_for_minor")}
              sx={{marginTop: 1, marginBottom: 1, marginLeft: {xs: 0, md: 2}}}
            />
          </Box>
          <StyledButton
            as_link
            onClick={() => {
              this.setState({forgot_link: true}, () => {
                if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                  this.props.helpers.web_log.do_refresh({
                    locale: this.props.i18n.language,
                    callback: (r, full_data) => {
                      window.argenco_uuid = r;
                    },
                    parameters: {
                      id: this.props.login?.id,
                      uuid: window.argenco_uuid,
                      u: window.location.pathname,
                      r: document.referrer,
                      a: 'button_click',
                      d: 'emission.landing.forgot_link',
                      s: window.argenco_source,
                      ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                    }
                  });
                }
                window.scrollTo(0, 0);
              });
            }}
            text={t("emission.landing.cta.forgot_link")}
            sx={{marginTop: 1, marginBottom: 1}}
          />
        </Grid>}
      </Grid>
    </Container>);
  }

  render_loader = () => {
    return (<Container sx={{paddingLeft: 0, paddingRight: 0}}>
      <Grid container spacing="2" style={{marginTop: this.props.theme.spacing(2)}}>
        <Grid item xs={12} align="center">
          <CircularProgress />
        </Grid>
      </Grid>
    </Container>);
  }

  render_steps = () => {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Container sx={{paddingLeft: 0, paddingRight: 0}}>
      <Grid container spacing="2" style={{marginTop: this.props.theme.spacing(2)}}>

        {!this.state.voogd && this.state.step === 1 && <Grid item xs={12}>
          <StepProfile
            {...this.props}
            state={{...this.state}}
            visible={this.state.step === 1}
            onChangeState={(ob) => {
              this.setState(ob);
            }}
            onChange={(ob) => {

            }}
          />
        </Grid>}
        {!!this.state.voogd && this.state.step === 1 && <Grid item xs={12}>
          <StepVoogdProfile
            {...this.props}
            state={{...this.state}}
            visible={this.state.step === 1}
            onChangeState={(ob) => {
              this.setState(ob);
            }}
            onChange={(ob) => {

            }}
          />
        </Grid>}

        {this.state.step === 2 && <Grid item xs={12}>
          <StepStock
            {...this.props}
            state={{...this.state}}
            visible={this.state.step === 2}
            onChangeState={(ob) => {
              this.setState(ob);
            }}
            onChange={(ob) => {

            }}
          />
        </Grid>}

        {/* {this.state.step === 3 && <Grid item xs={12}>
          <StepBorderel
            {...this.props}
            state={{...this.state}}
            visible={this.state.step === 3}
            onChangeState={(ob) => {
              this.setState(ob);
            }}
            onChange={(ob) => {

            }}
          />
        </Grid>} */}

      </Grid>
    </Container>);
  }

  render_waiting_list = () => {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Container sx={{paddingLeft: 0, paddingRight: 0}}>
      <Grid container spacing="2" style={{marginTop: this.props.theme.spacing(2)}}>
        <Grid item xs={12} align="center">
          <Typography variant="h1" className={classes.component_title}>{t("waitlist.title")}</Typography>
        </Grid>

        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}} />
        <Grid item xs={12} md={6}>
          <PurifiedTypo text={t("waitlist.content")} />
        </Grid>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}} />

        <Grid item xs={12} sx={{marginTop: {xs: 2, md: 4}}} />

        <Grid item xs={12} md={4} sx={{display: {xs: 'none', md: 'block'}}} />
        <Grid item xs={12} md={4}>
          <StyledTextField
            label={t("waitlist.fields.email").replace(/<[^>]*>?/gm, '')}
            value={this.state.waitlist_entry.email}
            id="email"
            onChange={(v) => {this.setState({waitlist_entry: {...this.state.waitlist_entry, email: v}});}}
            style={{marginTop: this.props.theme.spacing(0.5)}}
          />
        </Grid>
        {/* <Grid item xs={12} md={3} sx={{paddingLeft: {xs: 0, md: "8px !important"}}}>
          <StyledTextField
            label={t("waitlist.fields.phone").replace(/<[^>]*>?/gm, '')}
            value={this.state.waitlist_entry.phone}
            id="phone"
            onChange={(v) => {this.setState({waitlist_entry: {...this.state.waitlist_entry, phone: v}});}}
            style={{marginTop: this.props.theme.spacing(0.5)}}
          />
        </Grid> */}
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'block'}}} />

        <Grid item xs={12} sx={{marginTop: {xs: 2, md: 4}}} />

        {!!this.state.waitlist_loading && <Grid item xs={12} align="center">
          <CircularProgress />
        </Grid>}

        {!this.state.waitlist_loading && !this.state.waitlist_response && <Grid item xs={12} align="center">
          <StyledButton disabled={this.state.waitlist_entry.email.length < 10} text={t("waitlist.actions.submit").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} onClick={(e) => {
            this.register_waiting_list();
          }} is_red />
        </Grid>}

        {!this.state.waitlist_loading && !!this.state.waitlist_response && <Grid item xs={12} align="center">
          <PurifiedTypo text={t(this.state.waitlist_response)} />
        </Grid>}
      </Grid>
    </Container>);
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      {!!this.state.loader && this.render_loader()}
      {!this.state.loader && !!this.state.emission.id && this.state.step < 1 && !this.state.forgot_link && this.render_landing()}
      {!this.state.loader && !!this.state.emission.id && this.state.step < 1 && !!this.state.forgot_link && this.render_forgot_module()}
      {!this.state.loader && !!this.state.emission.id && this.state.step > 0 && this.render_steps()}
      {!this.state.loader && !this.state.emission.id && this.render_waiting_list()}

      {false && !!this.state.testing && <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container spacing="2" sx={{marginTop: 8}}>
          <Grid item xs={12}>
            <Button color="secondary" variant="contained" onClick={() => {this.setState({emission: {...this.state.emission, id: (!!this.state.emission.id ? null : 1), name: "Test Emission by switch"}});}}>Switch to other mode</Button>
          </Grid>
        </Grid>
      </Container>}
    </Page>);
  }
}

EmissionLanding.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(EmissionLanding))));
