import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';

import {
  Typography as Typo
} from '@mui/material';

var he = require('he');

class Typography extends Component {
  render() {
    return (<Typo {...this.props}>{typeof(this.props.children) === "string" ? he.decode(this.props.children.replace(/<[^>]*>?/gm, '')) : this.props.children}</Typo>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(Typography));
