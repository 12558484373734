import News from '../actions/news';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function news(state = initial, action) {
  return News.state_switch(state, action);
}
