import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';

import {
  Button
} from '@mui/material';

var he = require('he');

class StyledButton extends Component {
  render() {
    const {secondary, is_red, text, theme, sx, ...other} = this.props;

    let pd = 4;
    if (!!this.props.paddingless) pd = 0;

    return (<Button
      variant={this.props.as_link ? "text" : "contained"}
      size="small"
      sx={this.props.as_link ? {
        // borderRadius: 20,
        paddingLeft: pd,
        paddingRight: pd,
        height: 36,
        whiteSpace: 'nowrap',
        color: theme.palette.tertiary.main,
        ":hover": {
          textDecoration: 'underline'
        },
        ...sx
      } : {
        backgroundColor: (is_red ? theme.palette.secondary.main : (secondary ? 'white' : theme.palette.primary.main)),
        border: '1px solid white',
        borderColor: (!!this.props.disabled ? 'transparent' : (is_red ? theme.palette.secondary.main : theme.palette.primary.main)),
        color: (secondary ? theme.palette.primary.main : 'white'),
        // borderRadius: 20,
        paddingLeft: pd,
        paddingRight: pd,
        height: 36,
        '&:hover': {
          backgroundColor:(is_red ? theme.palette.secondary.dark : (secondary ? 'white' : theme.palette.primary.dark))
        },
        whiteSpace: 'nowrap',
        ...sx
      }}
      {...other}
      onAuxClick={(e) => {this.props.onClick(e);}}
      onMouseDown={(e) => {if (e.button == 1) this.props.onClick(e);}}
    >{he.decode(text.replace(/<[^>]*>?/gm, ''))}</Button>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(StyledButton));
