import { connect } from 'react-redux';
import DeathMessage from '../../actions/death_message';
// import Devise from '../../actions/devise';
import ReportDeath from '../../components/pages/ReportDeath';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      death_messages: {
        submit: _params => {
          dispatch(DeathMessage.submit(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(ReportDeath);
