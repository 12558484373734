import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from "../parts/PurifiedTypo";
import Typography from "../parts/Typography";

class BlockImageLeftTitleText extends Component {
  render() {
    const {block, theme, locale} = this.props;

    return (<React.Fragment>
      <Grid item container xs={12} md={6}>
        {!!block.item && block.item.indexOf("missing") < 0 && <Grid item xs={12} sx={{paddingRight: {xs: 0, md: 3}, paddingTop: {xs: 0, md: '1em'}}}>
          <img src={block.item} style={{maxWidth: '100%'}} />
        </Grid>}
        {(!block.item || block.item.indexOf("missing") > -1) && !!block.extra_data?.image_link && <Grid item xs={12} sx={{paddingRight: {xs: 0, md: 3}, paddingTop: {xs: 0, md: '1em'}}}>
          <img src={block.extra_data.image_link} style={{maxWidth: '100%'}} />
        </Grid>}
      </Grid>
      <Grid item container xs={12} md={6}>
        {!!block.extra_data && !!block.extra_data.text && !!block.extra_data.text.title && block.extra_data.text.title[locale] && <Grid item xs={12}>
          <Typography variant="h2">{block.extra_data.text.title[locale]}</Typography>
        </Grid>}
        {!!block.extra_data && !!block.extra_data.text && !!block.extra_data.text.content && block.extra_data.text.content[locale] && <Grid item xs={12}>
          <PurifiedTypo text={block.extra_data.text.content[locale]} />
        </Grid>}
      </Grid>
    </React.Fragment>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(BlockImageLeftTitleText));
