import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow
} from '@mui/material';

import BirthDate from "../../../shared/parts/onboarding/BirthDate";
import PurifiedTypo from "../../../shared/parts/PurifiedTypo";
import ShareShower from "../../../shared/parts/ShareShower";
import StyledButton from "../../../shared/parts/StyledButton";
import Typography from "../../../shared/parts/Typography";
import Vennr from "../../../shared/parts/onboarding/Vennr";

var he = require('he');

class EmissionStepProfileExisting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state
    };
  }

  componentDidMount() {
    this.props.renew_sign_in({
      locale: this.props.i18n.language,
      login: this.props.login,
      parameters: {
        fields: ['id', 'first_name', 'last_name', 'email', 'auth_token', "type", "is_admin", "vennoot_number", 'address', 'stock_number', 'share_type|tag', 'birth_date', 'stock_id']
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<React.Fragment>
      <Grid container spacing="0" sx={{marginTop: 2}}>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
        <Grid container item xs={12} md={6} spacing="8">

          {this.state.user.share_type_tag === "A" && <React.Fragment>
            <Grid item xs={12}>
              <PurifiedTypo text={t("emission.steps.profile.error_a_vennoot")} />
            </Grid>
          </React.Fragment>}

          {this.state.user.share_type_tag === "B" && <React.Fragment>
            {this.state.user.stock_number < 200 && <React.Fragment>
              <Grid item xs={12} sx={{marginTop: 2}}>
                <Paper sx={{
                  border: `1px solid ${this.props.theme.palette.primary.main}`,
                  marginTop: 1,
                  boxShadow: 'none'
                }}>
                  <Table>
                    <TableRow>
                      <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.name')}</Typography></TableCell>
                      <TableCell sx={{width: '50%'}}><Typography>{this.state.user.first_name} {this.state.user.last_name}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.vennr')}</Typography></TableCell>
                      <TableCell sx={{width: '50%'}}><Typography>{this.state.user.vennoot_number || this.state.user.vennr}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{width: '50%'}}><Typography variant='h4'>{t('transfer.detail.my_data_table.stock_total')}</Typography></TableCell>
                      <TableCell sx={{width: '50%'}}><ShareShower amount={this.state.user.stock_number} share={this.state.user.share_type_tag} /></TableCell>
                    </TableRow>
                  </Table>
                </Paper>
              </Grid>
              <Grid item xs={12} align="center">
                <StyledButton
                  text={t("emission.steps.profile.cta_existing.this_is_me").replace(/<[^>]*>?/gm, '')}
                  is_red
                  theme={this.props.theme}
                  sx={{marginTop: this.props.theme.spacing(4)}}
                  onClick={(e) => {
                    this.props.history.push(`/emissions/stocks/${this.state.user.stock_id}`, {});
                    // this.setState({step: 2, loading: false}, () => {
                    //   this.props.onChangeState(this.state);
                    // });
                  }}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <StyledButton
                  as_link
                  onClick={() => {
                    this.props.logout({locale: this.props.i18n.language, login: this.props.login, callback: () => {
                      window.location = "/emission";
                    }});
                    // this.setState({
                    //   ignore: {
                    //     bank_checked: false
                    //   },
                    //   profile_step: {
                    //     picker_step: (!!this.props.login ? 2 : 0),
                    //     user_mode: 0,
                    //     use_itsme: 0
                    //   },
                    //   user: (this.props.login || {
                    //     first_name: '',
                    //     last_name: '',
                    //     birth_date: '',
                    //     gender: '',
                    //     vennoot_number: '',
                    //     stock_number: 0,
                    //     email: '',
                    //     repeat_email: '',
                    //     mobile: '',
                    //     street: '',
                    //     city: '',
                    //     zip: '',
                    //     city: '',
                    //     country: 'BE',
                    //     zicht_account: ''
                    //   }),
                    //   errors: {}
                    // }, () => {
                    //   this.props.onChangeState(this.state);
                    // });
                  }}
                  text={t("emission.steps.profile.cta_existing.this_is_not_me").replace(/<[^>]*>?/gm, '')}
                />
              </Grid>
            </React.Fragment>}

            {this.state.user.stock_number >= 200 && <React.Fragment>
              <Grid item xs={12}>
                <PurifiedTypo text={t("emission.steps.profile.error_max_stock")} />
              </Grid>
            </React.Fragment>}
          </React.Fragment>}
        </Grid>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
      </Grid>
    </React.Fragment>);
  }
}

EmissionStepProfileExisting.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(EmissionStepProfileExisting))));
