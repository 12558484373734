import { connect } from 'react-redux';
import Benefit from '../../actions/benefit';
// import Devise from '../../actions/devise';
import Frontpager from '../../actions/frontpager';
import News from '../../actions/news';
import Homepage from '../../components/pages/Homepage';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      benefits: {
        fetch_all: (_params) => {
          dispatch(Benefit.fetch_all(_params));
        }
      },
      frontpager: {
        fetch_active: _params => {
          dispatch(Frontpager.fetch_active(_params));
        }
      },
      news: {
        fetch_all: (_params) => {
          dispatch(News.fetch_all(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Homepage);
