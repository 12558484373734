import BenefitsTag from '../actions/benefits_tag';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function benefits_tag(state = initial, action) {
  return BenefitsTag.state_switch(state, action);
}
