import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableRow
} from '@mui/material';

import {DateTime} from "luxon";
import {DropzoneArea} from 'material-ui-dropzone';
import QRCode from "react-qr-code";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PrintIcon from '@mui/icons-material/Print';
import QrCodeIcon from '@mui/icons-material/QrCode';

import BoomTrans from '../../../images/boom_filled.png';

import PurifiedTypo from "../../shared/parts/PurifiedTypo";
import ShareShower from "../../shared/parts/ShareShower";
import StyledButton from "../../shared/parts/StyledButton";
import StyledConfirm from "../../shared/parts/StyledConfirm";
import StyledTextField from "../../shared/parts/StyledTextField";
import StyledWideButton from "../../shared/parts/StyledWideButton";
import Typography from "../../shared/parts/Typography";

var he = require('he');

class EmissionStepStock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      stock_step: {
        active_key: null,
        confirm: false,
        qr_confirm: false,
        confirm_status: ''
      }
    };
  }

  componentDidMount() {
    this.props.helpers.user.emission_information({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (rrr, full_dataa) => {
        let ak = null;

        if (this.props.location?.search?.indexOf("a=") > -1) {
          let qr = new URLSearchParams(this.props.location.search);
          rrr.emission_keys.forEach(k => {
            if (k.id === parseInt(qr.get('a'), 10)) ak = k;
          });
        }

        this.setState({emission_information: rrr, user: rrr.user, stock_step: {...this.state.stock_step, active_key: ak}}, () => {
          if (!!this.props.onChangeState) this.props.onChangeState(this.state);
        });
      },
      error_callback: (r) => {
        if (r === 'invalid_stock_id') {
          this.setState({show_stock_id_error: true}, () => {
            if (!!this.props.onChangeState) this.props.onChangeState(this.state);
          });
        }
      },
      parameters: {
        // id: r[0].id,
        emission_id: (!!this.state.testing ? this.state.testing_id : this.state.emission.id),
        testing: (!!this.state.testing ? 1 : 0),
        sub: this.state.sub,
        stock_id: this.state.stock_id
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    ['state'].map(p => {
      if (this.props[p] !== prevProps[p]) {
        this.setState({...this.props[p]});
      }
      return null;
    });
  }

  cancel_order = () => {
    this.setState({loading: true}, () => {
      let formData = new FormData();
      formData.append('active_key', this.state.stock_step.active_key.id);
      formData.append('step', 'cancel');
      formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));
      formData.append('testing', (!!this.state.testing ? 1 : 0));
      formData.append('sub', this.state.sub);
      formData.append('stock_id', this.state.stock_id);

      this.props.helpers.user.emission_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({user: r.user, emission_information: r, loading: false, stock_step: {...this.state.stock_step, active_key: null}}, () => {
            this.props.onChangeState(this.state);
            window.scrollTo(0, 0);
          });
        },
        error_callback: (r) => {
          this.setState({show_error: true, loading: false}, () => {
            this.props.onChangeState(this.state);
          });
        },
        parameters: formData
      });
    });
  }

  confirm_with_borderel = (event, generate_borderel) => {
    this.setState({loading: true}, () => {
      event.preventDefault();

      let formData = new FormData();
      formData.append('active_key', this.state.stock_step.active_key.id);
      formData.append('amount', this.state.stock_step.active_key.proposed_amount);
      formData.append('generate_borderel', (generate_borderel ? 1 : 0));
      formData.append('step', 'save_amount');
      formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));
      formData.append('testing', (!!this.state.testing ? 1 : 0));
      formData.append('sub', this.state.sub);
      formData.append('stock_id', this.state.stock_id);

      this.props.helpers.user.emission_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({user: r.user, emission_information: r, loading: false, stock_step: {...this.state.stock_step, active_key: r.active_key, show_step_success: true}}, () => {
            this.props.onChangeState(this.state);
            window.scrollTo(0, 0);
          });
        },
        error_callback: (r) => {
          this.setState({show_error: true, loading: false}, () => {
            this.props.onChangeState(this.state);
          });
        },
        parameters: formData
      });
    });
  }

  save_borderel = (event) => {
    this.setState({loading: true}, () => {
      event.preventDefault();

      let formData = new FormData();
      formData.append('active_key', this.state.stock_step.active_key.id);
      formData.append('item', this.state.stock_step.active_key.item);
      formData.append('step', 'save_borderel');
      formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));
      formData.append('testing', (!!this.state.testing ? 1 : 0));
      formData.append('sub', this.state.sub);
      formData.append('stock_id', this.state.stock_id);

      this.props.helpers.user.emission_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({user: r.user, emission_information: r, loading: false, stock_step: {...this.state.stock_step, active_key: r.active_key}}, () => {
            this.props.onChangeState(this.state);
            window.scrollTo(0, 0);
          });
        },
        error_callback: (r) => {
          this.setState({show_error: true, loading: false}, () => {
            this.props.onChangeState(this.state);
          });
        },
        parameters: formData
      });
    });
  }

  save_itsme_borderel = (event) => {
    this.setState({loading: true}, () => {
      event.preventDefault();

      let formData = new FormData();
      formData.append('active_key', this.state.stock_step.active_key.id);
      formData.append('amount', this.state.stock_step.active_key.proposed_amount);
      formData.append('step', 'itsme_borderel');
      formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));
      formData.append('testing', (!!this.state.testing ? 1 : 0));
      formData.append('sub', this.state.sub);
      formData.append('stock_id', this.state.stock_id);

      this.props.helpers.user.emission_information({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          window.location = r.active_key.itsme_url;
        },
        error_callback: (r) => {
          this.setState({show_error: true, loading: false}, () => {
            this.props.onChangeState(this.state);
          });
        },
        parameters: formData
      });
    });
  }

  state_row = (static_key, children) => {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<TableRow>
      <TableCell sx={{display: {xs: 'none', md: 'table-cell'}, width: '50%', paddingLeft: 0.5, paddingRight: 0.5}}>
        <Typography variant='h4'>{t(static_key)}</Typography>
      </TableCell>
      <TableCell sx={{width: {xs: '100%', md: '50%'}, paddingLeft: 0.5, paddingRight: 0.5}}>
        <Typography variant='h4' sx={{display: {xs: 'block', md: 'none'}, fontSize: '0.8rem'}}>{t(static_key)}</Typography>
        {children}
      </TableCell>
    </TableRow>);
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let new_vennoot = this.state.user.stock_number === 0;
    // new_vennoot = true;
    let borderel_part = (this.state.stock_step.active_key?.status_key === 'borderel');
    let using_itsme = !!this.state.emission_information?.user.sub;

    if (this.state.emission_information?.user.has_guardian) {
      using_itsme = false;
    }

    return (!!this.props.visible ? (<React.Fragment>
      {!!this.state.show_stock_id_error && <Grid container spacing="0" sx={{marginTop: 2}}>
        <Grid item xs={12} align="left">
          <PurifiedTypo text={t("emission.steps.stock.stock_id_error")} />
        </Grid>
        <Grid item xs={12} align="center">
          <StyledButton
            as_link
            onClick={() => {
              this.props.history.push('/');
              window.scrollTo(0, 0);
            }}
            text={t("emission.landing.cta.go_back_short")}
            sx={{marginTop: 2}}
          />
        </Grid>
      </Grid>}
      {!this.state.stock_step.active_key && !this.state.show_stock_id_error && <Grid container spacing="0" sx={{marginTop: 2}}>
        <Grid container item xs={12} spacing="8">
          <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
            <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 0}}>{t("emission.steps.stock.title.orders")}</Typography>
            <Typography variant="h3" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 0, fontSize: '1.1rem', fontWeight: 100, color: this.props.theme.palette.primary_grey.main}}>{this.state.emission_information?.user.first_name} {this.state.emission_information?.user.last_name}, {this.state.emission_information?.user.vennoot_number}</Typography>
            {this.state.emission_information?.user.has_guardian && <Typography variant="h3" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 0, fontSize: '1.1rem', fontWeight: 100, color: this.props.theme.palette.primary_grey.main}}>{t("emission.steps.stock.subtitle.guardian").replace(/<[^>]*>?/gm, '')} {this.state.emission_information?.guardian.first_name} {this.state.emission_information?.guardian.last_name}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.stock.orders_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
          </Grid>
          {this.state.emission_information?.emission_keys.length > 0 && <Grid item xs={12}>
            <PurifiedTypo text={t("emission.steps.stock.orders_extra_asn", {asn: this.state.emission_information.emission_keys[0].asn_key})} sx={{marginTop: '-1em', lineHeight: '1.5', "& p": {marginTop: '0px !important', marginBottom: '0px !important'}}} />
          </Grid>}
          <Grid container item xs={12} sx={{marginTop: 2}}>
            <Grid container item xs={12} sm={6} md={4} lg={4} xl={3} onClick={(e) => {
              this.setState({stock_step: {
                ...this.state.stock_step,
                active_key: {
                  time_requested_short: DateTime.now().toFormat("dd/MM/yyyy"),
                  proposed_amount: 0,
                  conditions: !new_vennoot,
                  use_itsme: 0,
                  status_key: 'open'
                }
              }}, () => {
                if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                  this.props.helpers.web_log.do_refresh({
                    locale: this.props.i18n.language,
                    callback: (r, full_data) => {
                      window.argenco_uuid = r;
                    },
                    parameters: {
                      id: this.props.login?.id,
                      uuid: window.argenco_uuid,
                      u: window.location.pathname,
                      r: document.referrer,
                      a: 'button_click',
                      d: 'emission.orders.new_order',
                      s: window.argenco_source,
                      ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                    }
                  });
                }
                window.scrollTo(0, 0);
              });
            }}>
              <Grid item xs={12} sx={{
                cursor: 'pointer',
                padding: 1,
                border: `1px solid ${this.props.theme.palette.primary.main}`,
                borderRadius: '5px',
                margin: 1
              }}>
                <Grid item xs={12} align="center">
                  <AddCircleIcon sx={{color: this.props.theme.palette.primary.main, fontSize: '80px'}} />
                </Grid>
                <Grid item xs={12} align="center" sx={{marginTop: 'auto'}}>
                  <Typography>{t('emission.steps.stock.emission_key.new.title')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {this.state.emission_information?.emission_keys.map(emission_key => <Grid container item xs={12} sm={6} md={4} lg={4} xl={3} onClick={(e) => {
              this.setState({stock_step: {...this.state.stock_step, active_key: emission_key}}, () => {
                this.props.onChangeState(this.state);
                if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                  this.props.helpers.web_log.do_refresh({
                    locale: this.props.i18n.language,
                    callback: (r, full_data) => {
                      window.argenco_uuid = r;
                    },
                    parameters: {
                      id: this.props.login?.id,
                      uuid: window.argenco_uuid,
                      u: window.location.pathname,
                      r: document.referrer,
                      a: 'button_click',
                      d: 'emission.orders.open_order',
                      s: window.argenco_source,
                      ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                    }
                  });
                }
                window.scrollTo(0, 0);
              });
            }}>
              <Grid item xs={12} sx={{
                cursor: 'pointer',
                padding: 1,
                border: `1px solid ${this.props.theme.palette.primary.main}`,
                borderRadius: '5px',
                margin: 1,
                overflow: 'hidden',
                position: 'relative'
              }}>
                <Grid item xs={12} align="right">
                  <Typography sx={{fontSize: '0.7em'}}>{t("emission.steps.stock.emission_key.block.date")}</Typography>
                  <Typography sx={{fontSize: '1.05em'}}>{emission_key.time_requested}</Typography>
                </Grid>
                <Grid item xs={12} align="right" sx={{marginTop: 1}}>
                  <Typography sx={{fontSize: '0.7em'}}>{t("emission.steps.stock.emission_key.block.amount")}</Typography>
                  <ShareShower sx={{fontSize: '1.05em'}} amount={emission_key.proposed_amount || 0} share={this.state.user.share_type_tag} />
                </Grid>
                <Grid item xs={12} align="right" sx={{marginTop: 1}}>
                  <Typography sx={{fontSize: '0.7em'}}>{t("emission.steps.stock.emission_key.block.status")}</Typography>
                  <Typography sx={{fontSize: '1.05em', color: emission_key.get_status_colour}}>{emission_key.status}</Typography>
                  <Typography sx={{fontSize: '0.8em', color: emission_key.get_status_colour}}>{emission_key.status_updated}</Typography>
                </Grid>
                <img src={BoomTrans} style={{
                  position: 'absolute',
                  bottom: '-10%',
                  left: '-10%',
                  width: '40%',
                  zIndex: 99
                }} />
              </Grid>
            </Grid>)}
          </Grid>
        </Grid>
      </Grid>}

      {this.state.stock_step.active_key && <Grid container spacing="0" sx={{marginTop: 2}}>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
        <Grid container item xs={12} md={6} spacing="8">
          <Grid item xs={12} align="left">
            <StyledButton paddingless text={t("emission.landing.cta.go_back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link startIcon={<ArrowBackIcon />} onClick={() => {
              this.setState({stock_step: {...this.state.stock_step, active_key: null}}, () => {
                this.props.onChangeState(this.state);
                window.scrollTo(0, 0);
              });
            }} />
          </Grid>

          {this.state.stock_step.active_key && <React.Fragment>
            <Grid item xs={12} sx={{position: 'relative', padding: '0px !important'}}>
              <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 0}}>{t("emission.steps.stock.title.order", {date: this.state.stock_step.active_key.time_requested_short})}</Typography> {false && this.its_okay(true)}
            </Grid>

            {this.state.stock_step.active_key.status_key !== 'open' && <Grid item xs={12}>
              <Paper sx={{
                border: `1px solid ${this.props.theme.palette.primary.main}`,
                marginTop: 1,
                boxShadow: 'none'
              }}>
                <Table>
                  {this.state_row('emission.steps.stock.order_detail.time_requested', (<Typography sx={{fontWeight: {xs: 'bold', md: 'normal'}}}>{this.state.stock_step.active_key.time_requested}</Typography>))}
                  {this.state_row('emission.steps.stock.order_detail.amount', (<ShareShower sx={{fontWeight: {xs: 'bold', md: 'normal'}}} amount={this.state.stock_step.active_key.proposed_amount} share={this.state.user.share_type_tag} />))}
                  {this.state.user.stock_number < 1 && !this.state.stock_step.active_key.borderel_signed_updated_at && this.state.stock_step.active_key.status_key === 'borderel' && this.state_row('emission.steps.stock.order_detail.borderel_uploaded', (<React.Fragment>
                    {!this.state.stock_step.active_key.borderel_signed_updated_at && <Typography sx={{fontWeight: {xs: 'bold', md: 'normal'}}}>{t("emission.steps.stock.order_detail.borderel_uploaded_not")}</Typography>}
                    {!!this.state.stock_step.active_key.borderel_signed_updated_at && <PurifiedTypo sx={{'& p': {marginTop: '0px !important', marginBottom: '0px !important', fontWeight: {xs: 'bold', md: 'normal'}}}} text={t("emission.steps.stock.order_detail.borderel_uploaded_yes", {time: this.state.stock_step.active_key.borderel_signed_updated_at})} />}
                  </React.Fragment>))}
                  {this.state.stock_step.active_key.status_key === 'awaiting_payment' && this.state_row('emission.steps.stock.order_detail.payment', (<Typography sx={{fontWeight: {xs: 'bold', md: 'normal'}}}>{t("emission.steps.stock.order_detail.payment_not_received")}</Typography>))}
                  {this.state.stock_step.active_key.status_key === 'awaiting_payment' && <React.Fragment>
                    <TableRow>
                      <TableCell sx={{display: {xs: 'table-cell', md: 'none'}, width: '100%', paddingLeft: 0.5, paddingRight: 0.5, textAlign: 'left'}}>
                        <Typography variant='h4' sx={{display: {xs: 'block', md: 'none', fontSize: '0.8rem'}}}>{t('emission.steps.stock.order_detail.payment_title.amount')}</Typography>
                        <Box sx={{display: 'flex', alignItems: 'center', marginRight: -0.5, height: '40px'}}>
                          <Typography sx={{fontWeight: 'bold'}}>{parseInt(this.state.stock_step.active_key.proposed_value, 10).toString()} EUR</Typography>
                        </Box>
                        <Typography variant='h4' sx={{display: {xs: 'block', md: 'none', fontSize: '0.8rem'}}}>{t('emission.steps.stock.order_detail.payment_title.account')}</Typography>
                        <Box sx={{display: 'flex', alignItems: 'center', marginRight: -0.5, height: '40px'}}>
                          <Typography sx={{fontWeight: 'bold', display: 'flex'}}>{t('emission.steps.stock.order_detail.payment_title.account_value')}</Typography>
                          <IconButton color="primary" sx={{display: 'flex', marginLeft: 'auto'}} title={he.decode(t("emission.steps.stock.order_detail.cta_hint.copy").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(he.decode(t('emission.steps.stock.order_detail.payment_title.account_value').replace(/<[^>]*>?/gm, '')));
                          }}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Box>
                        <Typography variant='h4' sx={{display: {xs: 'block', md: 'none', fontSize: '0.8rem'}}}>{t('emission.steps.stock.order_detail.payment_title.strucmed')}</Typography>
                        <Box sx={{display: 'flex', alignItems: 'center', marginRight: -0.5, height: '40px'}}>
                          <Typography sx={{fontWeight: 'bold'}}>{this.state.emission_information?.emission_keys[0]?.asn_key}</Typography>
                          <IconButton color="primary" sx={{display: 'flex', marginLeft: 'auto'}} title={he.decode(t("emission.steps.stock.order_detail.cta_hint.copy").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(he.decode(this.state.emission_information?.emission_keys[0]?.asn_key.replace(/<[^>]*>?/gm, '')));
                          }}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{display: {xs: 'none', md: 'table-cell'}, width: '50%', paddingLeft: 0.5, paddingRight: 0.5, textAlign: 'left'}}>
                        <Box sx={{display: 'flex', alignItems: 'center', marginRight: -0.5, height: '40px'}}>
                          <Typography variant='h4'>{t('emission.steps.stock.order_detail.payment_title.amount')}</Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', marginRight: -0.5, height: '40px'}}>
                          <Typography variant='h4'>{t('emission.steps.stock.order_detail.payment_title.account')}</Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', marginRight: -0.5, height: '40px'}}>
                          <Typography variant='h4'>{t('emission.steps.stock.order_detail.payment_title.strucmed')}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{display: {xs: 'none', md: 'table-cell'}, width: '50%', paddingLeft: 0.5, paddingRight: 0.5, textAlign: 'left', position: 'relative'}}>
                        <Box sx={{display: 'flex', alignItems: 'center', marginRight: -0.5, height: '40px'}}>
                          <Typography sx={{fontWeight: 'bold'}}>{parseInt(this.state.stock_step.active_key.proposed_value, 10).toString()} EUR</Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', marginRight: -0.5, height: '40px'}}>
                          <Typography sx={{fontWeight: 'bold', display: 'flex'}}>{t('emission.steps.stock.order_detail.payment_title.account_value')}</Typography>
                          <IconButton color="primary" sx={{display: 'flex', marginLeft: 'auto'}} title={he.decode(t("emission.steps.stock.order_detail.cta_hint.copy").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(he.decode(t('emission.steps.stock.order_detail.payment_title.account_value').replace(/<[^>]*>?/gm, '')));
                          }}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', marginRight: -0.5, height: '40px'}}>
                          <Typography sx={{fontWeight: 'bold'}}>{this.state.emission_information?.emission_keys[0]?.asn_key}</Typography>
                          <IconButton color="primary" sx={{display: 'flex', marginLeft: 'auto'}} title={he.decode(t("emission.steps.stock.order_detail.cta_hint.copy").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(he.decode(this.state.emission_information?.emission_keys[0]?.asn_key.replace(/<[^>]*>?/gm, '')));
                          }}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Box>

                        <IconButton color="primary" sx={{position: 'absolute', top: 0.5, right: 0.5}} title={he.decode(t("emission.steps.stock.order_detail.cta_hint.qr_code").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                          this.setState({qr_confirm: true});
                        }}>
                          <QrCodeIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>}
                  {(this.state.stock_step.active_key.status_key === 'paid' || !!this.state.stock_step.active_key.fake_paid_status) && this.state_row('emission.steps.stock.order_detail.payment', (<Typography sx={{fontWeight: {xs: 'bold', md: 'normal'}}}>{t("emission.steps.stock.order_detail.payment_received")}</Typography>))}
                  {this.state.stock_step.active_key.status_key === 'approved' && this.state_row('emission.steps.stock.order_detail.assigned_on', (<Typography sx={{fontWeight: {xs: 'bold', md: 'normal'}}}>{this.state.stock_step.active_key.assigned_on}</Typography>))}
                  {this.state.stock_step.active_key.status_key !== 'approved' && !!this.state.stock_step.active_key.id && <TableRow>
                    <TableCell colSpan={2} sx={{width: '100%', paddingLeft: 0.5, paddingRight: 0.5}} align="center">
                      <StyledButton
                        startIcon={<DeleteForeverIcon sx={{fontSize: '1.3rem !important'}} />}
                        as_link
                        onClick={(e) => {
                          this.setState({confirm: true});
                        }}
                        text={t("emission.steps.stock.cta.cancel_order")}
                        sx={{color: this.props.theme.palette.tertiary.main}}
                      />
                    </TableCell>
                  </TableRow>}
                </Table>
              </Paper>
            </Grid>}

            {this.state.stock_step.active_key.status_key === 'borderel' && this.state.stock_step.show_step_success && <React.Fragment>
              <Grid item xs={12} align="center" sx={{marginBottom: 2, backgroundColor: this.props.theme.palette.primary.main_p5, color: 'white', padding: 3, marginTop: 2}}>
                <Typography>{t("emission.steps.stock.msg_success.borderel")}</Typography>
              </Grid>
            </React.Fragment>}

            {this.state.stock_step.active_key.status_key === 'open' && <React.Fragment>
              <Grid item xs={12}>
                <PurifiedTypo text={t("emission.steps.stock.order_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
              </Grid>
              <Grid item xs={12} sx={{marginTop: -1}}>
                <Typography>{t(`emission.steps.stock.field.amount`)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  label=""
                  value={this.state.stock_step.active_key.proposed_amount}
                  id='amount'
                  onChange={(v) => {
                    let a = parseInt(v, 10);
                    if (!a || a < 0 || a > this.state.user.available_stock_number) a = 0;
                    this.setState({stock_step: {...this.state.stock_step, active_key: {...this.state.stock_step.active_key, proposed_amount: a}}});
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{alignSelf: 'center'}}>
                <Typography>{t("emission.steps.stock.field.amount_calculated", {amount: (this.state.stock_step.active_key.proposed_amount * 50).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")})}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{fontStyle: 'italic', fontSize: '0.8rem'}}>{t("emission.steps.stock.max_amount_note", {amount: this.state.user.available_stock_number.toString()})}</Typography>
              </Grid>
            </React.Fragment>}

            {this.state.stock_step.active_key.status_key === 'open' && new_vennoot && <React.Fragment>
              <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 0}}>{t("emission.steps.stock.title.conditions")}</Typography> {false && this.its_okay(true)}
              </Grid>
              {/* <Grid item xs={12}>
                <PurifiedTypo text={t("emission.steps.stock.conditions_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
              </Grid> */}
              <Grid item xs={2} sx={{marginTop: -0.5}}>
                <Checkbox
                  checked={!!this.state.stock_step.active_key.conditions}
                  onChange={(v) => {this.setState({stock_step: {...this.state.stock_step, active_key: {...this.state.stock_step.active_key, conditions: v.target.checked}}});}}
                />
              </Grid>
              <Grid item xs={10}>
                <PurifiedTypo text={t('emission.steps.stock.title.conditions_checkbox')} sx={{
                  "& > p": {
                    // marginBottom: {xs: '2em !important', md: '1em !important'},
                    marginBottom: 0,
                    marginTop: 0
                  },
                  "& > ul": {
                    // marginBottom: {xs: '2em !important', md: '1em !important'},
                    marginBottom: 0,
                    marginTop: 0
                  }
                }} onClick={(e) => {
                  this.setState({stock_step: {...this.state.stock_step, active_key: {...this.state.stock_step.active_key, conditions: !this.state.stock_step.active_key.conditions}}});
                }} />
              </Grid>
            </React.Fragment>}

            {this.state.stock_step.active_key.status_key === 'open' && new_vennoot && <React.Fragment>
              <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 0}}>{t("emission.steps.stock.title.confirm")}</Typography> {false && this.its_okay(true)}
              </Grid>
              <Grid item xs={12}>
                {!using_itsme && <PurifiedTypo text={t("emission.steps.stock.confirm_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />}
                {!!using_itsme && <PurifiedTypo text={t("emission.steps.stock.confirm_extra_itsme")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />}
              </Grid>
              {!this.state.emission_information?.user.has_guardian && <Grid item xs={12} md={6} sx={{padding: 1}}>
                <StyledWideButton
                  disabled={!this.state.stock_step.active_key.conditions || !this.state.stock_step.active_key.proposed_amount}
                  theme={this.props.theme}
                  text={t("emission.steps.stock.borderel.itsme")}
                  itsme
                  is_confirm
                  onClick={(e) => {
                    if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                      this.props.helpers.web_log.do_refresh({
                        locale: this.props.i18n.language,
                        callback: (r, full_data) => {
                          window.argenco_uuid = r;
                        },
                        parameters: {
                          id: this.props.login?.id,
                          uuid: window.argenco_uuid,
                          u: window.location.pathname,
                          r: document.referrer,
                          a: 'button_click',
                          d: 'emission.orders.new_save_mount.itsme',
                          s: window.argenco_source,
                          ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                        }
                      });
                    }
                    this.save_itsme_borderel(e);
                  }}
                />
              </Grid>}
              {!using_itsme && <Grid item xs={12} md={6} sx={{padding: 1}}>
                <StyledWideButton
                  disabled={!!this.state.loading || !this.state.stock_step.active_key.conditions || !this.state.stock_step.active_key.proposed_amount}
                  theme={this.props.theme}
                  text={t("emission.steps.stock.borderel.not_itsme")}
                  onClick={(e) => {
                    this.setState({stock_step: {...this.state.stock_step, active_key: {...this.state.stock_step.active_key, use_itsme: 2}}}, () => {
                      this.props.onChangeState(this.state);
                      // window.scrollTo(0, 0);
                      if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                        this.props.helpers.web_log.do_refresh({
                          locale: this.props.i18n.language,
                          callback: (r, full_data) => {
                            window.argenco_uuid = r;
                          },
                          parameters: {
                            id: this.props.login?.id,
                            uuid: window.argenco_uuid,
                            u: window.location.pathname,
                            r: document.referrer,
                            a: 'button_click',
                            d: 'emission.orders.new_save_mount.not_itsme',
                            s: window.argenco_source,
                            ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                          }
                        });
                      }
                      this.confirm_with_borderel(e, true);
                    });
                  }}
                  icon={<PrintIcon sx={{height: '60px', width: 'auto'}} />}
                />
              </Grid>}
            </React.Fragment>}

            {this.state.stock_step.active_key.status_key === 'open' && !new_vennoot && <React.Fragment>
              <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 0}}>{t("emission.steps.stock.title.confirm_existing")}</Typography> {false && this.its_okay(true)}
              </Grid>
              <Grid item xs={12}>
                <PurifiedTypo text={t("emission.steps.stock.confirm_extra_existing")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
              </Grid>
              <Grid item xs={12} md={6} sx={{padding: 1}}>
                <StyledButton
                  is_red
                  disabled={!!this.state.loading || !this.state.stock_step.active_key.proposed_amount}
                  theme={this.props.theme}
                  text={t("emission.steps.stock.confirm")}
                  itsme
                  onClick={(e) => {
                    this.setState({loading: true}, () => {
                      this.props.onChangeState(this.state);
                      if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                        this.props.helpers.web_log.do_refresh({
                          locale: this.props.i18n.language,
                          callback: (r, full_data) => {
                            window.argenco_uuid = r;
                          },
                          parameters: {
                            id: this.props.login?.id,
                            uuid: window.argenco_uuid,
                            u: window.location.pathname,
                            r: document.referrer,
                            a: 'button_click',
                            d: 'emission.orders.existing_save_amount',
                            s: window.argenco_source,
                            ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                          }
                        });
                      }
                      // window.scrollTo(0, 0);
                      this.confirm_with_borderel(e, false);
                    });
                  }}
                />
              </Grid>
            </React.Fragment>}

            {this.state.stock_step.active_key.status_key === 'borderel' && <React.Fragment>
              <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 0}}>{t("emission.steps.stock.title.borderel")}</Typography> {false && this.its_okay(true)}
              </Grid>
              <Grid item xs={12}>
                <PurifiedTypo text={t("emission.steps.stock.title.borderel_intro")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
              </Grid>
              {this.state.stock_step.active_key.borderel_blank.indexOf("missing.png") < 0 && <Grid item xs={12} sx={{marginTop: -3, marginBottom: 1}}>
                <StyledButton
                  text={t("emission.steps.stock.detail.cta.blank_document").replace(/<[^>]*>?/gm, '')}
                  theme={this.props.theme}
                  sx={{marginTop: 1}}
                  onClick={(e) => {
                    window.open(this.state.stock_step.active_key.borderel_blank, '_BLANK');
                    if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                      this.props.helpers.web_log.do_refresh({
                        locale: this.props.i18n.language,
                        callback: (r, full_data) => {
                          window.argenco_uuid = r;
                        },
                        parameters: {
                          id: this.props.login?.id,
                          uuid: window.argenco_uuid,
                          u: window.location.pathname,
                          r: document.referrer,
                          a: 'button_click',
                          d: 'emission.blank_borderel.click',
                          s: window.argenco_source,
                          ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                        }
                      });
                    }
                  }}
                />
              </Grid>}
              <Grid item xs={12}>
                <PurifiedTypo text={t("emission.steps.stock.title.borderel_extra")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
              </Grid>
              <Grid item xs={12} sx={{display: {xs: 'none', md: 'flex'}, marginTop: -1}}>
                <DropzoneArea
                  acceptedFiles={['image/*', '.pdf']}
                  filesLimit={1}
                  dropzoneText={he.decode(t('general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
                  onChange={(files) => {this.setState({stock_step: {...this.state.stock_step, active_key: {...this.state.stock_step.active_key, item: files[0]}}});}}
                  maxFileSize={3000000}
                  previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                  dropzoneParagraphClass={classes.dropZoneText}
                  previewGridClasses={{
                    item: classes.preview,
                    container: classes.preview_grid
                  }}
                  dropzoneClass={classes.dropzoneMainClass}
                />
              </Grid>
              <Grid item xs={12} sx={{display: {xs: 'flex', md: 'none'}, marginTop: -1}}>
                <DropzoneArea
                  acceptedFiles={['image/*', '.pdf']}
                  filesLimit={1}
                  dropzoneText={he.decode(t('general.dropzone_text_mobile').replace(/<[^>]*>?/gm, ''))}
                  onChange={(files) => {this.setState({stock_step: {...this.state.stock_step, active_key: {...this.state.stock_step.active_key, item: files[0]}}});}}
                  maxFileSize={3000000}
                  previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                  dropzoneParagraphClass={classes.dropZoneText}
                  previewGridClasses={{
                    item: classes.preview,
                    container: classes.preview_grid
                  }}
                  dropzoneClass={classes.dropzoneMainClassMobile}
                />
              </Grid>
              {false && !this.state.emission_information?.user.has_guardian && <React.Fragment>
                <Grid item xs={12} sx={{marginTop: 4, position: 'relative', padding: '0px !important'}}>
                  <Typography variant="h2" sx={{color: this.props.theme.palette.tertiary.main, paddingLeft: 0}}>{t("emission.steps.stock.title.skip_borderel_use_itsme")}</Typography> {false && this.its_okay(true)}
                </Grid>
                <Grid item xs={12}>
                  <PurifiedTypo text={t("emission.steps.stock.text.skip_borderel_use_itsme")} sx={{marginTop: '-1em', lineHeight: '1.5'}} />
                </Grid>
                <Grid item xs={12} md={6} sx={{padding: 1, marginTop: 1}}>
                  <StyledWideButton
                    theme={this.props.theme}
                    text={t("emission.steps.stock.borderel.itsme")}
                    itsme
                    onClick={(e) => {
                      this.save_itsme_borderel(e);
                    }}
                  />
                </Grid>
              </React.Fragment>}
            </React.Fragment>}

            {!!this.state.show_error && <Grid item xs={12}>
              <PurifiedTypo text={t("emission.steps.stock.generic_error")} />
            </Grid>}

            {this.state.stock_step.active_key.status_key === 'borderel' && <Grid item xs={12} sx={{marginTop: 4}} align="left">
              <StyledButton
                is_red
                disabled={!this.state.stock_step.active_key.item || this.state.loading}
                onClick={(e) => {
                  this.setState({loading: true}, () => {
                    this.props.onChangeState(this.state);
                    if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                      this.props.helpers.web_log.do_refresh({
                        locale: this.props.i18n.language,
                        callback: (r, full_data) => {
                          window.argenco_uuid = r;
                        },
                        parameters: {
                          id: this.props.login?.id,
                          uuid: window.argenco_uuid,
                          u: window.location.pathname,
                          r: document.referrer,
                          a: 'button_click',
                          d: 'emission.orders.save_borderel',
                          s: window.argenco_source,
                          ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                        }
                      });
                    }
                    // window.scrollTo(0, 0);
                    this.save_borderel(e);
                  });
                }}
                text={t("emission.steps.stock.cta.upload_borderel")}
              />
            </Grid>}
            <Grid item xs={12} align="left">
              <StyledButton paddingless text={t("emission.landing.cta.go_back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} as_link startIcon={<ArrowBackIcon />} onClick={() => {
                this.setState({stock_step: {...this.state.stock_step, active_key: null}}, () => {
                  this.props.onChangeState(this.state);
                  window.scrollTo(0, 0);
                });
              }} />
            </Grid>
          </React.Fragment>}

        </Grid>
        <Grid item xs={12} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>&nbsp;</Grid>
      </Grid>}

      <StyledConfirm
        theme={this.props.theme}
        t={this.props.t}
        key="cancel_order"
        open={this.state.confirm}
        nullify={() => {this.setState({confirm: false});}}
        title={t(`emission.steps.stock.confirm.cancel_title`)}
        msg={t(`emission.steps.stock.confirm.cancel_body`)}
        onSubmit={(e) => {
          this.setState({loading: true, confirm: false}, () => {
            this.props.onChangeState(this.state);
            if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
              this.props.helpers.web_log.do_refresh({
                locale: this.props.i18n.language,
                callback: (r, full_data) => {
                  window.argenco_uuid = r;
                },
                parameters: {
                  id: this.props.login?.id,
                  uuid: window.argenco_uuid,
                  u: window.location.pathname,
                  r: document.referrer,
                  a: 'button_click',
                  d: 'emission.orders.do_cancel_order',
                  s: window.argenco_source,
                  ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                }
              });
            }
            // window.scrollTo(0, 0);
            this.cancel_order();
          });
        }}
        cancel={t("emission.steps.stock.confirm.cancel_no").replace(/<[^>]*>?/gm, '')}
        confirm={t("emission.steps.stock.confirm.cancel_yes").replace(/<[^>]*>?/gm, '')}
      />

      {!!this.state.stock_step.active_key && <StyledConfirm
        theme={this.props.theme}
        t={this.props.t}
        key="qr"
        open={this.state.qr_confirm}
        nullify={() => {this.setState({qr_confirm: false});}}
        hide_cancel
        qr={(<QRCode value={`BCD\n001\n1\nSCT\nARSPBE22\nArgen-Co CV\nBE84973171450059\nEUR${parseInt(this.state.stock_step.active_key.proposed_value, 10).toString()}\n\n${this.state.emission_information?.emission_keys[0]?.asn_key}\n\n`} />)}
        title={t(`emission.steps.stock.qr_confirm.title`)}
        msg={t(`emission.steps.stock.qr_confirm.body`)}
        onSubmit={(e) => {
          this.setState({qr_confirm: false});
        }}
        confirm={t("emission.steps.stock.qr_confirm.cta_ok").replace(/<[^>]*>?/gm, '')}
      />}
    </React.Fragment>) : '');
  }
}

EmissionStepStock.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  dropZoneText: {
    fontFamily: theme.typography.fontFamily
  },
  preview: {
    maxWidth: '100%',
    '& img': {
      maxHeight: '150px'
    },

  },
  preview_grid: {
    width: '100%',
    margin: '0px',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropzoneMainClassMobile: {
    minHeight: '175px !important',
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiDropzonePreviewList-removeButton': {
      right: '8px'
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: '8px'
    }
  },
  dropzoneMainClass: {
    '& .MuiDropzoneArea-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiDropzonePreviewList-removeButton': {
      right: '8px'
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      padding: '8px'
    }
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(EmissionStepStock))));
