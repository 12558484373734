import { connect } from 'react-redux';
import Devise from '../../../actions/devise';
import Emission from '../../../actions/emission';
import WaitingList from '../../../actions/waiting_list';
import WebLog from '../../../actions/web_log';
import Landing from '../../../components/pages/emission/Landing';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      emissions: {
        fetch_all: (_params) => {
          dispatch(Emission.fetch_all(_params));
        }
      },
      user: {
        emission_information: (_params) => {
          dispatch(Devise.emission_information(_params));
        },
        validate_simple: (_params) => {
          dispatch(Devise.validate_simple(_params));
        }
      },
      waiting_lists: {
        do_save: (_params) => {
          dispatch(WaitingList.do_save(_params));
        }
      },
      web_log: {
        do_refresh: _params => {
          dispatch(WebLog.do_refresh(_params));
        }
      }
    },
    logout: (_params) => {
      dispatch(Devise.sign_out(_params));
    },
    renew_sign_in: _params => {
      dispatch(Devise.renew_sign_in(_params));
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Landing);
