import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material';


import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    if (window.location.hash.indexOf("#") > -1) {
      const section = document.querySelector(window.location.hash);
      if (!!section) {
        const y = section.getBoundingClientRect().top + window.pageYOffset -160;
        window.scrollTo({top: y, behavior: 'smooth'});
      } else {
        setTimeout(() => {
          const section = document.querySelector(window.location.hash);
          if (!!section) {
            const y = section.getBoundingClientRect().top + window.pageYOffset -160;
            window.scrollTo({top: y, behavior: 'smooth'});
          }
        }, 1000);
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let custom_stock_why = false;
    if (this.props.location.pathname === "/stock/why") custom_stock_why = true;
    let custom_stock_how = false;
    if (this.props.location.pathname === "/stock/how") custom_stock_how = true;
    let custom_stock_transfer = false;
    if (this.props.location.pathname === "/stock/transfer") custom_stock_transfer = true;

    let content = "";
    if (this.props.location.pathname === "/stock/how") content = "contentpage.stock.how.content";
    // if (this.props.location.pathname === "/stock/transfer") content = "contentpage.stock.transfer.content";
    if (this.props.location.pathname === "/stock/prospectus") content = "contentpage.stock.prospectus.content";
    if (this.props.location.pathname === "/about/history_mission") content = "contentpage.about.history_mission.content";
    if (this.props.location.pathname === "/about/team") content = "contentpage.about.team.content";
    if (this.props.location.pathname === "/about/documents") content = "contentpage.about.jaarverslag.content";

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Grid container spacing="2">
          {!custom_stock_why && !custom_stock_how && !custom_stock_transfer && <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '50vh'}}>
            <PurifiedTypo text={t(content)} />
          </Grid>}

          {!!custom_stock_how && <React.Fragment>
            <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <PurifiedTypo text={t(content)} />
            </Grid>
            <Grid item xs={12} align="center">
              <Button variant="contained" color="secondary" sx={{marginTop: 2}} onClick={() => {
                this.props.history.push(t("contentpage.stock.how.cta_url").replace(/<[^>]*>?/gm, ''));
                window.scrollTo(0, 0);
              }}>{t("contentpage.stock.how.cta_text").replace(/<[^>]*>?/gm, '')}</Button>
            </Grid>
          </React.Fragment>}

          {!!custom_stock_why && <React.Fragment>
            <Grid item xs={12}>
              <PurifiedTypo text={t("contentpage.stock.why.content_title")} />
            </Grid>
            <Grid item xs={12} md={6} sx={{paddingRight: {xs: 0, md: 2}}}>
              <img src={t("contentpage.stock.why.content_left").replace(/<[^>]*>?/gm, '')} style={{maxWidth: '100%', height: 'auto'}} />
            </Grid>
            <Grid item xs={12} md={6}>
              <PurifiedTypo text={t("contentpage.stock.why.content")} />
            </Grid>
            <Grid item xs={12}>
              <PurifiedTypo text={t("contentpage.stock.why.content_bottom")} />
            </Grid>
          </React.Fragment>}

          {!!custom_stock_transfer && <React.Fragment>
            <Grid item xs={12}>
              <PurifiedTypo text={t("contentpage.stock.transfer.content_title")} />
            </Grid>
            <Grid item xs={12} md={6} sx={{paddingRight: {xs: 0, md: 2}}}>
              <PurifiedTypo text={t("contentpage.stock.transfer.content")} />
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={t("contentpage.stock.transfer.content_right").replace(/<[^>]*>?/gm, '')} style={{maxWidth: '100%', height: 'auto'}} />
            </Grid>
            <Grid item xs={12}>
              <PurifiedTypo text={t("contentpage.stock.transfer.content_bottom")} />
            </Grid>
          </React.Fragment>}
        </Grid>
      </Container>
    </Page>);
  }
}

Content.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Content))));
