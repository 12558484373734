import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from "../../../shared/parts/PurifiedTypo";

class SlideProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes, ...other } = this.props;
    const {question, hidden, locale} = other;

    return (<React.Fragment>
      {!!question.top_info[locale] && <Grid xs={12} sx={{display: !!hidden ? 'none' : 'block'}}>
        <PurifiedTypo text={question.top_info[locale]} />
      </Grid>}
      {!!question.image_url && <Grid xs={12} sm={4} sx={{display: !!hidden ? 'none' : 'block'}}>
        <img src={question.image_url[locale]} className={classes.slide_image} alt="" />
      </Grid>}
      <Grid xs={12} sm={!!question.image_url ? 8 : 12} sx={{display: !!hidden ? 'none' : 'block'}}>
        <PurifiedTypo text={question.question[locale]} className={classes.profile_name} />
        <PurifiedTypo text={question.toelichting[locale]} />
      </Grid>
      {!!question.bottom_info[locale] && <Grid xs={12} sx={{display: !!hidden ? 'none' : 'block'}}>
        <PurifiedTypo text={question.bottom_info[locale]} />
      </Grid>}
      {parseInt(question.quorum, 10) > 0 && <Grid xs={12} sx={{display: !!hidden ? 'none' : 'block', marginTop: 2}}>
        999 - yes1 - no0 - blank2
      </Grid>}
    </React.Fragment>);
  }
}

SlideProfile.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  slide_image: {
    maxHeight: 300,
    maxWidth: '100%'
  },
  profile_name: {
    fontWeight: 'bold',
    fontSize: '1.8em'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(SlideProfile))));
