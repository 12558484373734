import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  CircularProgress,
  Container,
  Grid
} from '@mui/material';

import {DateTime} from "luxon";

import Page from "../../shared/Page";
// import PurifiedTypo from "../../shared/parts/PurifiedTypo";
import StyledButton from "../../shared/parts/StyledButton";
import Typography from "../../shared/parts/Typography";

class PhysicalChoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      user: !!this.props.location && !!this.props.location.state && !!this.props.location.state.user ? this.props.location.state.user : {},
      meeting: !!this.props.location && !!this.props.location.state && !!this.props.location.state.meeting ? this.props.location.state.meeting : {
        name: 'placeholder'
      },
      mode: 0,
      loading: false
    };
  }

  componentDidMount() {
    if (!this.state.meeting.id) {
      this.props.helpers.meetings.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r) => {
          this.setState({meeting: r});
        },
        parameters: {
          vennr: this.state.user.vennoot_number,
          birth_date: this.state.user.birth_date,
          fields: [
            'id', 'name', 'field_intro', 'is_mandatory', 'field_location', 'meeting_day', 'last_day', 'last_day_vote', 'attendance'
          ]
        }
      });
    }

    if (!!this.props.login) {
      this.props.renew_sign_in({
        locale: this.props.i18n.language,
        login: this.props.login,
        parameters: {
          fields: ['id', 'first_name', 'last_name', 'email', 'auth_token', "type", "is_admin", "vennoot_number", 'address', 'stock_number', 'share_type|tag', 'birth_date', 'stock_id']
        }
      });
    }
  }

  confirm_physical(event) {
    event.preventDefault();
    this.setState({loading: true});

    let errors = {};
    if (!this.state.mode) errors.mode = true;

    if (Object.keys(errors).length === 0) {
      this.props.helpers.meetings.do_save({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r) => {
          this.setState({loading: false});
          this.props.history.push({pathname: '/success', state: {success_type: 'physical'}});
          window.scrollTo(0, 0);
        },
        parameters: {
          vennr: this.state.user.vennoot_number,
          birth_date: this.state.user.birth_date,
          fields: [],
          mode: this.state.mode,
          meeting_id: this.state.meeting.id
        }
      });
    } else {
      this.setState({errors: errors});
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let active = DateTime.fromISO(this.state.meeting.last_day) >= DateTime.now();
    let already_registered = (this.state.meeting && this.state.meeting.attendance && this.state.meeting.attendance.physical && this.state.meeting.attendance.physical.enabled);
    let already_voted = (this.state.meeting && this.state.meeting.attendance && this.state.meeting.attendance.online && this.state.meeting.attendance.online.enabled);
    let gave_volmacht = (this.state.meeting && this.state.meeting.attendance && this.state.meeting.attendance.physical && !!this.state.meeting.attendance.physical.volmacht);

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Typography variant="h3">{t("meeting.physical.attendance.title").replace(/<[^>]*>?/gm, '')}</Typography>

        {/* ook nog checken voor - volmacht */}

        <Grid container spacing="2" style={{marginTop: this.props.theme.spacing(2)}}>
          {!active && <Grid item xs={12} align="center">
            <Typography className={classes.grey_error} variant="h5">{t("meetings.notification.error.after_physical_last_day").replace(/<[^>]*>?/gm, '')}</Typography>
          </Grid>}
          {!!active && <React.Fragment>
            {!!already_voted && <Grid xs={12}>
              <Typography>{t("meetings.physical.attendance.already_voted").replace(/<[^>]*>?/gm, '')}</Typography>
            </Grid>}
            {!already_voted && <React.Fragment>
              {!!gave_volmacht && <Grid xs={12}>
                <Typography>{t("meetings.physical.attendance.gave_volmacht").replace(/<[^>]*>?/gm, '')}</Typography>
              </Grid>}
              {!gave_volmacht && <React.Fragment>
                {!!already_registered && <Grid xs={12}>
                  <Typography>{t("meetings.physical.attendance.already_registered").replace(/<[^>]*>?/gm, '')}</Typography>
                </Grid>}
                {!already_registered && <React.Fragment>
                  <Grid xs={12}>
                    <Typography>{t("meetings.physical.attendance.intro").replace(/<[^>]*>?/gm, '')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} align="center">
                    <Box className={classes.button_box} sx={{
                      opacity: (!this.state.mode || this.state.mode === 1 ? 1 : 0.4)
                    }} onClick={() => {this.setState({mode: 1});}}>
                      <Typography variant="h4" style={{fontWeight: 'bold'}}>{t("meetings.physical.attendance.by_mail.title").replace(/<[^>]*>?/gm, '')}</Typography>
                      {/* <PurifiedTypo text={t("meetings.physical.attendance.by_mail.text")} /> */}
                      <Typography variant="h4">{this.state.user.email}</Typography>
                      <StyledButton text={t("general.actions.choose").replace(/<[^>]*>?/gm, '')} fullWidth as_link theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {this.setState({mode: 1});}} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} align="center">
                    <Box className={classes.button_box} sx={{
                      opacity: (!this.state.mode || this.state.mode === 2 ? 1 : 0.4)
                    }} onClick={() => {this.setState({mode: 2});}}>
                      <Typography variant="h4" style={{fontWeight: 'bold'}}>{t("meetings.physical.attendance.by_post.title").replace(/<[^>]*>?/gm, '')}</Typography>
                      {/* <PurifiedTypo text={t("meetings.physical.attendance.by_post.text")} /> */}
                      <Typography variant="h4">{this.state.user.address}</Typography>
                      <StyledButton text={t("general.actions.choose").replace(/<[^>]*>?/gm, '')} as_link theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {this.setState({mode: 2});}} />
                    </Box>
                  </Grid>
                  <Grid xs={12} align="center">
                    {!!this.state.mode && !this.state.loading && <StyledButton text={t("meetings.physical.attendance.confirm").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{marginTop: this.props.theme.spacing(4)}} onClick={(e) => {
                      this.confirm_physical(e);
                    }} />}
                    {!!this.state.mode && !!this.state.loading && <CircularProgress />}
                  </Grid>
                </React.Fragment>}
              </React.Fragment>}
            </React.Fragment>}
          </React.Fragment>}
        </Grid>
      </Container>
    </Page>);
  }
}

PhysicalChoice.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  grey_error: {
    fontStyle: 'italic',
    opacity: 0.7
  },
  button_box: {
    cursor: 'pointer',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '25px',
    padding: theme.spacing(8),
    margin: theme.spacing(2),
    opacity: 0.7,
    // backgroundColor: theme.palette.primary.light,

  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(PhysicalChoice))));
