import rqst_post from '../lib/server_helper.js';

export default class Meeting {
  static FETCH_ALL = 'Meeting.FETCH_ALL';
  static FETCH_ALL_BEGIN = 'Meeting.FETCH_ALL_BEGIN';
  static FETCH_ALL_SUCCESS = 'Meeting.FETCH_ALL_SUCCESS';
  static FETCH_ALL_ERROR = 'Meeting.FETCH_ALL_ERROR';

  static SAVE = 'Meeting.SAVE';
  static SAVE_BEGIN = 'Meeting.SAVE_BEGIN';
  static SAVE_SUCCESS = 'Meeting.SAVE_SUCCESS';
  static SAVE_ERROR = 'Meeting.SAVE_ERROR';

  static VOTE = 'Meeting.VOTE';
  static VOTE_BEGIN = 'Meeting.VOTE_BEGIN';
  static VOTE_SUCCESS = 'Meeting.VOTE_SUCCESS';
  static VOTE_ERROR = 'Meeting.VOTE_ERROR';

  static fetch_all = ({
    locale, parameters = {}, login, callback, error_callback
  }) => {
    return rqst_post({
      url: `/${locale}/v2/meetings/fetch_all`,
      begin: Meeting.FETCH_ALL_BEGIN,
      succes: Meeting.FETCH_ALL_SUCCESS,
      error: Meeting.FETCH_ALL_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback,
      error_callback: error_callback
    });
  };

  static do_save = ({
    locale, parameters = {}, login, callback, error_callback
  }) => {
    return rqst_post({
      url: `/${locale}/v2/meetings/do_save`,
      begin: Meeting.SAVE_BEGIN,
      succes: Meeting.SAVE_SUCCESS,
      error: Meeting.SAVE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback,
      error_callback: error_callback
    });
  };

  static do_vote = ({
    locale, parameters = {}, login, callback, error_callback
  }) => {
    return rqst_post({
      url: `/${locale}/v2/meetings/do_vote`,
      begin: Meeting.VOTE_BEGIN,
      succes: Meeting.VOTE_SUCCESS,
      error: Meeting.VOTE_ERROR,
      locale: locale,
      parameters: parameters,
      login: login,
      callback: callback,
      error_callback: error_callback
    });
  };

  // ---

  static state_switch = (state, action) => {
    switch (action.type) {
      case Meeting.FETCH_ALL:
        return {...state, loading: false, data: null};
      case Meeting.FETCH_ALL_BEGIN:
        return {...state, loading: true, error: null};
      case Meeting.FETCH_ALL_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Meeting.FETCH_ALL_ERROR:
        return {...state, loading: false, error: action.payload};

      case Meeting.SAVE:
        return {...state, loading: false, data: null};
      case Meeting.SAVE_BEGIN:
        return {...state, loading: true, error: null};
      case Meeting.SAVE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Meeting.SAVE_ERROR:
        return {...state, loading: false, error: action.payload};

      case Meeting.VOTE:
        return {...state, loading: false, data: null};
      case Meeting.VOTE_BEGIN:
        return {...state, loading: true, error: null};
      case Meeting.VOTE_SUCCESS:
        return {...state, loading: false, data: action.payload};
      case Meeting.VOTE_ERROR:
        return {...state, loading: false, error: action.payload};

      default:
        return state;
    }
  }
}
