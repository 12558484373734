import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  CircularProgress,
  Grid
} from '@mui/material';

import ArgencoTree from '../../../images/Argenco Tree 2022.svg';

import OtpField from '../../shared/parts/onboarding/OtpField';
import PurifiedTypo from '../../shared/parts/PurifiedTypo';
import StyledButton from '../../shared/parts/StyledButton';
import StyledTextField from '../../shared/parts/StyledTextField';
import Typography from '../../shared/parts/Typography';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state !== prevProps.state) {
      this.setState(this.props.state);
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Grid container>
      <Grid item sm={1} md={2} sx={{display: {sm: 'block', xs: 'none'}}}></Grid>
      <Grid item xs={12} sm={5} md={4} sx={{minHeight: '300px', paddingRight: {xs: 0, md: 1.5}}}>
        <Typography variant="h1">{t("onboarding.login.title").replace(/<[^>]*>?/gm, '')}</Typography>
        <StyledTextField
          label={t("index_field_username").replace(/<[^>]*>?/gm, '')}
          value={this.state.username}
          id="username"
          onChange={(v) => {this.setState({username: v}, () => {this.props.onChangeState(this.state)});}}
          sx={{marginTop: 4}}
          error={this.state.errors.username}
          onKeyPress={(event) => {if (event.key === 'Enter' && !(this.state.username.length === 0 || this.state.password.length === 0)) this.props.onLogin(event);}}
        />
        <StyledTextField
          label={t("index_field_password").replace(/<[^>]*>?/gm, '')}
          value={this.state.password}
          id="password"
          onChange={(v) => {this.setState({password: v}, () => {this.props.onChangeState(this.state)});}}
          sx={{marginTop: 2}}
          error={this.state.errors.password}
          type={this.state.show_password ? "text" : "password"}
          onPwdVisibility={(visible) => {this.setState({show_password: visible}, () => {this.props.onChangeState(this.state)});}}
          onKeyPress={(event) => {if (event.key === 'Enter' && !(this.state.username.length === 0 || this.state.password.length === 0)) this.props.onLogin(event);}}
        />
        {this.state.show_otp_code && <React.Fragment>
          <PurifiedTypo text={t("authentication.mfa.auth_short_intro")} sx={{fontSize: '0.9rem', marginTop: 1, '& > p': {marginBottom: '-8px !important'}}} />
          <OtpField
            {...this.props}
            no_verification={true}
            onKeyPress={(event) => {if (event.key === 'Enter' && !(this.state.username.length === 0 || this.state.password.length === 0)) this.props.onLogin(event);}}
            onSubmit={(code) => {
              // saved upon verification
              this.setState({otp_code: code}, () => {this.props.onChangeState(this.state)});
            }}
            sx={{marginTop: 1}}
          />
        </React.Fragment>}
        {!this.state.logging_in && <StyledButton disabled={!!this.state.show_otp_code ? (this.state.username.length === 0 || this.state.password.length === 0 || this.state.otp_code.length < 6) : (this.state.username.length === 0 || this.state.password.length === 0)} text={t("index_button_login").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{float: 'right', marginTop: 4}} onClick={(e) => {this.props.onLogin(e);}} />}
        {!!this.state.logging_in && <CircularProgress sx={{alignSelf: 'flex-end', marginTop: 4}} />}
        {/* <StyledButton text={t("index_link_forgot_password").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{alignSelf: 'flex-end', marginTop: 4}} secondary onClick={() => {this.setState({show_first_time: true});}} /> */}
      </Grid>
      <Grid item xs={12} sm={5} md={4} sx={{paddingLeft: {xs: 0, md: 1.5}, display: {xs: 'none', md: 'block'}}}>
        <Box sx={{border: `2px solid transparent`, textAlign: 'right', marginTop: {xs: 6, md: 11}}}>
          <img src={ArgencoTree} style={{
            maxHeight: '150px',
            maxWidth: '100%'
          }} />
          {/* <Typography variant="h3">{t("onboarding.register.title").replace(/<[^>]*>?/gm, '')}</Typography>
          <Typography sx={{marginTop: 1}} variant="h4">{t("onboarding.register.subtitle").replace(/<[^>]*>?/gm, '')}</Typography>
          <StyledButton text={t("onboarding.register.cta").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{alignSelf: 'flex-end', marginTop: this.props.theme.spacing(4)}} onClick={(e) => {this.setState({show_first_time: true});}} /> */}
        </Box>
      </Grid>
      <Grid item sm={1} md={2} sx={{display: {sm: 'block', xs: 'none'}}}></Grid>
      <Grid item sm={1} md={2} sx={{display: {sm: 'block', xs: 'none'}}}></Grid>
      <Grid item xs={12} sm={10} md={8} sx={{marginTop: 6}}>
        <Box sx={{padding: 2, paddingTop: 6, borderTop: `1px solid ${this.props.theme.palette.primary.main}`}}>
          <Typography variant="h3">{t("onboarding.register.title").replace(/<[^>]*>?/gm, '')}</Typography>
          <Typography sx={{marginTop: 1}} variant="h4">{t("onboarding.register.subtitle").replace(/<[^>]*>?/gm, '')}</Typography>
          <StyledButton text={t("onboarding.register.cta").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{alignSelf: 'flex-end', marginTop: this.props.theme.spacing(4)}} onClick={(e) => {this.setState({show_first_time: true}, () => {window.scrollTo(0, 0); this.props.onChangeState(this.state);});}} />
        </Box>
      </Grid>
      <Grid item sm={1} md={2} sx={{display: {sm: 'block', xs: 'none'}}}></Grid>
    </Grid>);
  }
}

Login.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Login))));
