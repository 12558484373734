import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import DOMPurify from 'dompurify'
import classNames from 'classnames';
import {withStyles} from '@mui/styles';

import {
  Typography
} from '@mui/material';

class PurifiedTypo extends Component {
  render() {
    const {text, classes} = this.props;

    return (<Typography className={classNames(classes.wrapper, this.props.className, classes.purified, "purified")} gutterBottom={this.props.gutterBottom} style={this.props.style} dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(text, {
          ALLOWED_TAGS: [
            "p",
            "a",
            "i",
            "u",
            "b",
            "strong",
            "span",
            "ul",
            "ol",
            "li",
            "img",
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "iframe",
            "em",
            "del",
            "br",
            "video",
            "audio",
            "source"
          ],
          ALLOWED_ATTR: ["href", "onClick", "target", "src", "allowfullscreen", "width", "height", "style", "controls", "type", "id"]
        })
      }} sx={{
        "a": {
          color: this.props.theme.palette.tertiary.main,
          textDecoration: 'none',
          fontWeight: 'bold'
        },
        "a:hover": {
          textDecoration: 'underline'
        },
        "img": {
          maxWidth: '100%',
          height: 'auto'
        },
        "video": {
          maxWidth: '100%',
          height: 'auto'
        },
        "p": {
          lineHeight: '1.8'
        },
        "& > p": {
          marginBottom: {xs: '2em !important', md: '1em !important'},
          // marginBottom: '2em'
        },
        "h1": {
          fontSize: "2rem",
          fontFamily: ['Nunito Sans'],
          color: '#5D6071',
          fontWeight: 700
        },
        "h2": {
          fontSize: "1.5rem",
          fontFamily: ['Nunito Sans'],
          color: '#5D6071'
        },
        "h3": {
          fontSize: "1.25rem",
          fontFamily: ['Nunito Sans'],
          color: '#5D6071'
        },
        "h4": {
          fontSize: "1rem",
          fontFamily: ['Nunito Sans'],
          color: '#5D6071'
        },
        ...this.props.sx
      }} onClick={(e) => {if (!!this.props.onClick) this.props.onClick(e);}}></Typography>);
  }
}

const styles = theme => ({
  purified: {
    // flex: 1
    fontFamily: ['Raleway', 'sans-serif'].join(',')
  },
  wrapper: {
    // display: "flex",
    // alignItems: "center"
    maxWidth: '100%'
  }
});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(PurifiedTypo));
