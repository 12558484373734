import Meeting from '../actions/meeting';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function meeting(state = initial, action) {
  return Meeting.state_switch(state, action);
}
