import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from '../../shared/parts/PurifiedTypo';
import StyledButton from '../../shared/parts/StyledButton';
import StyledTextField from '../../shared/parts/StyledTextField';
import Typography from '../../shared/parts/Typography';

class StepSecQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state !== prevProps.state) {
      this.setState(this.props.state);
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Grid container>
      <Grid item xs={1} sm={2} md={4}></Grid>
      <Grid item container xs={10} sm={8} md={4} sx={{minHeight: '300px', paddingLeft: {xs: 0, md: 1.5}}}>
        <Grid item xs={12} sx={{marginTop: 4, marginBottom: 1}}>
          <Typography variant="h3">{t("first_time.step_05.messages.sec_q_title").replace(/<[^>]*>?/gm, '')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <PurifiedTypo text={t("first_time.step_05.messages.sec_q").replace(/<[^>]*>?/gm, '')} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{marginTop: 4}}>{this.state.user.sec_q_q1}</Typography>
          <StyledTextField
            label={t("first_time.step_05.fields.answer_1").replace(/<[^>]*>?/gm, '')}
            value={this.state.sec_q.answer_1}
            id="sec_q_answer_1"
            onChange={(v) => {this.setState({sec_q: {...this.state.sec_q, answer_1: v}}, () => {this.props.onChangeState(this.state)});}}
            sx={{marginTop: 1}}
            error={this.state.sec_q_error}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{marginTop: 4}}>{this.state.user.sec_q_q2}</Typography>
          <StyledTextField
            label={t("first_time.step_05.fields.answer_2").replace(/<[^>]*>?/gm, '')}
            value={this.state.sec_q.answer_2}
            id="sec_q_answer_1"
            onChange={(v) => {this.setState({sec_q: {...this.state.sec_q, answer_2: v}}, () => {this.props.onChangeState(this.state)});}}
            sx={{marginTop: 1}}
            error={this.state.sec_q_error}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{marginTop: 4}}>{this.state.user.sec_q_q3}</Typography>
          <StyledTextField
            label={t("first_time.step_05.fields.answer_3").replace(/<[^>]*>?/gm, '')}
            value={this.state.sec_q.answer_3}
            id="sec_q_answer_1"
            onChange={(v) => {this.setState({sec_q: {...this.state.sec_q, answer_3: v}}, () => {this.props.onChangeState(this.state)});}}
            sx={{marginTop: 1}}
            error={this.state.sec_q_error}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledButton disabled={(this.state.sec_q.answer_1 && this.state.sec_q.answer_1.length === 0) || (this.state.sec_q.answer_2 && this.state.sec_q.answer_2.length === 0) || (this.state.sec_q.answer_3 && this.state.sec_q.answer_3.length === 0)} text={t("first_time.step_05.buttons.continue").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{alignSelf: 'flex-end', marginTop: this.props.theme.spacing(4)}} onClick={(e) => {this.props.OnCheckSecQ(e);}} />
        </Grid>
        <Grid item xs={12}>
          <StyledButton text={t("first_time.step_05.buttons.back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{alignSelf: 'flex-end', marginTop: this.props.theme.spacing(4)}} secondary onClick={(e) => {this.setState({first_time_step: 0}, () => {this.props.onChangeState(this.state)});}} />
        </Grid>
      </Grid>
      <Grid item xs={1} sm={2} md={4}></Grid>
    </Grid>);
  }
}

StepSecQ.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(StepSecQ))));
