import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Devise from '../actions/devise';
import WebLog from '../actions/web_log';
import Main from '../components/Main';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      web_log: {
        do_refresh: _params => {
          dispatch(WebLog.do_refresh(_params));
        }
      }
    },
    onLogout: _params => {
      dispatch(Devise.sign_out());
    }
  };
};

export default withRouter(connect(
  map_state_to_props,
  map_dispatch_to_props
)(Main));
