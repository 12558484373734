import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';

import "react-placeholder/lib/reactPlaceholder.css";

import {
  Box,
  Container,
  Grid,
  Hidden,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  // Typography
} from '@mui/material';

import ArgencoTree from '../../images/Argenco Tree 2022_NEG.svg';
import SocialFacebook from '../../images/Facebook.svg';
import SocialInstagram from '../../images/Instagram.svg';
import SocialLinkedin from '../../images/Linkedin.svg';

import ReactPlaceholder from 'react-placeholder';
import {DateTime} from "luxon";

import PurifiedTypo from './parts/PurifiedTypo';
import StyledButton from './parts/StyledButton';
import StyledTextField from './parts/StyledTextField';
import Typography from './parts/Typography';

class Footer extends Component {
  constructor(props) {
    super(props);

    let is_admin = false;
    if (props.login && props.login.type === "User::Admin") is_admin = true;

    let parallax_sources = [
      'https://old.argenco.be/support/assets/744',
      'https://old.argenco.be/support/assets/218',
      'https://old.argenco.be/support/assets/227',
      'https://old.argenco.be/support/assets/228',
      'https://old.argenco.be/support/assets/229',
      'https://old.argenco.be/support/assets/219',
      'https://old.argenco.be/support/assets/220',
      'https://old.argenco.be/support/assets/221',
      'https://old.argenco.be/support/assets/222',
      'https://old.argenco.be/support/assets/223',
      'https://old.argenco.be/support/assets/224',
      'https://old.argenco.be/support/assets/225',
      'https://old.argenco.be/support/assets/226'
    ];

    this.state = {
      parallax_source: parallax_sources[Math.floor(Math.random() * parallax_sources.length)],
      login_menu_open: false,
      is_admin: is_admin,
      contact: {
        first_name: '',
        last_name: '',
        email: '',
        reason: '',
        message: '',
        vennr: ''
      },
      news_loading: true,
      news: [],
      benefits: [],
      benefits_loading: true,
      open_news: null,
      open_benefit: null
    }
  }

  componentDidMount() {
    // this.fetch_benefits();
    // this.fetch_news();
  }
  componentDidUpdate(prevProps) {}
  componentWillUnmount() {}

  // fetch_benefits() {
  //   this.props.helpers.benefits.fetch_all({
  //     locale: this.props.i18n.language,
  //     login: this.props.login,
  //     callback: (r) => {
  //       this.setState({benefits: r, benefits_loading: false});
  //     },
  //     parameters: {
  //       search: "",
  //       fields: ['id', 'name', 'field_body', 'image_url', 'name', 'colour'],
  //       order: 'weight DESC, created_at DESC',
  //       page: 0,
  //       per_page: 12
  //     }
  //   });
  // }

  // fetch_news() {
  //   this.props.helpers.news.fetch_all({
  //     locale: this.props.i18n.language,
  //     login: this.props.login,
  //     callback: (r) => {
  //       this.setState({news: r, news_loading: false});
  //     },
  //     parameters: {
  //       search: "",
  //       fields: ['id', 'field_title', 'field_body', 'image_url', 'created_at'],
  //       order: 'created_at DESC',
  //       page: 0,
  //       per_page: 12
  //     }
  //   });
  // }

  render_benefit(key) {
    return (<div>
      <div class="benefit_box">
        <a class="benefit-box bg-blue-light" onClick={(e) => {this.setState({open_benefit: this.state.benefits[key]}, () => {this.scroll_to(this.props.ref_benefits);});}} href="javascript:void(0);" style={{backgroundImage: `url('${this.state.benefits[key].image_url}')`}}>
          <span class="benefit-title" style={{textAlign: 'center', backgroundColor: (this.state.benefits[key].colour.split(",").length === 3 ? this.state.benefits[key].colour.replace(")", ", 0.7)") : this.state.benefits[key].colour)}}>{this.state.benefits[key].name}</span>
        </a>
      </div>
    </div>);
  }

  scroll_to = (ref) => {
    if (ref && ref.current) {
      window.scrollTo(0, ref.current.offsetTop - 130);
    }
  }

  clickable_typo = (path, text) => {
    const {t, classes} = this.props;

    return (<Typography
      variant="h5"
      className={classes.footer_action}
      onMouseDown={(e) => {if (e.button == 1) this.goto_page(e, path);}}
      onAuxClick={(e) => {this.goto_page(e, path);}}
      onClick={(e) => {this.goto_page(e, path);}}
    >
      {t(text)}
    </Typography>);
  }

  goto_page = (e, path) => {
    const {history} = this.props;

    if (e.ctrlKey || (e.nativeEvent && e.nativeEvent.which === 2) || (e.button === 1)) {
      window.open(path);
    } else {
      history.push(path);
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {t, classes} = this.props;

    let is_root = this.props.location.pathname === "/";
    let is_online_voting = this.props.location.pathname === "/meeting/voting";

    return (<React.Fragment>
      {/* {!is_online_voting && <Box className={classes.news_wrapper} ref={this.props.ref_news}>
        <Box className={classes.parallax_divider} sx={{marginBottom: 2}} style={{backgroundImage: `url(${this.state.parallax_source})`}}>
          <Box className={classes.parallax_child} />
        </Box>
        <Container sx={{paddingLeft: 0, paddingRight: 0}}>
          <Grid container>
            <Grid item xs={12} className={classes.inner_wrapper} sx={{position: 'relative'}}>
              <Typography variant="h3" className={classes.component_title}>{t("components.news.title")}</Typography>
              {!!this.state.open_news && <IconButton sx={{position: 'absolute', top: 1, right: 1}} onClick={(e) => {this.setState({open_news: null});}}><CloseIcon /></IconButton>}
            </Grid>
            {!this.state.open_news && <Grid item xs={12}>
              <ImageList gap={16} rowHeight={200} sx={{
                width: '100%',
                overflowY: 'hidden',
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(auto-fill,minmax(320px,1fr)) !important",
                gridAutoColumns: "minmax(320px, 1fr)"
              }}>
                {!!this.state.news_loading && <React.Fragment>
                  {[0, 1, 2, 3, 4].map((i) => (<ImageListItem key={i} style={{flex: 1}}><ReactPlaceholder type='media' rows={7} /></ImageListItem>))}
                </React.Fragment>}

                {!this.state.news_loading && this.state.news.map((news_item) => (<ImageListItem key={news_item.id} onClick={(e) => {this.setState({open_news: news_item}, () => {this.scroll_to(this.props.ref_news);});}} style={{cursor: 'pointer'}}>
                  <img src={news_item.image_url} alt="NewsItem" />
                  <ImageListItemBar title={news_item.field_title} sx={{fontWeight: 700}}/>
                </ImageListItem>))}
              </ImageList>
            </Grid>}
            {!!this.state.open_news && <React.Fragment>
              <Grid item xs={12} sm={6} md={4} sx={{marginTop: 2}}>
                <img src={this.state.open_news.image_url} alt="NewsItem" style={{maxWidth: '100%'}} />
              </Grid>
              <Grid item xs={12} sm={6} md={8} sx={{paddingLeft: 1, marginTop: 2}}>
                <Typography variant="h4" className={classes.component_title}>{this.state.open_news.field_title}</Typography>
                <Typography className={classes.component_title} sx={{fontSize: '0.8em'}}>{DateTime.fromISO(this.state.open_news.created_at).toLocaleString()}</Typography>
                <PurifiedTypo text={this.state.open_news.field_body} sx={{"> p": {textAlign: {xs: 'left !important', md: 'justify !important'}}}} />
              </Grid>
            </React.Fragment>}
          </Grid>
        </Container>
        <Box className={classes.parallax_divider} style={{backgroundImage: `url(${this.state.parallax_source})`}}>
          <Box className={classes.parallax_child} />
        </Box>
      </Box>} */}

      {/* {!is_online_voting && <Box className={classes.benefits_wrapper} ref={this.props.ref_benefits}>
        <Container sx={{paddingLeft: 0, paddingRight: 0}}>
          <Grid container>
            <Grid item xs={12} className={classes.inner_wrapper} sx={{position: 'relative'}}>
              <Typography variant="h3" className={classes.component_title}>{t("components.benefits.title")}</Typography>
              {!!this.state.open_benefit && <IconButton sx={{position: 'absolute', top: 1, right: 1}} onClick={(e) => {this.setState({open_benefit: null});}}><CloseIcon /></IconButton>}
            </Grid>
            {!this.state.open_benefit && <Grid item xs={12}>
              <ImageList gap={16} cols={1}>
                {!!this.state.benefits_loading && <React.Fragment>
                  {[0, 1, 2, 3, 4].map((i) => (<ImageListItem key={i} style={{flex: 1}}><ReactPlaceholder type='media' rows={7} /></ImageListItem>))}
                </React.Fragment>}

                {!this.state.benefits_loading && this.state.benefits && <ImageListItem key="bla">
                  <div class="tile_grid">
                    <div class="tile_column_1">
                      <div class="tile1 tile">
                        {this.render_benefit(0)}
                      </div>
                    </div>
                    <div class="tile_column_2">
                      <div class="tile_row_1">
                        <div class="tile2 tile">
                          {this.render_benefit(1)}
                        </div>
                        <div class="tile3 tile">
                          {this.render_benefit(2)}
                        </div>
                        <div class="tile4 tile">
                          {this.render_benefit(3)}
                        </div>
                      </div>
                      <div class="tile_row_2">
                        <div class="tile5 tile">
                          {this.render_benefit(4)}
                        </div>
                      </div>
                    </div>
                  </div>
                </ImageListItem>}
              </ImageList>
            </Grid>}
            {!!this.state.open_benefit && <React.Fragment>
              <Grid item xs={12} sm={6} md={4} sx={{marginTop: 2}}>
                <img src={this.state.open_benefit.image_url} alt="Benefit" style={{maxWidth: '100%'}} />
              </Grid>
              <Grid item xs={12} sm={6} md={8} sx={{paddingLeft: 1, marginTop: 2}}>
                <Typography variant="h4" className={classes.component_title}>{this.state.open_benefit.name}</Typography>
                <PurifiedTypo text={this.state.open_benefit.field_body} sx={{"> p": {textAlign: {xs: 'left !important', md: 'justify !important'}}}} />
              </Grid>
            </React.Fragment>}

          </Grid>
        </Container>
        <Box className={classes.parallax_divider} style={{backgroundImage: `url(${this.state.parallax_source})`}}>
          <Box className={classes.parallax_child} />
        </Box>
      </Box>} */}

      {/* {(!is_online_voting && is_root) && <Box className={classes.about_wrapper} ref={this.props.ref_about}>
        <Container sx={{paddingLeft: 0, paddingRight: 0}}>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.inner_wrapper}>
              <Typography variant="h3" className={classes.component_title}>{t("components.about.title_top")}</Typography>
              <PurifiedTypo text={t("components.about.text_top")} style={{}} />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inner_wrapper} sx={{paddingLeft: 2}}>
              <Box className={classes.fancy_image_outer}>
                <Box className={classes.fancy_image_inner} style={{backgroundImage: `url('${t("components.about.image_top").replace(/<[^>]*>?/gm, '')}')`}} />
              </Box>
            </Grid>
            <Hidden only='xs'>
              <Grid item xs={12} sm={6} className={classes.inner_wrapper} sx={{paddingRight: 2}}>
                <Box className={classes.fancy_image_outer}>
                  <Box className={classes.fancy_image_inner} style={{backgroundImage: `url('${t("components.about.image_bottom").replace(/<[^>]*>?/gm, '')}')`}} />
                </Box>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} className={classes.inner_wrapper} sx={{paddingLeft: {xs: 0, md: 2}}}>
              <Typography variant="h3" className={classes.component_title} sx={{textAlign: {xs: 'left', md: 'right'}}}>{t("components.about.title_bottom").replace(/<[^>]*>?/gm, '')}</Typography>
              <PurifiedTypo text={t("components.about.text_bottom")} style={{}} />
            </Grid>
          </Grid>
        </Container>
        <Box className={classes.parallax_divider} style={{backgroundImage: `url(${this.state.parallax_source})`}}>
          <Box className={classes.parallax_child} />
        </Box>
      </Box>} */}

      {/* {!is_online_voting && <Box className={classes.contact_wrapper} ref={this.props.ref_contact}>
        <Container sx={{paddingLeft: 0, paddingRight: 0}}>
          <Grid container>
            <Grid item xs={12} className={classes.inner_wrapper}>
              <Typography variant="h3" className={classes.component_title}>{t("components.contact.title")}</Typography>

              {this.props.login && this.props.login.is_exception && <Typography>{t("contact_argenta_message")}</Typography>}
              {(!this.props.login || (this.props.login && !this.props.login.is_exception)) && <Grid container sx={{marginTop: 3}} spacing={2}>
                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
                <Grid item xs={12} sm={6} md={3}>
                  <StyledTextField
                    label={t("contact_field_first_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.contact.first_name}
                    onChange={(v) => {this.setState({contact: {...this.state.contact, first_name: v}});}}
                   />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StyledTextField
                    label={t("contact_field_last_name").replace(/<[^>]*>?/gm, '')}
                    value={this.state.contact.last_name}
                    onChange={(v) => {this.setState({contact: {...this.state.contact, last_name: v}});}}
                   />
                </Grid>
                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>

                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
                <Grid item xs={12} sm={6} md={3}>
                  <StyledTextField
                    label={t("contact_field_email").replace(/<[^>]*>?/gm, '')}
                    value={this.state.contact.email}
                    onChange={(v) => {this.setState({contact: {...this.state.contact, email: v}});}}
                   />
                </Grid>
                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>

                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
                <Grid item xs={12} sm={6} md={3}>
                  <StyledTextField
                    label={t("contact_field_reason").replace(/<[^>]*>?/gm, '')}
                    value={this.state.contact.reason}
                    onChange={(v) => {this.setState({contact: {...this.state.contact, reason: v}});}}
                   />
                </Grid>
                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>

                <Grid item xs={12}>&nbsp;</Grid>

                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    label={t("contact_field_message").replace(/<[^>]*>?/gm, '')}
                    value={this.state.contact.message}
                    onChange={(v) => {this.setState({contact: {...this.state.contact, message: v}});}}
                    multiline
                    rows={4}
                   />
                </Grid>
                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>

                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}></Box>
                <Grid item xs={12} md={6} sx={{textAlign: 'right'}}>
                  <StyledButton text={t("contact_cta_send").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} />
                  <Box display={{xs: "block", md: 'none'}} sx={{marginTop: 2}}>
                    <StyledButton text={t("menu.death").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} secondary />
                  </Box>
                </Grid>
                <Box component={Grid} item md={3} display={{xs: "none", md: 'block'}}>
                  <StyledButton text={t("menu.death").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} secondary onClick={() => {this.props.history.push({pathname: '/overlijden'}); window.scrollTo(0, 0);}} />
                </Box>
              </Grid>}

            </Grid>
          </Grid>
        </Container>
        <Box className={classes.parallax_divider} style={{backgroundImage: `url(${this.state.parallax_source})`}}>
          <Box className={classes.parallax_child} />
        </Box>
      </Box>} */}

      <Box className={classes.footer_wrapper}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} textAlign="left">
              <img src={ArgencoTree} style={{
                maxHeight: '150px',
                maxWidth: '100%'
              }} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h3" className={classes.footer_title}>{t("footer.stock.title")}</Typography>
              {this.clickable_typo('/stock/why', 'about.stock.why.title')}
              {this.clickable_typo('/stock/how', 'about.stock.how.title')}
              {this.clickable_typo('/about/history_mission', 'about.stock.previous_emissions.title')}
              {this.clickable_typo('/prospectus', 'about.stock.prospectus.title')}
              {/* <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/stock/why', state: {}}); window.scrollTo(0, 0);}}>{t("about.stock.why.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/stock/how', state: {}}); window.scrollTo(0, 0);}}>{t("about.stock.how.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/about/history_mission', state: {}}); window.scrollTo(0, 0);}}>{t("about.stock.previous_emissions.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/emission', state: {}}); window.scrollTo(0, 0);}}>{t("about.stock.prospectus.title")}</Typography> */}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h3" className={classes.footer_title}>{t("footer.general.title")}</Typography>
              {this.clickable_typo('/about/documents', 'about.general.statutes.title')}
              {this.clickable_typo('/about/documents', 'about.general.internal_rules.title')}
              {this.clickable_typo('/about/documents', 'about.general.jaarverslag.title')}
              {/* <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/about/documents', state: {}}); window.scrollTo(0, 0);}}>{t("about.general.statutes.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/about/documents', state: {}}); window.scrollTo(0, 0);}}>{t("about.general.internal_rules.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/about/documents', state: {}}); window.scrollTo(0, 0);}}>{t("about.general.jaarverslag.title")}</Typography> */}
              {/* <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/stock/prospectus', state: {}}); window.scrollTo(0, 0);}}>{t("about.general.previous_emissions.title")}</Typography> */}
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h3" className={classes.footer_title}>{t("footer.faq.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/faq', state: {active: 'who_can_buy_stock'}}); window.scrollTo(0, 0);}}>{t("faq.who_can_buy_stock.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/faq', state: {active: 'need_to_know'}}); window.scrollTo(0, 0);}}>{t("faq.need_to_know.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/faq', state: {active: 'av_and_div'}}); window.scrollTo(0, 0);}}>{t("faq.av_and_div.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/faq', state: {active: 'online_data'}}); window.scrollTo(0, 0);}}>{t("faq.online_data.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/faq', state: {active: 'benefits'}}); window.scrollTo(0, 0);}}>{t("faq.benefits.title")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {this.props.history.push({pathname: '/faq', state: {active: 'transfer_stock'}}); window.scrollTo(0, 0);}}>{t("faq.transfer_stock.title")}</Typography>
            </Grid> */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h3" className={classes.footer_title}>{t("footer.contact.title")}</Typography>
              <PurifiedTypo text={t("footer.contact.content")} sx={{color: 'white', fontSize: '0.8rem'}} />
              {/* <Typography variant="h5" className={classes.footer_action} onClick={() => {window.open("https://www.facebook.com/argencocv", "_BLANK");}}>{t("general.actions.follow_us_fb")}</Typography>
              <Typography variant="h5" className={classes.footer_action} onClick={() => {window.open("https://www.instagram.com/argencocv", "_BLANK");}}>{t("general.actions.follow_us_ig")}</Typography> */}
            </Grid>

            {/* <Grid item xs={6} sx={{marginTop: 1, textAlign: {xs: 'left', md: 'right'}}}>
              <Typography variant="h5" className={classes.footer_action} sx={{display: 'inline-block'}} onClick={() => {this.props.i18n.changeLanguage('nl', () => {document.location.reload();}); window.scrollTo(0, 0);}}>{t("footer.languages.dutch")}</Typography>
            </Grid>
            <Grid item xs={6} sx={{marginTop: 1}}>
              <Typography variant="h5" className={classes.footer_action} sx={{display: 'inline-block'}} onClick={() => {this.props.i18n.changeLanguage('fr', () => {document.location.reload();}); window.scrollTo(0, 0);}}>{t("footer.languages.french")}</Typography>
            </Grid> */}

            <Grid item xs={12} align="right" sx={{marginTop: 2}}>
              <img src={SocialFacebook} style={{
                  color: this.props.theme.palette.tertiary.main,
                  cursor: 'pointer',
                  fontSize: '3rem',
                  marginRight: 16
                }}
                onMouseDown={(e) => {window.open("https://www.facebook.com/argencocv", "_BLANK");}}
                onAuxClick={(e) => {window.open("https://www.facebook.com/argencocv", "_BLANK");}}
                onClick={(e) => {window.open("https://www.facebook.com/argencocv", "_BLANK");}}
              />
              <img src={SocialLinkedin} style={{
                  color: this.props.theme.palette.tertiary.main,
                  cursor: 'pointer',
                  fontSize: '3rem',
                  marginRight: 16
                }}
                onMouseDown={(e) => {window.open("https://www.linkedin.com/company/argen-co/", "_BLANK");}}
                onAuxClick={(e) => {window.open("https://www.linkedin.com/company/argen-co/", "_BLANK");}}
                onClick={(e) => {window.open("https://www.linkedin.com/company/argen-co/", "_BLANK");}}
              />
              <img src={SocialInstagram} style={{
                  color: this.props.theme.palette.tertiary.main,
                  cursor: 'pointer',
                  fontSize: '3rem'
                }}
                onMouseDown={(e) => {window.open("https://www.instagram.com/argencocv", "_BLANK");}}
                onAuxClick={(e) => {window.open("https://www.instagram.com/argencocv", "_BLANK");}}
                onClick={(e) => {window.open("https://www.instagram.com/argencocv", "_BLANK");}}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{paddingTop: 1, paddingBottom: 1, textAlign: 'center'}}>
        <Container sx={{paddingLeft: 0, paddingRight: 0}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{display: 'inline-block'}}>&copy; Argen-Co {DateTime.now().year}</Typography>
              &nbsp;&nbsp;-&nbsp;&nbsp;
              <Typography
                variant="h5"
                sx={{cursor: 'pointer', display: 'inline-block'}}
                onMouseDown={(e) => {window.open(t("general.urls.disclaimer").replace(/<[^>]*>?/gm, ''), "_BLANK");}}
                onAuxClick={(e) => {window.open(t("general.urls.disclaimer").replace(/<[^>]*>?/gm, ''), "_BLANK");}}
                onClick={(e) => {window.open(t("general.urls.disclaimer").replace(/<[^>]*>?/gm, ''), "_BLANK");}}
              >{t("footer.disclaimer.title")}</Typography>
              &nbsp;&nbsp;-&nbsp;&nbsp;
              <Typography
                variant="h5"
                sx={{cursor: 'pointer', display: 'inline-block'}}
                onMouseDown={(e) => {window.open(t("general.urls.privacy").replace(/<[^>]*>?/gm, ''), "_BLANK");}}
                onAuxClick={(e) => {window.open(t("general.urls.privacy").replace(/<[^>]*>?/gm, ''), "_BLANK");}}
                onClick={(e) => {window.open(t("general.urls.privacy").replace(/<[^>]*>?/gm, ''), "_BLANK");}}
              >{t("footer.privacy.title")}</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>);
  }
}

const styles = theme => ({
  footer_wrapper: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3)
  },
  // news_wrapper: {
  //   paddingTop: theme.spacing(2)
  // },
  // benefits_wrapper: {
  //   paddingTop: theme.spacing(2)
  // },
  // about_wrapper: {
  //   paddingTop: theme.spacing(2)
  // },
  // contact_wrapper: {
  //   paddingTop: theme.spacing(2)
  // },
  // parallax_divider: {
  //   backgroundImage: 'url(https://old.argenco.be/support/assets/744)',
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   backgroundAttachment: 'fixed',
  //   marginTop: theme.spacing(2)
  // },
  // parallax_child: {
  //   backgroundColor: 'rgba(255, 255, 255, 0.8)',
  //   minHeight: 100
  // },
  // inner_wrapper: {
  //   paddingTop: theme.spacing(2),
  //   paddingBottom: theme.spacing(2)
  // },
  footer_title: {
    color: 'white !important',
    textTransform: 'uppercase',
    fontWeight: '700 !important',
    marginBottom: `${theme.spacing(1)} !important`,
    [theme.breakpoints.up('md')]: {
      marginBottom: `${theme.spacing(2)} !important`
    }
  },
  footer_action: {
    color: 'white',
    cursor: 'pointer',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  // component_title: {
  //   textTransform: 'uppercase',
  //   fontWeight: '700 !important'
  // },
  // fancy_image_outer: {
  //   width: '100%',
  //   height: '100%',
  //   position: 'relative',
  //   overflow: 'hidden'
  // },
  // fancy_image_inner: {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   width: '100%',
  //   height: '100%',
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center'
  // }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Footer))));
