import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';

import {
  Box,
  LinearProgress,
  List
} from '@mui/material';

import PurifiedTypo from "../parts/PurifiedTypo";

class CustomStepper extends Component {

  render() {
    const {steps, current, theme} = this.props;

    let is_mobile = window.innerWidth < 900;

    return (<List sx={{
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-around",
      padding: 0,
      width: "100%"
    }}>
      {steps.map((step, i) => {return (<Box component="li" key={`stepper_step${i}`} sx={{
        display: (i === 0 || i === steps.length - 1 ? "flex" : {xs: 'none', md: 'flex'}),
        position: "relative",
        flexFlow: "row nowrap",
        justifyContent: "space-around",
        alignItems: "baseline",
        width: "100%"
      }}>
        <Box sx={{
          display: 'flex',
          flexFlow: "column nowrap",
          alignItems: "center"
        }}>
          <Box component="span" sx={{
            backgroundColor: (i <= current ? theme.palette.primary.main : "#dedede"),
            color: (i <= current ? "#fff" : "#999"),
            fontWeight: (i <= current ? 700 : 400),
            width: "30px",
            height: "30px",
            lineHeight: "30px",
            borderRadius: "50%",
            textAlign: "center",
            marginBottom: "5px"
          }}>{i < current ? "✓" : i + 1}</Box>
          <PurifiedTypo sx={{
            color: "#999",
            margin: 0,
            textAlign: 'center'
          }} text={step} />
        </Box>
        {!!i && <LinearProgress variant="determinate" value={(is_mobile ? current/steps.length*100 : (current >= i ? 100 : 0))} sx={{
          position: 'absolute',
          display: i === steps.length - 1 ? "flex" : {xs: 'none', md: 'flex'},
          flex: "1 1 auto",
          top: 12,
          left: 'calc(-50% + 20px)',
          right: 'calc(50% + 20px)'
        }} />}
      </Box>);})}
    </List>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(CustomStepper));
