import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Grid
} from '@mui/material';

class BlockSingleImage extends Component {
  render() {
    const {block, theme, locale} = this.props;

    return (<React.Fragment>
      {!!block.extra_data?.image_link && <Grid item xs={12} sx={{
        paddingRight: {xs: 0, md: 3},
        paddingTop: {xs: 0, md: '1em'},
        marginTop: block.settings?.mtop || 0,
        marginBottom: block.settings?.mbot || 0
      }}>
        <img src={block.extra_data.image_link[locale]} style={{maxWidth: '100%'}} />
      </Grid>}
    </React.Fragment>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(BlockSingleImage));
