import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from '../../shared/parts/PurifiedTypo';
import StyledButton from '../../shared/parts/StyledButton';
import StyledTextField from '../../shared/parts/StyledTextField';
import Typography from '../../shared/parts/Typography';

class StepAuthEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state !== prevProps.state) {
      this.setState(this.props.state);
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Grid container>
      <Grid item xs={1} sm={2} md={4}></Grid>
      <Grid item container xs={10} sm={8} md={4} sx={{minHeight: '300px', paddingLeft: {xs: 0, md: 1.5}}}>
        <Grid item xs={12} sx={{marginTop: 4, marginBottom: 1}}>
          <Typography variant="h3">{t("first_time_step_2_message_welcome").replace(/<[^>]*>?/gm, '')} {this.state.user.full_name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <PurifiedTypo text={t("first_time_step_2_message_welcome_description").replace(/<[^>]*>?/gm, '')} />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            label={t("index_field_username").replace(/<[^>]*>?/gm, '')}
            value={this.state.user.username}
            id="usrname"
            onChange={(v) => {this.setState({user: {...this.state.user, username: v}}, () => {this.props.onChangeState(this.state)});}}
            sx={{marginTop: 4}}
            error={this.state.errors.username || this.state.errors.username_exists}
            helperText={this.state.errors.username_exists ? t("first_time_step_step_1_error_username_not_unique").replace(/<[^>]*>?/gm, '') : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            label={t("index_field_password").replace(/<[^>]*>?/gm, '')}
            value={this.state.user.password}
            id="pword"
            onChange={(v) => {this.setState({user: {...this.state.user, password: v}}, () => {this.props.onChangeState(this.state)});}}
            sx={{marginTop: 2}}
            error={this.state.errors.password}
            onPwdVisibility={(visible) => {this.setState({show_password: visible}, () => {this.props.onChangeState(this.state)});}}
            type={this.state.show_password ? "text" : "password"}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            label={t("first_time_step_2_field_redo_password").replace(/<[^>]*>?/gm, '')}
            value={this.state.user.repeat_password}
            id="repeat_password"
            onChange={(v) => {this.setState({user: {...this.state.user, repeat_password: v}}, () => {this.props.onChangeState(this.state)});}}
            sx={{marginTop: 2}}
            error={this.state.errors.repeat_password}
            onPwdVisibility={(visible) => {this.setState({show_password: visible}, () => {this.props.onChangeState(this.state)});}}
            type={this.state.show_password ? "text" : "password"}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledButton
            disabled={(this.state.user.username && this.state.user.username.length === 0) || (this.state.user.password && this.state.user.password.length === 0) || (this.state.user.repeat_password && this.state.user.repeat_password.length === 0) || this.state.user.password !== this.state.user.repeat_password}
            text={t("first_time_step_2_button_continue").replace(/<[^>]*>?/gm, '')}
            theme={this.props.theme}
            sx={{float: 'right', marginTop: this.props.theme.spacing(4)}}
            onClick={(e) => {this.props.OnCheckLogin(e);}}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledButton text={t("first_time_step_2_button_back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{float: 'right', marginTop: this.props.theme.spacing(4)}} secondary onClick={(e) => {this.setState({first_time_step: 0}, () => {this.props.onChangeState(this.state)});}} />
        </Grid>
      </Grid>
      <Grid item xs={1} sm={2} md={4}></Grid>
    </Grid>);
  }
}

StepAuthEdit.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(StepAuthEdit))));
