import { connect } from 'react-redux';
import Benefit from '../../actions/benefit';
import BenefitTag from '../../actions/benefits_tag';
import Benefits from '../../components/pages/Benefits';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      benefits: {
        fetch_all: (_params) => {
          dispatch(Benefit.fetch_all(_params));
        }
      },
      benefits_tag: {
        fetch_all: (_params) => {
          dispatch(BenefitTag.fetch_all(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Benefits);
