import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';

import {MenuItem as OriginalMenuItem} from '@mui/material';

class MenuItem extends Component {
  render() {
    return (<OriginalMenuItem
      {...this.props}
      onAuxClick={(e) => {this.props.onClick(e);}}
      onMouseDown={(e) => {if (e.button == 1) this.props.onClick(e);}}
    />);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(MenuItem));
