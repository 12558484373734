import Investment from '../actions/investment';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function investment(state = initial, action) {
  return Investment.state_switch(state, action);
}
