import SecurityQuestion from '../actions/security_question';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function security_question(state = initial, action) {
  return SecurityQuestion.state_switch(state, action);
}
