import { connect } from 'react-redux';
// import Devise from '../../actions/devise';
import {default as MeetingAction} from '../../actions/meeting';
import Meeting from '../../components/notifications/Meeting';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      meetings: {
        fetch_all: _params => {
          dispatch(MeetingAction.fetch_all(_params));
        }
      }
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Meeting);
