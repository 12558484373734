import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter, Link} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Box,
  Divider,
  Typography
} from '@mui/material';

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    this.scrollRef.current.scrollTop = 0;
  }

  render() {
    const {classes, t, children, style, title, actions, crumbs} = this.props;

    let action_list = [];
    if (!!actions) {
      actions.map((action) => action_list.push(<Link to={{pathname: `${action.path}`, state: action.pass_state}}>{this.props.t(...action.name)}</Link>));
    }

    let crumb_list = [];
    if (!!crumbs) {
      crumb_list.push(<Link to={{pathname: "/", state: {}}}>{t("menu_home", "Dashboard")}</Link>);
      crumbs.map((crumb) => crumb_list.push(<Link to={crumb[0]}>{crumb[1]}</Link>));
    }

    return (<div className={classes.page} style={!!this.props.banner_image ? {minHeight: 400, height: 'auto'} : {}}>
      {this.props.banner_image && <Box sx={{backgroundImage: this.props.banner_image, height: {xs: '300px', md: 'calc(100% - 30px)'}}} className={classes.banner}></Box>}
      <div className={classNames(classes.container, this.props.flex_override ? 'flex_override' : '')} style={{minHeight: '600px', ...style}} ref={this.scrollRef} id="main_scroller">
        {
          !!crumbs && (<React.Fragment>
            <Typography variant={"subtitle1"} gutterBottom={true} className={classNames(classes.breadcrumb, 'breadcrumb')}>{crumb_list.reduce((a, b) => [a, ' / ', b])}</Typography>
          </React.Fragment>)
        }
        {
          !!title && (<React.Fragment>
            <Typography variant={"h1"} gutterBottom={true}>{title}</Typography>
            <Divider light={true} className={classes.divider}></Divider>
            {
              !!action_list && (<React.Fragment>{action_list}</React.Fragment>)
            }
          </React.Fragment>)
        }
        {children}
      </div>
    </div>);
  }
}

Page.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  page: {
    flex: 1,
    display: "flex",
    position: "relative",
    padding: 0
  },
  container: {
    // display: "flex",
    flex: 1,
    paddingBottom: theme.spacing(3),
    overflowX: "hidden",
    overflowY: "auto",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    zIndex: 200
  },
  flex_override: {
    flexDirection: 'row !important',
    writingMode: 'vertical-lr !important',
    '& > *': {
      writingMode: 'horizontal-tb !important'
    }
  },
  divider: {
    marginBottom: theme.spacing(1.5)
  },
  breadcrumb: {
    [theme.breakpoints.up('md')]: {
      fontSize: 10,
      marginBottom: 16
    }
  },
  banner: {
    marginTop: theme.spacing(3),
    width: '100%',
    height: 400,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    zIndex: 20
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Page))));
