import { connect } from 'react-redux';
import Devise from '../../actions/devise';
import Onboarding from '../../components/pages/Onboarding';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {

    },
    check_vennr: _params => {
      dispatch(Devise.check_vennr(_params));
    },
    first_time_set_contact: _params => {
      dispatch(Devise.first_time_set_contact(_params));
    },
    first_time_set_email: _params => {
      dispatch(Devise.first_time_set_email(_params));
    },
    first_time_set_login: _params => {
      dispatch(Devise.first_time_set_login(_params));
    },
    do_login: _params => {
      dispatch(Devise.sign_in(_params));
    },
    do_register: _params => {
      dispatch(Devise.sign_up(_params));
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(Onboarding);
