import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Typography
} from '@mui/material';

import {DateTime} from "luxon";
import QRCode from "react-qr-code";

import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import BankAccount from "../shared/parts/BankAccount";
import CountryPicker from "../shared/parts/CountryPicker";
import Meeting from "../../containers/notifications/Meeting";
import OtpField from "../shared/parts/onboarding/OtpField";
import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import Security from "../notifications/Security";
import StyledButton from "../shared/parts/StyledButton";
import StyledTextField from "../shared/parts/StyledTextField";
import Typography from "../shared/parts/Typography";
import VisualAmount from "../shared/parts/VisualAmount";

const queryString = require('query-string');
var he = require('he');

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        first_name: "",
        last_name: "",
        birth_date: "",
        zicht_account: "",
        username: "",
        street: "",
        number: "",
        zip: "",
        city: "",
        country: "",
        cor_street_2: "",
        cor_street: "",
        cor_number: "",
        cor_zip: "",
        cor_city: "",
        cor_country: "",
        phone: "",
        mobile: "",
        files_fiscal: [],
        files_register: [],
        files_divcalc: [],
        list_transactions: [],
        mfa_status: 0,
        sec_q_status: 0,
        ...this.props.login},
      errors: {},
      editing: false,
      has_cor: false,
      wants_cor: false,
      edit_address: false,
      edit_cor: false,
      fiscal: false,
      register: false,
      divcalc: false,
      trans_page: 0,
      trans_page_size: 5,
      dialog_shares_sell: false,
      dialog_shares_buy: false,
      security_questions: []
    };

    this.sec_ref = React.createRef();
  }

  componentDidMount() {
    this.props.renew_sign_in({
      locale: this.props.i18n.language,
      login: this.props.login,
      parameters: {
        fields: ['id', 'first_name', 'last_name', 'email', 'auth_token', "type", "is_admin", "vennoot_number", 'address', 'stock_number', 'share_type|tag', 'birth_date', 'stock_id', 'phone', 'mobile', 'zicht_account', 'street', 'number', 'zip', 'city']
      },
      callback: (r, full_data) => {
        // this.setState({user: r, changes: r});
      }
    });

    this.fetch_security_questions();
    this.props.helpers.user.fetch_data({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (r) => {
        this.setState({user: r, old_mfa_status: r.mfa_status, old_sec_q_status: r.sec_q_status}, () => {
          if (r.cor_street && r.cor_number && r.cor_zip && r.cor_city) this.setState({has_cor: true});
        });
      },
      parameters: {
        fields: [
          'id', 'first_name', 'last_name', 'birth_date', 'zicht_account',
          'username', 'street', 'number', 'zip', 'city',
          'country', 'cor_street', 'cor_number', 'cor_zip', 'cor_city',
          'cor_country', 'phone', 'mobile', 'email', 'files_fiscal',
          'files_register', 'files_divcalc', 'list_transactions', 'emission_active', 'commercial_mail_enabled',
          'auth_token', 'type', 'is_admin', 'vennoot_number', 'cor_street_2', 'mfa_status', 'otp_provisioning', 'sec_q_status',
          'sq1_id', 'sq1_answer', 'sq2_id', 'sq2_answer', 'sq3_id', 'sq3_answer', 'brief_exit_data'
        ]
      }
    });
  }

  fetch_security_questions = () => {
    this.setState({loader: true}, () => {
      this.props.helpers.security_questions.fetch_all({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r, full_data) => {
          this.setState({security_questions: r, loader: false});
        },
        parameters: {
          per_page: 1000
        }
      });
    });
  }

  save_profile(event) {
    event.preventDefault();

    let errors = {};
    // if (typeof(this.state.user.first_name) === 'undefined' || this.state.user.first_name === null || this.state.user.first_name.trim() === "") errors.first_name = true;
    // if (typeof(this.state.user.last_name) === 'undefined' || this.state.user.last_name === null || this.state.user.last_name.trim() === "") errors.last_name = true;
    if (typeof(this.state.user.zicht_account) === 'undefined' || this.state.user.zicht_account === null || this.state.user.zicht_account.trim() === "") errors.zicht_account = true;
    if (typeof(this.state.user.street) === 'undefined' || this.state.user.street === null || this.state.user.street.trim() === "") errors.street = true;
    if (typeof(this.state.user.number) === 'undefined' || this.state.user.number === null || this.state.user.number.trim() === "") errors.number = true;
    if (typeof(this.state.user.zip) === 'undefined' || this.state.user.zip === null || this.state.user.zip.trim() === "") errors.zip = true;
    if (typeof(this.state.user.city) === 'undefined' || this.state.user.city === null || this.state.user.city.trim() === "") errors.city = true;
    if (typeof(this.state.user.country) === 'undefined' || this.state.user.country === null || this.state.user.country.trim() === "") errors.country = true;

    if (Object.keys(errors).length === 0) {
      this.props.helpers.user.save({
        locale: this.props.i18n.language,
        login: this.props.login,
        callback: (r) => {
          this.setState({editing: !this.state.editing, return_value: r}, () => {
            if (r.cor_street && r.cor_number && r.cor_zip && r.cor_city && r.cor_country) this.setState({has_cor: true});
          });
        },
        parameters: {
          fields: [
            'id', 'first_name', 'last_name', 'birth_date', 'zicht_account',
            'username', 'street', 'number', 'zip', 'city',
            'country', 'cor_street', 'cor_number', 'cor_zip', 'cor_city',
            'cor_country', 'phone', 'mobile', 'email', 'files_fiscal',
            'files_register', 'files_divcalc', 'list_transactions', 'emission_active', 'commercial_mail_enabled',
            'auth_token', 'type', 'is_admin', 'vennoot_number', 'cor_street_2', 'mfa_status', 'otp_provisioning', 'sec_q_status',
            'sq1_id', 'sq1_answer', 'sq2_id', 'sq2_answer', 'sq3_id', 'sq3_answer'
          ],
          user: this.state.user
        }
      });
    } else {
      this.setState({errors: errors});
    }
  }

  save_security_questions(event, pars) {
    event.preventDefault();

    this.props.helpers.user.save({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (r) => {
        this.setState({old_sec_q_status: r.sec_q_status});
      },
      parameters: {
        fields: [
          'id', 'first_name', 'last_name', 'birth_date', 'zicht_account',
          'username', 'street', 'number', 'zip', 'city',
          'country', 'cor_street', 'cor_number', 'cor_zip', 'cor_city',
          'cor_country', 'phone', 'mobile', 'email', 'files_fiscal',
          'files_register', 'files_divcalc', 'list_transactions', 'emission_active', 'commercial_mail_enabled',
          'auth_token', 'type', 'is_admin', 'vennoot_number', 'cor_street_2', 'mfa_status', 'otp_provisioning', 'sec_q_status',
          'sq1_id', 'sq1_answer', 'sq2_id', 'sq2_answer', 'sq3_id', 'sq3_answer'
        ],
        user: pars
      }
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let list_transactions_paginated = (this.state.user.list_transactions || []).reduce((acc, val, i) => {
      let idx = Math.floor(i / this.state.trans_page_size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);
      return acc;
    }, []);
    let transaction_types = {
      "Transaction::Bought": t("profile.my_transactions.types.bought").replace(/<[^>]*>?/gm, ''),
      "Transaction::Sold": t("profile.my_transactions.types.sold").replace(/<[^>]*>?/gm, ''),
      "Transaction::TransferBought": t("profile.my_transactions.types.transfer_bought").replace(/<[^>]*>?/gm, ''),
      "Transaction::TransferSold": t("profile.my_transactions.types.transfer_sold").replace(/<[^>]*>?/gm, ''),
      "Transaction::Conversion": t("profile.my_transactions.types.conversion").replace(/<[^>]*>?/gm, '')
    };

    const parsed = queryString.parse(this.props.location.search);

    let exit_started = false;
    let docs_uploaded = false;
    let argenco_checked = false;
    if (!!this.state.user?.brief_exit_data) {
      if (Object.keys(this.state.user.brief_exit_data).length > 0) {
        exit_started = this.state.user.brief_exit_data.exit_active;
        if (!!this.state.user.brief_exit_data.exit_itsme) {
          exit_started = !!this.state.user.brief_exit_data.exit_itsme_success
        }

        docs_uploaded = !!this.state.user.brief_exit_data.item_url && this.state.user.brief_exit_data.item_url.indexOf("missing.png") < 0;
        if (!!this.state.user.brief_exit_data.exit_itsme_success) {
          docs_uploaded = true;
        }

        argenco_checked = this.state.user.brief_exit_data.checked;
      }
    }

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Meeting open={!!parsed.open_meeting && parseInt(parsed.open_meeting, 10) === 1} />
      <Security state={this.state} scrollRef={this.sec_ref} />

      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Typography variant="h3" className={classes.component_title}>{t("profile.title")}</Typography>

        <Grid container spacing="2" style={{marginTop: this.props.theme.spacing(2)}}>
          <Grid item xs={12} md={5} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 100}}>
            <Typography variant="h4">{t("profile.my_data.title")}</Typography>

            {!this.state.editing && <Grid container spacing="4" style={{marginTop: this.props.theme.spacing(2), paddingLeft: this.props.theme.spacing(3), paddingRight: this.props.theme.spacing(3)}}>
              <Grid item xs={12} md={6}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.field.first_name")}</Typography>
                <Typography>{this.state.user.first_name || "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.field.last_name")}</Typography>
                <Typography>{this.state.user.last_name || "-"}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.field.birth_date")}</Typography>
                <Typography>{DateTime.fromISO(this.state.user.birth_date).setLocale(this.props.i18n.language).toLocaleString(DateTime.DATE_SHORT) || "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}></Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.field.zicht_account")}</Typography>
                <Typography>{this.state.user.zicht_account || "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}></Grid>

              <Grid item xs={12} md={12}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.field.email")}</Typography>
                <Typography>{this.state.user.email || "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.field.username")}</Typography>
                <Typography>{this.state.user.username || "-"}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.address")}</Typography>
                <Typography>
                  {this.state.user.street || "-"} {this.state.user.number || "-"}<br />
                  {this.state.user.zip || "-"} {this.state.user.city || "-"}<br />
                  {!(!!this.state.user.country && this.state.user.country === "BE") && <React.Fragment>{this.state.user.country || "-"}<br /></React.Fragment>}
                </Typography>
              </Grid>

              {this.state.has_cor && <Grid item xs={12}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.cor_address")}</Typography>
                <Typography>
                  {!!this.state.user.cor_street_2 && <React.Fragment>{this.state.user.cor_street_2}<br /></React.Fragment>}
                  {this.state.user.cor_street || "-"} {this.state.user.cor_number || "-"}<br />
                  {this.state.user.cor_zip || "-"} {this.state.user.cor_city || "-"}<br />
                  {!(!!this.state.user.cor_country && this.state.user.cor_country === "BE") && <React.Fragment>{this.state.user.cor_country || "-"}<br /></React.Fragment>}
                </Typography>
              </Grid>}

              {!!this.state.user.phone && <Grid item xs={12} md={6}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.field.phone")}</Typography>
                <Typography>{this.state.user.phone || "-"}</Typography>
              </Grid>}
              {!!this.state.user.mobile && <Grid item xs={12} md={6}>
                <Typography variant="caption" sx={{color: this.props.theme.palette.tertiary.main, fontWeight: 'bold'}}>{t("profile.my_data.field.mobile")}</Typography>
                <Typography>{this.state.user.mobile || "-"}</Typography>
              </Grid>}
            </Grid>}

            {!!this.state.editing && <Grid container spacing="4" style={{marginTop: this.props.theme.spacing(2), paddingLeft: this.props.theme.spacing(3), paddingRight: this.props.theme.spacing(3)}}>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">{t("profile.my_data.field.first_name")}</Typography>
                <Typography>{this.state.user.first_name || "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">{t("profile.my_data.field.last_name")}</Typography>
                <Typography>{this.state.user.last_name || "-"}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="caption">{t("profile.my_data.field.birth_date")}</Typography>
                <Typography>{this.state.user.birth_date || "-"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}></Grid>

              <Grid item xs={12}>
                <BankAccount
                  onChange={(e) => {this.setState({user: {...this.state.user, zicht_account: e.target.value}});}}
                  value={this.state.user.zicht_account}
                  label={t("profile.my_data.field.zicht_account").replace(/<[^>]*>?/gm, '')}
                  id="zicht_account"
                  style={{marginTop: this.props.theme.spacing(2)}}
                  error={this.state.errors.zicht_account}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledTextField
                  label={t("profile.my_data.field.email").replace(/<[^>]*>?/gm, '')}
                  value={this.state.user.email}
                  id="email"
                  onChange={(v) => {this.setState({user: {...this.state.user, email: v}});}}
                  style={{marginTop: this.props.theme.spacing(2)}}
                  error={this.state.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">{t("profile.my_data.field.username")}</Typography>
                <Typography>{this.state.user.username || "-"}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption" style={{marginTop: this.props.theme.spacing(2)}}>{t("profile.my_data.address")}</Typography>
                {!this.state.edit_address && <Typography>
                  {this.state.user.street || "-"} {this.state.user.number || "-"}<br />
                  {this.state.user.zip || "-"} {this.state.user.city || "-"}<br />
                  {!(!!this.state.user.country && this.state.user.country === "BE") && <React.Fragment>{this.state.user.country || "-"}<br /></React.Fragment>}
                </Typography>}
                {!this.state.edit_address && <StyledButton
                  secondary
                  as_link
                  startIcon={<EditIcon />}
                  text={t("profile.my_data.edit_address").replace(/<[^>]*>?/gm, '')}
                  theme={this.props.theme}
                  style={{paddingLeft: 0, paddingRight: 0}}
                  onClick={(e) => {this.setState({edit_address: true});}}
                />}
              </Grid>
              {!!this.state.edit_address && <React.Fragment>
                <Grid item xs={8}>
                  <StyledTextField
                    label={t("profile.my_data.field.street").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.street}
                    id="street"
                    onChange={(v) => {this.setState({user: {...this.state.user, street: v}});}}
                    style={{marginTop: this.props.theme.spacing(0.5)}}
                    error={this.state.errors.street}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label={t("profile.my_data.field.number").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.number}
                    id="number"
                    onChange={(v) => {this.setState({user: {...this.state.user, number: v}});}}
                    style={{marginTop: this.props.theme.spacing(0.5)}}
                    error={this.state.errors.number}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledTextField
                    label={t("profile.my_data.field.zip").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.zip}
                    id="zip"
                    onChange={(v) => {this.setState({user: {...this.state.user, zip: v}});}}
                    style={{marginTop: this.props.theme.spacing(0.5)}}
                    error={this.state.errors.zip}
                  />
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    label={t("profile.my_data.field.city").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.city}
                    id="city"
                    onChange={(v) => {this.setState({user: {...this.state.user, city: v}});}}
                    style={{marginTop: this.props.theme.spacing(0.5)}}
                    error={this.state.errors.city}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CountryPicker
                    label={t("profile.my_data.field.country").replace(/<[^>]*>?/gm, '')}
                    value={this.state.user.country}
                    id="country"
                    onChange={(v) => {this.setState({user: {...this.state.user, country: v.target.value}});}}
                    style={{marginTop: this.props.theme.spacing(0.5)}}
                    error={this.state.errors.country}
                  />
                </Grid>
              </React.Fragment>}

              {!this.state.has_cor && !this.state.wants_cor && <React.Fragment>
                <Grid item xs={12}>
                  <StyledButton
                    secondary
                    text={t("profile.my_data.add_cor_address").replace(/<[^>]*>?/gm, '')}
                    theme={this.props.theme}
                    style={{marginTop: this.props.theme.spacing(2)}}
                    onClick={(e) => {this.setState({wants_cor: true});}}
                  />
                </Grid>
              </React.Fragment>}
              {(!!this.state.has_cor || this.state.wants_cor) && <React.Fragment>
                <Grid item xs={12}>
                  <Typography variant="caption" style={{marginTop: this.props.theme.spacing(2)}}>{t("profile.my_data.cor_address")}</Typography>
                  {!!this.state.has_cor && !this.state.edit_cor && <Typography>
                    {!!this.state.user.cor_street_2 && <React.Fragment>{this.state.user.cor_street_2}<br /></React.Fragment>}
                    {this.state.user.cor_street || "-"} {this.state.user.cor_number || "-"}<br />
                    {this.state.user.cor_zip || "-"} {this.state.user.cor_city || "-"}<br />
                    {!(!!this.state.user.cor_country && this.state.user.cor_country === "BE") && <React.Fragment>{this.state.user.cor_country || "-"}<br /></React.Fragment>}
                  </Typography>}
                  {!!this.state.has_cor && !this.state.edit_cor && <StyledButton
                    secondary
                    as_link
                    startIcon={<EditIcon />}
                    text={t("profile.my_data.edit_cor").replace(/<[^>]*>?/gm, '')}
                    theme={this.props.theme}
                    style={{paddingLeft: 0, paddingRight: 0}}
                    onClick={(e) => {this.setState({edit_cor: true});}}
                  />}
                </Grid>
                {((!!this.state.has_cor && !!this.state.edit_cor) || this.state.wants_cor) && <React.Fragment>
                  <Grid item xs={12}>
                    <StyledTextField
                      label={t("profile.my_data.field.cor_street_2").replace(/<[^>]*>?/gm, '')}
                      value={this.state.user.cor_street_2}
                      id="cor_street_2"
                      onChange={(v) => {this.setState({user: {...this.state.user, cor_street_2: v}});}}
                      style={{marginTop: this.props.theme.spacing(0.5)}}
                      error={this.state.errors.cor_street_2}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <StyledTextField
                      label={t("profile.my_data.field.cor_street").replace(/<[^>]*>?/gm, '')}
                      value={this.state.user.cor_street}
                      id="cor_street"
                      onChange={(v) => {this.setState({user: {...this.state.user, cor_street: v}});}}
                      style={{marginTop: this.props.theme.spacing(0.5)}}
                      error={this.state.errors.cor_street}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledTextField
                      label={t("profile.my_data.field.cor_number").replace(/<[^>]*>?/gm, '')}
                      value={this.state.user.cor_number}
                      id="cor_number"
                      onChange={(v) => {this.setState({user: {...this.state.user, cor_number: v}});}}
                      style={{marginTop: this.props.theme.spacing(0.5)}}
                      error={this.state.errors.cor_number}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <StyledTextField
                      label={t("profile.my_data.field.cor_zip").replace(/<[^>]*>?/gm, '')}
                      value={this.state.user.cor_zip}
                      id="cor_zip"
                      onChange={(v) => {this.setState({user: {...this.state.user, cor_zip: v}});}}
                      style={{marginTop: this.props.theme.spacing(0.5)}}
                      error={this.state.errors.cor_zip}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <StyledTextField
                      label={t("profile.my_data.field.cor_city").replace(/<[^>]*>?/gm, '')}
                      value={this.state.user.cor_city}
                      id="cor_city"
                      onChange={(v) => {this.setState({user: {...this.state.user, cor_city: v}});}}
                      style={{marginTop: this.props.theme.spacing(0.5)}}
                      error={this.state.errors.cor_city}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CountryPicker
                      label={t("profile.my_data.field.cor_country").replace(/<[^>]*>?/gm, '')}
                      value={this.state.user.cor_country}
                      id="cor_country"
                      onChange={(v) => {this.setState({user: {...this.state.user, cor_country: v.target.value}});}}
                      style={{marginTop: this.props.theme.spacing(0.5)}}
                      error={this.state.errors.cor_country}
                    />
                  </Grid>
                </React.Fragment>}
              </React.Fragment>}

              <Grid item xs={12} md={6}>
                <StyledTextField
                  label={t("profile.my_data.field.phone").replace(/<[^>]*>?/gm, '')}
                  value={this.state.user.phone}
                  id="phone"
                  onChange={(v) => {this.setState({user: {...this.state.user, phone: v}});}}
                  style={{marginTop: this.props.theme.spacing(2)}}
                  error={this.state.errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTextField
                  label={t("profile.my_data.field.mobile").replace(/<[^>]*>?/gm, '')}
                  value={this.state.user.mobile}
                  id="mobile"
                  onChange={(v) => {this.setState({user: {...this.state.user, mobile: v}});}}
                  style={{marginTop: this.props.theme.spacing(2)}}
                  error={this.state.errors.mobile}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption" style={{marginTop: this.props.theme.spacing(2)}}>{t("profile.my_data.commercial_mail_enabled")}</Typography>
                <FormControlLabel
                  control={<Checkbox
                    checked={this.state.user.commercial_mail_enabled}
                    onChange={(v) => {this.setState({user: {...this.state.user, commercial_mail_enabled: v.target.checked}});}}
                  />}
                  label={t("profile.my_data.field.commercial_mail_enabled").replace(/<[^>]*>?/gm, '')}
                />
              </Grid>
            </Grid>}

            {!this.state.editing && <Grid item xs={12} align="right" style={{paddingLeft: this.props.theme.spacing(3), paddingRight: this.props.theme.spacing(3)}}>
              <StyledButton text={t("general.actions.edit").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {this.setState({editing: !this.state.editing});}} />
            </Grid>}

            {!!this.state.editing && <Grid item xs={12} align="right" style={{paddingLeft: this.props.theme.spacing(3), paddingRight: this.props.theme.spacing(3)}}>
              <StyledButton text={t("general.actions.cancel").replace(/<[^>]*>?/gm, '')} as_link theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2), marginRight: this.props.theme.spacing(2)}} onClick={(e) => {this.setState({editing: !this.state.editing}); window.scrollTo(0, 0);}} />
              <StyledButton text={t("general.actions.save").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {this.save_profile(e);}} />
            </Grid>}

          </Grid>
          <Grid item xs={12} md={7} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 100}} sx={{borderLeft: {xs: "none", md: "1px solid black"}, marginTop: {xs: this.props.theme.spacing(2), md: 0}}}>
            <Grid container sx={{paddingLeft: {xs: 0, md: this.props.theme.spacing(3)}, paddingRight: {xs: 0, md: this.props.theme.spacing(3)}}}>
              <Grid item xs={12}>
                <Typography variant="h4">{t("profile.my_docs.title")}</Typography>
                <Grid container spacing="4" style={{marginTop: this.props.theme.spacing(2)}}>
                  <Grid item xs={12} md={6} align="center">
                    <StyledButton text={t("profile.my_docs.fiscal").replace(/<[^>]*>?/gm, '')} as_link capitalise theme={this.props.theme} style={{}} onClick={(e) => {this.setState({fiscal: !this.state.fiscal, register: false, divcalc: false});}} endIcon={this.state.fiscal ? (<KeyboardArrowUpIcon />) : (<KeyboardArrowDownIcon />)} />
                  </Grid>
                  <Grid item xs={12} md={6} align="center">
                    <StyledButton text={t("profile.my_docs.register").replace(/<[^>]*>?/gm, '')} as_link capitalise theme={this.props.theme} style={{}} onClick={(e) => {this.setState({register: !this.state.register, fiscal: false, divcalc: false});}} endIcon={this.state.register ? (<KeyboardArrowUpIcon />) : (<KeyboardArrowDownIcon />)} />
                  </Grid>
                  <Grid item xs={12} md={4} align="center" sx={{display: 'none'}}>
                    <StyledButton text={t("profile.my_docs.divcalc").replace(/<[^>]*>?/gm, '')} as_link capitalise theme={this.props.theme} style={{}} onClick={(e) => {this.setState({divcalc: !this.state.divcalc, register: false, fiscal: false});}} endIcon={this.state.divcalc ? (<KeyboardArrowUpIcon />) : (<KeyboardArrowDownIcon />)} />
                  </Grid>

                  {this.state.fiscal && <Grid item xs={12} align="center">
                    <Typography variant="h4" sx={{display: {xs: 'block', md: 'none'}, textAlign: 'left'}}>{t("profile.my_docs.fiscal")}</Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{display: 'flex', flexDirection: {xs: 'row-reverse', md: 'row'}}}>
                            <TableCell>{he.decode(t("profile.my_docs.table.header.created_at").replace(/<[^>]*>?/gm, ''))}</TableCell>
                            <TableCell align="center">{he.decode(t("profile.my_docs.table.header.file").replace(/<[^>]*>?/gm, ''))}</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!!this.state.user.files_fiscal && this.state.user.files_fiscal.map((row) => (
                            <TableRow sx={{ '&:last-child td, &:last-child th': {border: 0}, display: 'flex', flexDirection: {xs: 'row-reverse', md: 'row'}}}>
                              <TableCell component="th" scope="row">{row[0]}</TableCell>
                              <TableCell align="center">{t(row[1]).replace(/<[^>]*>?/gm, '')} {row[3]}</TableCell>
                              <TableCell align="right">
                                <StyledButton text={t("general.actions.download").replace(/<[^>]*>?/gm, '')} as_link theme={this.props.theme} style={{height: 17}} onClick={(e) => {
                                  window.open(row[2], "_BLANK");
                                }} endIcon={<DownloadIcon />} />
                              </TableCell>
                            </TableRow>
                          ))}
                          {!this.state.user.files_fiscal && <TableRow sx={{ '&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row">{t("profile.my_docs.table.no_files").replace(/<[^>]*>?/gm, '')}</TableCell>
                          </TableRow>}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>}
                  {this.state.register && <Grid item xs={12} align="center">
                    <Typography variant="h4" sx={{display: {xs: 'block', md: 'none'}, textAlign: 'left'}}>{t("profile.my_docs.register")}</Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{display: 'flex', flexDirection: {xs: 'row-reverse', md: 'row'}}}>
                            <TableCell>{t("profile.my_docs.table.header.created_at").replace(/<[^>]*>?/gm, '')}</TableCell>
                            <TableCell align="center">{t("profile.my_docs.table.header.file").replace(/<[^>]*>?/gm, '')}</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!!this.state.user.files_register && this.state.user.files_register.map((row) => (
                            <TableRow sx={{ '&:last-child td, &:last-child th': {border: 0}, display: 'flex', flexDirection: {xs: 'row-reverse', md: 'row'}}}>
                              <TableCell component="th" scope="row">{row[0]}</TableCell>
                              <TableCell align="center">{t(row[1]).replace(/<[^>]*>?/gm, '')}</TableCell>
                              <TableCell align="right">
                                <StyledButton text={t("general.actions.download").replace(/<[^>]*>?/gm, '')} as_link theme={this.props.theme} style={{height: 17}} onClick={(e) => {
                                  window.open(row[2], "_BLANK");
                                }} endIcon={<DownloadIcon />} />
                              </TableCell>
                            </TableRow>
                          ))}
                          {!this.state.user.files_register && <TableRow sx={{ '&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row">{t("profile.my_docs.table.no_files").replace(/<[^>]*>?/gm, '')}</TableCell>
                          </TableRow>}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>}
                  {this.state.divcalc && <Grid item xs={12} align="center">
                    <Typography variant="h4" sx={{display: {xs: 'block', md: 'none'}, textAlign: 'left'}}>{t("profile.my_docs.divcalc")}</Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{display: 'flex', flexDirection: {xs: 'row-reverse', md: 'row'}}}>
                            <TableCell>{t("profile.my_docs.table.header.created_at").replace(/<[^>]*>?/gm, '')}</TableCell>
                            <TableCell align="center">{t("profile.my_docs.table.header.file").replace(/<[^>]*>?/gm, '')}</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!!this.state.user.files_divcalc && this.state.user.files_divcalc.map((row) => (
                            <TableRow sx={{ '&:last-child td, &:last-child th': {border: 0}, display: 'flex', flexDirection: {xs: 'row-reverse', md: 'row'}}}>
                              <TableCell component="th" scope="row">{row[0]}</TableCell>
                              <TableCell align="center">{t(row[1]).replace(/<[^>]*>?/gm, '')} {row[3]}</TableCell>
                              <TableCell align="right">
                                <StyledButton text={t("general.actions.download").replace(/<[^>]*>?/gm, '')} as_link theme={this.props.theme} style={{height: 17}} onClick={(e) => {
                                  window.open(row[2], "_BLANK");
                                }} endIcon={<DownloadIcon />} />
                              </TableCell>
                            </TableRow>
                          ))}
                          {!this.state.user.files_divcalc && <TableRow sx={{ '&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row">{t("profile.my_docs.table.no_files").replace(/<[^>]*>?/gm, '')}</TableCell>
                          </TableRow>}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{marginTop: this.props.theme.spacing(4)}}>
                <Typography variant="h4">{t("profile.my_transactions.title")}</Typography>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("profile.my_transactions.table.header.date").replace(/<[^>]*>?/gm, '')}</TableCell>
                        <TableCell align="center">{t("profile.my_transactions.table.header.transaction").replace(/<[^>]*>?/gm, '')}</TableCell>
                        <TableCell align="center">{t("profile.my_transactions.table.header.amount").replace(/<[^>]*>?/gm, '')}</TableCell>
                        <TableCell align="center">{t("profile.my_transactions.table.header.value").replace(/<[^>]*>?/gm, '')}</TableCell>
                        <TableCell align="center">{t("profile.my_transactions.table.header.total_amount").replace(/<[^>]*>?/gm, '')}</TableCell>
                        <TableCell align="center">{t("profile.my_transactions.table.header.total_value").replace(/<[^>]*>?/gm, '')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!list_transactions_paginated && list_transactions_paginated[this.state.trans_page] && list_transactions_paginated[this.state.trans_page].map((row) => (
                        <TableRow sx={{ '&:last-child td, &:last-child th': {border: 0}}}>
                          <TableCell component="th" scope="row">{row.transaction_date}</TableCell>
                          <TableCell align="center">{transaction_types[row.type]} {row.stock_type}</TableCell>
                          <TableCell align="center">{row.stock_number}</TableCell>
                          <TableCell align="center"><VisualAmount amount={(row.type === "Transaction::Conversion" ? row.stock_saldo_value : (row.stock_number * row.stock_value))} t={t} /></TableCell>
                          <TableCell align="center">{row.stock_saldo}</TableCell>
                          <TableCell align="center"><VisualAmount amount={row.stock_saldo_value} t={t} /></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {list_transactions_paginated.length > 1 && <Grid container style={{marginTop: this.props.theme.spacing(2)}}>
                  <Grid item xs={12} align="center">
                    <Pagination count={list_transactions_paginated.length} page={this.state.trans_page + 1} onChange={(e, i) => {this.setState({trans_page: i - 1});}} variant="outlined" color="primary" size="small" sx={{ul: {justifyContent: 'center'}}} />
                  </Grid>
                </Grid>}

                {this.state.user.list_transactions[0] && <Grid container style={{marginTop: this.props.theme.spacing(4), fontSize: '1.2em', fontWeight: 'bold', color: this.props.theme.palette.primary_grey.main, textTransform: 'uppercase'}}>
                  <Grid item xs={12} sm={8}>{t("profile.my_transactions.current_saldo", {date: DateTime.now().toLocaleString(DateTime.DATE_SHORT)}).replace(/<[^>]*>?/gm, '')}</Grid>
                  <Grid item xs={12} sm={4} sx={{textAlign: {xs: 'left', sm: 'right'}}}>
                    <VisualAmount amount={this.state.user.list_transactions[0].stock_saldo_value} t={t} />
                  </Grid>
                </Grid>}

                {!this.state.user.brief_exit_data?.exit_active && <Grid container style={{marginTop: this.props.theme.spacing(4)}}>
                  <Grid item xs={12} md={4} sx={{textAlign: {xs: "center", md: "left"}}}>
                    <StyledButton is_red text={t("profile.my_transactions.buy_stock").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {
                      if (this.state.user.emission_active) {
                        // active
                        this.props.history.push("/stock/how");
                        window.scrollTo(0, 0);
                      } else {
                        // inactive
                        this.setState({dialog_shares_buy: true});
                      }
                    }} />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{textAlign: {xs: "center", md: "center"}}}>
                    <StyledButton text={t("profile.my_transactions.transfer_stock").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {
                      this.props.history.push("/transfer");
                      window.scrollTo(0, 0);
                    }} />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{textAlign: {xs: "center", md: "right"}}}>
                    <StyledButton text={t("profile.my_transactions.sell_stock").replace(/<[^>]*>?/gm, '')} secondary theme={this.props.theme} style={{marginTop: this.props.theme.spacing(2)}} onClick={(e) => {
                      if (DateTime.now().month >= 7) {
                        // active
                        this.props.history.push("/exit");
                        window.scrollTo(0, 0);
                      } else {
                        // inactive
                        this.setState({dialog_shares_sell: true});
                      }
                    }} />
                  </Grid>
                </Grid>}

                {!!this.state.user.brief_exit_data?.exit_active && <Grid container style={{marginTop: this.props.theme.spacing(4)}}>
                  <Grid item xs={12}>
                    <Box sx={{backgroundColor: this.props.theme.palette.primary.main_p12, padding: 2}}>
                      <Typography variant="h3" sx={{color: this.props.theme.palette.primary.main, marginBottom: 2}}>{t("profile.exit.active_title")}</Typography>
                      {!exit_started && <PurifiedTypo sx={{"& > p": {marginBottom: '4px !important', marginTop: '4px'}}} text={t("exit.overview.exit_started", {status: (exit_started ? t("exit.overview.state_yes").replace(/<[^>]*>?/gm, '') : t("exit.overview.state_no").replace(/<[^>]*>?/gm, ''))})} />}
                      {!!exit_started && !docs_uploaded && <PurifiedTypo sx={{"& > p": {marginBottom: '4px !important', marginTop: '4px'}}} text={t("exit.overview.docs_uploaded", {status: (docs_uploaded ? t("exit.overview.state_yes").replace(/<[^>]*>?/gm, '') : t("exit.overview.state_no").replace(/<[^>]*>?/gm, ''))})} />}
                      {!!exit_started && !!docs_uploaded && !argenco_checked && <PurifiedTypo sx={{"& > p": {marginBottom: '4px !important', marginTop: '4px'}}} text={t("exit.overview.argenco_validated", {status: (argenco_checked ? t("exit.overview.state_yes").replace(/<[^>]*>?/gm, '') : t("exit.overview.state_no").replace(/<[^>]*>?/gm, ''))})} />}
                      {!!exit_started && !!docs_uploaded && !!argenco_checked && <PurifiedTypo sx={{"& > p": {marginBottom: '4px !important', marginTop: '4px'}}} text={t("profile.exit.overview.paid", {status: t("exit.overview.state_no").replace(/<[^>]*>?/gm, '')})} />}

                      <StyledButton
                        is_red
                        onClick={() => {
                          this.props.history.push("/exit");
                          window.scrollTo(0, 0);
                        }}
                        text={t("profile.exit.cta.to_exit")}
                        sx={{marginTop: 1, marginBottom: 1}}
                      />
                    </Box>
                  </Grid>
                </Grid>}

              </Grid>
            </Grid>
          </Grid>

          <Grid ref={this.sec_ref} container xs={12} sx={{minHeight: 100, marginTop: 4, backgroundColor: 'rgb(235, 235, 235)', padding: 3, scrollMargin: 150}}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.component_title}>{t("profile.security.title")}</Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{marginTop: 2}}>
              <Typography variant="h4">{t("profile.security.mfa.title")}</Typography>
              <PurifiedTypo text={t("profile.security.mfa.explanation")} sx={{fontSize: '0.9rem'}} />
            </Grid>
            <Grid item xs={12} md={6} sx={{marginTop: 2, borderLeft: {xs: "none", md: "1px solid black"}, paddingLeft: {xs: 0, md: 3}}}>
              <RadioGroup value={this.state.user.mfa_status} onChange={(e) => {
                this.setState({user: {...this.state.user, mfa_status: e.target.value}});
              }}>
                <FormControlLabel value="1" control={<Radio />} label={he.decode(t("profile.security.mfa.options.auth").replace(/<[^>]*>?/gm, ''))} />
                {/* <FormControlLabel value="2" control={<Radio />} label={he.decode(t("profile.security.mfa.options.sms").replace(/<[^>]*>?/gm, ''))} /> */}
                <FormControlLabel value="0" control={<Radio />} label={he.decode(t("profile.security.mfa.options.off").replace(/<[^>]*>?/gm, ''))} />
              </RadioGroup>

              {parseInt(this.state.old_mfa_status, 10) !== parseInt(this.state.user.mfa_status, 10) && parseInt(this.state.user.mfa_status, 10) === 1 && <Box sx={{marginTop: 4}}>
                <PurifiedTypo text={t("profile.security.mfa.auth_explanation.initial_explanation")} sx={{fontSize: '0.9rem', marginBottom: 2}} />
                {!!this.state.user.otp_provisioning && <QRCode value={this.state.user.otp_provisioning} />}
                <PurifiedTypo text={t("profile.security.mfa.auth_explanation.input_explanation")} sx={{fontSize: '0.9rem', marginBottom: 2, marginTop: 2}} />
                <OtpField
                  {...this.props}
                  onSubmit={(code) => {
                    // saved upon verification
                    this.setState({old_mfa_status: 1});
                  }}
                />
              </Box>}

              {parseInt(this.state.old_mfa_status, 10) !== parseInt(this.state.user.mfa_status, 10) && parseInt(this.state.user.mfa_status, 10) === 2 && <Box sx={{marginTop: 4}}>
                explanation + phone field + (re)send + input for code
              </Box>}

              {parseInt(this.state.old_mfa_status, 10) !== parseInt(this.state.user.mfa_status, 10) && parseInt(this.state.user.mfa_status, 10) === 0 && <Box sx={{marginTop: 4}}>
                <StyledButton
                  text={t("profile.security.mfa.cta.update").replace(/<[^>]*>?/gm, '')}
                  theme={this.props.theme}
                  onClick={(e) => {
                    this.props.helpers.otp.fetch_mfa_status({
                      locale: this.props.i18n.language,
                      login: this.props.login,
                      callback: (r) => {
                        this.setState({old_mfa_status: 0});
                      },
                      parameters: {
                        set_mfa_status: 3
                      }
                    });
                  }}
                />
              </Box>}
            </Grid>

            <Grid item xs={12} sx={{marginTop: 4}}>
              <Divider color="black" />
            </Grid>

            <Grid item xs={12} md={6} sx={{marginTop: 2}}>
              <Typography variant="h4">{t("profile.security.sec_q.title")}</Typography>
              <PurifiedTypo text={t("profile.security.sec_q.explanation")} sx={{fontSize: '0.9rem'}} />
            </Grid>
            <Grid item xs={12} md={6} sx={{marginTop: 2, borderLeft: {xs: "none", md: "1px solid black"}, paddingLeft: {xs: 0, md: 3}}}>
              <RadioGroup value={this.state.user.sec_q_status} onChange={(e) => {
                this.setState({user: {...this.state.user, sec_q_status: e.target.value}});
              }}>
                <FormControlLabel value="1" control={<Radio />} label={he.decode(t("profile.security.sec_q.options.on").replace(/<[^>]*>?/gm, ''))} />
                <FormControlLabel value="0" control={<Radio />} label={he.decode(t("profile.security.sec_q.options.off").replace(/<[^>]*>?/gm, ''))} />
              </RadioGroup>

              {parseInt(this.state.old_sec_q_status, 10) !== parseInt(this.state.user.sec_q_status, 10) && parseInt(this.state.user.sec_q_status, 10) === 0 && <Box sx={{marginTop: 4}}>
                <StyledButton
                  text={t("profile.security.mfa.cta.update").replace(/<[^>]*>?/gm, '')}
                  theme={this.props.theme}
                  onClick={(e) => {
                    this.save_security_questions(e, {
                      sq1_id: '', sq1_answer: '',
                      sq2_id: '', sq2_answer: '',
                      sq3_id: '', sq3_answer: ''
                    });
                  }}
                />
              </Box>}
            </Grid>
            {parseInt(this.state.old_sec_q_status, 10) !== parseInt(this.state.user.sec_q_status, 10) && parseInt(this.state.user.sec_q_status, 10) === 1 && <Grid item xs={12} sx={{marginTop: 4}}>
              <PurifiedTypo text={t("profile.security.sec_q.initial_explanation")} sx={{fontSize: '0.9rem', marginBottom: 2}} />
              <Box sx={{marginTop: 1}}>
                <FormControl fullWidth>
                  <InputLabel id="sec_q_1">{he.decode(t("profile.security.sec_q.question_1").replace(/<[^>]*>?/gm, ''))}</InputLabel>
                  <Select
                    labelId="sec_q_1"
                    id="sec_q_1_select"
                    value={this.state.user.sq1_id}
                    label={he.decode(t("profile.security.sec_q.question_1").replace(/<[^>]*>?/gm, ''))}
                    onChange={(e) => {this.setState({user: {...this.state.user, sq1_id: e.target.value}});}}
                  >
                    {this.state.security_questions.map(q => (<MenuItem value={q.id}>{he.decode(q.question.replace(/<[^>]*>?/gm, ''))}</MenuItem>))}
                  </Select>
                </FormControl>
                <StyledTextField
                  label={t("profile.security.sec_q.question_1_answer").replace(/<[^>]*>?/gm, '')}
                  value={this.state.user.sq1_answer}
                  id="sec_q_1_answer"
                  sx={{marginTop: 1}}
                  onChange={(v) => {this.setState({user: {...this.state.user, sq1_answer: v}});}}
                />
              </Box>
              <Box sx={{marginTop: 3}}>
                <FormControl fullWidth>
                  <InputLabel id="sec_q_2">{he.decode(t("profile.security.sec_q.question_2").replace(/<[^>]*>?/gm, ''))}</InputLabel>
                  <Select
                    labelId="sec_q_2"
                    id="sec_q_2_select"
                    value={this.state.user.sq2_id}
                    label={he.decode(t("profile.security.sec_q.question_2").replace(/<[^>]*>?/gm, ''))}
                    onChange={(e) => {this.setState({user: {...this.state.user, sq2_id: e.target.value}});}}
                    error={this.state.user.sq2_id === this.state.user.sq1_id}
                  >
                    {this.state.security_questions.map(q => (<MenuItem value={q.id}>{he.decode(q.question.replace(/<[^>]*>?/gm, ''))}</MenuItem>))}
                  </Select>
                </FormControl>
                <StyledTextField
                  label={t("profile.security.sec_q.question_2_answer").replace(/<[^>]*>?/gm, '')}
                  value={this.state.user.sq2_answer}
                  id="sec_q_2_answer"
                  sx={{marginTop: 1}}
                  onChange={(v) => {this.setState({user: {...this.state.user, sq2_answer: v}});}}
                />
              </Box>
              <Box sx={{marginTop: 3}}>
                <FormControl fullWidth>
                  <InputLabel id="sec_q_3">{he.decode(t("profile.security.sec_q.question_3").replace(/<[^>]*>?/gm, ''))}</InputLabel>
                  <Select
                    labelId="sec_q_3"
                    id="sec_q_3_select"
                    value={this.state.user.sq3_id}
                    label={he.decode(t("profile.security.sec_q.question_3").replace(/<[^>]*>?/gm, ''))}
                    onChange={(e) => {this.setState({user: {...this.state.user, sq3_id: e.target.value}});}}
                    error={this.state.user.sq3_id === this.state.user.sq1_id || this.state.user.sq3_id === this.state.user.sq2_id}
                  >
                    {this.state.security_questions.map(q => (<MenuItem value={q.id}>{he.decode(q.question.replace(/<[^>]*>?/gm, ''))}</MenuItem>))}
                  </Select>
                </FormControl>
                <StyledTextField
                  label={t("profile.security.sec_q.question_3_answer").replace(/<[^>]*>?/gm, '')}
                  value={this.state.user.sq3_answer}
                  id="sec_q_3_answer"
                  sx={{marginTop: 1}}
                  onChange={(v) => {this.setState({user: {...this.state.user, sq3_answer: v}});}}
                />
              </Box>

              <StyledButton
                text={t("profile.security.mfa.cta.update").replace(/<[^>]*>?/gm, '')}
                theme={this.props.theme}
                sx={{marginTop: 4}}
                onClick={(e) => {
                  this.save_security_questions(e, {
                    sq1_id: this.state.user.sq1_id, sq1_answer: this.state.user.sq1_answer,
                    sq2_id: this.state.user.sq2_id, sq2_answer: this.state.user.sq2_answer,
                    sq3_id: this.state.user.sq3_id, sq3_answer: this.state.user.sq3_answer
                  });
                }}
              />
            </Grid>}
          </Grid>
        </Grid>
      </Container>

      <Dialog open={this.state.dialog_shares_buy} onClose={() => {this.setState({dialog_shares_buy: false});}}>
        <DialogTitle>{t("profile.dialog.stock_buy.title").replace(/<[^>]*>?/gm, '')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("profile.dialog.stock_buy.content").replace(/<[^>]*>?/gm, '')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {this.setState({dialog_shares_buy: false});}} autoFocus>{t("general.actions.ok").replace(/<[^>]*>?/gm, '')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.dialog_shares_sell} onClose={() => {this.setState({dialog_shares_sell: false});}}>
        <DialogTitle>{t("profile.dialog.stock_sell.title").replace(/<[^>]*>?/gm, '')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("profile.dialog.stock_sell.content").replace(/<[^>]*>?/gm, '')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {this.setState({dialog_shares_sell: false});}} autoFocus>{t("general.actions.ok").replace(/<[^>]*>?/gm, '')}</Button>
        </DialogActions>
      </Dialog>
    </Page>);
  }
}

Profile.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  },
  orange_box: {
    border: `4px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(3),
    position: 'relative'
  },
  orange_box_dense: {
    border: `4px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'relative',
    cursor: 'pointer'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Profile))));
