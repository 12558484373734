import Emission from '../actions/emission';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function emission(state = initial, action) {
  return Emission.state_switch(state, action);
}
