import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import classNames from 'classnames';
// import {NavLink} from 'react-router-dom';

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Popover,
  Select,
  Toolbar
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import KeyIcon from '@mui/icons-material/Key';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import Logo from '../../images/logo_green.png';

import MenuItem from '../shared/parts/fake_polyfill/MenuItem';
import PurifiedTypo from '../shared/parts/PurifiedTypo';
import StyledButton from '../shared/parts/StyledButton';
import Typography from '../shared/parts/Typography';

import HelpersVersion from '../../actions/helpers/version';

var he = require('he');

// const ForwardNavLink = React.forwardRef((props, ref) => (
//   <NavLink {...props} innerRef={ref} />
// ));
//
// class ListItemButton extends Component {
//
//   render() {
//     const {classes} = this.props;
//
//     return (!this.props.hide && <ListItem button={!this.props.noButton} className={classes.is_inactive} component={ForwardNavLink} to={this.props.path} activeClassName={classes.is_active} exact={this.props.exact} style={this.props.style}>
//       <ListItemText style={{whiteSpace: "nowrap"}} primary={this.props.title}/>
//     </ListItem>)
//   }
// }

class ListItemInlineButton extends Component {

  render() {
    const {classes} = this.props;

    return (!this.props.hide && <ListItem sx={{'& div span': {color: this.props.theme.palette.primary.main}}} button={true} className={classes.is_inactive} style={this.props.style} onMouseDown={(e) => {if (e.button == 1) this.props.onClick(e);}} onAuxClick={(e) => {this.props.onClick(e);}} onClick={(e) => {this.props.onClick(e);}} secondaryAction={!!this.props.arrow ? (this.props.active_menu === this.props.arrow ? (<KeyboardArrowUpIcon sx={{marginTop: 1, color: this.props.theme.palette.primary.main}} />) : (<KeyboardArrowDownIcon sx={{marginTop: 1, color: this.props.theme.palette.primary.main}} />)) : undefined}>
      <ListItemText style={{whiteSpace: "nowrap"}} primary={this.props.title}/>
    </ListItem>)
  }
}

class WhiteListItemInlineButton extends Component {

  render() {
    const {classes} = this.props;

    return (!this.props.hide && <ListItem sx={{'& div span': {color: this.props.theme.palette.primary.contrastText}, paddingBottom: 0, paddingTop: 0}} button={true} className={classes.is_inactive} style={this.props.style} onMouseDown={(e) => {if (e.button == 1) this.props.onClick(e);}} onAuxClick={(e) => {this.props.onClick(e);}} onClick={(e) => {this.props.onClick(e);}} secondaryAction={!!this.props.arrow ? (this.props.active_menu === this.props.arrow ? (<KeyboardArrowUpIcon sx={{marginTop: 1, color: this.props.theme.palette.primary.contrastText}} />) : (<KeyboardArrowDownIcon sx={{marginTop: 1, color: this.props.theme.palette.primary.contrastText}} />)) : undefined}>
      <ListItemText sx={{whiteSpace: "nowrap", color: this.props.theme.palette.primary.contrastText, "& span": {fontSize: "0.875rem !important", marginTop: '-7px', marginBottom: '-4px'}}} primary={this.props.title}/>
    </ListItem>)
  }
}

class Header extends Component {
  constructor(props) {
    super(props);

    let is_admin = false;
    if (props.login && props.login.type === "User::Admin") is_admin = true;

    this.state = {
      login_menu_open: false,
      hamburger_menu_open: false,
      is_admin: is_admin,
      active_menu: '',
      open: false,
      anchore: null,
      banner_loading: true,
      banner: {}
    }

    this.topheader = React.createRef();
    this.rightmenu = React.createRef();
  }

  componentDidMount() {
    this.props.helpers.banner.fetch_active({
      locale: this.props.i18n.language,
      callback: (r) => {
        this.setState({banner: r, banner_loading: false});
      },
      error_callback: (r) => {
        this.setState({banner_loading: false});
      },
      parameters: {}
    });
  }
  componentDidUpdate(prevProps) {}
  componentWillUnmount() {}

  scroll_to = (ref) => {
    if (ref && ref.current) {
      window.scrollTo(0, ref.current.offsetTop - 130);
    }
  }

  goto_page = (e, path) => {
    const {history} = this.props;

    if (e.ctrlKey || (e.nativeEvent && e.nativeEvent.which === 2) || (e.button === 1)) {
      window.open(path);
    } else {
      window.scrollTo(0, 0);
      this.setState({login_menu_open: false, menu_anchor: null, open: false, anchor: null, active_menu: '', hamburger_menu_open: false, hamburger_menu_anchor: null});
      setTimeout(() => {history.push(path);}, 500);
      if (this.props.location.pathname === path) window.location.reload();
    }
  }

  render() {
    const {t, classes, history} = this.props;

    let is_root = this.props.location.pathname === "/";
    let is_online_voting = this.props.location.pathname === "/meeting/voting";

    let is_staging = window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1;

    return (<AppBar position="fixed" className={classNames(classes.app_bar)} sx={{paddingTop: (is_staging ? 3.5 : 0)}}>
      {HelpersVersion.show_version_notice() && <Box style={{position: 'absolute', top: 0, left: 0, width: '100%', padding: 5, zIndex: 3501, backgroundColor: 'rgba(255, 153, 0, 0.9)', color: 'black'}} align="center">
        <Typography variant="caption" component="p">{t("general.messages.version_notice")} <Button style={{fontSize: '0.7rem', marginLeft: 20}} size="small" variant="outlined" onClick={(e) => {e.preventDefault(); window.location.reload(true);}}>{t("general.messages.version_notice_cta")}</Button></Typography>
      </Box>}
      {is_staging && <Box style={{position: 'absolute', top: 0, left: 0, width: '100%', padding: 3, zIndex: 3500, backgroundColor: 'rgba(255, 153, 0, 0.9)', color: 'black'}} align="center">
        <Typography variant="caption" component="p" sx={{textAlign: 'center'}}>You are currently on the staging website</Typography>
      </Box>}

      <Box style={{position: 'absolute', top: (is_staging ? 28 : 0), left: 0, width: '100%', height: {xs: '8px', md: '46px'}, padding: 5, zIndex: 3000, backgroundColor: this.props.theme.palette.primary.main, color: this.props.theme.palette.primary.contrastText}}>
        <Container sx={{paddingLeft: 0, paddingRight: 0}}>
          <Box className={classes.top_wrapper} sx={{height: {xs: '8px', md: '36px'}}}>
            <Box sx={{marginTop: '-2px', display: {xs: 'none', md: 'flex'}, flexDirection: 'row', padding: 0, flexGrow: 1, justifyContent: 'end', height: '40px', alignItems: 'self-end', '& div div span': {
              color: this.props.theme.palette.primary.contrastText
            }}}>
              <List sx={{display: 'flex', flexDirection: 'row', marginBottom: 0, color: this.props.theme.palette.primary.contrastText}}>
                {!is_online_voting && <WhiteListItemInlineButton key={`menu_8`} {...this.props} title={he.decode(t("menu.faq").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                  this.goto_page(e, "/faq");
                }}/>}
                <Box sx={{marginTop: '-2px'}}>|</Box>
                {!is_online_voting && <WhiteListItemInlineButton key={`menu_9`} {...this.props} title={he.decode(t("menu.contact").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                  this.goto_page(e, "/contact");
                }}/>}
                <Box sx={{marginTop: '-2px', marginRight: 0.5}}>|</Box>
              </List>
            </Box>

            {!!this.props.login && <Box ref={this.rightmenu} className={classes.profile_box} sx={{width: {xs: '100%', md: 'auto'}, flexGrow: 0, marginLeft: 2, display: {xs: 'none', md: 'flex'}}}>
              <Box sx={{display: 'inline-flex', verticalAlign: 'top', marginRight: 1, fontWeight: 700, marginTop: 1, fontSize: '0.875rem'}}>
                {this.props.login.first_name} {this.props.login.last_name}<span>&nbsp;&nbsp;</span>
                {this.props.login.vennoot_number}
              </Box>
              {/* <IconButton
                className={classes.profile_button}
                aria-owns={this.state.login_menu_open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={(event) => this.setState({login_menu_open: true, menu_anchor: event.currentTarget})}
                sx={{marginTop: -1}}
              >
                <Avatar style={{backgroundColor: this.props.theme.palette.primary.main, width: 36, height: 36}}><PersonIcon /></Avatar>
              </IconButton> */}
              {false && <Menu id="menu-appbar" anchorEl={this.state.menu_anchor} anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }} transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }} open={this.state.login_menu_open} onClose={() => this.setState({login_menu_open: false})}>
                {!this.state.is_admin && <MenuItem onClick={(e) => {
                  this.goto_page(e, "/profile");
                }}>{t("general.actions.profile").replace(/<[^>]*>?/gm, '')}</MenuItem>}
                <MenuItem onClick={() => {
                  history.push("/");
                  this.props.logout({locale: this.props.i18n.language, login: this.props.login, callback: () => {window.location = "/login";}});
                  this.setState({login_menu_open: false, menu_anchor: null});
                }}>{t("general.actions.sign_out").replace(/<[^>]*>?/gm, '')}</MenuItem>
              </Menu>}
            </Box>}
            <Box ref={this.rightmenu} className={classes.profile_box} sx={{flexGrow: 0, display: {xs: 'none', md: 'block'}, height: 4}}>
              {!this.props.login && <StyledButton is_red sx={{marginTop: '-1px', marginLeft: 2}} onClick={(e) => {
                if (window.location.pathname === '/emission') {
                  window.scrollTo(0, document.body.scrollHeight);
                } else {
                  this.goto_page(e, "/emission");
                }
              }} text={t("general.actions.become_vennoot_short").replace(/<[^>]*>?/gm, '')} />}
              {!!this.props.login && <StyledButton is_red sx={{marginTop: '-1px', marginLeft: 2}} onClick={(e) => {
                if (window.location.pathname === '/emission') {
                  window.scrollTo(0, document.body.scrollHeight);
                } else {
                  this.goto_page(e, "/emission");
                }
              }} text={t("general.actions.become_vennoot_short_when_logged_in").replace(/<[^>]*>?/gm, '')} />}
              {!this.props.login && <StyledButton sx={{marginTop: '-1px', backgroundColor: 'white', color: this.props.theme.palette.primary.main, marginLeft: 2, '&:hover': {backgroundColor: 'lightgrey'}}} onClick={(e) => {
                this.goto_page(e, "/login");
              }} text={t("general.actions.sign_in").replace(/<[^>]*>?/gm, '')} />}
              <Select
                MenuProps={{disableScrollLock: true}}
                variant="standard"
                sx={{
                  marginLeft: 2,
                  color: 'white',
                  fontSize: '0.875rem',
                  "& svg": {
                    color: 'white'
                  },
                  "&:before": {
                    borderColor: 'transparent'
                  },
                  "&:hover:before": {
                    borderColor: 'transparent !important'
                  }
                }}
                id="language_picker"
                value={((this.props.i18n.language || 'nl').length < 1 ? 'nl' : (this.props.i18n.language || 'nl'))}
                onChange={(e) => {
                  this.props.i18n.changeLanguage(e.target.value, () => {
                    document.location.reload();
                  });
                  window.scrollTo(0, 0);
                }}
              >
                {(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1) && <MenuItem value={"xx"}>KEYS</MenuItem>}
                <MenuItem value={"nl"}>{he.decode(t("footer.languages.dutch").replace(/<[^>]*>?/gm, ''))}</MenuItem>
                <MenuItem value={"fr"}>{he.decode(t("footer.languages.french").replace(/<[^>]*>?/gm, ''))}</MenuItem>
              </Select>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container sx={{paddingLeft: 0, paddingRight: 0}}>
        <Toolbar className={classNames(classes.toolbar)} ref={this.topheader} sx={{paddingTop: {xs: '16px', md: '47px'}}}>


          {/* <Divider style={{width: '100%'}} color="primary"/> */}

          <Box className={classes.center_wrapper}>
            <Box className={classes.logo_wrapper}>
              <img
                onMouseDown={(e) => {if (e.button == 1) this.goto_page(e, "/");}} onAuxClick={(e) => {this.goto_page(e, "/");}} onClick={(e) => {this.goto_page(e, "/");}}
                src={Logo}
                alt="ArgenCo"
                className={classes.logo}
              />
            </Box>
            <List sx={{display: {xs: 'none', md: 'flex'}, flexDirection: 'row', padding: 0}}>
              {!is_online_voting && <ListItemInlineButton key={`menu_0`} {...this.props} title={he.decode(t("menu.stock").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {this.setState({active_menu: 'stock', open: true, anchor: e.currentTarget});}} arrow='stock' active_menu={this.state.active_menu}/>}
              {!is_online_voting && <ListItemInlineButton key={`menu_1`} {...this.props} title={he.decode(t("menu.benefits").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                this.goto_page(e, "/benefits");
              }}/>}
              {!is_online_voting && <ListItemInlineButton key={`menu_2`} {...this.props} title={he.decode(t("menu.about").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {this.setState({active_menu: 'about', open: true, anchor: e.currentTarget});}} arrow='about' active_menu={this.state.active_menu}/>}
              {!is_online_voting && <ListItemInlineButton key={`menu_3`} {...this.props} title={he.decode(t("menu.news").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                this.goto_page(e, "/news");
              }}/>}
              {/* {!is_online_voting && <ListItemInlineButton key={`menu_3`} {...this.props} title={he.decode(t("menu.contact").replace(/<[^>]*>?/gm, ''))} onClick={() => {
                this.goto_page("/contact");
              }}/>} */}
            </List>

            {<Popover
              sx={{display: {xs: 'none', md: 'flex'}, flexDirection: 'row', padding: 0}}
              open={this.state.open}
              anchorEl={this.state.anchor}
              onClose={() => {this.setState({open: false, anchor: null, active_menu: ''});}}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              disableScrollLock
              PaperProps={{sx: {display: 'flex', flexDirection: 'column', minWidth: '600px'}}}
            >
              <Box sx={{width: '100%', minWidth: '600px', display: 'flex', flexDirection: 'row', alignContent: 'space-between'}}>
                {this.state.active_menu === 'stock' && <Box sx={{width: '50%', minWidth: '200px', padding: 1, flexGrow: 0}}>
                  <Box sx={{width: '100%', padding: 2, paddingTop: 1}}>
                    <Typography variant="h3" sx={{color: this.props.theme.palette.primary.main}}>{he.decode(t(`menu.${this.state.active_menu}`).replace(/<[^>]*>?/gm, ''))}</Typography>
                  </Box>
                  {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                    this.goto_page(e, "/stock/why");
                  }}>{he.decode(t("menu.sub_stock.why").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                  {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                    this.goto_page(e, "/stock/how");
                  }}>{he.decode(t("menu.sub_stock.how").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                  {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                    this.goto_page(e, "/stock/transfer");
                  }}>{he.decode(t("menu.sub_stock.transfer").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                  {/* {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                    this.goto_page(e, "/stock/prospectus");
                  }}>{he.decode(t("menu.sub_stock.prospectus").replace(/<[^>]*>?/gm, ''))}</MenuItem>} */}
                </Box>}
                {this.state.active_menu === 'about' && <Box sx={{width: '50%', minWidth: '200px', padding: 1, flexGrow: 0}}>
                  <Box sx={{width: '100%', padding: 2, paddingTop: 1}}>
                    <Typography variant="h3" sx={{color: this.props.theme.palette.primary.main}}>{he.decode(t(`menu.${this.state.active_menu}`).replace(/<[^>]*>?/gm, ''))}</Typography>
                  </Box>
                  {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                    this.goto_page(e, "/about/history_mission");
                  }}>{he.decode(t("menu.sub_about.history_mission").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                  {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                    this.goto_page(e, "/about/investments");
                  }}>{he.decode(t("menu.sub_about.investments").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                  {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                    this.goto_page(e, "/about/team");
                  }}>{he.decode(t("menu.sub_about.team").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                  {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                    this.goto_page(e, "/about/documents");
                  }}>{he.decode(t("menu.sub_about.jaarverslag").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                </Box>}
                {this.state.active_menu === 'stock' && !!this.state.banner.stock && <Box sx={{minWidth: '200px', padding: 1, borderLeft: '1px solid rgba(0, 0, 0, 0.3)', position: 'relative', width: 'auto', overflow: 'hidden', flexGrow: 1}}>
                  <img src={this.state.banner.stock.item} style={{width: '100%', height: 'auto', maxWidth: '100%', maxHeight: '200px', marginBottom: '-8px'}} />
                  <Box sx={{position: 'absolute', right: 1, top: 1, width: '100%', height: '100%', padding: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingRight: 2}}>
                    <PurifiedTypo text={this.state.banner.stock.body} sx={{marginBottom: 'auto', lineHeight: 1}} />
                    {!!this.state.banner.stock.cta_link && !!this.state.banner.stock.cta_text && <StyledButton is_red onClick={(e) => {
                      this.goto_page(e, (this.state.banner.stock.cta_link || '/'));
                    }} text={(this.state.banner.stock.cta_text || '').replace(/<[^>]*>?/gm, '')} sx={{marginTop: 'auto', marginBottom: 1, alignSelf: 'end'}} />}
                  </Box>
                </Box>}
                {this.state.active_menu === 'about' && !!this.state.banner.who && <Box sx={{minWidth: '200px', padding: 1, borderLeft: '1px solid rgba(0, 0, 0, 0.3)', position: 'relative', width: 'auto', overflow: 'hidden', flexGrow: 1}}>
                  <img src={this.state.banner.who.item} style={{width: '100%', height: 'auto', maxWidth: '100%', maxHeight: '250px', marginBottom: '-8px'}} />
                  <Box sx={{position: 'absolute', right: 1, top: 1, width: '100%', height: '100%', padding: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingRight: 2}}>
                    <PurifiedTypo text={this.state.banner.who.body} sx={{marginBottom: 'auto', lineHeight: 1}} />
                    {!!this.state.banner.who.cta_link && !!this.state.banner.who.cta_text && <StyledButton is_red onClick={(e) => {
                      this.goto_page(e, (this.state.banner.who.cta_link || '/'));
                    }} text={(this.state.banner.who.cta_text || '').replace(/<[^>]*>?/gm, '')} sx={{marginTop: 'auto', marginBottom: 1, alignSelf: 'end'}} />}
                  </Box>
                </Box>}
              </Box>
              {/* <Box sx={{width: '100%', minWidth: '600px', height: 1}}>
                &nbsp;
              </Box> */}
            </Popover>}

            <IconButton
              className={classes.profile_button}
              aria-owns={this.state.hamburger_menu_open ? 'hamburger-appbar' : undefined}
              aria-haspopup="true"
              onClick={(event) => this.setState({hamburger_menu_open: true, hamburger_menu_anchor: event.currentTarget})}
              color="inherit"
              sx={{display: {xs: 'block', md: 'none'}, marginLeft: 'auto'}}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Menu id="menu-appbar" anchorEl={this.state.hamburger_menu_anchor} anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }} transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }} sx={{
                '& .MuiPaper-root': {
                  position: 'fixed',
                  top: '0px !important',
                  left: '0px !important',
                  width: '95%',
                  height: '99%',
                  paddingLeft: 2
                }
              }} open={this.state.hamburger_menu_open} onClose={() => this.setState({hamburger_menu_open: false})}>
              {this.state.active_menu === '' && <React.Fragment>
                <MenuItem sx={{flexDirection: 'row-reverse'}}>
                  <IconButton onClick={() => this.setState({hamburger_menu_open: false})} sx={{float: 'right'}}><CloseIcon color="primary" /></IconButton>
                  <img src={Logo} alt="ArgenCo" style={{maxHeight: '20px', marginLeft: '-5%', marginRight: 'auto'}} />
                </MenuItem>
                <Divider sx={{marginLeft: '-5%'}} />

                {!is_online_voting && !!this.props.login && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/profile");
                }}>{he.decode(t("menu.my_argenco").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && !!this.props.login && <Divider sx={{marginLeft: '-5%'}} />}

                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={() => {
                  this.setState({active_menu: 'stock'});
                }}>
                  {he.decode(t("menu.stock").replace(/<[^>]*>?/gm, ''))}
                  <IconButton color="primary" sx={{marginLeft: 'auto'}}><ArrowForwardIosIcon /></IconButton>
                </MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}

                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/benefits");
                }}>{he.decode(t("menu.benefits").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}

                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={() => {
                  this.setState({active_menu: 'about'});
                }}>
                  {he.decode(t("menu.about").replace(/<[^>]*>?/gm, ''))}
                  <IconButton color="primary" sx={{marginLeft: 'auto'}}><ArrowForwardIosIcon /></IconButton>
                </MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}

                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/news");
                }}>{he.decode(t("menu.news").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}

                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/faq");
                }}>{he.decode(t("menu.faq").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}

                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/contact");
                }}>{he.decode(t("menu.contact").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}

                {!is_online_voting && !this.props.login && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/login");
                }}><LoginIcon color="primary" sx={{marginRight: 1}} /> {he.decode(t("menu.login").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && !this.props.login && <Divider sx={{marginLeft: '-5%'}} />}

                {!is_online_voting && !!this.props.login && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={() => {
                  history.push("/");
                  this.props.logout({locale: this.props.i18n.language, login: this.props.login, callback: () => {
                    window.location = "/login";
                  }});
                }}><LogoutIcon color="primary" sx={{marginRight: 1}} /> {he.decode(t("menu.logout").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && !!this.props.login && <Divider sx={{marginLeft: '-5%'}} />}
              </React.Fragment>}

              {this.state.active_menu === 'stock' && <React.Fragment>
                <MenuItem sx={{flexDirection: 'row-reverse'}}>
                  <IconButton onClick={() => this.setState({hamburger_menu_open: false})}><CloseIcon color="primary" /></IconButton>
                  <span style={{marginLeft: 'auto', marginRight: 'auto', color: this.props.theme.palette.primary.main}}>{he.decode(t("menu.stock").replace(/<[^>]*>?/gm, ''))}</span>
                  <IconButton onClick={() => this.setState({active_menu: ''})} sx={{marginLeft: '-5%'}}><ArrowBackIosNewIcon color="primary" /></IconButton>
                </MenuItem>
                <Divider sx={{marginLeft: '-5%'}} />

                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/stock/why");
                }}>{he.decode(t("menu.sub_stock.why").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}
                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/stock/how");
                }}>{he.decode(t("menu.sub_stock.how").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}
                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/stock/transfer");
                }}>{he.decode(t("menu.sub_stock.transfer").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}
                {/* {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/stock/prospectus");
                }}>{he.decode(t("menu.sub_stock.prospectus").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />} */}
              </React.Fragment>}

              {this.state.active_menu === 'about' && <React.Fragment>
                <MenuItem sx={{flexDirection: 'row-reverse'}}>
                  <IconButton onClick={() => this.setState({hamburger_menu_open: false})}><CloseIcon color="primary" /></IconButton>
                  <span style={{marginLeft: 'auto', marginRight: 'auto'}}>{he.decode(t("menu.about").replace(/<[^>]*>?/gm, ''))}</span>
                  <IconButton onClick={() => this.setState({active_menu: ''})} sx={{marginLeft: '-5%'}}><ArrowBackIosNewIcon color="primary" /></IconButton>
                </MenuItem>
                <Divider sx={{marginLeft: '-5%'}} />

                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/about/history_mission");
                }}>{he.decode(t("menu.sub_about.history_mission").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}
                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/about/investments");
                }}>{he.decode(t("menu.sub_about.investments").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}
                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/about/team");
                }}>{he.decode(t("menu.sub_about.team").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}
                {!is_online_voting && <MenuItem sx={{color: this.props.theme.palette.primary.main}} onClick={(e) => {
                  this.goto_page(e, "/about/documents");
                }}>{he.decode(t("menu.sub_about.jaarverslag").replace(/<[^>]*>?/gm, ''))}</MenuItem>}
                {!is_online_voting && <Divider sx={{marginLeft: '-5%'}} />}
              </React.Fragment>}

              {!is_online_voting && !this.props.login && <StyledButton is_red sx={{marginTop: 2, width: '95%'}} onClick={(e) => {
                this.goto_page(e, "/emission");
              }} text={t("general.actions.become_vennoot_short").replace(/<[^>]*>?/gm, '')} />}

              {((this.props.i18n.language || 'nl').length < 1 ? 'nl' : (this.props.i18n.language || 'nl')) === 'nl' && <Box sx={{marginTop: 1, textAlign: 'center', padding: 1}} onClick={() => {
                this.props.i18n.changeLanguage('fr', () => {
                  document.location.reload();
                });
                window.scrollTo(0, 0);
              }}><Typography sx={{fontSize: '0.8rem', color: this.props.theme.palette.primary.main}}>{t("mobile.language.french")}</Typography></Box>}
              {((this.props.i18n.language || 'nl').length < 1 ? 'nl' : (this.props.i18n.language || 'nl')) !== 'nl' && <Box sx={{marginTop: 1, textAlign: 'center', padding: 1}} onClick={() => {
                this.props.i18n.changeLanguage('nl', () => {
                  document.location.reload();
                });
                window.scrollTo(0, 0);
              }}><Typography sx={{fontSize: '0.8rem', color: this.props.theme.palette.primary.main}}>{t("mobile.language.dutch")}</Typography></Box>}

            </Menu>

            {!!this.props.login && <List sx={{display: {xs: 'none', md: 'flex'}, flexDirection: 'row', padding: 0, marginLeft: 'auto'}}>
              {this.props.login.is_admin && <ListItem key={`menu_12`} {...this.props} button={true} onClick={() => {window.location = "https://old.argenco.be/administration";}}>
                <ListItemIcon style={{minWidth: 20}}><KeyIcon color="primary" /></ListItemIcon>
              </ListItem>}
              <ListItemInlineButton key={`menu_10`} {...this.props} title={he.decode(t("menu.my_argenco").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {this.goto_page(e, "/profile");}}/>
              <ListItem key={`menu_11`} {...this.props} button={true} style={{paddingRight: 8}} onClick={() => {history.push("/"); this.props.logout({locale: this.props.i18n.language, login: this.props.login, callback: () => {window.location = "/login";}});}}>
                <ListItemIcon style={{minWidth: 20}}><LogoutIcon color="primary" /></ListItemIcon>
              </ListItem>
            </List>}
          </Box>

        </Toolbar>
      </Container>
    </AppBar>);
  }
}

const styles = theme => ({
  app_bar: {
    zIndex: 6666,
    // paddingTop: theme.spacing(3),
    backgroundColor: 'white !important',
    scrollMarginTop: 120
  },
  toolbar: {
    height: {xs: 73, md: 105},
    minHeight: {xs: 73, md: 105},
    display: 'flex',
  	flexDirection: 'column',
  	flexWrap: 'nowrap',
  	justifyContent: 'flex-start',
  	alignItems: 'stretch',
  	alignContent: 'stretch',
    paddingLeft: '0px !important',
    paddingRight: '0px !important'
  },
  logo_wrapper: {
    justifyContent: "flex-start",
    textAlign: "left",
    alignItems: "center",
    display: "flex",
    maxWidth: 200,
    marginLeft: 0
  },
  logo: {
    maxWidth: "80%",
    height: 50,
    objectFit: "contain",
    // marginBottom: 20,
    cursor: 'pointer'
  },
  top_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  profile_box: {
    textAlign: 'right',
    flexGrow: 1,
    height: 50,
    marginLeft: 'auto'
  },
  center_wrapper: {
    width: '100%',
    display: "flex",
    height: 50,
    justifyContent: "flex-start",
    alignItems: "center"
  },
  app_bar_title: {
    // flexGrow: 1,
    marginLeft: -12,
    marginRight: 20
  },
  menu_button: {
    marginLeft: -12,
    marginRight: 20
  },
  profile_button: {
    marginLeft: 10,
    marginRight: -12
  },
  inputRoot: {
    width: '100%'
  },
  menu_link: {
    cursor: 'pointer'
  },
  is_active: {
    borderBottom: `5px solid ${'primary.main'}`
  },
  is_inactive: {
    borderBottom: `5px solid transparent`,
    marginBottom: '-5px'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Header))));
