import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from "../parts/PurifiedTypo";

class BlockText extends Component {
  render() {
    const {block, theme, locale} = this.props;

    return (<React.Fragment>
      {!!block.extra_data && !!block.extra_data.text && !!block.extra_data.text.content && block.extra_data.text.content[locale] && <Grid item xs={12} sx={{...(block.extra_data?.border_color ? {paddingLeft: 0.5, paddingRight: 0.5, border: `1px solid ${block.extra_data.border_color}`} : {})}}>
        <PurifiedTypo text={block.extra_data.text.content[locale]} />
      </Grid>}
    </React.Fragment>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(BlockText));
