import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from '../../shared/parts/PurifiedTypo';
import StyledButton from '../../shared/parts/StyledButton';
import StyledTextField from '../../shared/parts/StyledTextField';
import Typography from '../../shared/parts/Typography';

class StepEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state !== prevProps.state) {
      this.setState(this.props.state);
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Grid container>
      <Grid item xs={1} sm={2} md={4}></Grid>
      <Grid item container xs={10} sm={8} md={4} sx={{minHeight: '300px', paddingLeft: {xs: 0, md: 1.5}}}>
        <Grid item xs={12} sx={{marginTop: 4, marginBottom: 1}}>
          <Typography variant="h3">{t("first_time_step_4_message").replace(/<[^>]*>?/gm, '')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <PurifiedTypo text={t("first_time_step_4_message_description").replace(/<[^>]*>?/gm, '')} />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            label={t("first_time_step_4_field_email").replace(/<[^>]*>?/gm, '')}
            value={this.state.user.email}
            id="email"
            onChange={(v) => {this.setState({user: {...this.state.user, email: v}}, () => {this.props.onChangeState(this.state)});}}
            sx={{marginTop: this.props.theme.spacing(2)}}
            error={this.state.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            label={t("first_time_step_4_field_redo_email").replace(/<[^>]*>?/gm, '')}
            value={this.state.user.email_repeat}
            id="email_repeat"
            onChange={(v) => {this.setState({user: {...this.state.user, email_repeat: v}}, () => {this.props.onChangeState(this.state)});}}
            sx={{marginTop: this.props.theme.spacing(2)}}
            error={this.state.errors.email_repeat}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledButton disabled={this.state.user.email !== this.state.user.email_repeat} text={t("first_time_step_4_button_continue").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{alignSelf: 'flex-end', marginTop: this.props.theme.spacing(4)}} onClick={(e) => {this.props.OnCheckEmail(e);}} />
        </Grid>
        <Grid item xs={12}>
          <StyledButton text={t("first_time_step_4_button_back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{float: 'right', marginTop: this.props.theme.spacing(4)}} secondary onClick={(e) => {this.setState({first_time_step: 2}, () => {this.props.onChangeState(this.state)});}} />
        </Grid>
      </Grid>
      <Grid item xs={1} sm={2} md={4}></Grid>
    </Grid>);
  }
}

StepEmail.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(StepEmail))));
