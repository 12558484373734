import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from "../parts/PurifiedTypo";

class BlockCol2ImageTitleText extends Component {
  render() {
    const {block, theme, locale} = this.props;

    return (<React.Fragment>
      <Grid item container xs={12} md={6}>
        {!!block.extra_data && !!block.extra_data.text && !!block.extra_data.text.title && block.extra_data.text.title[locale] && <Grid item xs={12}>
          <PurifiedTypo text={block.extra_data.text.title[locale]} />
        </Grid>}
        {!!block.extra_data && !!block.extra_data.text && !!block.extra_data.text.content && block.extra_data.text.content[locale] && <Grid item xs={12}>
          <PurifiedTypo text={block.extra_data.text.content[locale]} />
        </Grid>}
      </Grid>
      <Grid item container xs={12} md={6}>
        {!!block.extra_data && !!block.extra_data.text && !!block.extra_data.text.title2 && block.extra_data.text.title2[locale] && <Grid item xs={12}>
          <PurifiedTypo text={block.extra_data.text.title2[locale]} />
        </Grid>}
        {!!block.extra_data && !!block.extra_data.text && !!block.extra_data.text.content2 && block.extra_data.text.content2[locale] && <Grid item xs={12}>
          <PurifiedTypo text={block.extra_data.text.content2[locale]} />
        </Grid>}
      </Grid>
    </React.Fragment>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles)(BlockCol2ImageTitleText));
