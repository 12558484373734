import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';

import {
  Box
} from '@mui/material';

import {DateTime} from "luxon";

import Typography from "./Typography";

class ItemBlock extends Component {
  render() {
    const {created_at, tag_name, image_url, name, lines, theme} = this.props;
    let x_lines = 3;
    if (!!lines) x_lines = lines;

    let line_height = x_lines * 23;

    return (<Box sx={{
      width: '100%',
      '-webkit-transition': 'all 0.25s cubic-bezier(0, 0.1, 0.3, 1)',
      transition: 'all 0.25s cubic-bezier(0, 0.1, 0.3, 1)',
      position: 'relative',
      height: `${340 - 66 + line_height}px`,
      backgroundColor: '#fff',
      '-webkit-box-shadow': '0px 0px 10px 4px rgba(0, 0, 0, 0.07)',
      '-moz-box-shadow': '0px 0px 10px 4px rgba(0, 0, 0, 0.07)',
      boxShadow: '0px 0px 10px 4px rgba(0, 0, 0, 0.07)',
      cursor: 'pointer',
      border: `1px solid rgba(0, 0, 0, 0.1)`
    }}>
      {!!created_at && <Box sx={{
        display: 'flex',
        justifyContent: 'left',
        '-webkit-box-align': 'left',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 10,
        height: '40px',
        paddingLeft: '20px',
        paddingRight: '20px',
        color: theme.palette.primary.main,
        fontSize: '12px'
      }}>{DateTime.fromISO(created_at).toLocaleString(DateTime.DATE_SHORT)}</Box>}
      {!!tag_name && <Box sx={{
        display: 'flex',
        justifyContent: 'right',
        '-webkit-box-align': 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        height: '40px',
        paddingRight: '20px',
        paddingLeft: '20px',
        backgroundColor: `rgba(16, 81, 86, 0.6)`,
        color: '#fff',
        fontSize: '12px',
        fontWeight: 700
      }}>{tag_name}</Box>}
      <Box sx={{
        height: '217px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: `url('${image_url}')`
      }}></Box>
      <Box sx={{
        height: `${123 - 66 + line_height}px`,
        padding: '17px 30px',
        overflow: 'hidden',
        position: 'absolute',
        width: '100%',
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'end',
        paddingBottom: '40px'
      }}>
        <Typography variant="h5" sx={{
          fontSize: '18px',
          fontWeight: 700,
          height: `${line_height}px`,
          display: '-webkit-box',
          color: theme.palette.tertiary.main,
          '-webkit-line-clamp': '3',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
          lineHeight: 1.3,
        }}>{name.length > 185 ? `${name.substring(0, 815)}...` : name}</Typography>
      </Box>
    </Box>);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(ItemBlock));
