import DeathMessage from '../actions/death_message';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function death_message(state = initial, action) {
  return DeathMessage.state_switch(state, action);
}
