import React from "react";
import numeral from 'numeral';
import 'numeral/locales';

numeral.register('locale', 'nl', {
  delimiters: {
    thousands: ' ',
    decimal  : ','
  },
  abbreviations: {
    thousand : 'k',
    million  : ' mln',
    billion  : ' mld',
    trillion : ' bln'
  },
  ordinal : function (number) {
    var remainder = number % 100;

    return ((number !== 0 && remainder <= 1) || remainder === 8 || remainder >= 20) ? 'ste' : 'de';
  },
  currency: {
    symbol: '€ '
  }
});

const Context = React.createContext();

// create Context.Provider : the value prop is our toolbox
export const LocalizedNumeral = props => {
  return (
    <Context.Provider value={(...args) => {numeral.locale(props.i18n ? props.i18n.language.split("-")[0] : 'nl'); return numeral(...args);}}>
      {props.children}
    </Context.Provider>
  );
};

// a higher order component to save us the hassle of calling Context.Consumer
export function withNumeralLocalization(Component) {
  return function LocalizedComponent(props) {
    return (
      <Context.Consumer>
        {context => {
          return (<Component {...props} numeral={context}/>);
        }}
      </Context.Consumer>
    );
  };
}
