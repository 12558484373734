import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../../localizationContext';
import PropTypes from 'prop-types';

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';

import PurifiedTypo from "../../../shared/parts/PurifiedTypo";

class SlideNormal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes, ...other } = this.props;
    const {t, question, hidden, locale, is_help_visible, value, onChange} = other;

    return (<React.Fragment>
      <Grid xs={12} sx={{display: !!hidden ? 'none' : 'block'}}>
        <img src={question.image_url[locale]} className={classes.slide_image} alt="" />
        {!!question.top_info[locale] && <PurifiedTypo text={question.top_info[locale]} />}
        <PurifiedTypo text={question.question[locale]} className={classes.profile_name} />
        {!!is_help_visible && <PurifiedTypo text={question.toelichting[locale]} className={classes.is_toelichting} sx={{color: '#1f92b8'}} />}
        {!!question.bottom_info[locale] && <PurifiedTypo text={question.bottom_info[locale]} />}
      </Grid>
      {parseInt(question.quorum, 10) > 0 && <Grid xs={12} sx={{display: !!hidden ? 'none' : 'block', marginTop: 2}} align="center">
        <FormControl>
          <RadioGroup row value={value} onChange={onChange}>
            <FormControlLabel value={1} control={<Radio />} label={t("meeting.online.btn.yes").replace(/<[^>]*>?/gm, '')} />
            <FormControlLabel value={0} control={<Radio />} label={t("meeting.online.btn.no").replace(/<[^>]*>?/gm, '')} />
            <FormControlLabel value={2} control={<Radio />} label={t("meeting.online.btn.blank").replace(/<[^>]*>?/gm, '')} />
          </RadioGroup>
        </FormControl>
      </Grid>}
    </React.Fragment>);
  }
}

SlideNormal.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  slide_image: {
    maxHeight: 300,
    maxWidth: '100%'
  },
  profile_name: {
    fontWeight: 'bold',
    fontSize: '1.8em'
  },
  is_toelichting: {
    fontSize: '0.8em !important',
    fontStyle: 'italic'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(SlideNormal))));
