import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Grid
} from '@mui/material';

import Page from "../shared/Page";
import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import Typography from "../shared/parts/Typography";

class Faq extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Page {...other} style={{paddingTop: this.props.theme.spacing(8)}}>
      <Container sx={{padding: {xs: 0, md: 2}}}>
        <Grid container spacing="2">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              className={classes.component_title}
              sx={{textAlign: 'center'}}
            >
              {t(`generic_errors.itsme.title`).replace(/<[^>]*>?/gm, '')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
            <PurifiedTypo text={t("generic_errors.itsme.description")} />
          </Grid>
          <Grid item xs={12} sx={{marginTop: 2}} align="center">
            <StyledButton
              is_red
              onClick={(e) => {
                this.props.history.push('/emission', {
                  step: 1,
                  profile_step: {
                    picker_step: 1,
                    user_mode: 2,
                    use_itsme: 0
                  }
                });
              }}
              text={t("generic_errors.itsme.cta_retry")}
            />
          </Grid>
          <Grid item xs={12} sx={{marginTop: 2}}>&nbsp;</Grid>
        </Grid>
      </Container>
    </Page>);
  }
}

Faq.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  component_title: {
    // textTransform: 'uppercase',
    fontWeight: '700 !important'
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Faq))));
