import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Grid
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import CountryPicker from '../../shared/parts/CountryPicker';
import PurifiedTypo from '../../shared/parts/PurifiedTypo';
import StyledButton from '../../shared/parts/StyledButton';
import StyledTextField from '../../shared/parts/StyledTextField';
import Typography from '../../shared/parts/Typography';

class StepContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.state !== prevProps.state) {
      this.setState(this.props.state);
    }
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<Grid container>
      <Grid item xs={1} sm={2} md={3}></Grid>
      <Grid item container xs={10} sm={8} md={4} sx={{minHeight: '300px', paddingLeft: {xs: 0, md: 1.5}}}>
        <Grid item xs={12} sx={{marginTop: 4, marginBottom: 1}}>
          <Typography variant="h3">{t("first_time_step_3_message_address").replace(/<[^>]*>?/gm, '')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <PurifiedTypo text={t("first_time_step_3_message_address_description").replace(/<[^>]*>?/gm, '')} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{marginTop: 1}}>{t("first_time_step_3_message_address_we_have").replace(/<[^>]*>?/gm, '')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {!this.state.edit_address && <React.Fragment>
            <Typography sx={{marginTop: 2}}>{t("onboarding.register.actual_address").replace(/<[^>]*>?/gm, '')}</Typography>
            <Typography sx={{fontWeight: 'bold'}}>{this.state.user.street} {this.state.user.number}</Typography>
            <Typography sx={{fontWeight: 'bold'}}>{this.state.user.zip} {this.state.user.city}</Typography>
            {!(!!this.state.user.country && this.state.user.country === "BE") && <Typography sx={{fontWeight: 'bold'}}>{this.state.user.country}</Typography>}
            <StyledButton
              secondary
              as_link
              startIcon={<EditIcon />}
              text={t("onboarding.register.address_update_cta").replace(/<[^>]*>?/gm, '')}
              theme={this.props.theme}
              sx={{paddingLeft: 0, paddingRight: 0, marginRight: 'auto'}}
              onClick={(e) => {this.setState({edit_address: true}, () => {this.props.onChangeState(this.state)});}}
            />
          </React.Fragment>}
        </Grid>
        {!!this.state.edit_address && <React.Fragment>
          <Grid item xs={12}>
            <Typography sx={{marginTop: 2}}>{t("onboarding.register.edit_actual_address").replace(/<[^>]*>?/gm, '')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: this.props.theme.spacing(2)}}>
              <StyledTextField
                label={t("first_time_step_3_field_street").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.street}
                id="street"
                onChange={(v) => {this.setState({user: {...this.state.user, street: v}}, () => {this.props.onChangeState(this.state)});}}
                error={this.state.errors.street}
                sx={{width: '78%'}}
              />
              <StyledTextField
                label={t("first_time_step_3_field_number").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.number}
                id="number"
                onChange={(v) => {this.setState({user: {...this.state.user, number: v}}, () => {this.props.onChangeState(this.state)});}}
                error={this.state.errors.number}
                sx={{width: '20%', marginLeft: 'auto'}}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: this.props.theme.spacing(2)}}>
              <StyledTextField
                label={t("first_time_step_3_field_zip").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.zip}
                id="zip"
                onChange={(v) => {this.setState({user: {...this.state.user, zip: v}}, () => {this.props.onChangeState(this.state)});}}
                error={this.state.errors.zip}
                sx={{width: '20%'}}
              />
              <StyledTextField
                label={t("first_time_step_3_field_city").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.city}
                id="city"
                onChange={(v) => {this.setState({user: {...this.state.user, city: v}}, () => {this.props.onChangeState(this.state)});}}
                error={this.state.errors.city}
                sx={{width: '78%', marginLeft: 'auto'}}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CountryPicker
              label={t("first_time_step_3_field_country").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.country}
              id="country"
              onChange={(v) => {this.setState({user: {...this.state.user, country: v.target.value}}, () => {this.props.onChangeState(this.state)});}}
              sx={{marginTop: this.props.theme.spacing(2)}}
              error={this.state.errors.country}
            />
          </Grid>
        </React.Fragment>}
        <Grid item xs={12}>

          {!this.state.edit_cor && this.state.user.cor_street && this.state.user.cor_number && this.state.user.cor_zip && this.state.user.cor_city && this.state.user.cor_country && <React.Fragment>
            <Typography sx={{marginTop: 2}}>{t("onboarding.register.cor_address").replace(/<[^>]*>?/gm, '')}</Typography>
            {!!this.state.user.cor_street_2 && <Typography sx={{fontWeight: 'bold'}}>{this.state.user.cor_street_2}</Typography>}
            <Typography sx={{fontWeight: 'bold'}}>{this.state.user.cor_street} {this.state.user.cor_number}</Typography>
            <Typography sx={{fontWeight: 'bold'}}>{this.state.user.cor_zip} {this.state.user.cor_city}</Typography>
            {!(!!this.state.user.cor_country && this.state.user.cor_country === "BE") && <Typography sx={{fontWeight: 'bold'}}>{this.state.user.cor_country}</Typography>}
            <StyledButton
              secondary
              as_link
              startIcon={<EditIcon />}
              text={t("onboarding.register.cor_address_update_cta").replace(/<[^>]*>?/gm, '')}
              theme={this.props.theme}
              sx={{paddingLeft: 0, paddingRight: 0, marginRight: 'auto'}}
              onClick={(e) => {this.setState({edit_cor: true}, () => {this.props.onChangeState(this.state)});}}
            />
          </React.Fragment>}
        </Grid>
        <Grid item xs={12}>

          {!this.state.edit_cor && !(this.state.user.cor_street && this.state.user.cor_number && this.state.user.cor_zip && this.state.user.cor_city && this.state.user.cor_country) && <React.Fragment>
            <Typography sx={{marginTop: 2}}>{t("onboarding.register.cor_address_create_message").replace(/<[^>]*>?/gm, '')}</Typography>
            <StyledButton text={t("onboarding.register.cor_address_create_cta").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{alignSelf: 'center', marginTop: this.props.theme.spacing(1), marginRight: 'auto'}} secondary onClick={(e) => {this.setState({edit_cor: true}, () => {this.props.onChangeState(this.state)});}} />
          </React.Fragment>}
        </Grid>
        <Grid item xs={12}>

          {!!this.state.edit_cor && <React.Fragment>
            <Typography sx={{marginTop: 2}}>{t("onboarding.register.edit_cor_address").replace(/<[^>]*>?/gm, '')}</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: this.props.theme.spacing(2)}}>
              <StyledTextField
                label={t("first_time_step_3_field_street_2").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.cor_street_2}
                id="cor_street_2"
                onChange={(v) => {this.setState({user: {...this.state.user, cor_street_2: v}}, () => {this.props.onChangeState(this.state)});}}
                error={this.state.errors.cor_street_2}
              />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: this.props.theme.spacing(2)}}>
              <StyledTextField
                label={t("first_time_step_3_field_street").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.cor_street}
                id="cor_street"
                onChange={(v) => {this.setState({user: {...this.state.user, cor_street: v}}, () => {this.props.onChangeState(this.state)});}}
                error={this.state.errors.cor_street}
                sx={{width: '78%'}}
              />
              <StyledTextField
                label={t("first_time_step_3_field_number").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.cor_number}
                id="cor_number"
                onChange={(v) => {this.setState({user: {...this.state.user, cor_number: v}}, () => {this.props.onChangeState(this.state)});}}
                error={this.state.errors.cor_number}
                sx={{width: '20%', marginLeft: 'auto'}}
              />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: this.props.theme.spacing(2)}}>
              <StyledTextField
                label={t("first_time_step_3_field_zip").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.cor_zip}
                id="cor_zip"
                onChange={(v) => {this.setState({user: {...this.state.user, cor_zip: v}}, () => {this.props.onChangeState(this.state)});}}
                error={this.state.errors.cor_zip}
                sx={{width: '20%'}}
              />
              <StyledTextField
                label={t("first_time_step_3_field_city").replace(/<[^>]*>?/gm, '')}
                value={this.state.user.cor_city}
                id="cor_city"
                onChange={(v) => {this.setState({user: {...this.state.user, cor_city: v}}, () => {this.props.onChangeState(this.state)});}}
                error={this.state.errors.cor_city}
                sx={{width: '78%', marginLeft: 'auto'}}
              />
            </Box>
            <CountryPicker
              label={t("first_time_step_3_field_country").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.cor_country}
              id="cor_country"
              onChange={(v) => {this.setState({user: {...this.state.user, cor_country: v.target.value}}, () => {this.props.onChangeState(this.state)});}}
              sx={{marginTop: this.props.theme.spacing(2)}}
              error={this.state.errors.cor_country}
            />
          </React.Fragment>}
        </Grid>
        <Grid item xs={12}>

          {!this.state.edit_phones && <React.Fragment>
            <Typography sx={{marginTop: 2}}>{t("onboarding.register.telephony").replace(/<[^>]*>?/gm, '')}</Typography>
            <Typography sx={{marginTop: 2}}>{t("first_time_step_3_field_phone").replace(/<[^>]*>?/gm, '')}</Typography>
            <Typography sx={{fontWeight: 'bold'}}>{this.state.user.phone}</Typography>
            <Typography sx={{marginTop: 2}}>{t("first_time_step_3_field_mobile").replace(/<[^>]*>?/gm, '')}</Typography>
            <Typography sx={{fontWeight: 'bold'}}>{this.state.user.mobile}</Typography>
            <StyledButton
              secondary
              as_link
              startIcon={<EditIcon />}
              text={t("onboarding.register.phone_update_cta").replace(/<[^>]*>?/gm, '')}
              theme={this.props.theme}
              sx={{paddingLeft: 0, paddingRight: 0, marginRight: 'auto'}}
              onClick={(e) => {this.setState({edit_phones: true}, () => {this.props.onChangeState(this.state)});}}
            />
          </React.Fragment>}
        </Grid>
        <Grid item xs={12}>

          {!!this.state.edit_phones && <React.Fragment>
            <Typography sx={{marginTop: 2}}>{t("onboarding.register.telephony").replace(/<[^>]*>?/gm, '')}</Typography>
            <StyledTextField
              label={t("first_time_step_3_field_phone").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.phone}
              id="phone"
              onChange={(v) => {this.setState({user: {...this.state.user, phone: v}}, () => {this.props.onChangeState(this.state)});}}
              error={this.state.errors.phone}
              sx={{marginTop: this.props.theme.spacing(2)}}
            />
            <StyledTextField
              label={t("first_time_step_3_field_mobile").replace(/<[^>]*>?/gm, '')}
              value={this.state.user.mobile}
              id="mobile"
              onChange={(v) => {this.setState({user: {...this.state.user, mobile: v}}, () => {this.props.onChangeState(this.state)});}}
              error={this.state.errors.mobile}
              sx={{marginTop: this.props.theme.spacing(2)}}
            />
          </React.Fragment>}
        </Grid>

        <Grid item xs={12}>
          <StyledButton text={t("first_time_step_3_button_continue").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{float: 'right', marginTop: this.props.theme.spacing(4)}} onClick={(e) => {this.props.OnCheckAddress(e);}} />
        </Grid>
        <Grid item xs={12}>
          <StyledButton text={t("first_time_step_3_button_back").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} sx={{float: 'right', marginTop: this.props.theme.spacing(4)}} secondary onClick={(e) => {this.setState({first_time_step: 1}, () => {this.props.onChangeState(this.state)});}} />
        </Grid>
      </Grid>
      <Grid item xs={1} sm={2} md={3}></Grid>
    </Grid>);
  }
}

StepContact.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(StepContact))));
