import Benefit from '../actions/benefit';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function benefit(state = initial, action) {
  return Benefit.state_switch(state, action);
}
