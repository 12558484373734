import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../../../localizationContext';
import PropTypes from 'prop-types';

import {
  Grid
} from '@mui/material';

import PurifiedTypo from "../../../shared/parts/PurifiedTypo";
import StyledButton from "../../../shared/parts/StyledButton";

class EmissionStepProfileChoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state
    };
  }

  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    return (<React.Fragment>
      {/* <Grid item xs={12} sx={{marginTop: 2}}>
        <PurifiedTypo sx={{marginBottom: 2}} text={t("emission.steps.profile.cta_step_0.intro")} />
      </Grid> */}
      <Grid item xs={12} sx={{marginTop: 4}} align="center">
        <StyledButton
          onClick={() => {
            this.setState({profile_step: {...this.state.profile_step, picker_step: 1, user_mode: 1}}, () => {
              this.props.onChangeState(this.state);
              if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                this.props.helpers.web_log.do_refresh({
                  locale: this.props.i18n.language,
                  callback: (r, full_data) => {
                    window.argenco_uuid = r;
                  },
                  parameters: {
                    id: this.props.login?.id,
                    uuid: window.argenco_uuid,
                    u: window.location.pathname,
                    r: document.referrer,
                    a: 'button_click',
                    d: 'emission.for_me.existing',
                    s: window.argenco_source,
                    ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                  }
                });
              }
              window.scrollTo(0, 0);
            });
          }}
          text={t("emission.steps.profile.cta_step_0.existing")}
        />
      </Grid>
      <Grid item xs={12} sx={{marginTop: 1}} align="center">
        <StyledButton
          onClick={() => {
            this.setState({profile_step: {...this.state.profile_step, picker_step: 1, user_mode: 2}}, () => {
              this.props.onChangeState(this.state);
              if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
                this.props.helpers.web_log.do_refresh({
                  locale: this.props.i18n.language,
                  callback: (r, full_data) => {
                    window.argenco_uuid = r;
                  },
                  parameters: {
                    id: this.props.login?.id,
                    uuid: window.argenco_uuid,
                    u: window.location.pathname,
                    r: document.referrer,
                    a: 'button_click',
                    d: 'emission.for_me.new',
                    s: window.argenco_source,
                    ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
                  }
                });
              }
              window.scrollTo(0, 0);
            });
          }}
          text={t("emission.steps.profile.cta_step_0.new")}
        />
      </Grid>
    </React.Fragment>);
  }
}

EmissionStepProfileChoice.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({

});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(EmissionStepProfileChoice))));
