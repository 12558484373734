import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import CssBaseline from '@mui/material/CssBaseline';
import {Route, Switch, Redirect} from "react-router-dom";

import {withStyles} from '@mui/styles';
import {Helmet} from "react-helmet";

import {
  Box
} from '@mui/material';

import About from "../containers/pages/About";
import Av from "../containers/pages/Av";
import Benefits from "../containers/pages/Benefits";
import BenefitsItem from "../containers/pages/BenefitsItem";
import Contact from "../containers/pages/Contact";
import ContentPage from "../containers/pages/Content";
import EmissionLanding from "../containers/pages/emission/Landing";
import Exit from "../containers/pages/Exit";
import Faq from "../containers/pages/Faq";
import Footer from "../containers/shared/Footer";
import GenericError from "../components/pages/GenericError";
import GenericSuccess from "../components/pages/GenericSuccess";
import Header from "../containers/shared/Header";
import Homepage from "../containers/pages/Homepage";
import Investments from "../containers/pages/Investments";
import InvestmentItem from "../containers/pages/InvestmentItem";
import LandingPage from "../containers/pages/LandingPage";
import News from "../containers/pages/News";
import NewsItem from "../containers/pages/NewsItem";
import Onboarding from "../containers/pages/Onboarding";
import PhysicalChoice from "../containers/pages/meeting/PhysicalChoice";
import Raffle from "../containers/pages/Raffle";
import Profile from "../containers/pages/Profile";
import ReportDeath from "../containers/pages/ReportDeath";
import Transfer from "../containers/pages/Transfer";
import Voting from "../containers/pages/meeting/Voting";

const queryString = require('query-string');

class Main extends Component {
  constructor(props) {
    super(props);

    let is_admin = false;
    if (props.login && props.login.type === "User::Admin") is_admin = true;

    this.state = {
      is_admin: is_admin
    }
  }

  componentDidMount() {
    if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1)) {
      const parsed = queryString.parse(this.props.location.search);
      if (!!parsed.utm_source) {
        window.argenco_source = parsed.utm_source;
      } else {
        window.argenco_source = undefined;
      }

      // if (this.props.location.pathname.indexOf("/landing/") > -1) {
      //   // console.log(this.props.location.pathname.split("?")[0].split("/").reverse()[0]);
      //   window.argenco_source = this.props.location.pathname.split("?")[0].split("/").reverse()[0];
      // }

      this.props.helpers.web_log.do_refresh({
        locale: this.props.i18n.language,
        callback: (r, full_data) => {
          window.argenco_uuid = r;
        },
        parameters: {
          id: this.props.login?.id,
          uuid: window.argenco_uuid,
          u: window.location.pathname,
          r: document.referrer,
          a: 'page_load',
          d: '',
          s: window.argenco_source,
          ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
        }
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('staging.') > -1) && this.props.location !== prevProps.location) {
      if (this.props.location.pathname.indexOf("/landing/") > -1) {
        // console.log(this.props.location.pathname.split("?")[0].split("/").reverse()[0]);
        window.argenco_source = this.props.location.pathname.split("?")[0].split("/").reverse()[0];
      }

      this.props.helpers.web_log.do_refresh({
        locale: this.props.i18n.language,
        callback: (r, full_data) => {
          window.argenco_uuid = r;
        },
        parameters: {
          id: this.props.login?.id,
          uuid: window.argenco_uuid,
          u: window.location.pathname,
          r: prevProps.location?.pathname,
          a: 'page_change',
          d: '',
          s: window.argenco_source,
          ds: (window.innerWidth < 600 ? 'xs' : (window.innerWidth < 900 ? 'sm' : (window.innerWidth < 1200 ? 'md' : (window.innerWidth < 1536 ? 'lg' : 'xl'))))
        }
      });
    }
  }

  render() {
    const {classes} = this.props;

    document.documentElement.lang = this.props.i18n.language;

    // let menu = [];
    // if (!this.state.is_admin) {
    //   menu = [
    //     {title: t("menu.news"), path: "/", exact: true},
    //     {title: t("menu.benefits"), path: "/", exact: true},
    //     {title: t("menu.about"), path: "/", exact: true},
    //     {title: t("menu.contact"), path: "/", exact: true}
    //   ];
    // }
    // if (this.state.is_admin) {
    //   menu = [
    //     {title: t("menu.home"), path: "/", exact: true},
    //     {title: t("general.actions.users"), path: "/client/1/users", exact: true}
    //   ];
    // }

    return (<React.Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="google" content="notranslate" />
        <meta name="robots" content="notranslate" />
        <meta name="googlebot" content="notranslate" />
        <title>Argen-Co</title>
        <meta property="og:url" content="https://www.argenco.be/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Argenta Coöperatieve CV" />
        <meta property="og:description" content="Argen-co investeert vandaag in lokale, duurzame projecten die onze wereld voorbereiden op de uitdagingen van morgen. Onze vennoten helpen ons daarbij, alle 60.000." />
        <meta property="og:image" content="https://old.argenco.be/cdn/support/assets/items/000/000/744/original/jumbotron.jpg" />
      </Helmet>
      <CssBaseline></CssBaseline>
      <div className={classes.root}>
        <Header/>
        {/* <Header menu={menu}></Header> */}
        <Box className={classes.content} id="scroller" sx={{paddingTop: {xs: '45px', md: '75px'}}}>
          <div className={classes.toolbar}></div>

          <Switch>
            <Redirect from="/faqs" to="/faq"/>
            <Redirect from="/cancellation" to="/exit"/>
            <Redirect from="/cancellation_over" to="/exit"/>
            <Redirect from="/overdracht" to="/transfer"/>
            <Redirect from="/emissions/subscribe_new" to="/emission"/>
            <Redirect from="/emissions/subscribe" to="/emission"/>
            <Redirect from="/about/jaarverslag" to="/about/documents"/>
            {/* {!!this.props.login && <Redirect from="/av" to="/profile"/>} */}

            <Route path='/administration' component={() => {
              window.location.href = 'https://old.argenco.be/administration';
              return null;
            }} />
            <Route path='/prospectus' component={() => {
              window.location.href = 'https://cdn.argenco.be/J3XabQCugiBZ3D9Bu6qDu2Uf';
              return null;
            }} />

            <Route exact={true} path='/' component={Homepage}/>
            {/* <Route exact={true} path='/about' component={About}/> */}
            <Route exact={true} path='/av' component={Av}/>
            <Route exact={true} path='/benefits' component={Benefits}/>
            <Route exact={true} path='/benefits/:id' component={BenefitsItem}/>
            <Route exact={true} path='/contact' component={Contact}/>
            <Route exact={true} path='/emissions/stocks/:id' component={EmissionLanding} key="landing_stocks"/>
            <Route exact={true} path='/emissions/registered/:sub' component={EmissionLanding} key="landing_registered"/>
            <Route exact={true} path='/emission' component={EmissionLanding} key="landing_base"/>
            <Route exact={true} path='/exit' component={Exit}/>
            <Route exact={true} path='/faq' component={Faq}/>
            <Route exact={true} path='/about/investments' component={Investments}/>
            <Route exact={true} path='/about/investment/:id' component={InvestmentItem}/>
            <Route exact={true} path='/landing/:key' component={LandingPage}/>
            <Route exact={true} path='/news' component={News}/>
            <Route exact={true} path='/news/:id' component={NewsItem}/>
            <Route exact={true} path='/news_items/:id' component={NewsItem}/>
            {!this.props.login && <Route exact={true} path='/login' component={Onboarding}/>}
            {!!this.props.login && <Route exact={true} path='/profile' component={Profile}/>}
            <Route exact={true} path='/overlijden' component={ReportDeath}/>
            <Route exact={true} path='/raffle/:id' component={Raffle}/>
            <Route exact={true} path='/stock/why' component={ContentPage}/>
            <Route exact={true} path='/stock/how' component={ContentPage}/>
            <Route exact={true} path='/stock/transfer' component={ContentPage}/>
            {/* <Route exact={true} path='/stock/prospectus' component={ContentPage}/> */}
            <Route exact={true} path='/about/history_mission' component={ContentPage}/>
            <Route exact={true} path='/about/team' component={ContentPage}/>
            <Route exact={true} path='/about/documents' component={ContentPage}/>
            <Route exact={true} path='/transfer' component={Transfer}/>
            <Route exact={true} path='/meeting/attend' component={PhysicalChoice}/>
            <Route exact={true} path='/meeting/voting' component={Voting}/>

            <Redirect from="/about" to="/landing/Emissie2023"/>

            <Route exact={true} path='/error' component={GenericError}/>
            <Route exact={true} path='/success' component={GenericSuccess}/>

            {!!this.props.login && <Redirect from="/meeting" to="/profile"/>}
            <Route component={() => (<Redirect to="/"/>)}/>
          </Switch>

          <Footer/>
        </Box>

      </div>
    </React.Fragment>);
  }
}

const styles = theme => ({
  root: {
    // display: "flex",
    // minHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  content: {
    // paddingTop: {xs: 50, md: 80} //105
  }
});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(Main));
