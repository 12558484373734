import { combineReducers } from 'redux';

import banner from './banner';
import benefit from './benefit';
import benefits_tag from './benefits_tag';
import contact from './contact';
import death_message from './death_message';
import devise from './devise';
import emission from './emission';
import faq from './faq';
import frontpager from './frontpager';
import investment from './investment';
import landing from './landing';
import meeting from './meeting';
import news from './news';
import news_tag from './news_tag';
import otp from './otp';
import security_question from './security_question';
import waiting_list from './waiting_list';
import web_log from './web_log';

const drawer = (state = {
  open: false
}, action) => {
  switch (action.type) {
    case 'DRAWER':
      return {...state, ...action.payload};
    default:
      return state
  }
};

export default combineReducers({
  drawer,

  banner,
  benefit,
  benefits_tag,
  contact,
  death_message,
  devise,
  emission,
  faq,
  frontpager,
  investment,
  landing,
  meeting,
  news,
  news_tag,
  otp,
  security_question,
  waiting_list,
  web_log
});
