import Contact from '../actions/contact';

const initial = {
  data: null,
  loading: false,
  error: null
};

export default function contact(state = initial, action) {
  return Contact.state_switch(state, action);
}
