import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';

import {
  InputAdornment,
  IconButton,
  TextField
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

var he = require('he');

class StyledTextField extends Component {
  render() {
    const {onChange} = this.props;

    return (<TextField
      variant="outlined"
      {...this.props}
      label={he.decode(this.props.label)}
      onChange={(e) => {onChange(e.target.value)}}
      sx={{width: '100%', ...(!!this.props.sx ? this.props.sx : {})}}
      InputProps={{
        endAdornment: !!this.props.error ?(<InputAdornment position="end">
          <ErrorIcon style={{color: '#d32f2f'}} />
        </InputAdornment>) : (!!this.props.onPwdVisibility ? this.props.type === "password" ? (<InputAdornment position="end">
          <IconButton
            onClick={() => {this.props.onPwdVisibility(true);}}
            onMouseDown={() => {this.props.onPwdVisibility(true);}}
          ><VisibilityIcon /></IconButton>
        </InputAdornment>) : (<InputAdornment position="end">
          <IconButton
            onClick={() => {this.props.onPwdVisibility(false);}}
            onMouseDown={() => {this.props.onPwdVisibility(false);}}
          ><VisibilityOffIcon /></IconButton>
        </InputAdornment>) : null)
      }}
    />);
  }
}

const styles = theme => ({

});

export default withTranslation('translations')(withStyles(styles, {withTheme: true})(StyledTextField));
