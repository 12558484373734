import { connect } from 'react-redux';
import Devise from '../../../actions/devise';
import Meeting from '../../../actions/meeting';
import PhysicalChoice from '../../../components/pages/meeting/PhysicalChoice';

const map_state_to_props = state => ({
  login: state.devise.data
})

const map_dispatch_to_props = dispatch => {
  return {
    helpers: {
      meetings: {
        fetch_all: _params => {
          dispatch(Meeting.fetch_all(_params));
        },
        do_save: _params => {
          dispatch(Meeting.do_save(_params));
        }
      }
    },
    renew_sign_in: _params => {
      dispatch(Devise.renew_sign_in(_params));
    }
  };
};

export default connect(
  map_state_to_props,
  map_dispatch_to_props
)(PhysicalChoice);
